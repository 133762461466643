import React, { useEffect, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import AuthContext from '../../../middlewares/AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Login = () => {
    const { t }     = useTranslation();
    const { token } = useContext(AuthContext);
    let   navigate  = useNavigate();

    useEffect(() => {
      console.log(token)
    }, [])
    
/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/************************************************************************************************************************/
    const signup = async (params) => { 
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        await axios.post(`${url}auth/signup`, params, {headers: headers})
			.then(async (response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response?.data?.response,
                });
                navigate('/');
			}).catch(err =>{
                if(err?.response?.data?.status === 'warning' && err?.response?.data?.message === null){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err?.response?.data?.response,
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err?.message,
                    })
                }
			});
    }
/*************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        password: Yup.string()
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&.¡,+¿()]+/, "One special character")
        .matches(/\d+/, "One number")
        .required(),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
/*************************************************************************************************************************/

    if (token) {
        return <Navigate to="/dashboard"/>;
    }

/*************************************************************************************************************************/
  return (
    <>
	        <div className='container-fluid mb-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-8 col-md-8 col-lg-6'>
                    <main className='mt-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                        <Formik
                                initialValues={{ 
                                    email: '', 
                                    password: '',
                                    passwordConfirm: '',
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => signup(values, actions)}
                            >
                                <Form>
                                <h1 className="h3 mb-3 mt-2 fw-normal text-center">{t('header.signup')}</h1>

                                <div className='mb-3'>
                                    <div className="form-floating">
                                        <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                        <label htmlFor="floatingInput">{t('login.email')}</label>
                                    </div>
                                    <ErrorMessage name="email" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                </div>

                                <div className='mb-3'>
                                    <div className="form-floating">
                                        <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                        <label htmlFor="floatingPassword">{t('login.password')}</label>
                                    </div>
                                    <ErrorMessage name="password" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                </div>

                                <div className="mb-3">
                                    <div className="form-floating">
                                        <Field type="password" className="form-control" id="floatingPassword2" placeholder="Confirm Password" name="passwordConfirm"/>
                                        <label htmlFor="floatingPassword2">{t('login.pass_confirm')}</label>
                                    </div>
                                    <ErrorMessage name="passwordConfirm" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                </div>


                                <button className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" type="submit">{t('header.signup')}</button>
                                <Link to="/login" className='link-primary'>
                                    <span>{t('login.login_account')}</span>
                                </Link>   
                                 
                                <p className="mt-5 mb-0 text-muted">&copy; 2017–2022</p>
                            </Form>
                        </Formik>
                    </main>
                </div>
            </div>
        </div>
	</>
  )
}

export default Login