import React, { useEffect, useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../middlewares/AuthContext';

const Link = () => {  
    let navigate = useNavigate();
/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { getHeaders }        = useContext(AuthContext)
    const { t }                 = useTranslation();
    const [loading, setloading] = useState(false);
    //Validate form
    const formSchema = Yup.object().shape({
        licenses: Yup.string()
            .trim()
            .min(25)
            .max(260)
            .required(t('utilities.is_a_required_field')),
    });
/************************************************************************************************************************/

    const linkLicenses = async(params, {resetForm}) => { 

        setloading(true);
        const licensesArray = params.licenses.split(/\r?\n/);
        const licenses = [];
        for (const license of licensesArray) {
            if(license !== '' && license.length > 20 && license.length <30){
                licenses.push(license.trim());
            }
        }
        console.log(licenses)
        const data = { licenses }
        // return;
        const headers = await getHeaders();
        // await axios.post(`${url}licenses/link`, params, {headers: headers})
        await axios.post(`${url}licenses/link`, data, {headers})
                   .then(res =>{
                        resetForm();
                        Swal.fire({
                            icon             : 'success',
                            title            : res.data.response,
                            showCloseButton  : false,
                            allowOutsideClick: false,
                            allowEscapeKey   : false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                return navigate("/licenses");
                            }
                          });
                        
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                            text: err?.response?.data?.message,
                        });
                   })
                   .then(() =>{
                        setloading(false);
                   })    

    }

/************************************************************************************************************************/
    return (
        <div className='row'>
            <div className="col-md-12">
                <div className="card card-shadow">
                    <div className="card-header">
                        <h5 className='text-center mb-0'>{t('licenses.link.link_licenses')}</h5>
                    </div>
                    <div className='card-body'>
                        <Formik
                            initialValues={{ 
                                licenses: '',
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, actions) => linkLicenses(values, actions)}
                        >
                            <Form>
                                <div className="row">
                                    <div className="col-md-2 order-md-last">
                                        <p className='text-muted mb-2'>{t('licenses.link.example')}:</p>
                                        <div className='border rounded p-3'>
                                            <div className='lh-lg'>
                                                <p className='mb-0'>XXXXXYYYYYZZZZZXXXXXYYYYY</p>
                                                <p className='mb-0'>XXXXXYYYYYZZZZZXXXXXYYYYY</p>
                                                <p className='mb-0'>XXXXXYYYYYZZZZZXXXXXYYYYY</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <p className='fw-bold mb-2'>{t('licenses.link.your_licenses')}:</p>
                                        <div className="form-floating">
                                            <Field 
                                                as="textarea"
                                                name="licenses"
                                                className="form-control lh-lg" 
                                                placeholder={t('licenses.link.your_licenses')} 
                                                id="floatingTextarea"
                                                style={{height: '300px'}}
                                            ></Field>
                                            <label htmlFor="floatingTextarea"></label>
                                            <ErrorMessage name="licenses" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='col-12 col-sm-12 col-md-4 mt-3'>
                                    <button 
                                        type='submit'
                                        className='btn btn-shadow btn-bd-primary fw-bold w-100'
                                    >
                                        <span>{t('licenses.link.send_licenses')}</span>
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Link