import React, { Fragment } from 'react'
import img_1 from '../../img/img_1.png'
import img_2 from '../../img/img_2.png'
import img_3 from '../../img/img_3.jpg'
import img_4 from '../../img/img_4.jpg'
import img_5 from '../../img/img_5.png'
import { useTranslation } from 'react-i18next';
import FAQS from './FAQS'
import { Link } from 'react-router-dom'
const Sections = () => {
	const { t } = useTranslation();
	
	return (
		<>
			<section className='container-fluid py-5 border-bottom border-5 border-secondary bg-gradient'>
				<div className='row align-items-center p-5 text-dark-emphasis'>
					<div className='col-md-6'>
						<h3 className='display-4'>{t('home.title2')} <span className='text-bs-primary'>{t('home.title2_2')}</span></h3>
						<p className='fs-4 text-justify'>
							{t('home.text2')}
						</p>
						<div className='text-center'>
							<Link to="https://labeldictate.com/text2barcode/" target='_blank' className='btn btn-lg btn-bd-primary fw-bold fs-3'>
								{t('utilities.download')}
							</Link>
						</div>
					</div>
					<div className='col-md-6'>
						<img src={img_5} className="img-fluid rounded" />
					</div>
				</div>
			</section>

			<section className='container-fluid py-5 border-bottom border-5 border-secondary bg-gradient-reverse'>
				<div className='row align-items-center p-5 text-dark-emphasis '>
					<div className='col-md-6'>
						<img src={img_3} className="img-fluid rounded" />
					</div>

					<div className='col-md-6'>
						<h3 className='display-4'>{t('home.title3')}<span className='text-bs-primary'>{t('home.title3_3')}</span></h3>
						<p className='fs-4 text-justify'>
							{t('home.text3')}
						</p>
						<div className='text-center'>
							<Link to="https://labeldictate.com/suitemobilepos/" target='_blank' className='btn btn-lg btn-bd-primary fw-bold fs-3'>
								{t('utilities.download')}
							</Link>
						</div>
					</div>
					
				</div>
			</section>

			<section className='container-fluid py-5 border-bottom border-5 border-secondary bg-gradient'>
				<div className='row align-items-center p-5 text-dark-emphasis'>
					<div className='col-md-6'>
						<h3 className='display-4'>{t('home.title4')} <span className='text-bs-primary'>{t('home.title4_4')}</span></h3>
						<p className='fs-4 text-justify'>
							{t('home.text4')}
						</p>
						<div className='text-center'>
							<Link to="https://web2barcode.labeldictate.com/" target='_blank' className='btn btn-lg btn-bd-primary fw-bold fs-3'>
								{t('utilities.go_to')}
							</Link>
						</div>
					</div>
					<div className='col-md-6'>
						<img src={img_1} className="img-fluid rounded" />
					</div>
				</div>
			</section>

			<FAQS/>
		</>
	)
}

export default Sections