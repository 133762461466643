import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCircleXmark, faFilePdf, faUpload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import CustomLoader from '../layouts/CustomLoader';
import Swal from 'sweetalert2';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";
import { Formik, Form, Field } from 'formik';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const Marketings = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url       = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)   
    //search
    const [_stripe, set_stripe]       = useState('');                               // --customer & Partner
    const [_startDate, _setStartDate] = useState(new Date('2023-01-01 00:00:01'));
    const [_endDate, _setEndDate]     = useState(new Date());

    const [startDate, setStartDate] = useState('2022-01-01');
    const [endDate, setEndDate]     = useState('2099-12-31');

    const [invoices, setinvoices] = useState(null)
    const btnInvoices             = useRef(null);
    const {t, i18n}               = useTranslation()
/***************************************************************************************************************************/
    const [data, setData]               = useState([]);
    const [loading, setLoading]         = useState(false);
    const [totalRows, setTotalRows]     = useState(0);
    const [perPage, setPerPage]         = useState(10);
    const [columns, setcolumns]         = useState([]);
    const paginationComponentOptions    = usePaginationComponentOptions();
/***************************************************************************************************************************/
  const getPayments = async (page) => {
  setLoading(true);
      const headers = await getHeaders();
      await axios.get(`${url}payments/marketing?page=${page}&per_page=${perPage}&stripe=${_stripe}&start_date=${startDate}&end_date=${endDate}`, {headers})
                  .then(res =>{
                      setData(res.data.message);
                      setTotalRows(res.data.total);
                      console.log(res.data);
                  })
                  .catch(err =>{
                      console.log(err)
                  })
                  .then(() =>{
                      setLoading(false)
                  });
  }
/***************************************************************************************************************************/
  const handlePageChange = page => {
      getPayments(page);
  };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/marketing?page=${page}&per_page=${newPerPage}&stripe=${_stripe}&start_date=${startDate}&end_date=${endDate}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                        
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const cancelSubscription = async (params) => { 
            console.log(params)
            Swal.fire({
                title: t('payments.marketing.remove'),
                text: t('payments.marketing.remove_txt'),
                showCancelButton: true,
                confirmButtonText: t('payments.marketing.unsubcribe'),
                customClass: {
                    confirmButton: 'btn btn-outline-danger',
                    cancelButton: 'btn btn-primary mx-2'
                },
                buttonsStyling: false,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            }).then(async (result) => {

                const data = { subscription: params }
                const headers = await getHeaders();
                await axios.post(`${url}marketings/unsubscribe`, data, {headers})
                            .then(res =>{
                                console.log(res.data.message)
                                Swal.fire({
                                    icon: 'success',
                                    title: res.data.response,
                                });
                                getPayments(1);
                                setResetPaginationToggle(!resetPaginationToggle)
                            })
                            .catch(err =>{
                                Swal.fire({
                                    icon: 'error',
                                    title: err?.response?.data?.response || err,
                                });      
                                console.log(err)
                            });
                });            
    }
/***************************************************************************************************************************/
    const getInvoices = (pi) => { 
        console.log(pi)
        setinvoices( pi )
        btnInvoices.current.click()
    }

/***************************************************************************************************************************/
  const marketings = () => { 
      const result = [
          {
              maxWidth: "25%",
              name: t('payments.marketing.stripe_data'),
              cell: row => {
                  return  <>
                              <ul className="ps-0 my-2">
                                  <li className='list-group-item pb-1 mb-1 border-bottom'>
                                      <b>Checkout ID: </b>
                                      <span>
                                          {row?.cs_stripe_id}
                                      </span>
                                  </li> 
                                  <li className='list-group-item pb-1 mb-1 bottom'>
                                      <p className='mb-0'><b>Payment intent ID: </b>{row?.pi_stripe_id}</p>
                                  </li>                             
                              </ul>   
                          </>
              },
          },
          {
              maxWidth: "10%",
              name: t('payments.marketing.status'),
              cell: (row, index, column, id) => {                   
                  return  <>
                          {
                              (row.status_suscription === 'active')
                              ?   <div className='badge text-bg-primary'>
                                      <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                          {row.status_suscription}
                                      </p>                                
                                  </div>
                              :   <div className='badge text-bg-warning'>
                                      <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                          {row.status_suscription}
                                      </p>                                
                                  </div>
                          }
                            <div>
                                <p>{t('payments.marketing.count')}: <b>{row.count_subscription}</b></p>
                            </div>
                          </>
              }
          },
          {
              maxWidth: "10%",
              name: t('payments.marketing.amount_total'),
              cell: (row, index, column, id) => {
                  const total_amount = (row.total_amount !== null) ? `$${parseFloat(row.total_amount).toFixed(2)}` : '-'
                  return <p className='text-capitalize'>{total_amount}</p>
              }
          },
          {
              maxWidth: "15%",
              name: t('payments.marketing.due_date'),
              cell: (row, index, column, id) => {
                  const createdAt = DateTime.fromISO(row.createdAt)
                  const expireAt = DateTime.fromISO(row.expireAt)
                  return <>
                        <span className='text-capitalize'><b>{t('payments.marketing.created_at')}:</b> {createdAt.toFormat('yyyy-MM-dd')}</span>
                        <span className='text-capitalize'><b>{t('payments.marketing.expired_at')}:</b> {expireAt.toFormat('yyyy-MM-dd')}</span>
                    </>
              }
          },
          {
              maxWidth: "20%",
              name: t('payments.marketing.region'),
              cell: (row, index, column, id) => {
                  return <div className='text-start w-100'>
                            <span className='text-capitalize'><b>{t('payments.marketing.country')}:</b> {row?.Markprice?.country}</span>
                            <p className='text-capitalize'></p>
                            <p className='text-capitalize m-0'><b>{t('payments.marketing.states')}:</b></p>
                            {
                                row?.Markprice?.States.length > 0 &&
                                row?.Markprice?.States.map((e,i) => {
                                    return <p className="m-0" key={`state-${i}`}>{e.name}</p>
                                })
                            }
                        </div>
              }
          },
          {
              maxWidth: "20%",
              name: t('payments.marketing.actions'),
              cell: (row, index, column, id) => {
                  return <>                          
                            {
                                row.pi_stripe_id !== null &&
                                <div className="dropdown mb-2">
                                    <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                        <span>{t('payments.marketing.options')}</span>	
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>                                                                             
                                            <a 
                                                className="dropdown-item fw-bold" 
                                                href="#!"
                                                onClick={() => getInvoices(row)}
                                            >
                                                <FontAwesomeIcon icon={faFilePdf} className='me-2'/>  
                                                <span>Invoices</span>
                                            </a>
                                        </li>
                                        <li>                                                                             
                                            <Link 
                                                className="dropdown-item fw-bold" 
                                                to={`/marketing/advertising/${row.pi_stripe_id}`}
                                            >
                                                <FontAwesomeIcon icon={faUpload} className='me-2'/>  
                                                <span>{t('payments.marketing.upload_banners')}</span>
                                            </Link>
                                        </li>
                                        <li>      
                                            <a 
                                                className={row.status_suscription !== 'active' ? `disabled dropdown-item` : 'dropdown-item text-danger fw-bold'}
                                                href="#!" 
                                                onClick={() => cancelSubscription(row.pi_stripe_id)}
                                            >
                                                <FontAwesomeIcon icon={faCircleXmark} className='me-2'/>  
                                                <span>{t('payments.marketing.cancel_recurring')}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            }
                      </>
              },
          },
      ];
  return result;
  }
/**************************************************************************************************************************************/
  const getInvoiceMarketings = async(params) => { 
      console.log(params);
      console.log(invoices.id);
    //   return;
    //   download.current.click();
      const headers = await getHeaders();
      const data = { pi: params, markpaymentId: invoices.id }
      await axios.post(`${url}marketings/get-invoices-sub-pdf`, data, {headers})
                  .then(res =>{
                      // console.log(res.data)
                      // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                      const url           = `data:application/pdf;base64,${res.data.message}`;
                      const link          = document.createElement('a');
                      const fileName      = res.data.name;
                            link.href     = url;
                            link.download = fileName;
                          document.body.appendChild(link);
                          link.click();
                  })
                  .catch(err =>{
                      console.log(err)
                      Swal.fire({
                          icon: 'error',
                          title: err?.response?.data?.response || err,
                      });                        
                  })
                  .then(() =>{
                      setTimeout(async() => {
                          // await downloadCls.current.click();
                      }, 500);
                  });
  }
/***************************************************************************************************************************/
  const setSearch = async(params) => { 

      setLoading(true);
      set_stripe(params.stripe);
      const startDate_ = DateTime.fromJSDate(_startDate).toFormat('yyyy-MM-dd');
      const endDate_   = DateTime.fromJSDate(_endDate).toFormat('yyyy-MM-dd');
      setStartDate(startDate_);
      setEndDate(endDate_);


      const stripe  = params.stripe;
      const headers = await getHeaders();
      await axios.get(`${url}payments/marketing?page=1&per_page=${perPage}&stripe=${stripe}&start_date=${startDate_}&end_date=${endDate_}`, {headers})
                  .then(res =>{
                      setData(res.data.message);
                      setTotalRows(res.data.total);
                      console.log(res.data);
                  })
                  .catch(err =>{
                      console.log(err)
                  })
                  .then(() =>{
                      setLoading(false)
                  });

  }
/***************************************************************************************************************************/
  useEffect(() => {
      setcolumns(marketings);
      getPayments(1);
  }, [i18n.language]);
/*************************************************************************************************************************/

  return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#invoices" ref={btnInvoices}></button>

        {/* <!-- Modal --> */}
        <div className="modal fade" id="invoices" tabIndex="-1" aria-labelledby="invoicesLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="invoicesLabel">{t('payments.marketing.download_invoices')}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className='list-group list-group-flush'>
                            {
                                invoices !== null &&
                                invoices.invoices.map((e,i) => {
                                        return <li className='list-group-item' key={`in-${i}`}>
                                            <a className='link-offset-2 link-underline link-underline-opacity-0' 
                                               href='#' 
                                               onClick={() => getInvoiceMarketings(e)
                                            }>
                                                {e}
                                            </a>
                                        </li>
                                    }
                                )
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>


        {/*  MAIN  */}
        <div className='row align-items-start'>
            <div className="col-sm-4 col-md-2 col-lg-2 mb-3 my-1">
                <Link 
                    className='btn btn-bd-primary btn-shadow'
                    to={`/marketing/advertising/create`}
                >
                    <FontAwesomeIcon icon={faPlusCircle} className='me-2'/>
                    <span className='fw-bold'>{t('payments.marketing.add_advertising')}</span>
                </Link>
            </div>

            <div className="col-sm-8 col-md-10 col-lg-10 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            <Formik
                                initialValues={{ 
                                    stripe : _stripe,
                                }}
                                onSubmit={(values, actions) => setSearch(values, actions)}
                                enableReinitialize
                            >
                                <Form>
                                    <div className="row align-items-center mb-3">
                                        <label htmlFor="stripe" className="col-sm-2 col-form-label col-form-label-sm">{t('payments.marketing.search.stripe_payment')}</label>
                                        <div className="col-md-10">
                                            <Field 
                                                type="text" 
                                                className="form-control form-control-sm" 
                                                placeholder={t('payments.marketing.search.stripe_payment')} 
                                                name="stripe"
                                                id="stripe"
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="due-date" className="col-form-label col-form-label-sm fw-bold">{t('payments.marketing.search.start_due_date')}</label>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={_startDate} 
                                                onChange={(value) => {
                                                    // console.log(value);
                                                    _setStartDate(value);
                                                }}
                                                className="form-control" id="start" placeholder="start" name="start"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="due-date" className="col-form-label col-form-label-sm fw-bold">{t('payments.marketing.search.end_due_date')}</label>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={_endDate} 
                                                onChange={(value) => {
                                                    console.log(value);
                                                    _setEndDate(value);
                                                }}
                                                className="form-control" id="end" placeholder="end" name="end"
                                            />
                                        </div>
                                        
                                    </div>

                                    <div className='mt-3'>
                                        <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                            <span className='fw-bold'>
                                            {t('utilities.search_btn')}
                                            </span>
                                        </button>
                                    </div>                
                                </Form>
                            </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-5">
                <div className="card card-shadow">
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="500px"
                        progressPending={loading}                      
                        paginationResetDefaultPage={resetPaginationToggle}
                        pagination
                        paginationPerPage={perPage}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

export default Marketings