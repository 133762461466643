import React from 'react'
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NoMatch = () => {

	const {t} = useTranslation()

  	return (
		<div className='container animate__animated animate__fadeIn'>
			<div id='oopss'>
				<div id='error-text'>
					<img className='nf-img' src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg " alt="404" />
					<h1 className='display-1'>{t('no_match.not_found')}</h1>
					<p className="fs-5">{t('no_match.p1')}</p>
					<p className="fs-5">{t('no_match.p2')}</p>
					<NavLink to="/" className="back">{t('no_match.back')} </NavLink>
				</div>
			</div>
		</div>
  )
}

export default NoMatch