import React, {useState, useEffect, useCallback} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [loading, setloading] = useState(false);
/***************************************************************************************************************************/
    const { executeRecaptcha } = useGoogleReCaptcha();
    const {t,i18n}                  = useTranslation()
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
    }

    const token = await executeRecaptcha('yourAction');
    // Do whatever you want with the token
    console.log(token);
    return token;
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
    handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
/***************************************************************************************************************************/

    const sendContact = async (params, {resetForm}) => { 
        setloading(true);
        const token               = await handleReCaptchaVerify();
              params.token_verify = token

        await axios.post(`${url}home/contact-us`, params)
                   .then(res =>{
                        console.log(res);
                        Swal.fire({
                            icon : 'success',
                            title: 'Success',
                            text : res.data.response,
                        });
                        resetForm();
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        })
                   }).then(() =>{
                        setloading(false)
                   });
        
    }

/*************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string().min(3).max(30).email()
            .required(),
        name: Yup.string().min(3).max(50)
            .required(),
        company: Yup.string().min(3).max(30)
            .required(),
        comments: Yup.string().min(3).max(255)
            .required(),
    });
/*************************************************************************************************************************/

    return (
        <>
            <main>
                <div className="px-4 py-5 border-bottom border-5 border-secondary">
                    <h1 className="display-1  text-center fw-bold">{t('contact_us.contact')}<span className='text-bs-primary'>{t('contact_us.us')}</span></h1>
                    <div className="col-lg-8 mx-auto">
                        <ul className="list-group list-group-flush text-justify">                    
                        {
                            [0,1,2,3,4].map((e) =>{
                                return <li className="list-group-item border-0" key={`cu-${e}`}>
                                    {t(`contact_us.text${e}`)}
                                </li>
                            })
                        }
                        </ul>
                    </div>
                </div>
            </main>

            <section className='container-fluid py-3 border-bottom  border-secondary bg-gradient'>
			    <div className='row align-items-center justify-content-center text-dark-emphasis'>
                    <div className="col-lg-6 col-md-8 col-sm-8">
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='text-center fw-bold'>
                                    {t('contact_us.contact')} {t('contact_us.us')}
                                </h3>
                            </div>
                            <div className='card-body'>
                                <Formik
                                    initialValues={{ 
                                        name: '',
                                        email: '',
                                        company: '',
                                        comments: '',
                                    }}
                                    validationSchema={formSchema}
                                    onSubmit={(values, actions) => sendContact(values, actions)}
                                >
                                    <Form className="needs-validation" noValidate>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label fw-bold">{t('contact_us.name')}</label>
                                            <Field type="text" className="form-control" id="name" name="name" placeholder={t('contact_us.name_1')} />
                                            <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>

                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label fw-bold">{t('contact_us.email')}</label>
                                            <Field type="email" className="form-control" id="email" name="email" placeholder={t('contact_us.email')}/>
                                            <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>

                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="company" className="form-label fw-bold">{t('contact_us.company')}</label>
                                            <Field type="text" className="form-control" id="company" name="company" placeholder={t('contact_us.company_1')} />
                                            <ErrorMessage name="company" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="comments" className="form-label fw-bold">{t('contact_us.comments')}</label>
                                            <Field as="textarea" className="form-control" id="comments" rows="3" name='comments'></Field>
                                            <ErrorMessage name="comments" component="div" className='field-error text-danger fw-bold text-capitalize'/>

                                        </div>
                                        {/* <GoogleReCaptcha
                                            onVerify={onVerify}
                                            refreshReCaptcha={refreshReCaptcha}
                                            
                                        /> */}
                                        
                                        <div className="mb-3">
                                            <div className='row'>                                                                                                   
                                                <div className="col-lg-6 col-md-6 mb-4"> 
                                                    <button 
                                                        className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3 fw-bold" 
                                                        type="submit"
                                                        disabled={loading}
                                                    ><span className='me-3'>
                                                        {t('contact_us.send_form')}
                                                    </span>
                                                    {
                                                        loading &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    }
                                                    </button>                                                     
                                                </div>
                                                <div className="col-lg-12 col-md-12 p-1">
                                                    <div style={{fontSize: '.75rem', lineHeight: '1.2rem'}} className='p-1 rounded bg-body-tertiary'>
                                                        <span> {t('footer.recaptcha')} </span>
                                                        <Link to="https://policies.google.com/privacy" className=' text-capitalize'>{t('footer.privacy_google')}</Link> <span> & </span>
                                                        <Link to="https://policies.google.com/terms" className=' text-capitalize'>{t('footer.terms_google')}</Link> {(i18n.language === 'en') ?<span> apply</span> : <span> de google</span>}
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                    </div>
            </section>
        </>
    )
}

export default ContactUs