import React, {useContext} from 'react';
import { Navigate } from "react-router-dom";
import AuthContext from '../AuthContext';
import jwt_decode from "jwt-decode";

const ProtectedRoute = ({ children, roles }) => {
    const { token }   = useContext(AuthContext);
    const tokenDecode = (token!==null) ? jwt_decode(token) : null
    // const [tokenDecode, setTokenDecode] = useState(token!==null ? jwt_decode(token) : null)
    // if (!token) {
    if (token===null){
        // user is not authenticated
        return <Navigate to="/dashboard" />;
    }else{
        const x = tokenDecode?.roles.find(x => roles.includes(x.name) );
        if( x === undefined) {
            // user is not authenticated
            return <Navigate to="/dashboard" />;
        }
    }
    return children;
}

export default ProtectedRoute