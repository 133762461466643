import React, {useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import AuthContext from '../../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';

const CreateProduct = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/**************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const [loading, setloading] = useState(false);
    const [image, setImage]     = useState(null);
/***************************************************************************************************************************/
    const handleImage = (e) => {
        const _extension_ = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        console.log(e)
        console.log(e.target.files[0].name)
        console.log(_extension_)
        if(_extension_ !== 'png' && _extension_ !== 'jpg' && _extension_ !== 'jpeg' && _extension_ !== 'gif' && _extension_ !== 'bmp'){
            setImage(null);
            document.getElementById("formFile").value = "";
            Swal.fire({
                icon: 'info',
                title: t('products.invalid_extension'),
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return false;
        }

        setImage(e.target.files[0]);
    }
/***************************************************************************************************************************/
// const headers = await getHeaders();
    const createProduct = async (params, {resetForm}) => {
        let   _formData  = new FormData();
        for (const key in params) {
            _formData.append(key, params[key] );
        }
        _formData.append('image', image);
        const headers = await getHeaders();
        headers['Content-Type'] = 'multipart/form-data'
        await axios.post(`${url}products`, _formData, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        resetForm();
                        setImage(null);
                        document.getElementById("formFile").value = "";
                        console.log(res);
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setloading(false);
                    });
    }
/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        name        : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(t('utilities.is_a_required_field')),
        description : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(t('utilities.is_a_required_field')),
        list_price  : Yup.number().min(1, 'min 1').required(t('utilities.is_a_required_field')),
        list_price_2: Yup.number().min(1, 'min 1').required(t('utilities.is_a_required_field')),
        order       : Yup.number().min(1, 'min 1').max(99, 'max 99').required(t('utilities.is_a_required_field')),
        visibility  : Yup.number().min(1).max(2).required(t('utilities.is_a_required_field')),
    });
/***************************************************************************************************************************/
    return (
        <div className='container mb-5'>
            <div className='card'>
                <div className='card-header'>
                    <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                        <FontAwesomeIcon icon={faBoxOpen} className="me-2"/>
                        <span className='mb-0'>{t('products.create.creation')}</span>
                    </h1>
                </div>
                <div className='card-body'>
                    <Formik
                        initialValues={{ 
                            name           : '',
                            description    : '',
                            list_price     : 0,
                            list_price_2   : 0,
                            list_price_mx  : 0,
                            list_price_mx_2: 0,
                            visibility     : 0,
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => createProduct(values, actions)}
                    >
                        <Form>
                            <div className='row justify-content-center'>
                                <div className='col-md-8 col-lg-6'>
                                    <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                

                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="name" placeholder="name" name="name"/>
                                                <label htmlFor="name">{t('products.create.name')} (*)</label>
                                            </div>
                                            <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="description" placeholder="Description" name="description"/>
                                                <label htmlFor="description">{t('products.create.description')} (*)</label>
                                            </div>
                                            <ErrorMessage name="description" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        {/* list usd  */}
                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="list_price" placeholder="list_price" name="list_price"/>
                                                <label htmlFor="list_price">{t('products.create.list_price')}</label>
                                            </div>
                                            <ErrorMessage name="list_price" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="list_price_2" placeholder="list_price_2" name="list_price_2"/>
                                                <label htmlFor="list_price_2">{t('products.create.list_price_2')}</label>
                                            </div>
                                            <ErrorMessage name="list_price_2" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        {/* list mxn */}
                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="list_price_mx" placeholder="list_price_mx" name="list_price_mx"/>
                                                <label htmlFor="list_price_mx">Precio de lista MXN</label>
                                            </div>
                                            <ErrorMessage name="list_price_mx" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="list_price_mx_2" placeholder="list_price_mx_2" name="list_price_mx_2"/>
                                                <label htmlFor="list_price_mx_2">Precio de lista 2 años MXN</label>
                                            </div>
                                            <ErrorMessage name="list_price_mx_2" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        {/* Orden                                              */}
                                        <div className="mb-3">
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="order" placeholder="order" name="order"/>
                                                <label htmlFor="order">{t('products.create.order')} (*)</label>
                                            </div>
                                            <ErrorMessage name="order" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="visibility">
                                                <option value={0} disabled className="text-capitalize">{t('products.create.select_an_option')}</option>
                                                <option value={1} className="text-capitalize">{t('products.hidden')}</option>
                                                <option value={2} className="text-capitalize">{t('products.show')}</option>
                                                
                                            </Field>
                                            <ErrorMessage name="visibility" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">{t('products.create.select_image')}</label>
                                            <input className="form-control" 
                                                    type="file" 
                                                    id="formFile" 
                                                    onChange={handleImage} 
                                                    accept=".png,.jpg,.jpeg,.gif,.bmp"
                                            />
                                        </div>
                                    
                                        <div className="mt-4">
                                            <button 
                                                className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                type="submit"
                                                disabled={loading}
                                            ><span className='me-3 fw-bold'>
                                                {t('products.create.create')}
                                            </span>
                                            {
                                                loading &&
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            }
                                            </button> 
                                        </div>

                                    </main>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default CreateProduct