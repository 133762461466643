import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='container mt-3'>
                <div className="row">
                    <div className="col-3">
                        <button className='btn btn-sm btn-bd-primary fw-bold' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={faArrowLeft} className="mx-1"/>
                            {/* <span>
                                Back
                            </span> */}
                        </button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BackButton