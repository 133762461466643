import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faHandshake, faFileCsv, faLock, faPenToSquare, faEye, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const Package = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    //Search
     const [partner, setpartner]     = useState('0');
     const [_package, set_package]   = useState('');
     const [products, setproducts]   = useState('');
     const [useremail, setuseremail] = useState('');
     const [status, setstatus]       = useState('0');
     const [usertype, setusertype]   = useState('');
/***************************************************************************************************************************/
    const [data, setData]                 = useState([]);
    const [allPartner, setAllPartner]     = useState([]);
    const [partnerFirst, setPartnerFirst] = useState(false);
    const [loading, setLoading]           = useState(false);
    const [totalRows, setTotalRows]       = useState(0);
    const [perPage, setPerPage]           = useState(10);
    const [columns, setcolumns]           = useState([]);
    const [csv, setcsv]                   = useState("10");
    const download                        = useRef(null);
    const downloadCls                     = useRef(null);
    const {t}                             = useTranslation()
    const paginationComponentOptions      = usePaginationComponentOptions();
/***************************************************************************************************************************/
    const getPackages = async (page) => {
        setLoading(true);

        const search = `&partner=${partner}&package=${_package}&products=${products}&useremail=${useremail}&status=${status}&usertype=${usertype}`
        const headers = await getHeaders();
        await axios.get(`${url}packages?page=${page}&per_page=${perPage}${search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        if(!partnerFirst){
                            setAllPartner(res.data.data);
                            setPartnerFirst(true);
                        }
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPackages(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const search = `&partner=${partner}&package=${_package}&products=${products}&useremail=${useremail}&status=${status}&usertype=${usertype}`
        const headers = await getHeaders();
        await axios.get(`${url}packages?page=${page}&per_page=${newPerPage}${search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        setpartner(params.partner)  
        set_package(params._package)
        setproducts(params.products)
        setuseremail(params.useremail)        
        setstatus(params.status)    
        setusertype(params.usertype)

        const partner   = params.partner;
        const package_  = params._package;
        const products  = params.products;
        const useremail = params.useremail;
        const status    = params.status;
        const usertype  = params.usertype;

        const search = `&partner=${partner}&package=${package_}&products=${products}&useremail=${useremail}&status=${status}&usertype=${usertype}`
        const headers = await getHeaders();
        await axios.get(`${url}packages?page=1&per_page=${perPage}${search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const getLicensesCsv = async(params) => { 
        console.log(params);
        download.current.click();
        const headers = await getHeaders();
        await axios.get(`${url}licenses/csv/${params}`, {headers})
                    .then(res =>{
                        const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const link      = document.createElement('a');
                              link.href = url;
                            link.setAttribute('download', `licenses-${params}.csv`);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(err =>{
                        console.log(err.response)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            await downloadCls.current.click();
                        }, 500);
                    });
    }   
/***************************************************************************************************************************/
    const getPackagesCsv = async() => { 
        console.log(csv)
        download.current.click();
        const headers = await getHeaders();
        await axios.get(`${url}packages/csv/${csv}`, {headers})
                    .then(res =>{

                        const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const link      = document.createElement('a');
                              link.href = url;
                              link.setAttribute('download', `packages-${csv}.csv`);
                        document.body.appendChild(link);
                        link.click();
                        // console.log(res.data)
                    })
                    .catch(err =>{
                        console.log(err.response)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            await downloadCls.current.click();
                        }, 500);
                    });
    }   
/***************************************************************************************************************************/
    useEffect(() => {
        setcolumns(_columns());
        getPackages(1)
        console.log(tokenDecode);
    }, []);
/***************************************************************************************************************************/
    const changeStatus = async(params, data) => { 

        console.log(params);
        // return;
        Swal.fire({
            title: 'Change Status?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Change it!',
            showClass: { popup: 'animate__animated animate__fadeInDown' },
            hideClass: { popup: 'animate__animated animate__fadeOutUp' },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { value: formValues } = await Swal.fire({
                    title: 'Change Status',
                    showClass: { popup: 'animate__animated animate__fadeInDown' },
                    hideClass: { popup: 'animate__animated animate__fadeOutUp' },
                    html:
                    `<div className="row">
                        <div className="col-12">
                            <select id="swal-input1" class="form-select mb-5" aria-label="Defsault select example">
                                <option value="1" ${(params.StatusId === 1) ?'selected' : ''}>Available</option>
                                <option value="2" ${(params.StatusId === 2) ?'selected' : ''}>Disabled</option>
                                <option value="3" ${(params.StatusId === 3) ?'selected' : ''}>Activated</option>
                                <option value="4" ${(params.StatusId === 4) ?'selected' : ''}>Registered</option>
                                <option value="5" ${(params.StatusId === 5) ?'selected' : ''}>Expired</option>
                                <option value="6" ${(params.StatusId === 6) ?'selected' : ''}>Renovated</option>
                            </select>
                        <div>
                    <div>`,
                    focusConfirm: false,
                    preConfirm: () => document.getElementById('swal-input1').value
                });

                if (formValues) {
                    const _data = {
                        statusId : formValues
                    }
                    const headers = await getHeaders();
                    await axios.put(`${url}packages/update/${params.id}`, _data, {headers})
                                .then(res => {
                                    console.log(res.data.message)
                                    setData(res.data.message);
                                    setTotalRows(res.data.total);

                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });
                                })
                                .catch(err =>{
                                    console.log(err);
                                    Swal.fire({
                                        icon: 'error',
                                        title: err?.response?.data?.response,
                                    });
                                })
                                .then(() =>{
                                    setLoading(false)
                                });
                }
            }// if end
        })                  
    }
/***************************************************************************************************************************/
    const admin = () => { 
        const result = [
            {
                name: 'Partner',
                cell: row => {                                                
                            return (row?.User?.Partner?.name !== undefined && row?.User?.Partner?.name !== null)
                            ?   <p className='text-capitalize mb-0'>{row?.User?.Partner?.name} </p>
                            :   <p>-</p>                                                            
                }
            },
            {
                name: 'Package #S/N',
                cell: row => <p className='text-capitalize mb-0'>{row.serial_number} </p>     
            },
            {
                name: 'Comments',
                cell: row => <p className='text-capitalize mb-0'>{row.comments} </p>     
            },
            {
                name: 'Compatibility',
                cell: row => row.Products.map((e,i) => <p className='text-capitalize mb-1' key={`products-${i}`}>{e.name}</p> )
            },
            {
                wrap: true,
                name: 'User own',
                selector: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>

                }                            
            },
            {
                name: 'Status Package',
                selector: row => {
                    let _class = '';
                    switch (row.StatusId) {
                        case 1:
                            _class = 'badge text-bg-primary';
                            break;

                        case 2:
                            _class = 'badge text-bg-danger';
                            break;
                    
                        default:
                            _class = 'badge text-bg-warning';
                            break;
                    }
                    return <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>
                        {row.Status.name}
                    </p>
                }
            },
            {
                name: 'Status Licenes',
                selector: row => row.counts.map((e,i) => 
                    <p className='text-capitalize mb-0' key={`lic-${i}`}>
                        {e.Status.name} : {e.count}
                    </p>
                )                            
            },
            {
                name: 'Total Licenes',
                selector: row => row.license_count       
            },
            {
                // wrap: true,
                name: 'User type',
                cell: row => {
                    return <>
                            {
                                (row.UsertypeId === 1) &&
                                    <b>Admin</b>                            
                            }
                            {
                                (row.UsertypeId === 2) &&
                                    <b>Partner</b>                            
                            }
                            {
                                (row.UsertypeId === 3) &&
                                    <b>Customer (stripe)</b>                            
                            }
                            </>
                }                            
            },
            {
                wrap: true,
                name: 'Created At',
                selector: row => {
                    const d = new Date(row.createdAt);
                    return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>
                }
            },
            {
                name: 'Expiry time',
                selector: row => <p>{row.expiry_time} {row.Time.name}</p>
            },
            {
                minWidth: '150px',
                name: 'Actions',
                cell: (row, index, column, id) => {
                    return <>         
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>Options</span>	
                                </a>
                                <ul className="dropdown-menu" style={{width:'220px'}}>                        
                                    <li>
                                        <Link
                                            to={`show/${row.serial_number}`} 
                                            className="dropdown-item fw-bold"
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faEye} className="me-2"/>                                                
                                                </div>
                                                <div className="col-2">
                                                    <span className='fw-bold'>
                                                        Show all
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <a 
                                            href="#!"                      
                                            className={"dropdown-item fw-bold"}
                                            onClick={() => {
                                                changeStatus(row, data)
                                            }}    
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faRecycle}/>
                                                </div>
                                                <div className="col-10">
                                                    <span>Status</span>
                                                </div>
                                            </div>    
                                            
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            href="#!"                      
                                            className={"dropdown-item fw-bold"}
                                            onClick={() => {
                                                getLicensesCsv(row.id)
                                            }}    
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faFileCsv}/>
                                                </div>
                                                <div className="col-10">
                                                    <span>Download CSV</span>
                                                </div>
                                            </div>    
                                            
                                        </a>
                                    </li>
                                </ul>
                            </div>                 
                                {/* <div className='d-flex w-100 justify-content-center flex-column p-1'>
                                    <div className='mb-2'>
                                        <Link
                                            to={`show/${row.serial_number}`} 
                                            className='btn btn-success btn-shadow btn-sm'
                                        >
                                            <span className='fw-bold'>
                                                Show all
                                            </span>
                                        </Link>
                                    </div>  

                                    <div>
                                        <button 
                                            className={"btn btn-danger btn-shadow btn-sm mb-2"}
                                            onClick={() => {
                                                    changeStatus(row, data)
                                            }}    
                                        >
                                            <span className='fw-bold'>
                                                Status
                                            </span>
                                        </button>
                                    </div>  

                                    <div>
                                        <button 
                                            className={"btn btn-primary btn-shadow btn-sm mb-2"}
                                            onClick={() => {
                                                    getLicensesCsv(row.id)
                                            }}    
                                        >
                                            <FontAwesomeIcon icon={faFileCsv} className="mes-2"/>
                                        </button>
                                    </div>  
                                </div> */}
                        </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const customers = () => { 
        const result = [
            {
                name: 'Package #S/N',
                cell: row => <p className='text-capitalize mb-0'>{row.serial_number} </p>     
            },
            {
                minWidth: '270px',
                name: 'Licenses',
                cell: row => <div className='my-2 w-100'>
                                    {
                                        row.Licenses.map((e,i)=>
                                            <p className='text-capitalize mb-0' key={`pro-${i}`}>{e.product_key}</p>                                 
                                        )
                                    }
                                </div>
                            
            },
            {
                name: 'User own',
                selector: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.name}</p>
                        :   <p>-</p>

                }
                            
            },
            {
                name: 'Licenses',
                selector: row => {
                    return  <ul className='ps-0'>
                            {
                                row.counts.map((e,i)=>
                                    <li className='text-capitalize ' key={`lic-${i}`}>
                                        {e.Status.name} : {e.count}
                                    </li>
                                )
                            }                
                            </ul>

                }
                            
            },
            {
                // wrap: true,
                name: 'User type',
                cell: row => {
                    return <>
                            {
                                (row.UsertypeId === 1) &&
                                    <p>Created by Admin</p>                            
                            }
                            {
                                (row.UsertypeId === 2) &&
                                    <p>Adquiried by partner</p>                            
                            }
                            {
                                (row.UsertypeId === 3) &&
                                    <p>Pay online (stripe) by customer</p>                            
                            }
                            </>

                }
                            
            },
            {
                minWidth: '120px',
                name: 'Actions',
                cell: (row, index, column, id) => {
                    return <>                          
                                <div className='d-flex w-100 justify-content-center flex-column p-1'>
                                    <div className='mb-2'>
                                        <Link
                                            to={`show/${row.serial_number}`} 
                                            className='btn btn-success btn-shadow btn-sm'
                                        >
                                            {/* <FontAwesomeIcon icon={faPenToSquare} className="me-2"/> */}
                                            <span className='fw-bold'>
                                                Show all
                                            </span>
                                        </Link>
                                    </div>  
                                </div>
                        </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const partners = () => { 
        const result = [
            {
                name: t('packages.package'),
                cell: row => <p className='text-capitalize mb-0'>{row.serial_number} </p>     
            },
            {
                name: t('packages.comments'),
                cell: row => <p className='text-capitalize mb-0'>{row.comments} </p>     
            },
            {
                name: t('packages.compatibility'),
                cell: row => row.Products.map((e,i) => <p className='text-capitalize mb-1' key={`products-${i}`}>{e.name}</p> )
            },
            {
                wrap: true,
                name: t('packages.user_owner'),
                selector: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>

                }                            
            },
            {
                name: t('packages.status_package'),
                selector: row => {
                    let _class = '';
                    switch (row.StatusId) {
                        case 1:
                            _class = 'badge text-bg-primary';
                            break;

                        case 2:
                            _class = 'badge text-bg-danger';
                            break;
                    
                        default:
                            _class = 'badge text-bg-warning';
                            break;
                    }
                    return <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>
                        {t(`status.${row.Status.name}`)}
                    </p>
                }
            },
            {
                name: t('packages.status_license'),
                selector: row => row.counts.map((e,i) => 
                    <p className='mb-0' key={`lic-${i}`}>                        
                        {t(`status.${e.Status.name}`)} : {e.count}
                    </p>
                )                            
            },
            {
                name: t('packages.total_licenses'),
                selector: row => row.license_count       
            },
            {
                wrap: true,
                name: t('packages.created_at'),
                selector: row => {
                    const d = new Date(row.createdAt);
                    return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>
                }
            },
            {
                name: t('packages.expire_time'),
                selector: row => {
                    const type = (row.expiry_time > 1) ? row.Time.name+'s' : row.Time.name;                    
                    return <p>{row.expiry_time} {t(`utilities.${type}`)}</p>
                }
            },
            {
                minWidth: '150px',
                name: t('packages.actions'),
                cell: (row, index, column, id) => {
                    return <>
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>
                                        {t('packages.options')}
                                    </span>	
                                </a>
                                <ul className="dropdown-menu" style={{width:'220px'}}>                        
                                    <li>
                                        <Link
                                            to={`show/${row.serial_number}`} 
                                            className="dropdown-item fw-bold"
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faEye} className="me-2"/>                                                
                                                </div>
                                                <div className="col-2">
                                                    <span className='fw-bold'>
                                                        {t('packages.show_all')}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <a 
                                            href="#!"                      
                                            className={"dropdown-item fw-bold"}
                                            onClick={() => {
                                                    getLicensesCsv(row.id)
                                            }}    
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faFileCsv}/>
                                                </div>
                                                <div className="col-10">
                                                    <span>
                                                        {t('packages.download_csv')}
                                                    </span>
                                                </div>
                                            </div>    
                                            
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const subpartners = () => { 
        const result = [
            {
                name: t('packages.package'),
                cell: row => <p className='text-capitalize mb-0'>{row.serial_number} </p>     
            },
            {
                name: t('packages.comments'),
                cell: row => <p className='text-capitalize mb-0'>{row.comments} </p>     
            },
            {
                name: t('packages.compatibility'),
                cell: row => row.Products.map((e,i) => <p className='text-capitalize mb-1' key={`products-${i}`}>{e.name}</p> )
            },
            {
                wrap: true,
                name: t('packages.user_owner'),
                selector: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>

                }                            
            },
            {
                name: t('packages.status_package'),
                selector: row => {
                    let _class = '';
                    switch (row.StatusId) {
                        case 1:
                            _class = 'badge text-bg-primary';
                            break;

                        case 2:
                            _class = 'badge text-bg-danger';
                            break;
                    
                        default:
                            _class = 'badge text-bg-warning';
                            break;
                    }
                    return <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>
                        {t(`status.${row.Status.name}`)}
                    </p>
                }
            },
            {
                name: t('packages.status_license'),
                selector: row => row.counts.map((e,i) => 
                    <p className='mb-0' key={`lic-${i}`}>                        
                        {t(`status.${e.Status.name}`)} : {e.count}
                    </p>
                )                            
            },
            {
                name: t('packages.total_licenses'),
                selector: row => row.license_count       
            },
            {
                wrap: true,
                name: t('packages.created_at'),
                selector: row => {
                    const d = new Date(row.createdAt);
                    return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>
                }
            },
            {
                name: t('packages.expire_time'),
                selector: row => {
                    const type = (row.expiry_time > 1) ? row.Time.name+'s' : row.Time.name;                    
                    return <p>{row.expiry_time} {t(`utilities.${type}`)}</p>
                }
            },
            {
                minWidth: '150px',
                name: t('packages.actions'),
                cell: (row, index, column, id) => {
                    return <>
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>
                                        {t('packages.options')}
                                    </span>	
                                </a>
                                <ul className="dropdown-menu" style={{width:'220px'}}>                        
                                    <li>
                                        <Link
                                            to={`show/${row.serial_number}`} 
                                            className="dropdown-item fw-bold"
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faEye} className="me-2"/>                                                
                                                </div>
                                                <div className="col-2">
                                                    <span className='fw-bold'>
                                                        {t('packages.show_all')}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <a 
                                            href="#!"                      
                                            className={"dropdown-item fw-bold"}
                                            onClick={() => {
                                                    getLicensesCsv(row.id)
                                            }}    
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faFileCsv}/>
                                                </div>
                                                <div className="col-10">
                                                    <span>
                                                        {t('packages.download_csv')}
                                                    </span>
                                                </div>
                                            </div>    
                                            
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>
                },
            },
        ];
        return result;
    }

/**************************************************************************************************************************************/
    const _columns = () => { 
        console.log(tokenDecode.roles[0].name)
        let result = [];
        switch (tokenDecode.roles[0].name) {
            case 'customer':
                result = customers();
                break;

            case 'partner':
                result = partners();
                break;

            case 'subpartner':
                result = subpartners();
                break;

            case 'admin':
                result = admin();
                break;
        
            default:
                break;
        }
        return result;
    }
   
/***************************************************************************************************************************/
  return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={download}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={downloadCls}></button>
                    <div className="modal-body text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
                {
                    tokenDecode?.roles[0].name === 'admin' &&
                        <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                            <FontAwesomeIcon icon={faLock} className="me-2"/>
                            <span className='text-capitalize fw-bold'>Add Packages admin</span>
                        </Link>
                }
                {
                    tokenDecode?.roles[0].name === 'partner' &&
                        <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                            <FontAwesomeIcon icon={faHandshake} className="me-2"/>
                            <span className='text-capitalize fw-bold'>
                                {t('packages.create_packages')}
                            </span>
                        </Link>
                }

                <div className="input-group mt-3 card-shadow rounded">
                    <button 
                        className={"btn btn-primary btn-sm"}
                        id="button-addon1"
                        type="button"
                        onClick={() => {
                            getPackagesCsv()
                        }}    
                    >
                        <FontAwesomeIcon icon={faFileCsv} className="me-2"/>
                        <span className='fw-bold'>CSV</span>
                    </button>
                    <select 
                        className="form-select form-select-sm" 
                        aria-label="Default select example" 
                        defaultValue={"10"} 
                        onChange={(e) => setcsv(e.target.value)}
                        aria-describedby="button-addon1"    
                    >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">All</option>
                    </select>

                </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-9 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                    {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <Formik
                                    initialValues={{ 
                                        partner,
                                        _package,
                                        products,
                                        useremail,
                                        status,
                                        usertype,
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className={tokenDecode.roles[0].name === 'admin' ? "row align-items-center mb-3" : "d-none"}>
                                            <label htmlFor="partner" className="col-sm-2 col-form-label col-form-label-sm">Partner</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder="partner" 
                                                    name="partner"
                                                    id="partner"
                                                >
                                                    <option value='0' className='text-muted'>Seleccione un socio</option>
                                                    {
                                                        allPartner.map((item, index) => 
                                                            <option value={item.id} key={index} >{item.name}</option>                                                            
                                                        )
                                                    }

                                                </Field> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="_package" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.packages')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.packages')} 
                                                    name="_package"
                                                    id="_package"
                                                /> 

                                                <ErrorMessage name="_package" component="div" className='field-error text-danger fw-bold text-capitalize'/>                                                   

                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="products" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.compatibility_products')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.compatibility_products')}
                                                    name="products"
                                                    id="products"
                                                />                                               
                                            </div>
                                        </div>

                                        <div className={tokenDecode.roles[0].name === 'admin' ? "row align-items-center mb-3" : "d-none"}>
                                            <label htmlFor="useremail" className="col-sm-2 col-form-label col-form-label-sm">User Email</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder="User email" 
                                                    name="useremail"
                                                    id="useremail"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="status" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.status')}</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder={t('packages.status')}
                                                    name="status"
                                                    id="status"
                                                >
                                                    <option value='0' className='text-muted'>{t('packages.select_an_option')}</option>
                                                    <option value='1'>{t('status.available')}</option>
                                                    <option value='2'>{t('status.disabled')}</option>
                                                    <option value='3'>{t('status.activated')}</option>
                                                    <option value='4'>{t('status.registered')}</option>
                                                    <option value='5'>{t('status.expired')}</option>
                                                    <option value='6'>{t('status.renovated')}</option>
                                                    
                                                </Field>

                                                <ErrorMessage name="status" component="div" className='field-error text-danger fw-bold text-capitalize'/>                                                   

                                            </div>
                                        </div>

                                        <div className={tokenDecode.roles[0].name === 'admin' ? "row align-items-center mb-3" : "d-none"}>
                                            <label htmlFor="usertype" className="col-sm-2 col-form-label col-form-label-sm">User type</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder="usertype" 
                                                    name="usertype"
                                                    id="usertype"
                                                >
                                                    <option value='' className='text-muted'>Select a option</option>
                                                    <option value='admin'>Admin</option>
                                                    <option value='partner'>Partner</option>
                                                    <option value='customer'>Customer</option>
                                                    
                                                </Field>

                                                <ErrorMessage name="usertype" component="div" className='field-error text-danger fw-bold text-capitalize'/>                                                   

                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold'>
                                                    {t('utilities.search_btn')}
                                                </span>
                                            </button>
                                        </div>  
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-5">
                <div className="card card-shadow">
                    <DataTable
                        columns={columns}
                        data={data}
                        dense
                        fixedHeader
                        fixedHeaderScrollHeight="550px"
                        progressPending={loading}
                        pagination
                        paginationPerPage={perPage}
			            paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                    />
                </div>
            </div>
        </div>
    </>
  )
}

export default Package