import React, { useState, createContext, useMemo, useEffect } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import { useLocalStorage } from "./useLocalStorage";
import { useNavigate, useLocation  } from "react-router-dom"
import { useTranslation } from 'react-i18next';

const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage("token", null);
    let   location          = useLocation();
    let   navigate          = useNavigate();
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const {i18n} = useTranslation();
/***************************************************************************************************************************/
    const headers = async () => {
      let headers = {};
      if(token !== null && token !== undefined){
        const _token    = `Bearer ${token.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;
        headers = {
          'Authorization'   : _token,
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Accept-Language' : i18n.language || 'en'
        }
      }else{
        headers = {
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Accept-Language' : i18n.language || 'en'
        }
      }
        return headers;
    }
  
    const handleLogin = (token) => {
      setToken(token);
    };
  
    const handleLogout = () => {
      setToken(null);
    };
  
    const value = useMemo(
      () => ({
        token,
        onLogin   : handleLogin,
        onLogout  : handleLogout,
        getHeaders: headers,
      }),
    [token]
    );
  
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};

export default AuthProvider;