import React, {useEffect, useState, useContext, useRef} from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import countries from '../../countries.json';
import SelectSearch from 'react-select-search';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import utilities from '../../utilities/utilities';

const CreateStorePayment = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
	let { state }                     = useLocation();
	const { t }                       = useTranslation();
	const [loading, setloading]       = useState(false);
	const [country, setcountry]       = useState('');
	const [countryVal, setcountryVal] = useState(false);
	const [storeVal, setstoreVal]     = useState(false);
	
	const _url                                = useRef(null)
	const _urlCls                             = useRef(null)
	const [copyUrl, setcopyUrl]               = useState('');
	const [cs_checkout_id, setcs_checkout_id] = useState('');
	const [alertCopy, setalertCopy]           = useState(false);
	const [alertSend, setalertSend]           = useState(false);

	const [stores, setstores] = useState([]);
	const [store, setstore]   = useState(state?.store || '');

	const [email, setemail] = useState('')
/***************************************************************************************************************************/ 
	useEffect(() => {
		
		(async() =>{
			// console.log(state.store)
			const headers = await getHeaders();
			await axios.get(`${url}stores/available`, {headers})
						.then(res =>{
							console.log(res.data)
							setstores(res.data.message)
						})
						.catch( err =>{
							console.log(err)
						})
		})()
	}, [])
/***************************************************************************************************************************/ 
	const createPaymentLink = async (params, {resetForm}) => {
		
		if(country.trim() === '' ){
            setcountryVal(true);
            setloading(false)
            return false;
        }else{
            setcountryVal(false);
        }
		if(store.trim() === '' ){
            setstoreVal(true);
            setloading(false)
            return false;
        }else{
            setstoreVal(false);
        }
		const country_iso        = countries.find(e => e.name === country);
		      params.country     = country;
		      params.country_iso = country_iso.iso2;
		      params.store       = store
		console.log(params);

		setloading(true);
		const headers = await getHeaders();
		await axios.post(`${url}stores/payment`, params, {headers})
					.then(res => {
						console.log(res);
						setcountry('')
						setstore('')
						setcopyUrl(res?.data?.url);
						setcs_checkout_id(res?.data?.cs_checkout_id);
						setemail(params.email);
						_url.current.click();
						resetForm();
					})
					.catch(err =>{
						console.log(err)
						Swal.fire({
                            icon : 'error',
                            title: err?.response?.data?.response || err,
                            text : err?.response?.data?.message || err,
                        });
					})
					.then(() =>{
						setloading(false);
					})
	}
/*************************************************************************************************************************/
    const expiryTime = (params = 0, setFieldValue) => { 
        console.log(params);
        let cont = 0
        switch (parseInt(params)) {
            case 1:
				cont = 3;
                break;
            case 2:
                cont = 25;
                break;
            case 3:
                cont = 32;
                break;
        
            default:
                break;
        }
        let td = [];
        for (let i = 1; i < cont; i++) {
            td.push(<option key={`time_expiricy-${i}`} value={i}>{i}</option>);
        }
        return td;
    }
/***************************************************************************************************************************/ 
	const _copyUrl = () => { 

		setalertCopy(true);
		utilities.copyToClipboard(copyUrl);

		setTimeout(() => {
			setalertCopy(false)
		}, 3000);
	}
/***************************************************************************************************************************/ 

	const sendEmail = async (params) => { 
		
		Swal.fire({
			title: 'Confirm send email?',
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Send!'
		  }).then(async (result) => {
				if (result.isConfirmed) {
					const data = {
						email: params,
						url  : copyUrl,
						cs_checkout_id
					}
					const headers = await getHeaders();
					await axios.post(`${url}stores/send-email`, data, {headers})
								.then(res =>{
									setalertSend(true);
									setTimeout(() => {
										setalertSend(false);
			
									}, 3000);
								})
								.catch(err =>{
									console.log(err)
									Swal.fire({
										icon : 'error',
										title: err?.response?.data?.response || err,
										text : err?.response?.data?.message || err,
									});
								})
					console.log(params)
				}
		  })		
	}

/***************************************************************************************************************************/ 

    //Validate form
    const formSchema = Yup.object().shape({
        email      : Yup.string().email().required(),
        price      : Yup.number().min(0).max(999.99).required(),
        name       : Yup.string().trim().required(),
        description: Yup.string().trim().required(),
        // country    : Yup.string().required(),
        TimeId     : Yup.number().integer().min(1).max(3).required(),
        expiry_time: Yup.number().integer().min(1).max(31).required(),
    });
/*************************************************************************************************************************/
	return (
		<>
		{/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={_url}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div className="modal-content">
					<div className='modal-header'>
                    	<button 
							type="button" 
							className="btn-close" 
							data-bs-dismiss="modal" aria-label="Close" ref={_urlCls}
							disabled={alertSend}
						></button>
							
					</div>
                    <div className="modal-body text-center">
						<p className='fw-bold mb-0'> Link payment was created </p>
                        <p>You can copy this link to pay with stripe</p>
						<code>{copyUrl}</code>
                    </div>
					<div className="modal-footer">
						{
							alertCopy &&
							<div className="alert alert-success animate__animated animate__fade" role="alert">
								URL copied
							</div>
						  
						}
						
						<div className='row w-100'>
							<div className='col-md-6 mb-2'>
								<div className='row'>
									<div className='col-md-12'>
										{
											alertSend &&
											<div className="alert alert-success animate__animated animate__fade" role="alert">
												Email sending ...
											</div>
										
										}
										<button 
											disabled={alertSend}
											type="button"
											className='btn btn-shadow btn-success btn-sm fw-bold d-block'
											onClick={() => sendEmail(email)}
											data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Url copied"
										>
											<div className='d-flex align-items-center'>
												<span className='me-2'>
													Send email: {email}
												</span>
												{
													alertSend &&
													<div>
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
														<span className="visually-hidden">Loading...</span>
													</div>
												}
											</div>
											
										</button>
									</div>
								</div>
								<div className='col-md-12'>
									<p>
										<small>Include CC to contact@labeldictate.com *</small>
									</p>
								</div>
							</div>
							<div className='col-md-6 mb-2 text-md-end'>
								<button 
									type="button"
									className='btn btn-shadow btn-primary btn-sm fw-bold'
									onClick={() => _copyUrl()}
									data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Url copied"
								>
									Copy URL
								</button>
							</div>
						</div>
					</div>

                </div>
            </div>
        </div>

		<div className='container mb-5'>
			<div className='card card-shadow'>
				<div className="card-header">
					<h1 className="h3 m-0 fw-bold text-center">
						{t('stores.payment.create')}
					</h1>
				</div>
				<div className="card-body">
					<div className='row justify-content-center'>
						<div className='col-12 col-sm-10 col-md-8 col-lg-6'>
							<main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
								{
									<Formik
										initialValues={{ 
											email      : '',
											price      : '',
											name       : '',
											description: '',
											// country    : '',
											TimeId     : 0,
											expiry_time: 0,
										}}
										validationSchema={formSchema}
										onSubmit={(values, actions) => createPaymentLink(values, actions)}
									>
										{({ values, setFieldValue  }) => (
										<Form>
											<div className="mb-3">
												<div className="form-floating">
													<Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
													<label htmlFor="floatingInput">{t('login.email')}</label>
												</div>
												<ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>


											<div className="mb-3">
												<div className="form-floating">
													<Field type="price" className="form-control" id="floatingprice" placeholder="price" name="price"/>
													<label htmlFor="floatingprice">{t('stores.payment.price')}</label>
												</div>
												<ErrorMessage name="price" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<div className="form-floating">
													<Field type="name" className="form-control form-control-sm" id="floatingname" placeholder="name" name="name"/>
													<label htmlFor="floatingname">{t('stores.name')}</label>
												</div>
												<ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>
											
											<div className="mb-3">
												<div className="form-floating">
													<Field type="description" className="form-control" id="floatingname" placeholder="description" name="description"/>
													<label htmlFor="floatingname">{t('stores.description')}</label>
												</div>
												<ErrorMessage name="description" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<SelectSearch
													options={countries}
													value={country}
													onChange={(e) => {
														setcountry(e);                                          
													}}
													name="country" 
													placeholder={t('stores.payment.chose_country')}
													search
													autoComplete='off'
												/>
												{
													countryVal &&
													<div name="country-val" className='field-error text-danger fw-bold text-capitalize'>
														<p>Select a country</p>
													</div>
												}
											</div>

											{
												stores.length > 0 &&
												<div className="mb-3">
													<SelectSearch
														options={stores}
														value={store}
														onChange={(e) => {
															setstore(e);                                          
														}}
														name="store" 
														placeholder={t('stores.payment.chose_store')} 
														search
													/>
													{/* <p>You can only select a store without active pay *</p> */}
													{
														storeVal &&
														<div name="store-val" className='field-error text-danger fw-bold text-capitalize'>
															<p>{t('stores.payment.chose_store')}</p>
														</div>
													}
												</div>
											}

											<div className="mb-3">
												<Field 
													as="select" 
													className="form-select form-select-lg" 
													aria-label="Default select example" 
													name="TimeId"
													onChange={
														(e)=>{
															setFieldValue('TimeId', e.target.value);
															setFieldValue('expiry_time', 0);
														}
													}
												>
													<option value={0} disabled>{t('stores.payment.select_an_time')}</option>
													{
														['years', 'months', 'days'].map((e,i) => 
															<option key={`time-${i}`} value={i+1}>{t(`utilities.${e}`)}</option>
														)
													}                                                                                               
												</Field>
												<ErrorMessage name="TimeId" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="expiry_time">
													<option value={0} disabled className="text-capitalize">{t('stores.payment.select_expire_time')}</option>
													{ 
														expiryTime(values.TimeId) 
													}
												</Field>
												<ErrorMessage name="expiry_time" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<button 
												className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
												type="submit"
												disabled={loading}
											><span className='me-3 fw-bold'>
												{t(`stores.payment.create_pay`)}
											</span>
											{
												loading &&
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											}
											</button>   
										</Form>
										)}
									</Formik>
								}
							</main>
						</div>
					</div>
				</div>
			</div>			
		</div>
		</>
	)
}

export default CreateStorePayment