import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUserPen, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
const Users = () => {
    const { search }          = useLocation();
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [data, setData]           = useState([]);
    const [loading, setLoading]     = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage]     = useState(10);
    
    const [_email, set_email]        = useState('');
    const [_rol, set_rol]            = useState(0);
    const {t,i18n}                   = useTranslation();
    const paginationComponentOptions = usePaginationComponentOptions();
/***************************************************************************************************************************/

    const getUsers = async (page) => {
		setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}users?page=${page}&per_page=${perPage}&email=${_email}&rol=${_rol}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getUsers(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}users?page=${page}&per_page=${newPerPage}&email=${_email}&rol=${_rol}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_email(params.email);
        set_rol(params.rol);
        const headers = await getHeaders();
        await axios.get(`${url}users?page=1&per_page=${perPage}&email=${params.email}&rol=${params.rol}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/

    const deleteUser = (params) => {

        Swal.fire({
			title: t('users.deleted'),
			text: t('users.deleted_txt'),
			showCancelButton: true,
			confirmButtonText: t('users.delete'),
			customClass: {
				confirmButton: 'btn btn-outline-danger',
				cancelButton: 'btn btn-primary mx-2'
			},
			buttonsStyling: false,
			showClass: {
				popup: 'animate__animated animate__fadeInDown'
			},
			  hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			}
		  }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    await axios.delete(`${url}users/destroy/${params}`, {headers})
                               .then(res =>{
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Success delete',
                                    });                        
                                    getUsers(1);
                               })
                               .catch(err =>{
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error on delete',
                                    });
                               })
                               .then(() =>{

                               })
                }
		  })

    }


    useEffect(() => {
        getUsers(1)
    }, [i18n.language]);

/***************************************************************************************************************************/

    const columns = [
        {
            name: t('users.email'),
            cell: row => {
                return <div className='w-100 text-start'>
                    {row.email}
                </div>
            }
        },
        {
            name: 'Roles',
            cell: (row, index, column, id) => {
                let x = (row.Roles === undefined) ? undefined : row.Roles;
                
                return <div className='w-100 text-start'>
                            <ul className='ps-0'>
                                {
                                    x !== undefined &&
                                        x.map((e,i) =>{
                                            return <li className='list-group-item bg-body-tertiary' 
                                                        key={`role-${i}`}
                                                    >
                                                        <span>{t(`users.${e?.name}`)}</span>
                                                        {
                                                            e.name.trim() === 'partner' &&
                                                            <>
                                                                <span>: {row?.Partner?.name}</span>
                                                                <p className='mb-0'>¿Usa prepago?: {row?.prepay ? 'Si' : 'No'}</p>
                                                            </>
                                                        }
                                                        {
                                                            e.name.trim() === 'subpartner' &&
                                                            <>
                                                                <span>: {row?.Partner?.name}</span>
                                                                <span>: {row?.Subpartner?.email}</span>
                                                                <p className='mb-0'>¿Usa prepago?: {row?.prepay ? 'Si' : 'No'}</p>
                                                            </>
                                                        }
                                                        {
                                                            e.name.trim() === 'marketing' &&
                                                                <span>: {row?.Marketing?.name}</span>
                                                        }
                                                    </li>                                   
                                        })
                                }
                            </ul>
                        </div>
            },
        },
        {
            name: t('users.actions'),
            cell: (row, index, column, id) => {
                let x = (row.Roles === undefined) ? undefined : row.Roles;
                let y = "";
                if(x !== undefined){
                    y = x.find(x => x.name === 'admin') ? " disabled" : ""
                }
                return <>
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-bd-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>{t('users.options')}</span>	
                                </a>
                                <ul className="dropdown-menu">
                                    <li>                                                                             
                                        <Link 
                                            className="dropdown-item fw-bold text-success" 
                                            to={`edit/${row.id}`}
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faUserPen} className='me-2'/>  
                                                </div>
                                                <div className="col-10">
                                                    <span>{t('users.edit')}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>                                      
                                        <a           
                                            href="#!"                      
                                            className={"dropdown-item fw-bold text-danger"}
                                            onClick={() => {
                                                if(y === ""){
                                                    deleteUser(row.id)
                                                }
                                            }}      
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faTrash} className='me-2'/>
                                                </div>
                                                <div className="col-10">
                                                    <span>{t('users.delete')}</span>
                                                </div>
                                            </div>                                                                         
                                        </a>
                                    </li>
                                </ul>
                            </div>                   
                       </>
            },
        },
    ];
/*************************************************************************************************************************/
    return (
        <div className='row align-items-start'>
            <div className="col-sm-4 col-md-4 col-lg-2 mb-3">
                <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                    <FontAwesomeIcon icon={faUserPlus} className="me-2"/>
                    <span className='text-capitalize fw-bold'>{t('users.add_user')}</span>
                </Link>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                   {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <Formik
                                    initialValues={{ 
                                        email: _email,
                                        rol  : _rol
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="rol" className="col-sm-2 col-form-label col-form-label-sm">{t('users.email')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder={t('users.email')} 
                                                    aria-label="Recipient's username"
                                                    aria-describedby="button-addon2"
                                                    name="email"
                                                />                        
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="rol" className="col-sm-2 col-form-label col-form-label-sm">Rol</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder="rol" 
                                                    name="rol"
                                                    id="rol"
                                                >
                                                    <option value={0}>{t('users.all_roles')}</option>
                                                    <option value={1}>{t('users.admin')}</option>
                                                    <option value={2}>{t('users.partner')}</option>
                                                    <option value={4}>{t('users.marketing')}</option>
                                                    <option value={5}>{t('users.customer')}</option>
                                                    <option value={6}>{t('users.sw')}</option>
                                                    
                                                </Field>

                                                {/* <ErrorMessage name="rol" component="div" className='field-error text-danger fw-bold text-capitalize'/>                                                    */}

                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold me-2'>
                                                    {t('utilities.search_btn')}
                                                </span> 
                                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                            </button>
                                        </div>
                                    </Form>
                                </Formik>                                
                            </div>
                        </div>
                    </div>
                </div>              
            </div>

            <div className="col-md-12">
                <div className="card card-shadow mb-5">
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="850px"
                        progressPending={loading}
                        pagination
                        paginationPerPage={perPage}
			            paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                        dense
                    />
                </div>
            </div>
        </div>
  )
}

export default Users