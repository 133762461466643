import React from 'react'
import Header from './Header'

const Contentdos = () => {
  return (
    <>
    {/* <div className="col p-0"> */}
        <div className='h-100' style={{marginTop: '5rem'}}>
            <div >
                <p>jeis 2</p>
            </div>
        </div>
    {/* </div> */}
    </>
  )
}

export default Contentdos