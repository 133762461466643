import React, {useState, useEffect, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import CustomLoader from '../../layouts/CustomLoader';
import AuthContext from '../../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
const CreatePlan = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const { id }                = useParams();
    const [product, setProduct] = useState(null);
    const [charge, setcharge]   = useState(false);
    const [loading, setloading] = useState(false);
    const [times, setTimes]     = useState([]);
/***************************************************************************************************************************/

    const first = async () => {
        const headers = await getHeaders();
        await axios.get(`${url}products/edit/${id}`, {headers})
                    .then(res =>{
                        setProduct(res.data.message)
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                    });

    }

    const second = async () => { 
        const headers = await getHeaders();
        await axios.get(`${url}times`, {headers})
                    .then(res =>{
                        setTimes(res.data.message)
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                    })
                    .then(() =>{
                        setcharge(true)
                    })
    }

    useEffect(() => {
      
        first();
        second();
    
    }, [])
    

/***************************************************************************************************************************/
    const createPlan = async (params, {resetForm}) => {
        
        setloading(true);
        console.log(params);
        params.ProductId = id;
        // return;
        const headers = await getHeaders();
        await axios.post(`${url}plans`, params, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        resetForm();
                        console.log(res);
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setloading(false);
                    });
    }
/***************************************************************************************************************************/

    const expiryTime = (params = 0, setFieldValue) => { 
        console.log(params);
        let cont = 0
        switch (parseInt(params)) {
            case 1:
                cont = 3;
                
                break;
            case 2:
                cont = 25;
                break;
            case 3:
                cont = 32;
                break;
        
            default:
                break;
        }
        let td = [];
        for (let i = 1; i < cont; i++) {
            td.push(<option key={`time_expiricy-${i}`} value={i}>{i}</option>);
        }
        return td;
    }

/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        name            : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(t('utilities.is_a_required_field')),
        description     : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(t('utilities.is_a_required_field')),
        price           : Yup.number().min(1).required(t('utilities.is_a_required_field')),
        price_mxn       : Yup.number().min(1).required(t('utilities.is_a_required_field')),
        TimeId          : Yup.number().min(1).required(t('utilities.is_a_required_field')),
        expiry_time     : Yup.number().min(1).max(31).required(t('utilities.is_a_required_field')),
        quantity        : Yup.number().min(1).required(t('utilities.is_a_required_field')),
        best_value      : Yup.number().min(1).max(2).required(t('utilities.is_a_required_field')),
        visibility      : Yup.number().min(1).max(2).required(t('utilities.is_a_required_field')),
        order           : Yup.number().min(1, 'min 1').max(99, 'max 99').required(t('utilities.is_a_required_field')),
        list_description: Yup
        .array()
        .of(Yup.string().required(t('utilities.is_a_required_field')).min(3, 'min 3 chars'))
        .required(t('products.plans.at_least'))
        .min(1, t('products.plans.at_least')),
    });
/***************************************************************************************************************************/
    return (
        <div className='container mb-5'>
            <div className='card'>
                {
                !charge
                ?   <CustomLoader/>
                :   
                    <>
                    {
                        (product === null)
                        ?   <h1 className='text-center p-5'>{t('products.plans.not_exist')}</h1>
                        :
                            <>
                            <div className='card-header'>
                                <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                                    <FontAwesomeIcon icon={faClipboardList} className="me-2"/>
            
                                    <span className='mb-0'>{t('products.plans.creation')}</span>
                                </h1>
                            </div>
                            <div className='card-body'>
                                <Formik
                                    initialValues={{ 
                                        name            : '',
                                        description     : '',
                                        price           : 0,
                                        price_mxn       : 0,
                                        best_value      : 0,
                                        list_description: [],
                                        TimeId          : 0,
                                        expiry_time     : 0,
                                        quantity        : 1,
                                        visibility      : 0,
                                        order           : 0,
                                    }}
                                    validationSchema={formSchema}
                                    onSubmit={(values, actions) => createPlan(values, actions)}
                                >
                                    {({values, setFieldValue}) => (
                                    <Form>
                                        <div className='row justify-content-center'>
                                            <div className='col-md-8 col-lg-6'>
                                                <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                
            
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="name" placeholder="name" name="name"/>
                                                            <label htmlFor="name">{t('products.plans.name')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>
            
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="description" placeholder="Description" name="description"/>
                                                            <label htmlFor="description">{t('products.plans.description')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="description" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>
            
                                                    {/* price USD  */}
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="price" placeholder="price" name="price"/>
                                                            <label htmlFor="price">{t('products.plans.price')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="price" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    {/* price MXN  */}
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="price_mxn" placeholder="price_mxn" name="price_mxn"/>
                                                            <label htmlFor="price_mxn">Precio en Pesos MXN (*)</label>
                                                        </div>
                                                        <ErrorMessage name="price_mxn" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                   
                                                    <div className="mb-3">
                                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="best_value">
                                                            <option value={0} disabled className="text-capitalize">{t('products.plans.select_a_plan')}</option>
                                                            <option value={1} className="text-capitalize">{t('products.plans.normal')}</option>
                                                            <option value={2} className="text-capitalize">{t('products.plans.best')}</option>
                                                            
                                                        </Field>
                                                        <ErrorMessage name="best_value" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Field as="select"  
                                                                className="form-select form-select-lg text-capitalize" 
                                                                aria-label="Default select example" 
                                                                name="TimeId"
                                                                onChange={
                                                                    (e)=>{
                                                                        setFieldValue('TimeId', e.target.value);
                                                                        setFieldValue('expiry_time', 0);
                                                                    }
                                                                }
                                                        >
                                                            <option value={0} disabled className="text-capitalize">{t('products.plans.select_a_time')}</option>                                                            
                                                            <option value={1} className="text-capitalize">{t('utilities.years')}</option>
                                                            <option value={2} className="text-capitalize">{t('utilities.months')}</option>
                                                            <option value={3} className="text-capitalize">{t('utilities.days')}</option>                                                                                                                        
                                                        </Field>
                                                        <ErrorMessage name="TimeId" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="expiry_time">
                                                            <option value={0} disabled className="text-capitalize">{t('products.plans.select_a_expire')}</option>  
                                                            {
                                                                expiryTime(values.TimeId) 
                                                            }                                                          
                                                        </Field>
                                                        <ErrorMessage name="expiry_time" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="visibility">
                                                            <option value={0} disabled className="text-capitalize">{t('products.plans.select_a_option')}</option>
                                                            <option value={1} className="text-capitalize">{t('products.hidden')}</option>
                                                            <option value={2} className="text-capitalize">{t('products.show')}</option>
                                                            
                                                        </Field>
                                                        <ErrorMessage name="visibility" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="quantity" placeholder="quantity" name="quantity"/>
                                                            <label htmlFor="quantity">{t('products.plans.licenses_quantity')}</label>
                                                        </div>
                                                        <ErrorMessage name="quantity" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="order" placeholder="order" name="order"/>
                                                            <label htmlFor="order">{t('products.order')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="order" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                     {/* Array */}
                                                    <div className="mb-3">
                                                        <FieldArray
                                                            name="list_description"
                                                            render={({insert, remove, push}) => ( 
                                                            <>
                                                                <div className="row">
                                                                    <label className="col-form-label col-md-10">{t('products.plans.description_list')}</label>
                                                                    <div className="col-sm-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-shadow btn-bd-primary fw-bold mb-3 form-control-plaintext"
                                                                            onClick={() => push("")}
                                                                        >+</button>
                                                                    </div>
                                                                </div>
                                                                {values.list_description.map((element, index) => (
                                                                    <div key={index}>
                                                                        <div className="input-group mb-3">
                                                                            <Field type="text" className="form-control" name={`list_description.${index}`} placeholder={`#${index+1}.- Description list`}/>
                                                                            <button className="btn btn-danger" type="button" onClick={() => remove(index)}>-</button>
                                                                        </div>
                                                                        <ErrorMessage
                                                                            name={`list_description.${index}`}
                                                                            component='div'
                                                                            className='field-error text-danger'
                                                                        />
                                                                    </div>
                                                                ))}
                                                                {
                                                                    
                                                                    values.list_description.length <= 0  &&                                                                 
                                                                        <div className='field-error text-danger'>
                                                                            <p>{t('products.plans.at_least')}</p>
                                                                        </div>
                                                                }

                                                            </>
                                                            )}
                                                        />
                                                    </div>
                                                    {/* End Array  */}
                                                
                                                    <div className="mt-4">
                                                        <button 
                                                            className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                            type="submit"
                                                            disabled={loading}
                                                        ><span className='me-3 fw-bold'>
                                                            {t('products.plans.create')}
                                                        </span>
                                                        {
                                                            loading &&
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        }
                                                        </button> 
                                                    </div>
            
                                                </main>
                                            </div>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>
                            </div>
                            </>
                    }                                        
                    </>
                }                
            </div>
        </div>
    )
}

export default CreatePlan