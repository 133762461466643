import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faMagnifyingGlass, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import CardProduct from './CardProduct';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const ProductsPlans = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [products, setProducts]    = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [_search, set_search]      = useState('');
    const {t}                        = useTranslation()
    const paginationComponentOptions = usePaginationComponentOptions();
    const getProducts = async (page) => {
        const headers = await getHeaders();
        await axios.get(`${url}products?page=${page}&per_page=${perPage}&search=${_search}`, {headers})
        // await axios.get(`${url}products`)
                    .then(res =>{
                        setProducts(res.data.message);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getProducts(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        await axios.get(`${url}products?page=${page}&per_page=${newPerPage}&search=${_search}`)
                    .then(res =>{
                        setProducts(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_search(params.search);
        await axios.get(`${url}products?page=1&per_page=${perPage}&search=${params.search}`)
                    .then(res =>{
                        setProducts(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    useEffect(() => {
        getProducts(1);
    }, []);
/***************************************************************************************************************************/
    const columns = [
        {
            name: t('products.products'),
            cell: (row, index, column, id) => <CardProduct 
                                                    product={row}
                                                    i={index}
                                                    url={url}
                                                    setProducts={setProducts}
                                                />
            },
    
    ];

/***************************************************************************************************************************/
  return (
    <div className='row align-items-center'>
        <div className="col-sm-4 col-md-4 col-lg-2 mb-3">
            <Link to="products/create" className='btn btn-bd-primary btn-shadow d-block'>
                <FontAwesomeIcon icon={faBoxOpen} className="me-2"/>
                <span className='text-capitalize fw-bold'>{t('products.add_product')}</span>
            </Link>
        </div>
        <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
            <Formik
                initialValues={{ 
                    search: _search
                }}
                onSubmit={(values, actions) => setSearch(values, actions)}
                enableReinitialize
            >
                <Form>
                    <div className="input-group rounded-pill">
                        <Field 
                            type="text" 
                            className="form-control" 
                            placeholder={t('products.search')}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            name="search"
                        />                        
                        <button 
                            className="btn btn-outline-secondary" 
                            type="submit" 
                            id="button-addon2"
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>

        <div className="col-md-12 mb-5">
            <div className="card card-shadow">
                <DataTable
                    columns={columns}
                    data={products}
                    fixedHeader
                    fixedHeaderScrollHeight="1000px"
                    progressPending={loading}
                    pagination
                    paginationPerPage={perPage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    progressComponent={<CustomLoader />}
                    dense
                />

                <div className='card-footer'>
                    <div className='row'>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <Link to="products/restore" className='btn btn-sm btn-shadow btn-warning opacity-50 text-dark mt-3 w-100'>
                                <FontAwesomeIcon icon={faRecycle}/>
                                <span className='ms-2'>
                                    {t('products.plans_archived_txt')}
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductsPlans