import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { faUser, faCircleArrowRight, faHandshake, faCommentDollar, faStore } from '@fortawesome/free-solid-svg-icons';
import { faStripe, faCcStripe } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Payments = () => {

    const {t} = useTranslation();

    const allPayments = [
        {
            title   : t('payments.pay_customer'),
            subtitle: t('payments.go_customer'),
            link    : 'admin/customers',
            faicon  : faUser,
            opacity : 'opacity-100',
        },
        {
            title   : t('payments.pay_partners'),
            subtitle: t('payments.go_partners'),
            link    : 'admin/partners',
            faicon  : faHandshake,
            opacity : 'opacity-100',
        },
        {
            title   : t('payments.pay_marketing'),
            subtitle: t('payments.go_marketing'),
            link    : 'admin/marketings',
            faicon  : faCommentDollar,
            opacity : 'opacity-100',
        },
        {
            title   : t('payments.pay_stores'),
            subtitle: t('payments.go_stores'),
            link    : 'admin/stores',
            faicon  : faStore,
            opacity : 'opacity-100',
        },
        {
            title   : t('payments.create'),
            subtitle: t('payments.create'),
            link    : '#',
            // link    : 'admin/marketings',
            faicon  : faStripe,
            opacity : 'opacity-25',
        },
        {
            title   : t('payments.show'),
            subtitle: t('payments.show'),
            link    : '#',
            // link    : 'admin/marketings',
            faicon  : faCcStripe,
            opacity  : 'opacity-25',
        },
    ];
    return (
        <section className='container-fluid pb-5 border-bottom border-5 border-secondary '>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 g-4 p-5 border-1'>
                {
                    allPayments.map((e,i) =>
                        <div className={`col animate__animated animate__fadeIn ${e.opacity}`} key={`all-${i}`}>
                            <div className="card card-shadow h-100 bs-dark-border-subtle text-dark-emphasis bg-light-subtle">                        
                                <div className="card-body">
                                    <Link to={e.link} className='text-decoration-none text-body-emphasis'>
                                        <div className="row align-items-center">

                                            <div className="col-md-8 text-center  text-md-start">
                                                <h5 className="card-title fw-bold text-uppercase mb-0">{e.title}</h5>
                                            </div>

                                            <div className="col-md-4 text-center text-md-end mt-3 mt-md-0">
                                                <FontAwesomeIcon icon={e.faicon} size="3x"/>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                                <div className='card-footer bg-bs-primary'>
                                    <Link to={e.link} className='text-decoration-none text-light d-flex align-items-center justify-content-center'>
                                        <span className="card-text me-1">{e.subtitle}</span>
                                        <FontAwesomeIcon icon={faCircleArrowRight} size="1x"/>                        
                                    </Link>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    )
}

export default Payments