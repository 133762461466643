import React, {Fragment, useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUpload, faCircleXmark, faCommentDollar, faFilePdf, faPlusCircle, faEyeDropper, faEye, faMoneyBill, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons';
// import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { DateTime } from "luxon";
import CustomerForm from './adminForms/CustomerForm';
import PartnerForm from './adminForms/PartnerForm';
import MarketingForm from './adminForms/MarketingForm';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { faOpenid } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
const Admin = () => {
    const { usertypes }       = useParams();
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    //search
    const [_stripe, set_stripe]         = useState(''); // --customer & Partner
    //--customer
    const [_payeremail, set_payeremail] = useState('');
    const [_package, set_package]       = useState('');
    //--partner pospayment
    const [partner, setpartner] = useState('');
    //--marketing markpayment
    const [marketing, setmarketing] = useState('');
    const [invoices, setinvoices]   = useState(null)
    const btnInvoices               = useRef(null);
    const btnInvoicesStores         = useRef(null);
    const btnInvoicesStoresCls      = useRef(null);
    const {t, i18n}                       = useTranslation();
/***************************************************************************************************************************/
    const [data, setData]               = useState([]);
    const [loading, setLoading]         = useState(false);
    const [totalRows, setTotalRows]     = useState(0);
    const [perPage, setPerPage]         = useState(10);
    const [columns, setcolumns]         = useState([]);
    const paginationComponentOptions    = usePaginationComponentOptions();

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
/***************************************************************************************************************************/

    const getPayments = async (page) => {
		setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/admin/${usertypes}?page=${page}&per_page=${perPage}&stripe=${_stripe}&payeremail=${_payeremail}&package=${_package}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPayments(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/admin/${usertypes}?page=${page}&per_page=${newPerPage}&stripe=${_stripe}&payeremail=${_payeremail}&package=${_package}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const sumTotal = (items) => { 
        let sum = 0;

        for (const element of items) {
            sum += parseFloat(element.total_amount);
        }

        return sum.toFixed(2);
    }
/**************************************************************************************************************************************/
    const getInvoicePartnerPayment = async(params) => { 
        console.log(params);
        // download.current.click();
        const headers = await getHeaders();
        const data = {
            pi : params
        }
        await axios.post(`${url}partners/partner-pdf`, data, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/**************************************************************************************************************************************/
    const getCSVPartner = async(params) => { 
        console.log(params);
        // download.current.click();
        const headers = await getHeaders();
        const data = {
            pi : params
        }
        const date  = DateTime.now().toFormat('yyyy_LLL_dd_HH_mm_ss');
        await axios.post(`${url}partners/partner-csv`, data, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'text/csv'}));
                        const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const link      = document.createElement('a');
                              link.href = url;
                            //   link.setAttribute('download', `${link}-${_startDate.toISODate()}-${_endDate.toISODate()}.csv`);
                              link.setAttribute('download', `report-partner-range-${date}.csv`);
                              document.body.appendChild(link);
                              link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/***************************************************************************************************************************/
    const getInvoices = (pi, pay='marketing') => { 
        console.log(pi)
        setinvoices( pi )
        if(pay === 'marketing') {
            btnInvoices.current.click();
        }else{
            btnInvoicesStores.current.click();
        }
    }
/***************************************************************************************************************************/
    const getInvoiceMarketings = async(params) => { 
        console.log(params);
        console.log(invoices.id);
      //   return;
      //   download.current.click();
        const headers = await getHeaders();
        const data = { pi: params, markpaymentId: invoices.id }
        await axios.post(`${url}marketings/get-invoices-sub-pdf`, data, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/***************************************************************************************************************************/
    const getInvoiceStores = async(params) => { 
        console.log(params);
        btnInvoicesStores.current.click();

        const headers = await getHeaders();
        const data = { pi: params.invoice_store }
        await axios.post(`${url}stores/store-pdf`, data, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            await btnInvoicesStoresCls.current.click();
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/***************************************************************************************************************************/
    const notRefund = () => { 
        Swal.fire({
            icon: 'error',
            title: t('payments.admin.refound_title'),
            text: t('payments.admin.refound_txt')
        })
    }
/***************************************************************************************************************************/
    const customers = () => { 
        const result = [
            {
                maxWidth: "20%",
                name: t('payments.admin.stripe_payment'),
                cell: row => {
                    return  <>
                                <ul className="ps-0 my-2">
                                    <li className='list-group-item pb-1 mb-1 border-bottom'>
                                        <b>Checkout ID: </b>
                                        <span>
                                            {row.cs_stripe_id}
                                        </span>
                                    </li> 
                                    <li className='list-group-item pb-1 mb-1bottom'>
                                        <p className='mb-0'><b>Pi_ID: </b>{row.pi_stripe_id}</p>
                                    </li>                             
                                </ul>   
                            </>
                },
            },
            {
                maxWidth: "25%",
                name: t('payments.admin.stripe_data'),
                cell: (row, index, column, id) => {
                    const d = new Date(row.createdAt);
                    let amount = 0
                    return  <>
                                <div className='w-100 text-start mb-3'>
                                    <b>{t('payments.admin.payer_email')}: </b>
                                    <span>{row.Payeremail.email}</span>
                                </div>
                                
                                <div className='w-100 text-start mb-3'>
                                    <b>{t('payments.admin.date_purchase')}: </b>
                                    <span>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)}</span>
                                </div>

                                <div className='w-100 text-start mb-3'>
                                    <b>{t('payments.admin.amount_total')}: </b>
                                    <p>${sumTotal(row.Itempayments)} {row.Itempayments[0].currency}</p>                                 
                                </div>

                                <div className='w-100 text-start mb-1'>
                                    <b>{t('payments.admin.status_payment')}:</b>
                                </div>
                            {
                                
                                (row.payment_status === 'paid')
                                ?   <div className='bg-success rounded-pill text-center w-100 mb-2'>
                                        <p className="text-uppercase fw-bold p-1 mb-0 text-light">
                                            {row.payment_status}
                                        </p>                                
                                    </div>
                                :   <div className='bg-warning rounded-pill text-center w-100 mb-2'>
                                        <p className="text-uppercase fw-bold p-1 mb-0 text-light">
                                            {row.payment_status}
                                        </p>                                
                                    </div>
                            }   
                            </>
                }
            },
            {
                maxWidth: "20%",
                name: t('payments.admin.product'),
                cell: (row, index, column, id) => {
                    let counter = 0
                    return  <ul className="ps-0 my-2">
                                { 
                                    // row?.Plans.map((e,i) =>
                                    row?.Packages.map((e,i) =>
                                    {
                                        return <Fragment key={`package-${i}`}>
                                            <li className='list-group-item pb-1 mb-1 border-bottom heigths-pac-pro' >
                                                <div className='row'>
                                                    <div className='col-2 p-0'>
                                                        <b>{i+1}.-</b>
                                                    </div>
                                                    <div className='col-10 p-0'>
                                                        <div>
                                                            <b>Plan: </b> <span>{e?.Plans[0]?.name || '-'}</span>
                                                        </div>
                                                        <div>
                                                            <b>{t('payments.admin.product')}: </b> <span>{e?.Plans[0]?.Product?.name || '-'}</span>
                                                        </div>
                                                        <div>
                                                        {
                                                            row.Itempayments[0].currency === 'mxn'
                                                            ?   <>
                                                                    <b>{t('payments.admin.price')}: </b> <span>${parseFloat(e?.Plans[0]?.price_mxn).toFixed(2)  || '-'} mxn</span>
                                                                </>
                                                            :   <>
                                                                    <b>{t('payments.admin.price')}: </b> <span>${parseFloat(e?.Plans[0]?.price).toFixed(2)  || '-'} usd</span>
                                                                </>
                                                        }                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> 
                                        </Fragment>
                                    })
                                }
                            </ul>                         
                }
            },
            {
                maxWidth: "25%",
                name: t('payments.admin.package'),
                cell: (row, index, column, id) => {
                    return  <ul className="ps-0 my-2">
                                {
                                row?.Packages.map((e,i) =>
                                    {
                                        let days = 0, months = 0, years = 0;
                                        switch (parseInt(e.TimeId)) {
                                            case 1:
                                                years = e.expiry_time
                                                break;
                                            case 2:
                                                months = e.expiry_time
                                                break;
                                            case 3:
                                                days = e.expiry_time
                                                break;
                                        }
                                        const expiry = DateTime.fromISO(e.createdAt).plus({days, months, years}).toISODate();
                                        return <Fragment  key={`plans-${i}`}>
                                        <li className='list-group-item pb-1 mb-1 border-bottom heigths-pac-pro' >
                                            <div className='row'>
                                                <div className='col-2 p-0'>
                                                    <b>{i+1}.-</b>
                                                </div>
                                                <div className='col-10 p-0'>
                                                    <div>
                                                        <b>{t('payments.admin.package')}: </b>  
                                                        <Link to={`/packages/show/${e.serial_number}`} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                        >
                                                            <span>{e.serial_number}</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <b>{t('payments.admin.status')}: </b> <span>{e.Status.name}</span>
                                                    </div>
                                                    <div>
                                                        <b>{t('payments.admin.expire_at')}: </b> <span>{expiry}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                    </Fragment>}

                                )
                                }
                            </ul>                         
                }
            },
            {
                minWidth: "150px",
                name: t('payments.admin.actions'),
                cell: (row, index, column, id) => {
                    const d    = new Date(row.createdAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = now.diff(ex, ['days']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    console.log(diff.toObject().days)
                    const x = (diff.toObject().days >= 31 || row.payment_status === 'refunded') ? true : false;

                    return <>                          
                            <div className="dropdown mb-2">
                                <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                    <span>{t('payments.admin.options')}</span>	
                                </a>
                                <ul className="dropdown-menu">
                                    <li>                                      
                                        <a           
                                            href="#!"                      
                                            className={x ? `dropdown-item text-muted opacity-50 prohibed` : `dropdown-item fw-bold text-warning`}
                                            onClick={() => {
                                                x ? notRefund() : refund(row.pi_stripe_id)                                                
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-2">
                                                    <FontAwesomeIcon icon={faCommentDollar} className='me-2'/>
                                                </div>
                                                <div className="col-10">
                                                    <span>{t('payments.admin.refund')}</span>
                                                </div>
                                            </div>                                                                         
                                        </a>
                                    </li>
                                </ul>
                            </div>  
                        </>
                },
            },
        ];
    return result;
    }
/***************************************************************************************************************************/
    const partners = () => { 
        const result = [
            {
                name: t('payments.admin.stripe_data'),
                cell: row => {
                    return  <>
                                <ul className="ps-0 my-2">
                                    <li className='list-group-item pb-1 mb-1 border-bottom'>
                                        <b>Checkout ID: </b>
                                        <span>
                                            {row?.cs_stripe_id}
                                        </span>
                                    </li> 
                                    <li className='list-group-item pb-1 mb-1 bottom'>
                                        <p className='mb-0'><b>Payment intent ID: </b>{row?.pi_stripe_id}</p>
                                    </li>                             
                                </ul>   
                            </>
                },
            },
            {
                maxWidth: '120px',
                name: t('payments.admin.status'),
                cell: (row, index, column, id) => {                                       
                    return  <>
                            {
                                (row.payment_status === 'paid')
                                &&   <div className='badge text-bg-primary text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {t(`payments.partner.${row.payment_status}`) }
                                        </p>                                
                                    </div>                                
                            }
                            {
                                (row.payment_status === 'pending')
                                &&   <div className='badge text-bg-warning text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {t(`payments.partner.${row.payment_status}`) }
                                        </p>                                
                                    </div>
                            }                                   
                            {
                                (row.payment_status === 'pending transfer')
                                &&   <div className='badge text-bg-warning text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {/* {t(`payments.partner.${row.payment_status}`) } */}
                                            {row.payment_status}
                                        </p>                                
                                    </div>
                            }     
                            {
                                (row.payment_status === 'payment confirm send' )
                                &&   <div className='badge text-bg-light text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-dark">
                                        {row.payment_status}
                                        {/* {t(`payments.partner.${row.payment_status}`) } */}
                                        </p>                                
                                    </div>
                            }                                
                            {
                                (row.payment_status === 'externally paid')
                                &&   <div className='badge text-bg-primary text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {t(`payments.partner.externally`) }
                                        </p>                                
                                    </div>
                            }                                   
                            </>
                }
            },
            {
                maxWidth: '120px',
                name: t('payments.admin.quantity_license'),
                cell: (row, index, column, id) => <p>{row?.licenses_quantity}</p>
                
            },
            {
                maxWidth: "20px",
                name: t('user_type.partner'),
                cell: (row, index, column, id) => {
                    const prepay = row?.User?.prepay ? 'Prepay' : '';
                    return <div className='text-start w-100'>
                        <p className='text-capitalize '>{row.Partner.name}</p>
                        <p className='text-capitalize fw-bold'>{prepay}</p>
                    </div>
                }
            },
            {
                maxWidth: "20px",
                name: t('payments.admin.amount_total'),
                cell: (row, index, column, id) => <p className='text-capitalize'>${parseFloat(row.total_amount).toFixed(2)}</p>
            },
            {
                maxWidth: "220px",
                name: t('Fechas'),
                cell: (row, index, column, id) => {
                    const sd = new Date(row.start_date);
                    const ed = new Date(row.end_date);
                    const cd = new Date(row.createdAt);
                    return <div className='text-left'>
                        <p className='mb-0 fw-bold'>Periodo de suscripciones</p>
                        <p className='text-capitalize'>{sd.toISOString().slice(0,10)} - {ed.toISOString().slice(0,10)}</p>
                        <p className='mb-0 fw-bold'>Fecha de emisión de factura</p>
                        <p className='text-capitalize'>{cd.toISOString().slice(0,10)} {cd.toISOString().slice(11,19)}</p>
                    </div>
                }
            },
            {
                name: t('payments.admin.actions'),
                cell: (row, index, column, id) => {
                    const pcs = (row.payment_status === 'payment confirm send' && row?.User.prepay)
                    const pe  = (row.payment_status === 'externally paid' && row?.User.prepay)
                    return <>                          
                                <div className="dropdown mb-2" >
                                    <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle " href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{t('payments.admin.options')}</span>	
                                    </a>
                                    <ul className="dropdown-menu w-150" >
                                        <li>                                      
                                            <a           
                                                href="#!"                      
                                                className={`dropdown-item fw-bold`}
                                                onClick={() => getInvoicePartner(row.id) }
                                            >
                                                <div className="row">
                                                    <div className="col-2">
                                                        <FontAwesomeIcon icon={faFilePdf} className='me-2'/>
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Recibo</span>
                                                    </div>
                                                </div>                                                                         
                                            </a>
                                        </li>
                                        <li>                                      
                                            <a           
                                                href="#!"                      
                                                className={`dropdown-item fw-bold`}
                                                onClick={() => getCSVPartner(row.cs_stripe_id) }
                                            >
                                                <div className="row">
                                                    <div className="col-2">
                                                        <FontAwesomeIcon icon={faFileCsv} className='me-2'/>
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Reporte CSV</span>
                                                    </div>
                                                </div>                                                                         
                                            </a>
                                        </li>
                                        {
                                            pe && <>
                                                <li>                                               
                                                    <a           
                                                        href="#!"                      
                                                        className={`dropdown-item fw-bold`}
                                                        onClick={() => viewPaymentConfirm(row?.cs_stripe_id)}
                                                    >
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faEye} className='me-2'/>
                                                            </div>
                                                            <div className="col-10">
                                                                <span>Payment confirm</span>
                                                            </div>
                                                        </div>                                                                         
                                                    </a>
                                                </li>
                                                <li> 
                                                    <a
                                                        href="#!"
                                                        className={`dropdown-item fw-bold`}
                                                        // onClick={() => getInvoicePartner(row.id)}
                                                        onClick={() => getInvoicePartnerPayment(row.pi_stripe_id) }
                                                    > 
                                                        <FontAwesomeIcon icon={faMoneyBill}/>
                                                        <span className="ms-1">
                                                            Payment
                                                        </span>
                                                    </a>
                                                                                       
                                                    
                                                </li>
                                            </>
                                        }
                                        {
                                            pcs &&
                                            <>
                                                
                                                <li>                                               
                                                    <a           
                                                        href="#!"                      
                                                        className={`dropdown-item fw-bold`}
                                                        onClick={() => viewPaymentConfirm(row?.cs_stripe_id)}
                                                    >
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faEye} className='me-2'/>
                                                            </div>
                                                            <div className="col-10">
                                                                <span>Payment confirm</span>
                                                            </div>
                                                        </div>                                                                         
                                                    </a>
                                                </li>
                                                <li>                                               
                                                    <a           
                                                        href="#!"                      
                                                        className={`dropdown-item fw-bold text-warning`}
                                                        onClick={() => externallyPaid(row?.cs_stripe_id)}
                                                    >
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faCommentDollar} className='me-2'/>
                                                            </div>
                                                            <div className="col-10">
                                                                <span>{t(`payments.partner.externally`) }</span>
                                                            </div>
                                                        </div>                                                                         
                                                    </a>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>  
                        </>
                },
            },
        ];
    return result;
    }
/***************************************************************************************************************************/
    const marketings = () => { 
        const result = [
            {
                maxWidth: "5%",
                name: 'Marketing',
                cell: row => {
                    return  <div>
                                <p className='text-capitalize'>{row.Marketing.name}</p>
                                <p className='text-capitalize m-0'>Invoice email: {row.Marketing.invoice_email}</p>
                            </div>
                },
            },
            {
                maxWidth: "20%",
                name: t('payments.admin.stripe_data'),
                cell: row => {
                    return  <>
                                <ul className="ps-0 my-2">
                                    <li className='list-group-item pb-1 mb-1 border-bottom'>
                                        <b>Checkout ID: </b>
                                        <span>
                                            {row?.cs_stripe_id}
                                        </span>
                                    </li> 
                                    <li className='list-group-item pb-1 mb-1 bottom'>
                                        <p className='mb-0'><b>Pay ID: </b>{row?.pi_stripe_id}</p>
                                    </li>                             
                                </ul>   
                            </>
                },
            },
            {
                maxWidth: "10%",
                name: t('payments.admin.status'),
                cell: (row, index, column, id) => {                   
                    return  <>
                            {
                                (row.status_suscription === 'active')
                                ?   <div className='badge text-bg-primary'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {row.status_suscription}
                                        </p>                                
                                    </div>
                                :   <div className='badge text-bg-warning'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {row.status_suscription}
                                        </p>                                
                                    </div>
                            }
                              <div>
                                  <p>{t('payments.admin.count')}: <b>{row.count_subscription}</b></p>
                              </div>
                            </>
                }
            },
            {
                maxWidth: "10%",
                name: t('payments.admin.amount_total'),
                cell: (row, index, column, id) => {
                    const total_amount = (row.total_amount !== null) ? `$${parseFloat(row.total_amount).toFixed(2)}` : '-'
                    return <p className='text-capitalize'>{total_amount}</p>
                }
            },
            {
                maxWidth: "15%",
                name: t('payments.admin.expire_at'),
                cell: (row, index, column, id) => {
                    const createdAt = DateTime.fromISO(row.createdAt)
                    const expireAt = DateTime.fromISO(row.expireAt)
                    return <>
                          <span className='text-capitalize'><b>{t('payments.admin.created_at')}:</b> {createdAt.toFormat('yyyy-MM-dd')}</span>
                          <span className='text-capitalize'><b>{t('payments.admin.expire_at')}:</b> {expireAt.toFormat('yyyy-MM-dd')}</span>
                      </>
                }
            },
            {
                maxWidth: "20%",
                name: 'Region',
                cell: (row, index, column, id) => {
                    return <div className='text-start w-100'>
                              <span className='text-capitalize'><b>{t('payments.admin.country')}:</b> {row?.Markprice?.country}</span>
                              <p className='text-capitalize'></p>
                              <p className='text-capitalize m-0'><b>{t('payments.admin.states')}:</b></p>
                              {
                                  row?.Markprice?.States.length > 0 &&
                                  row?.Markprice?.States.map((e,i) => {
                                      return <p className="m-0" key={`state-${i}`}>{e.name}</p>
                                  })
                              }
                          </div>
                }
            },
            {
                maxWidth: "20%",
                name: t('payments.admin.actions'),
                cell: (row, index, column, id) => {
                    return <>                          
                              {
                                  row.pi_stripe_id !== null &&
                                  <div className="dropdown mb-2">
                                      <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                          <span>{t('payments.admin.options')}</span>	
                                      </a>
                                      <ul className="dropdown-menu">
                                            <li>                                                                             
                                                <a 
                                                    className="dropdown-item fw-bold" 
                                                    href="#!"
                                                    onClick={() => getInvoices(row)}
                                                >
                                                    <FontAwesomeIcon icon={faFilePdf} className='me-2'/>  
                                                    <span>Invoices</span>
                                                </a>
                                            </li>
                                            <li>                                                                             
                                            <Link 
                                                className="dropdown-item fw-bold" 
                                                to={`/marketings/advertising/${row.pi_stripe_id}`}
                                            >
                                                <FontAwesomeIcon icon={faUpload} className='me-2'/>  
                                                <span>{t('payments.admin.upload_banners')}</span>
                                            </Link>
                                        </li>
                                        <li className="d-none">      
                                            <a 
                                                className={row.status_suscription !== 'active' ? `disabled dropdown-item` : 'dropdown-item text-danger fw-bold'}
                                                href="#!" 
                                                // onClick={() => cancelSubscription(row.pi_stripe_id)}
                                            >
                                                <FontAwesomeIcon icon={faCircleXmark} className='me-2'/>  
                                                <span>Cancel recurring</span>
                                            </a>
                                        </li>
                                      </ul>
                                  </div>
                              }
                        </>
                },
            },
        ];
    return result;
    }
/***************************************************************************************************************************/
    const stores = () => { 
        const result = [
            {
                maxWidth: "15%",
                name: t('payments.admin.store'),
                cell: row => {
                    return  <div>
                                <p className='text-capitalize mb-0'><b>{row?.Store?.name || '-'}</b></p>
                                <p className='text-capitalize mb-0'>{row?.Store?.phone || '-'}</p>
                                <p className='text-capitalize mb-0'>{row?.Store?.whatsapp || '-'}</p>
                                <p className='text-capitalize mb-0'>{row?.Store?.email || '-'}</p>
                                <p className='text-capitalize mb-0'>{row?.Store?.link || '-'}</p>
                            </div>
                },
            },
            {
                maxWidth: "5%",
                name: t('payments.admin.name')+ ' & ' +t('payments.admin.description'),
                cell: row => {
                    return  <div>
                                <p className='text-capitalize mb-0'>{row.name}</p>
                                <p className='text-capitalize m-0'>{row.description}</p>
                            </div>
                },
            },
            {
                maxWidth: "20%",
                name: t('payments.admin.stripe_payment'),
                cell: row => {
                    return  <>
                                <ul className="ps-0 my-2">
                                    <li className='list-group-item pb-1 mb-1 border-bottom'>
                                        <b>Checkout ID: </b>
                                        <span>
                                            {row?.cs_checkout_id}
                                        </span>
                                    </li> 
                                    <li className='list-group-item pb-1 mb-1 bottom'>
                                        <p className='mb-0'><b>Payment intent ID: </b>{row?.pi_stripe_id}</p>
                                    </li>                             
                                </ul>   
                            </>
                },
            },
            {
                maxWidth: "5%",
                name: t('payments.admin.status'),
                cell: (row, index, column, id) => {                   
                    return  <>
                            {
                                (row.payment_status === 'active' || row.payment_status === 'paid')
                                ?   <div className='badge text-bg-primary'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {row.payment_status}
                                        </p>                                
                                    </div>
                                :   <div className='badge text-bg-warning'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                            {row.payment_status}
                                        </p>                                
                                    </div>
                            }
                            </>
                }
            },
            {
                maxWidth: "10%",
                name: t('payments.admin.price'),
                cell: (row, index, column, id) => {
                    const price = (row.price !== null) ? `$${parseFloat(row.price).toFixed(2)}` : '-'
                    return <p className='text-capitalize'>{price}</p>
                }
            },
            {
                maxWidth: "15%",
                name: t('payments.admin.expire_at'),
                cell: (row, index, column, id) => {
                    const createdAt = DateTime.fromISO(row.createdAt)
                    let expireAt = '';
                    if(!row?.expireAt !== null){
                        expireAt = DateTime.fromISO(row?.expireAt)
                        expireAt = expireAt.toFormat('yyyy-MM-dd')
                    }
                    return <>
                          <span className='text-capitalize'><b>{t('payments.admin.created_at')}:</b> {createdAt.toFormat('yyyy-MM-dd')}</span>
                          <span className='text-capitalize'><b>{t('payments.admin.expire_at')}:</b> {expireAt}</span>
                      </>
                }
            },
            {
                maxWidth: "10%",
                name: t('payments.admin.region_due_date'),
                cell: (row, index, column, id) => {
                    return <div className='text-start w-100'>
                              <span className='text-capitalize'><b>Country:</b> {row?.country} - {row?.country_iso}</span>
                              <p><b>Expiry time: </b> {row.expiry_time} {row.Time.name}</p>
                          </div>
                }
            },
            {
                maxWidth: "20%",
                name: t('payments.admin.actions'),
                cell: (row, index, column, id) => {
                    return <>                          
                              {
                                row.pi_stripe_id !== null &&
                                <div className="dropdown mb-2">
                                    <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                        <span>{t('payments.admin.options')}</span>	
                                    </a>
                                    <ul className="dropdown-menu">
                                        {
                                            row?.invoice_store !== null &&
                                            <li>                                                                             
                                                <a 
                                                    className="dropdown-item fw-bold" 
                                                    href="#!"
                                                    onClick={() => getInvoiceStores(row)}
                                                >
                                                    <FontAwesomeIcon icon={faFilePdf} className='me-2'/>  
                                                    <span>Invoice</span>
                                                </a>
                                            </li>
                                        }
                                        {
                                            row?.payment_status === 'open' &&
                                            <li>                                                                             
                                                <a 
                                                    className="dropdown-item fw-bold" 
                                                    href="#!"
                                                    onClick={() => getUrlStore(row?.cs_checkout_id)}
                                                >
                                                    <FontAwesomeIcon icon={faOpenid} className='me-2'/>  
                                                    <span>{t('payments.admin.get_url')}</span>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                              }
                        </>
                },
            },
        ];
    return result;
    }
/***************************************************************************************************************************/
    const getUrlStore = async(params) => { 
        Swal.fire({
            html: `<div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>`,
            showConfirmButton: false,
        });
        const headers = await getHeaders();
        await axios.get(`${url}stores/get-url/${params}`, {headers})
                    .then(res => {
                        Swal.close();
                        Swal.fire({
                            // icon: 'success',
                            html: `<code>${res.data.message}</code>`,
                            // title: res.data.message, 
                        });
                        console.log(res.data);
                    })
                    .catch(err => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err
                        });
                        console.log(err);
                    })
    }
/***************************************************************************************************************************/
    const getInvoicePartner = async(params) => { 
        console.log(params);
        // download.current.click();
        let headers = await getHeaders();
        headers['Content-Type'] = 'application/pdf';
        await axios.get(`${url}payments/admin/${usertypes}/invoice/${params}`, {headers})
                    .then(res =>{
                        console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/***************************************************************************************************************************/
    const externallyPaid = async (params) => { 
        // download.current.click();
        await Swal.fire({
            title: t('payments.admin.externally_title_1'),
            text: t('payments.admin.externally_text_1'),
            input: 'select',
            showCancelButton: true,
            inputOptions: {
                  confirm: 'Confirm payment',
                  error  : 'Error on transfer',
              },
            // confirmButtonText: t('payments.admin.confirm_externally'),
            confirmButtonText: 'Send',
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            console.log(result)
            if (result.isConfirmed) {
                let headers = await getHeaders();
                await axios.get(`${url}partners/externally-paid/${params}/${result.value}`, {headers})
                            .then(res =>{
                                getPayments(1);
                                setResetPaginationToggle(!resetPaginationToggle);
                                Swal.fire({
                                    icon : 'success',
                                    title: res.data.response,
                                    text : res.data.message,
                                });
                                
                            })
                            .catch(err =>{
                                console.log(err)
                                Swal.fire({
                                    icon : 'error',
                                    title: err?.response?.data?.response || err,
                                    text : err?.response?.data?.message || err,
                                });                        
                            });
            }
        })
        
    }
/***************************************************************************************************************************/

    const viewPaymentConfirm = async (params) => { 
        
        const headers = await getHeaders();
        await axios({
            url         : `${url}payments/view-confirm-prepayment/${params}`,
            method      : 'get',
            headers     : headers,
            responseType: 'blob'
        })
        .then(res => {
            const disposition = res.headers['content-disposition'];
            const match       = disposition.match(/filename="(.+)"/);
            const fileName    = match ? match[1] : 'File-download';
            const fileType    = res.headers['content-type'];

            const url         = window.URL.createObjectURL(new Blob([res.data]));
            const link        = document.createElement('a');
                  link.href   = url;
            link.setAttribute('download', fileName);
            link.setAttribute('type', fileType);
            document.body.appendChild(link);
            link.click();
            console.log(res)
        })
        .catch(err =>{
            Swal.fire({
                icon : 'error',
                title: err?.response?.data?.response || err,
                text : err?.response?.data?.message || err
            });
            console.log(err)
        })
    }

/***************************************************************************************************************************/
    const _columns = (usertype) => { 

        let result = [];
        switch (usertype) {
            case 'customers':
                result = customers();
                break;

            case 'partners':
                result = partners();
                break;

            case 'marketings':
                result = marketings();
                break;

            case 'stores':
                result = stores();
                break;
        
            default:
                break;
        }
        return result;
    }
/***************************************************************************************************************************/
    useEffect(() => {
        setcolumns(_columns(usertypes));
        getPayments(1);
    }, [i18n.language]);
/***************************************************************************************************************************/
    const refund = async(params) => { 
        Swal.fire({
            title: t('payments.admin.refound_title_2'),
            text: t('payments.admin.refound_text_2'),
            showCancelButton: true,
            confirmButtonText: 'Refund',
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {

                    const { value: formValues } = await Swal.fire({
                        title: 'Select a reason to refund',
                        html:
                          `
                        <div class="row">
                            <div class="col-12">
                                <select id="swal-input1" class="form-select mb-5" aria-label="Default select example">
                                    <option value="duplicate">Duplicate</option>
                                    <option value="fraudulent">Fraudulent</option>
                                    <option value="requested_by_customer">Requested by customer</option>
                                </select>
                            <div>
                        <div>`,
                        focusConfirm: false,
                        preConfirm: () => document.getElementById('swal-input1').value,                                                  
                      })

                    if (formValues) {
                        const _data = {
                            pi_stripe_id : params,
                            reason: formValues
                        }
                        const headers = await getHeaders();
                        await axios.post(`${url}payments/refund`, _data, {headers})
                                    .then(res =>{
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Success',
                                            text: res.data.response,
                                        });
                                        const payment = res.data.message
                                        console.log(payment);
                                        setData(
                                            data.map(item => 
                                                item.pi_stripe_id === payment.pi_stripe_id
                                                ?   {...item, payment_status: payment.payment_status}
                                                :   item
                                        ))
                                        console.log(res.data);
                                    })
                                    .catch(err =>{
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'error',
                                            text: err?.response?.data?.response,
                                        });
                                        // console.log(err)
                                    });
                    }
                }
        })
    }
/*************************************************************************************************************************/

    return (
        <>
        {/* <!-- Modal MARKETING INVOICES--> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#invoices" ref={btnInvoices}></button>
        <div className="modal fade" id="invoices" tabIndex="-1" aria-labelledby="invoicesLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="invoicesLabel">Download invoices</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className='list-group list-group-flush'>
                            {
                                invoices !== null &&
                                invoices.invoices.map((e,i) => {
                                        return <li className='list-group-item' key={`in-${i}`}>
                                            <a className='link-offset-2 link-underline link-underline-opacity-0' 
                                               href='#' 
                                               onClick={() => getInvoiceMarketings(e)
                                            }>
                                                {e}
                                            </a>
                                        </li>
                                    }
                                )
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Modal STORES INVOICES--> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#invoices-stores" ref={btnInvoicesStores}></button>
        <div className="modal fade" id="invoices-store" tabIndex="-1" aria-labelledby="invoicesLabelStores" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={btnInvoicesStoresCls}></button>
                    <div className="modal-body text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='row align-items-start'>
            <div className="col-sm-4 col-md-2 col-lg-2 mb-3 my-1">
                <span className='fw-bold'>{t(`payments.admin.${usertypes}`)}</span>
                { usertypes === 'partners' && <p>Pospayment</p> }
                { usertypes === 'stores' && 
                    <Link
                        to="/stores/create/payment"
                        className='btn btn-sm btn-shadow btn-bd-primary'
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>  
                        <span>{t(`payments.admin.create_pay`)}</span>
                    </Link>
                }
            </div>
            <div className="col-sm-8 col-md-10 col-lg-10 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                   {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {
                                    usertypes === 'customers' &&
                                    <CustomerForm
                                        _stripe={_stripe}
                                        _payeremail={_payeremail}
                                        _package={_package}
                                        set_stripe={set_stripe}
                                        set_payeremail={set_payeremail}
                                        set_package={set_package}
                                        setLoading={setLoading}
                                        usertypes={usertypes}
                                        perPage={perPage}
                                        setData={setData}
                                        setTotalRows={setTotalRows}
                                        getHeaders={getHeaders}
                                    />
                                }
                                {
                                    usertypes === 'partners' &&
                                    <PartnerForm
                                        _stripe={_stripe}
                                        partner={partner}
                                        set_stripe={set_stripe}
                                        setpartner={setpartner}
                                        setLoading={setLoading}
                                        usertypes={usertypes}
                                        perPage={perPage}
                                        setData={setData}
                                        setTotalRows={setTotalRows}
                                        getHeaders={getHeaders}
                                    />
                                }
                                {
                                    usertypes === 'marketings' &&
                                    <MarketingForm
                                        _stripe={_stripe}
                                        set_stripe={set_stripe}
                                        _payeremail={_payeremail}
                                        set_payeremail={set_payeremail}
                                        marketing={marketing}
                                        setmarketing={setmarketing}
                                        setLoading={setLoading}
                                        usertypes={usertypes}
                                        perPage={perPage}
                                        setData={setData}
                                        setTotalRows={setTotalRows}
                                        getHeaders={getHeaders}
                                    />
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card card-shadow">
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="500px"
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle}
                        pagination
                        paginationPerPage={perPage}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                        progressComponent={<CustomLoader />}
                        dense
                        
                    />
                </div>
            </div>
        </div>
    </>
    )
}

export default Admin