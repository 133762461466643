const resources = {

	en: {
		translation: {
			utilities:{
				search             : "Search",
				search_btn         : "Search",
				is_a_required_field: "Is a required Field",
				day                : "Day",
				days               : "Days",
				month              : "Month",
				months             : "Months",
				year               : "Year",
				years              : "Years",
				download           : "Download",
				go_to              : "Go to Web2Barcode",

			},
			user_type:{
				admin   : "Admin",
				partner : "Partner",
				customer: "Customer",
			},
			pagination_component:{
				rowsPerPageText      : "Rows per page",
				rangeSeparatorText   : "of",
				selectAllRowsItemText: "All",
			},
			status:{
				available : "Available",
				disabled  : "Disabled",
				activated : "Activated",
				registered: "Registered",
				expired   : "Expired",
				renovated : "Renovated",
			},
			header: {          
				login             : 'Log in',
				logout            : 'Logout',
				logout_session    : 'Logout session?',
				yes_logout_session: 'Yes, Logout session',
				main_portal       : 'Main portal',
				my_account        : 'My account',
				signup            : 'Sign up',
				language          : 'Language',
				products          : 'Products',
				cart              : {
					empty       : 'Empty',
					gotocheckout: 'Go to checkout',
					product     : 'Product',
					delete      : 'Delete',
					delete_item : 'Delete item',
					do_you      : 'Do you want to delete this item?'
				
				},
				partner      : 'Partner',
				subpartner   : 'Subpartner',
				administrator: 'Administrator',
			},
			home: {
				text1   : "Welcome to Portal Label Dictate! We are your partner in software solutions for barcode printing and identification. Easily manage your subscriptions and discover our innovative applications like Text2 Barcode, Suite Mobile POS, PDF label cut, Web2 Barcode, and more. Increase your efficiency and success with our comprehensive solutions. Go ahead, explore the possibilities!",
				title2  : "Maximize your efficiency with ",
				title2_2: "Text2 Barcode!",
				text2   : "Convert text to barcodes and instantly print labels across all platforms. Try Text2 Barcode today and revolutionize your labeling processes. Experience simplified barcode coding and printing!",
				title3  : "The Suite Mobile POS ",
				title3_3: "(SMP)",
				text3   : "It is an easy-to-use solution that covers many of your business needs. With SMP, you can quickly create code lists, manage your warehouse, print labels, search for products, review offers, and get product information simply by scanning barcodes. Its installation is easy, and it works with thermal printers, making it a perfect tool for any business.",
				title4  : "With ",
				title4_4: "Web2 Barcode",
				text4   : "Label printing is simple and accessible. Choose a design, upload your CSV file, and send it to print, all from any device. Discover the efficiency and convenience of Web2 Barcode and revolutionize your label printing process now.",
				faqs    : "FAQS",
				help    : 'Help center'
			},
			footer:{
				terms_and_conditions: "Terms & Conditions",
				privacy_policy      : "Privacy Policy",
				about_us            : "About Us",
				contact_us          : "Do you want to be a partner? Contact Us",
				recaptcha           : "This site is protected by reCAPTCHA and the Google",
				privacy_google      : "Privacy Policy",
				terms_google        : "Terms of Service",
			},
			pages:{
				product:{
					buynow   : "Buy now",
					addtocart: "Add to cart",
					bestvalue: "Best value",
					price    : "Price",
					license  : "Subscription",
					licenses : "Subscriptions",
					for      : "for",
				},
				products:{
					view_prices: "View prices",
				}
			},
			login:{
				email            : "Email address",
				password         : "Password",
				pass_confirm     : "Password confirm",
				please_login     : "Please Log in",
				create_account   : "Don´t have account? create it!",
				login_account    : "Do you have account? Log in!",
				forgot_password  : "I forgot my password",
				recovery_password: "Recovery password",
				update_password  : "Update password",
			},
			checkout: {
				choice    : "Excellent choice!",
				email_send: "E-mail to send licenses",
				email_ob  : "E-mail (Obligatory)",
				continue  : "Continue to checkout",
				your_cart : "Your cart",
				items     : "Items quantity",
			},
			sidebar:{
				users          : "Users",
				stores         : "Stores",
				partners       : "Partners",
				marketing      : "Marketing",
				products_plans : "Products & Plans",
				payments       : "Payments",
				billing        : "Billing",
				reports        : "Reports",
				packages       : "Packages",
				licenses       : "Subscriptions",
				add_advertising: "Add advertising",
			},
			dashboard:{			
				licenses_activated: "licenses activated",
				licenses_renovated: "licenses renovated",
				licenses_to_expire: "licenses to expire",
				licenses_expired  : "licenses expired",
				licenses_available: "licenses available",
				today             : "Today",
				activated         : "Activated",
				renovated         : "Renovated",
				to_expire         : "To expire",
				expired           : "Expired",
				available         : "Available",
			},
			users:{
				add_user   : "Add user",
				email      : "Email",
				all_roles  : "All roles",
				actions    : "Actiones",
				options    : "Options",
				partner    : "Partner",
				marketing  : "Marketing",
				subpartner : "Sub partner",
				customer   : "Cutomer",
				admin      : "Administrator",
				sw         : "Sw",
				edit       : "Edit",
				delete     : "Delete",
				deleted    : "Delete user?",
				deleted_txt: "Do you want to delete this user?",
				create     : {
					user_create     : "User create",
					select_an_option: "Select option",
					create          : "Create",
				},
				update   : "Update",
				user_edit: "User Edit"
			},
			stores:{
				options       : "Options",
				search        : "Search",
				actions       : "Actions",
				name          : "Name",
				email         : "Email",
				phone         : "Phone",
				link          : "Link",
				image         : "Image",
				edit          : "Edit",
				create_pay    : "Create pay",
				add_store     : "Add store",
				add_create_pay: "Create pay store",
				payments_store: "Payments stores",
				delete        : "Delete",
				description   : "Description",
				country       : "Country",
				expire_time   : "Expire time",
				payment_id    : "Payment ID",
				expire_at     : "Expire at",
				info          : "Info",
				info_pay      : "Info payment",
				status        : "Status payment",
				payment       : {
					create            : "Create store payment",
					price             : "Price",
					chose_country     : "Choose your country",
					chose_store       : "Choose store",
					select_an_time    : "Select a time",
					select_expire_time: "Select a expire time",
					create_pay        : "Create payment link"
				},
				create: {
					add     : "Add store",
					phone   : "Phone",
					link    : "Link",
					select  : "Select image",
					create  : "Create store",
					required: "Image required",
				}
			},		
			partners:{
				add_partner      : "Add partner",
				search           : "Search",
				name             : "Name",
				products_discount: "Products discout",
				actions          : "Actions",
				options          : "Opciones",
				edit             : "Edit",
				deleted          : "Delete",
				address          : "Address",
				address_invoice  : "Invoice Address",
				users            : "Users",
				delete           : {
					del    : "Delete partner?",
					del_txt: "Do you want to delete this partner?",
					del_btn: "Delete",
				},
				create : {
					partner_creation  : "Partner creation",
					partner_edition   : "Partner edition",
					description       : "Name",
					discount          : "Discount",
					street_address    : "Street address",
					address_line      : "Address line",
					city              : "City",
					zip               : "Zip code",
					state             : "State",
					country           : "Country",
					street_address_inv: "Invoice Street address",
					address_line_inv  : "Invoice Address line",
					city_inv          : "Invoice City",
					zip_inv           : "Invoice zip code",
					state_inv         : "Invoice state",
					country_inv       : "Invoice country",
					email_inv         : "Invoice email",
					create_partner    : "Create partner",
					update_partner    : "Update partner",
				},
			},
			payments: {
				pay_customer : "Payments Customers",
				pay_partners : "Pos - Payments Partners",
				pay_marketing: "Payments Marketing",
				pay_stores   : "Payments Stores",
				go_customer  : "Go to Customers",
				go_partners  : "Go to Partners",
				go_marketing : "Go to Marketing",
				go_stores    : "Go to Stores",
				create       : "Create link payment",
				show         : "Show all disputes stripe",
				admin        : {
					stripe_payment    : "Stripe payment",
					stripe_data       : "Stripe data",
					product           : "Product",
					package           : "Package",
					actions           : "Actions",
					options           : "Options",
					refund            : "Refund",
					payer_email       : "Payer Email",
					date_purchase     : "Date purchase",
					amount_total      : "Amount total",
					status_payment    : "Status payment",
					price             : "Price",
					status            : "Status",
					expire_at         : "Expire at",
					refound_title     : "You can´t refound",
					refound_txt       : "To make a refund it must be less than 30 days",
					refound_title_2   : 'Refund payment?',
					refound_text_2    : 'This package and licenses will be disabled and you return money to customer',
					customers         : "Customers",
					partners          : "Partners",
					marketings        : "Marketings",
					marketing_data    : "Marketing data",
					stores            : "Stores",
					create_pay        : "Create pay stripe",
					quantity_license  : "Quantity licenses",
					count             : "Count",
					created_at        : "Created at",
					country           : "Country",
					states            : "States",
					upload_banners    : "Upload banners",
					create_pay        : "Create stripe pay",
					name              : "Name",
					description       : "Description",
					region_due_date   : "Region & due date",
					get_url           : "Get URL to pay",
					externally_title_1: "Externally pay",
					externally_text_1 : "Make external payment? This will change the status of the payment",
					confirm_externally: "Confirm external payment",
					search            : {
						stripe_payment: "Stripe payment",
					},
				},
				customer     : {
					stripe_data   : "Stripe data",
					product       : "Product",
					package       : "Package",
					actions       : "Actions",
					invoice       : "Invoice",
					payer_email   : "Payer Email",
					date_purchase : "Date purchase",
					amount_total  : "Amount total",
					status_payment: "Status payment",
					price         : "Price",
					status        : "Status",
					expire_at     : "Expire at",
					search        : {
						stripe_payment: "Stripe payment",
					},
					payments: "Payments",
					billing : "Billing",
				},
				partner:{
					stripe_data : "Stripe data",
					product     : "Product",
					quantity    : "Quantity licenses charge",
					amount_total: "Amount total",
					actions     : "Actions",
					invoice     : "Invoice",
					range       : "Range",
					due_date    : "Date purchase",
					created_at  : "Created at",
					to          : "To",
					created_at  : "Created at",
					status      : "Status",
					checkout    : "Checkout ID",
					payment_id  : "Payment intent ID",
					pending     : "Pending",
					paid        : "Paid",
					externally  : "Externally paid",
					pay_now     : "Pay now",
					payment     : "Payment",
					options     : "Options",
					search      : {
						stripe_payment: "Stripe payment",
						start_due_date: "Start due date",
						end_due_date  : "End due date",
					},
					pospayments: "Pospayments",
					billing    : "Billing",
				},
				marketing:{
					stripe_data      : "Stripe data",
					status           : "Status",
					count            : "Count",
					amount_total     : "Amount total",
					due_date         : "Date purchase",
					created_at       : "Created at",
					expired_at       : "Expired at",
					region           : "Region",
					country          : "Country",
					states           : "States",
					actions          : "Actions",
					options          : "Options",
					invoices         : "Invoices",
					upload_banners   : "Upload banners",
					cancel_recurring : "Cancel recurring",
					add_advertising  : "Add advertising",
					download_invoices: "Download invoices",
					remove           : "Remove subscription?",
					remove_txt       : "You can subscribe again more later",
					unsubscribe      : "Unsubscribe",
					search           : {
						stripe_payment: "Stripe payment",
						start_due_date: "Start due date",
						end_due_date  : "End due date",
					},
					marketing_pay: "Marketing payments",
					billing      : "Billing",
				},
			},
			licenses: {
				renovated_individual: "Renovate Individual licenses",
				renovated_masive    : "Renovate Mavise licenses",
				charge_stripe       : "This create a charge for pay on stripe",
				time_to_expire      : "Time to expire",
				close               : "Close",
				year                : "Year",
				years               : "Years",
				download            : "Download",
				price_for_renovation: "Price for renovation",
				show_row_selected   : "Show rows selected",
				item                : "item",
				items               : "items",
				selected            : "Selected",
				product_key         : "Product Key",
				user_email          : "Email User",
				package             : "Package",
				customer            : {
					register_licenses : "Register Licenses",
					partner           : "Partner",
					package           : "Package #SN",
					product_key       : "Product Key",
					compatibility     : "Compatibility",
					current_device    : "Current device",
					number_activation : "Number activation",
					current_activation: "Current activation",
					expire_at         : "Expire At",
					status            : "Status",
					actions           : "Actions",
					options           : "Options",
					remove_from_device: "Remove from device",
					renovate          : "Renovate",
					product           : "Product",
					is_about_expire   : "Is about to expire",
					expire            : "License Expired!",
					package_disabled  : "Package disabled",
					remove            : "Remove",
					str_remove_license: "Remove license from current device?",
					str_this_license  : "This license can be activated on other or same device",
				},
				partner:{
					charge_month    : "Charge month invoice",
					created_at      : "Created at",
					email_user_owner: "Email user owner",
					first_activation: "First activation",
				},
				link: {
					link_licenses: "Link licenses",
					your_licenses: "Your licenses here:",
					example      : "Example",
					send_licenses: "Send licenses",
					success      : "Vincular licencias",
				}
			},
			packages:{
				package               : "Package #SN",
				comments              : "Comments",
				compatibility         : "Compatibility",
				user_owner            : "User owner",
				status_package        : "Status Package",
				status_license        : "Status License",
				total_licenses        : "Total Licenses",
				created_at            : "Created at",
				expire_time           : "Expire time",
				actions               : "Actions",
				options               : "Options",
				show_all              : "Show All",
				download_csv          : "Download CSV",
				create_packages       : "Create Packages",
				packages              : "Package",
				compatibility_products: "Compatibility Product",
				status                : "Status",
				select_an_option      : "Select an option",
				show                  : {
					partner               : "Partner",
					package               : "Package #SN",
					product_key           : "Product key",
					created_at            : "Created at",
					email_user_owner      : "Email user owner",
					user_type             : "User type",
					current_device        : "Current device",
					number_activation     : "Number activation",
					current_activation    : "Current activation",
					first_activation      : "First Activation",
					expire_at             : "Expire at",
					status                : "Status",
					compatibility_products: "Compatibility Product",
					edit                  : "Edit",
					no_device             : "No device",
				},
				create: {
					quantity            : "Quantity",
					select_an_time      : "Select an time",
					select_expire_time  : "Select expire time",
					comments            : "Comments",
					create_package      : "Create package",
					create_pack_licenses: "Create package licenses",
					partner             : "Partner",
					administrator       : "Administrator",
					compatible          : "Compatible with PDF label cut",
				}
			},
			marketing:{
				add_marketing  : "Add marketing",
				search         : "Search",
				name           : "Name",
				address        : "Address",
				address_invoice: "Address Invoice",
				price_4_state  : "Price for state",
				price          : "Price",
				users          : "Users",
				actions        : "Actions",
				options        : "Options",
				edit           : "Options",
				delete         : "Delete",
				delete_title   : "Delete marketing?",
				delete_txt     : "Do you want to delete this Marketing?",
				payment        : "Payment info",
				banners        : "Banners info",
				show_banners   : "Show banners",
				count          : "Count",
				pi_id          : "Payment ID",
				status         : "Status",
				total          : "Total",
				country        : "Country",
				states         : "States",
				description    : "Description",
				months         : "Months",
				email          : "E-mail",
				phone          : "Phone",
				no_payments    : "No payments mareting :(",
				advertising    : {
					freemium          : "Freemium marketing create",
					phone             : "Phone",
					email             : "Email",
					you_can           : "You can upload 3 images",
					you_can_txt       : "For a better visualization of your images, we recommend the size 720 x 1280 like this:",
					banners_list      : "Banners list",
					select_image      : "Select image",
					upload_advertising: "Upload advertising",
					invalid_extension : "Invalid extension",
					are_you_sure      : "Are you sure",
					upload_images     : "You will upload images to advertising, do not upload inapropiadet images!",
					yes_upload        : "Yes, Upload!",
					create            : {
						you_can             : "You can put your advertising on our applications!",
						select_a_country    : "Select a country and States to sell",
						select_a_state      : "Select a state",
						chose_your_country  : "Choose your country",
						states_selected     : "States selected",
						states_not_available: "States not available",
						total_amount        : "Total Amount",
						months              : "Months",
						select_option       : "Select option",
						go_to_checkout      : "Go to checkout (stripe)",
						only_values         : "Only values 3, 6, 12",
					}
				},
				create: {
					creation          : "Marketing creation",
					edition           : "Marketing edition",
					street_address    : "Street address",
					address_line      : "Address line",
					city              : "City",
					zip               : "Zip code",
					state             : "State",
					country           : "Country",
					street_address_inv: "Invoice Street address",
					address_line_inv  : "Invoice Address line",
					city_inv          : "Invoice City",
					zip_inv           : "Invoice zip code",
					state_inv         : "Invoice state",
					country_inv       : "Invoice country",
					email_inv         : "Invoice email",
					create_marketing  : "Create marketing",
					update_marketing  : "Update marketing",
				}
			},
			products:{
				add_product        : "Add product",
				search             : "Search",
				products           : "Products",
				visibility         : "Visibility",
				list_price         : "List price",
				list_price_2       : "List price 2",
				order              : "Order",
				plans_title        : "plans",
				add_plan           : "Add plan",
				plans_archived_txt : "Plans archived",
				archive_product    : "Archive product?",
				archive_product_txt: "Do you want to hide this product (on stripe and portal)?",
				restore_product    : "Restore product?",
				restore_product_txt: "Do you want to restore this product (on stripe and portal)?",
				archive_plan       : "Archive Plan?",
				archive_plan_txt   : "Do you want to hide this Plan (on stripe and portal)?",
				restore_plan       : "Restore Plan?",
				restore_plan_txt   : "Do you want to restore this Plan (on stripe and portal)?",
				restore            : "restore",
				hide               : "Hide",
				hidden             : "Hidden",
				show               : "Show",
				plan_name          : "Plan name",
				plan_desc          : "Plan description",
				duration           : "Duration",
				best_value         : "Best value",
				invalid_extension  : "Invalid exxtension",
				create             : {
					creation        : "Product creation",
					edition         : "Product edition",
					name            : "Name",
					description     : "Description",
					list_price      : "List price (*)",
					list_price_2    : "List price 2 (2 years *)",
					order           : "Order",
					select_an_option: "Select a option",
					select_image    : "Select image",
					create          : "Create product",
					update          : "Update product",
				},
				plans              : {
					not_exist        : "Product Does not exist :(",
					not_exist_plan   : "Plan Does not exist :(",
					creation         : "Plan creation",
					edition          : "Plan edition",
					name             : "Name",
					description      : "Description",
					price            : "Price",
					select_a_plan    : "Select a plan",
					normal           : "Normal plan",
					best             : "Best value",
					select_a_time    : "Select a time",
					select_a_expire  : "Select a expire time",
					select_a_option  : "Select a option",
					licenses_quantity: "Licenses quantity include on plan (*)",
					description_list : "Description list",
					at_least         : "At least one description",
					create           : "Create plan",
					update           : "Update plan",
				},
			},
			reports: {
				reports    : "Reports",
				payments   : "Payments customers",
				pospayment : "Pos-payment parnters",
				marketing  : "Payments marketings",
				store      : "Payments stores",
				suscription: "Subscription licenses",
				renovated  : "Subscription renovated",
				dashboard  : "Dashboard",
				get_report : "Get report",
				start_at   : "Start at",
				end_at     : "End at",
			},
			no_match:{
				not_found: "Page not found 404",
				p1       : "This page does not exist! Either your address has been deleted or changed.",
				p2       : "You can be directed to the home page...",
				back     : "Go back",
			},
			maintenance:{
				h1  : "We are performing maintenance tasks!",
				p1  : "We apologize for the inconvenience. We will be back soon.",
				p2  : "For inquiries, please contact us at ",
				hour: "Maintenance will end at approximately: ",
			},
			faqs: {
				title1  : "How do subscriptions work in Portal Label Dictate?",
				text1   : "With subscriptions in Portal Label Dictate, users can acquire and manage their licenses in a centralized manner. They can renew, cancel, or purchase new subscriptions directly on the platform, providing greater control and flexibility.",
				title2  : "Can I use any subscription on any Label Dictate product?",
				text2   : "No, Label Dictate subscriptions are unique and independent for each specific product and limited to a single device. Each subscription is activated and used exclusively on the corresponding product for which it was purchased. For example, a Text2 Barcode subscription can only be activated and used in the Text2 Barcode application.",
				text2_1 : "It's important to note that each subscription is designed for use on a single device. If you need to activate the subscription on multiple devices simultaneously, you'll need to purchase additional subscriptions for each additional device you want to use the corresponding product on.",
				title3  : "What benefits does the subscription model offer compared to activation codes from previous versions?",
				text3   : "The subscription model provides users with continuous access to the latest versions of our applications. It also simplifies the license management process by allowing renewals and the ability to manage subscriptions on the Portal Label Dictate platform.",
				title4  : "What benefits does the subscription model offer compared to activation codes from previous versions?",
				text4   : "The subscription model provides users with continuous access to the latest versions of our applications. It also simplifies the license management process by allowing renewals and the ability to manage subscriptions on the Portal Label Dictate platform.",
				title5  : "I didn't receive the subscription code, what should I do?",
				text5   : "If you haven't received the subscription code after making the payment on Portal Label Dictate, here are some actions you can take:",
				text5_1 : "Check your inbox: Review your email inbox, including the spam or junk mail folder. The email with the subscription code may have been incorrectly filtered into one of these folders.",
				text5_2 : "Download the subscription code image: When making the purchase on Portal Label Dictate, you're provided with the option to download the subscription code as an image. Check if you downloaded the image and make sure to save it in a secure place.",
				text5_3 : "Check the licenses on Portal Label Dictate: If you're already a user of Portal Label Dictate and logged into your account when making the purchase, you can check if the subscription is active in the licenses section of your account. If it appears there, it means the subscription is properly registered to your account.",
				text5_4 : "If after performing these checks you still haven't received the subscription code or have any other related questions, we recommend contacting our customer support team. We'll be happy to help you resolve any issues related to your subscription code.",
				title6  : "What happens if I already have an activation code from previous versions?",
				text6   : "If you already have a valid activation code, you can continue using it until the 3 reactivations on a single device are exhausted. However, to access the advantages and benefits of the subscription model, we recommend considering the transition to a subscription when it's time for renewal.",
				title7  : "Is the email used to purchase the subscription the same as the one used to log in to Portal Label Dictate?",
				text7   : "No, the email used to purchase the subscription is not necessarily the same as the one used to log in to Portal Label Dictate. The email provided when purchasing the subscription is used exclusively to send the subscription code.",
				text7_1 : "When creating an account in Portal Label Dictate, you will be asked for an email address that will be used to manage the purchased subscriptions. This email address for the Portal Label Dictate account will be used to activate the subscription on devices, along with the subscription code provided at the time of purchase.",
				text7_2 : "Please note that the email address for the Portal Label Dictate account is essential for the management and activation of your subscriptions, while the email used for purchasing the subscription is primarily used for sending the subscription and activation code.",
				title8  : "What happens if I already have an activation code from previous versions?",
				text8   : "If you already have a valid activation code, you can continue using it until the 3 reactivations on a single device are exhausted. However, to access the advantages and benefits of the subscription model, we recommend considering the transition to a subscription when it's time for renewal.",
				title9  : "How can I change or migrate the subscription from one device to another?",
				text9   : "To change devices with your subscription in Label Dictate applications, you have two available methods:",
				title9_1: "Method 1:",
				text9_1 : "Access Portal Label Dictate in your browser and deactivate the subscription on the current device.",
				text9_2 : "Then, on the new device where you want to transfer the subscription, open the Label Dictate application.",
				text9_3 : "In the Label Dictate application, look for the option to activate a subscription and enter your email address and the corresponding subscription code. Make sure to enter the data correctly.",
				title9_2: "Method 2:",
				text9_4 : "On the current device, open the Label Dictate application and deactivate the subscription.",
				text9_5 : "Next, on the new device, open the Label Dictate application.",
				text9_6 : "In the Label Dictate application on the new device, look for the option to activate a subscription and enter your email address and the corresponding subscription code.",
				text9_7 : "Follow the instructions provided in the application to activate the subscription on the new device.",
				text9_8 : "Remember that it's important to deactivate the subscription on the previous device before activating it on the new device. This will ensure that the subscription can be used correctly and avoid any conflicts in its use.",
				title10 : "Can I manage subscriptions purchased through an authorized distributor in Portal Label Dictate?",
				text10  : "Yes, subscriptions purchased through an authorized distributor can be managed in Portal Label Dictate. Our centralized platform allows you to conveniently and efficiently manage all your subscriptions.",
				text10_1: "However, it's important to note that activation codes corresponding to previous versions, which are limited to 3 activations on a device, cannot be managed in Portal Label Dictate. These activation codes are governed by their own restrictions and policies.",
				text10_2: "For subscriptions purchased through an authorized distributor, we recommend using Portal Label Dictate to manage your subscriptions, renewals, and any other related aspects. This provides you with greater control and a unified management experience on our platform.",
				title11 : "How can I cancel my subscription to Label Dictate?",
				text11  : "In the Label Dictate Portal, subscriptions are low-cost, and therefore, we do not offer the option to cancel or change subscriptions once they have been purchased. However, we understand that there may be unforeseen circumstances. Therefore, we offer refunds during the first 30 days after the purchase of a subscription. After this 30-day period, refunds cannot be requested due to the low cost of the subscriptions. We suggest that you carefully review the terms before purchasing a subscription to avoid any inconvenience.",
				title12 : "Does Label Dictate provide personalized technical support regarding the use and configuration of the applications?",
				text12  : "No, Label Dictate doesn't provide personalized technical support directly to end customers. Instead, specialized support is provided by authorized distributors of Label Dictate, who have experts in the use of Label Dictate applications.",
				text12_1: "If you need personalized technical assistance, we recommend sending an email to contact@labeldictate.com providing your name, country, specific application, and reason for inquiry. From there, you'll be directed to the corresponding official distributor in your country. This is done with the aim of providing specialized support locally, considering the devices and consumables available in your country.",
				text12_2: "Authorized distributors are trained to provide technical advice, troubleshooting, and support related to the use and configuration of Label Dictate applications. They have the necessary knowledge to address your inquiries and assist you efficiently and in a specialized manner.",
				text12_3: "We are committed to ensuring that our customers receive the best support and assistance experience, and we work in collaboration with our authorized distributors to achieve this.",
				title13 : "How can I distinguish between licenses for previous versions and subscriptions for new versions?",
				text13  : "New versions of our apps support the subscription model and use 25-character codes in the format AAAAABBBBBCCCCCDDDDDEEEEE. On the other hand, licenses that are backward compatible use 20-character codes in the format AAAA-BBBB-CCCC-DDDD-EEEE. It is crucial to identify what type of license or subscription you have to ensure that you download the correct and compatible version of our application.",				
			},
			terms_and_conditions: {
				main_title : "Terms and Conditions of ",
				title1     : "1. Introduction",
				text1      : "These Terms and Conditions govern the use of the Portal Label Dictate website (\"our site\") and all associated services (collectively, \"our services\") provided by Label Dictate Technologies LLC (\"we\", \"us\", \"our company\"). By using our site or our services, you (\"you\", \"the user\") agree to comply with these Terms and Conditions.",
				title2     : "2. Acceptable Use",
				text2      : "The user agrees to use our site and our services responsibly, ethically, and legally. Any use that may harm, overload, disrupt, or compromise the security of our site or our services is not permitted. Any use of our services for illegal purposes or violation of these Terms and Conditions may result in the termination of your access to our services.",
				title3     : "3. Accounts and Subscriptions",
				text3      : "By creating an account on our site, the user agrees to provide accurate and complete information. It is the user's responsibility to maintain the security of their account and the confidentiality of their password. Any subscription purchased through our site is subject to these Terms and Conditions and any additional terms specified at the time of purchase. All subscriptions are non-transferable and can only be used by the account holder.",
				title3_1   : "3.1 Use of Label Dictate's Free and Pro Versions of the Applications",
				text3_1    : "Label Dictate offers Free (free) and Pro (paid) versions of our applications. While the Free version is intended for personal and non-profit use, the Pro version is designed to be used by businesses or individuals seeking to use our applications for profit.",
				text3_2    : "Companies that commercially benefit from the use of our applications are required to use the Pro version. The Free version cannot be used for commercial purposes. The use of the Free version of our applications for commercial purposes will be considered a violation of these Terms and Conditions and may result in the termination of your account and possible legal liability.",
				text3_3    : "It is important to note that Label Dictate reserves the right to take legal action against any entity that uses the Free version of our applications for profit without obtaining a license for the Pro version. Such actions will be subject to the intellectual property and licensing laws of the corresponding country.",
				text3_4    : "All companies are required to comply with these usage conditions and respect our intellectual property rights. By using any version of our applications, you agree to comply with these Terms and Conditions. Any violation of these terms may result in the immediate termination of your access to our applications and services and may lead to legal action.",
				text3_5    : "By accepting these Terms and Conditions, you accept the responsibility to determine if your use of our applications is legal in your specific jurisdiction and agree to assume responsibility for any legal infringement that may arise from your misuse of our applications.",
				title3_2   : "3.2 Use and Activation of Licenses and Promotional Codes for Previous Versions of Label Dictate Technologies",
				text3_6    : "Applicability: These terms and conditions apply to the licenses or promotional codes acquired for the versions of the applications listed below that adhere to the 20-character license format: AAAA-BBBB-CCCC-DDDD-EEEE:",
				text3_7    : `Text2 Barcode Windows version 4.2.0 and below.`,
				text3_7_1  : `Text2 Barcode MacOS version 1.3.2 and below.`,
				text3_7_2  : `Text2 Barcode Android version 1.9.9 and below.`,
				text3_7_3  : `PDF Label Cut version 2.2.2 and below.`,
				text3_7_4  : `Suite Mobile POS version 3.9.9 and below.`,
				text3_8    : "Usage Limitation: Activation codes per license or promotional codes can only be used on a single device and are limited to a maximum of 3 activations. After three activations, the system will disable the activation code or promotional code.",
				text3_9    : "Continuation of Service: To continue using the paid version of the application after deactivation of the code, it will be necessary to acquire a subscription through the current activation method. The subscription will allow the use of the paid version of the application for the contracted period of time on the Label Dictate Portal.",
				text3_10   : "Exclusions: Activation codes per license or promotional codes cannot be used in the administrative system Label Dictate Portal and will not be eligible for an upgrade to the subscription model.",
				text3_11   : "Upgrades: To obtain an upgrade to the subscription model, it must be purchased through a direct purchase on the Label Dictate Portal (https://portal.labeldictate.com/). Such an upgrade will incur an additional cost.",
				text3_12   : "By having a license or promotional code, you agree to these terms and conditions. For any questions or inquiries, please contact our customer service team via email.",
				text3_13   : "Label Dictate Technologies reserves the right to modify these terms and conditions at any time without prior notice.",
				title4     : "4. Privacy Policy",
				text4      : "Our Privacy Policy, which can be found at [link],",
				text4_1    : "describes how we collect, use, and protect your personal information and is part of these Terms and Conditions.",
				title5     : "5. Payments, Renewals, Cancellations, and Refunds",
				text5      : "The user is responsible for all charges associated with their account, including, but not limited to, the purchase and renewal of subscriptions. All sales are final, except as provided in our refund policy, which is part of these Terms and Conditions. Subscription renewals must be done manually through our website.",
				title5_1   : "5.1 User Payments",
				text5_1    : "All payments for subscriptions to Label Dictate Technologies LLC applications are made through the Label Dictate Portal. We accept credit and debit cards as payment methods. By making a payment, you warrant that you have the legal right to use the selected payment method. All prices are presented and must be paid in the currency indicated on the Label Dictate Portal.",
				text5_1_2  : "Label Dictate Portal reserves the right to modify these terms and conditions at any time without prior notice.",
				title5_2   : "5.2 Distributor Payments",
				text5_2_1  : "Authorized distributors of Label Dictate Technologies have the option to make payments through two methods: prepaid and postpaid. Both methods allow the distributor to generate subscriptions for end-users but differ in the timing of payment and how subscription expiration is managed.",
				text5_2_2  : "Specific regulations and payment procedures for each method are detailed in the following subsections (5.2.1 Prepaid and 5.2.2 Postpaid). All distributors must adhere to these terms and conditions to ensure the continuous delivery of subscriptions and avoid potential penalties.",
				text5_2_3  : "Label Dictate Technologies reserves the right to change these terms and conditions at any time without prior notice. It is the distributor's responsibility to stay updated and comply with the current conditions at all times.",
				title5_2_1 : "5.2.1 Prepaid",
				text5_2_1_1: "Distributors with the prepaid feature will be able to generate subscriptions for the time period allowed by the system until their expiration. These subscriptions are meant to be distributed by the distributor and will have a maximum of 1 year to be activated by the end-user. If the activation is not done within this period, the subscriptions will be blocked and cannot be activated.",
				text5_2_1_2: "To activate the subscriptions, an invoice will be generated, which must be paid by the distributor. Once the invoice is paid, and the subscriptions are activated on the end-user's device, the countdown to the expiration of the subscriptions will begin, according to the validity time set in the generated subscriptions.",
				text5_2_1_3: "The payment deadline for these subscriptions is a maximum of 30 natural days from the invoice generation date. If the payment is not made within this period, Label Dictate Technologies has the authority to suspend the service of the generated subscriptions until the corresponding payment is received.",
				text5_2_1_4: "It is the distributor's responsibility to generate subscriptions responsibly and promptly to avoid an excess of unused subscriptions. Label Dictate Technologies is not liable for misuse and mismanagement in the generation of subscriptions. The service suspension may result in the inactivity of subscriptions for end-users and may affect the distributor's reputation and business relationship with Label Dictate Technologies and end-users.",
				text5_2_1_5: "Distributors are subject to these terms and conditions when generating subscriptions through prepaid. Any violation of these conditions may result in the revocation of the distributor's status and the loss of access to the prepaid feature.",
				text5_2_1_6: "Label Dictate Portal reserves the right to modify these terms and conditions at any time without prior notice.",
				title5_2_2 : "5.2.1 Postpaid",
				text5_2_2_1: "Distributors with the postpaid option can generate subscriptions with the duration allowed by the system until their expiration. These subscriptions are intended to be distributed by the distributor and will have a maximum of 1 year to be activated by the end-user. If the activation is not done, the subscriptions will be blocked and cannot be activated. It is the distributor's responsibility to generate subscriptions responsibly and promptly to avoid an excess of unused subscriptions. Label Dictate Technologies is not liable for misuse and mismanagement in the generation of subscriptions.",
				text5_2_2_2: "The payment for the subscriptions will be made in the following natural month after the activation of the subscription by the end-user on their device. The invoice corresponding to the subscriptions will be generated on the first day of each month, and the distributor will have 30 natural days to make the payment for the invoice. If the payment is not made within this period, Label Dictate Technologies has the authority to deactivate the active subscriptions corresponding to the generated invoice. Service suspension may result in the inactivity of subscriptions for end-users and may affect the distributor's reputation and business relationship with Label Dictate Technologies and end-users.",
				text5_2_2_3: "Once the subscriptions are activated on the end-user's device, the time until the expiration of these subscriptions will begin, according to the duration established when generating the subscriptions.",
				text5_2_2_4: "It is the distributor's responsibility to ensure the timely payment of the invoice generated based on the activated subscriptions. Any delay in payment may result in the interruption of end-users' subscriptions, the deactivation of pending subscriptions, and possible sanctions for the distributor.",
				text5_2_2_5: "Distributors are subject to these terms and conditions when generating subscriptions through postpaid. Any violation of these conditions may result in the revocation of the distributor's status and the loss of access to the postpaid feature.",
				text5_2_2_6: "Label Dictate Portal reserves the right to modify these terms and conditions at any time without prior notice.",
				title5_3   : "5.3 Renewals",
				text5_3    : "The renewal of subscriptions must be done manually by the user through the Label Dictate Portal. We do not process automatic or recurring charges because each license has different types of packages that can be purchased individually or together. The option to renew will be available one month before the expiration date of your subscription and will be indicated directly on the Label Dictate Portal. Additionally, a notification will be sent to the user via email.",
				title5_3_1 : "5.3.1 Distributor Subscription Renewals",
				text5_3_1  : "Authorized distributors of Label Dictate Technologies have the option to renew subscriptions they have distributed one month before their expiration date. This early renewal ensures uninterrupted access to Label Dictate Technologies' applications and services for the end-user.",
				text5_3_2  : "Renewals can be managed directly by the distributor through the Label Dictate Portal platform, giving them greater autonomy and control over their subscriptions and end-users.",
				text5_3_3  : "It is important to note that although the renewal process may be handled by the distributor, the generated renewals must be paid to Label Dictate Technologies according to the price set at that time towards the distributor. This price may vary and will depend on your business policy and market conditions.",
				text5_3_4  : "It is the distributor's responsibility to inform their end-users about the need and procedure to renew their subscriptions in a timely manner to avoid service suspension.",
				text5_3_5  : "Label Dictate Technologies is not responsible for the pricing policies of distributors, and any disputes related to renewal prices must be handled directly between the distributor and the end-user.",
				text5_3_6  : "Label Dictate Technologies reserves the right to modify these terms and conditions at any time without prior notice. It is the distributor's responsibility to stay informed and comply with the current terms and conditions at all times.",
				title5_4   : "5.4 User Cancellations and Refunds",
				text5_4    : "Label Dictate Portal offers a 30-day refund period for its subscriptions. Users who are not satisfied with their subscription can request a full refund within the first 30 days after the purchase of the subscription. After this period, due to the low-cost nature of our subscriptions, no refund or cancellation requests will be accepted. Subscriptions cannot be transferred, changed, or modified once they have been purchased. Users must carefully review the subscription terms before making the purchase to avoid any misunderstandings or future inconveniences. This term is designed to ensure a fair and equitable experience for all our users, and we appreciate your understanding and cooperation.",
				title5_5   : "5.5 Distributor Subscription Cancellations",
				text5_5    : "Label Dictate Technologies reserves the right to cancel any subscription generated by a distributor in case of non-compliance with the terms and conditions established on the Label Dictate Portal platform or in case of non-payment for the generated subscriptions. The cancellation may result in the suspension or termination of access to Label Dictate Technologies' applications and services for end-users related to those subscriptions.",
				text5_5_1  : "It is the distributor's responsibility to ensure that they comply with all terms and conditions and fulfill their payment obligations to avoid the cancellation of their end-users' subscriptions.",
				title5_6   : "5.6 Refund Policy for Distributors",
				text5_6    : "Since distributors have control over the generation and activation of subscriptions, distributors are not eligible for refunds for activated subscriptions generated by themselves. This is because distributors are considered responsible for properly managing subscriptions and interactions with end-users.",
				text5_6_1  : "Distributors must inform their end-users about this non-refundable policy at the time of sale and subscription activation. Any refund request or dispute related to refunds must be handled directly between the distributor and the end-user.",
				text5_6_2  : "Label Dictate Technologies is not responsible for distributors' refund policies and does not intervene in refund conflicts between distributors and end-users.",
				text5_6_3  : "As with all sections of these terms and conditions, Label Dictate Technologies reserves the right to modify these policies at any time and without prior notice. Distributors should regularly review these terms and conditions to be aware of any changes.",
				title6     : "6. Intellectual Property Rights",
				text6      : "All intellectual property rights on our site and our services, including but not limited to software, design, text, images, and graphics, are owned by our company. The user has no right to use, copy, reproduce, modify, or distribute our content without our prior written consent.",
				title7     : "7. Limitation of Liability",
				text7      : "To the maximum extent permitted by law, our company shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from the use or inability to use our services.",
				title8     : "8. Modifications to the Terms and Conditions",
				text8      : "We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our site. Continued use of our services after any changes will constitute acceptance of the modified Terms and Conditions.",
				title9     : "9. Applicable Law",
				text9      : "These Terms and Conditions shall be governed and interpreted in accordance with the laws of the state where our company is located."
			},
			privacy_policy: {
				policy   : "Privacy",
				privacy  : "Policy",
				title1   : "Introduction:",
				text1    : "Label Dictate Technologies LLC values your privacy and is concerned about how your personal information is used. This privacy policy applies to our website, Portal Label Dictate, and describes how we collect, use, and protect your personal information. Please read this privacy policy carefully and make sure you understand it. By using our website, you will be deemed to have accepted this privacy policy. If you do not agree with the terms of this policy, we recommend that you do not use our website.",
				title2   : "Information About Us:",
				text2    : "Portal Label Dictate is owned and operated by Label Dictate Technologies LLC, a limited company registered at 10909 Wurzbach Rd, San Antonio, TX 78230, USA. We are responsible for collecting and processing your personal information in accordance with this privacy policy.",
				title3   : "Collection of Personal Information:",
				text3    : "When you use our website and create an account on Portal Label Dictate, we may collect the following personal data:",
				text3_1  : "Name",
				text3_2  : "Email address",
				text3_3  : "Address",
				text3_4  : "Company name",
				text3_5  : "IP address",
				text3_5_1: "We use this information to manage your account, provide you with access to our website, and manage subscriptions purchased on Portal Label Dictate. We may also use your email address to communicate with you and send you relevant information about our applications and services.",
				title4   : "Disclosure of Personal Information:",
				text4    : "We do not share your personal information with third parties, except in the following cases:",
				text4_1  : "If we engage third parties to provide specific services, such as payment processing or order fulfillment. In such cases, we will take steps to ensure that your personal information is handled securely and in accordance with your rights and our legal obligations. When we are legally required to disclose your personal information, for example, in response to a court order or instructions from a government authority. Protection of Personal Information:",
				text4_2  : "We take appropriate security measures to protect your personal information and prevent unauthorized access or misuse. We implement technical and organizational safeguards to protect your personal information against loss, theft, and unauthorized access.",
				title5   : "Retention of Personal Information:",
				text5    : "We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, unless the law requires or allows a longer retention period. When we no longer need your personal information, we will securely delete or anonymize it.",
				title6   : "Your Rights:",
				text6    : "You have certain rights regarding your personal information. These rights include:",
				text6_1  : "Accessing your personal information and obtaining a copy of it. Correcting any inaccurate or incomplete personal information. Requesting the deletion of your personal information under certain circumstances. Restricting the processing of your personal information in certain situations. Objecting to the use of your personal information for direct marketing purposes. Requesting the portability of your personal data to another service provider, to the extent technically feasible. Filing a complaint with a data protection authority if you believe we have not fulfilled our data protection obligations.",
				title7   : "Cookies:",
				text7    : "We use cookies on our website to improve your user experience and provide you with personalized service. You can control the use of cookies through your browser settings.",
				title8   : "Changes to the Privacy Policy:",
				text8    : "We may update this privacy policy from time to time. We recommend that you periodically check this page to be aware of any changes. The changes will take effect from the date of their publication on our website.",
				title9   : "Contact Us:",
				text9    : "If you have any questions or concerns about our privacy policy or how we treat your personal information, please contact us through the contact details provided on our website."
			},
			about_us: {
				about: "About",
				us   : " Us",
				text0: "At Label Dictate, we are more than a software development company; we are a community passionate about providing innovative and efficient printing and identification solutions. Founded in San Antonio, Texas, and with a significant presence in Latin America, we have established ourselves as leaders in our field, driven by our dedication to excellence.",
				text1: "Our mission is clear and deeply rooted in all our efforts: to develop accessible, affordable, and user-friendly software solutions. We are forward-thinking, with a vision to adapt to the changing needs of the industry and stay at the forefront of technological innovation.",
				text2: "Our values are at the core of everything we do. We believe in integrity, excellence, innovation, and mutual respect. Our culture promotes a collaborative environment where each member of our team can grow, learn, and contribute their expertise.",
				text3: "The Label Dictate team consists of experienced professionals who are passionate about their work. Each member plays a vital role in delivering high-quality solutions to our clients. We take pride in having satisfied customers who attest to the effectiveness of our products and services.",
				text4: "We differentiate ourselves from the competition not only through our innovative and effective software solutions but also through our dedication to excellence. We strive to understand the unique needs of each customer and work together to find the best solution.",
				text5: "Welcome to Label Dictate. We are here to drive your success."
			},
			contact_us: {
				contact  : "Contact ",
				us       : "us!",
				name     : "Name",
				name_1   : "My name",
				email    : "Email address",
				company  : "Company",
				company_1: "My Company example",
				comments : "Comments",
				send_form: "Send form",
				text0: "We are excited to hear from you! If you are a user of our applications and have questions about your subscription on the Label Dictate Portal, or if you are a thermal printers and consumables distributor interested in becoming an official distributor of our products, we would love to hear from you.",
				text1: "Email: You can easily contact us by sending an email to contact@labeldictate.com. Our dedicated customer support team is ready to respond to all your inquiries quickly and efficiently.",
				text2: "If you have any questions or concerns about your subscription through the Label Dictate Portal, feel free to reach out to us. We are here to help you understand and make the most of our software solutions.",
				text3: "And if you are a distributor of thermal printing-related products and are interested in taking your business to the next level by becoming an official Label Dictate distributor, we would love to talk to you. Together, we can explore how our software solutions can complement and enhance your current offerings.",
				text4: "We look forward to hearing from you soon. Let's discuss how we can work together to improve and grow your business!",
			},
		
		} 
	},

	es: {
		translation: {
			utilities:{
				search             : "Búsqueda",
				search_btn         : "Buscar",
				is_a_required_field: "Es un campo requerido",
				day                : "Día",
				days               : "Días",
				month              : "Mes",
				months             : "Meses",
				year               : "Año",
				years              : "Años",
				download 		   : "Descargar",
				go_to              : "Ir a Web2Barcode",
			},
			user_type:{
				admin   : "Admin",
				partner : "Sócio",
				customer: "Cliente",
			},
			pagination_component:{
				rowsPerPageText      : "Filas por página",
				rangeSeparatorText   : "de",
				selectAllRowsItemText: "Todos",
			},
			status:{
				available : "Disponible",
				disabled  : "Deshabilitado",
				activated : "Activado",
				registered: "En registro",
				expired   : "Expirado",
				renovated : "Renovado",
			},
			header: {          
				login             : 'Iniciar sesión',
				logout            : 'Cerrar sesión',
				logout_session    : '¿Cerrar sesión?',
				yes_logout_session: 'Si, Cerrar sesión',
				main_portal       : 'Página principal',
				my_account        : 'Mi cuenta',
				signup            : 'Crear cuenta',
				language          : 'Idioma',
				products          : 'Productos',
				cart              : {
					empty       : 'Vacío',
					gotocheckout: 'Ir a pagar',
					product     : 'Producto',
					delete      : 'Eliminar',
					delete_item : 'Eliminar producto',
					do_you      : '¿Quiere eliminar el proucto?'
				},
				partner      : 'Socio',
				subpartner   : 'SubSocio',
				administrator: 'Administrador',
			},
			home: {
				text1   : "¡Bienvenido a Portal Label Dictate! Somos tu aliado en soluciones de software para impresión e identificación de código de barras. Gestiona tus suscripciones fácilmente y descubre nuestras innovadoras aplicaciones como Text2 Barcode, Suite Mobile POS, PDF label cut, Web2 Barcode entre otras. Aumenta tu eficiencia y éxito con nuestras soluciones integrales. ¡Adelante, explora las posibilidades!",
				title2  : "¡Maximiza tú eficiencia con ",
				title2_2: "Text2 Barcode!",
				text2   : "Convierte texto a códigos de barras e imprime etiquetas al instante en todas las plataformas. Prueba hoy Text2 Barcode y revoluciona tus procesos de etiquetado. ¡Experimenta la codificación e impresión de código de barras simplificada!",
				title3  : "Suite Mobile POS ",
				title3_3: "(SMP)",
				text3   : "Es una solución fácil de usar que cubre muchas necesidades de tu negocio. Con SMP, puedes hacer listas de códigos rápidamente, manejar tu almacén, imprimir etiquetas, buscar productos, revisar ofertas y obtener información de los productos simplemente escaneando códigos de barras. Su instalación es fácil y funciona con impresoras térmicas, lo que la convierte en una herramienta perfecta para cualquier negocio.",
				title4  : "Con ",
				title4_4: "Web2 Barcode",
				text4   : "La impresión de etiquetas es sencilla y accesible. Elige un diseño, carga tu archivo CSV y manda a imprimir, todo desde cualquier dispositivo. Descubre la eficiencia y comodidad de Web2 Barcode y revoluciona tu proceso de impresión de etiquetas ahora.",
				faqs    : "Preguntas frecuentes",
				help    : 'Centro de ayuda'
			},
			footer:{
				terms_and_conditions: "Terminos & condiciones",
				privacy_policy      : "Politica de privacidad",
				about_us            : "Sobre nosotros",
				contact_us          : "¿Quieres ser distribuidor? Contactanos",
				recaptcha           : "Este sitio está protegido por reCAPTCHA y se aplican la",
				privacy_google      : "Politica de privacidad",
				terms_google        : "Términos de servicio",
			},
			pages:{
				product:{
					buynow   : "Comprar ahora",
					addtocart: "Agregar al carrito",
					bestvalue: "Mejor valorado",
					price    : "Precio",
					license  : "Subscripción",
					licenses : "Subscripciones",
					for      : "por",
				},
				products:{
					view_prices: "Ver precios",
				}
			},
			login:{
				email            : "Correo electrónico",
				password         : "Contraseña",
				pass_confirm     : "Confirmar contraseña",
				please_login     : "Escriba sus credenciales",
				create_account   : "¿No tienes una cuenta? ¡Creala!",
				login_account    : "¿Ya tienes una cuenta? ¡Inicia sesión!",
				forgot_password  : "Olvide mi contraseña",
				recovery_password: "Recuperar contraseña",
				update_password  : "Actualizar contraseña",
			},
			checkout: {
				choice    : "¡Excelente elección!",
				email_send: "Correo al que se enviaran las licencias",
				email_ob  : "Correo electrónico (Obligatorio)",
				continue  : "Continuar al pago",
				your_cart : "Tu carrito",
				items     : "Cantidad de productos",
			},
			sidebar:{
				users          : "Usuarios",
				stores         : "Tiendas",
				partners       : "Socios",
				marketing      : "Marketing",
				products_plans : "Productos & Planes",
				payments       : "Pagos",
				billing        : "Facturas",
				reports        : "Reportes",
				packages       : "Paquetes",
				licenses       : "Subscripciones",
				add_advertising: "Agregar publicidad",
			},
			dashboard:{			
				licenses_activated: "Licencias activadas",
				licenses_renovated: "Licencias renovadas",
				licenses_to_expire: "Licencias por expirar",
				licenses_expired  : "Licencias expiradas",
				licenses_available: "Licencias disponibles",
				today             : "Hoy",
				activated         : "Activadas",
				renovated         : "Renovadas",
				to_expire         : "Por expirar",
				expired           : "Expiradas",
				available         : "Disponibles",
			},
			users:{
				add_user   : "Agregar usuario",
				email      : "Correo eléctronico",
				all_roles  : "Todos los roles",
				actions    : "Acciones",
				options    : "Opciones",
				partner    : "Socio",
				marketing  : "Marketing",
				subpartner : "Sub socio",
				customer   : "Cliente",
				admin      : "Administrador",
				sw         : "Sw",
				edit       : "Editar",
				delete     : "Eliminar",
				deleted    : "Eliminar usuario?",
				deleted_txt: "¿Estás seguro de eliminar este usuario?",
				create     : {
					user_create     : "Creación de usuario",
					select_an_option: "Selecciona una opción",
					create          : "Crear",
				},
				update   : "Actualizar",
				user_edit: "Edita usuario"
			},
			stores:{
				options       : "Opciones",
				search        : "Búsqueda",
				actions       : "Acciones",
				name          : "Nombre",
				email         : "Correo eléctronico",
				phone         : "Teléfono",
				link          : "Enlace",
				image         : "Imágen",
				edit          : "Editar",
				create_pay    : "Crear pago",
				add_store     : "Agregar tienda",
				add_create_pay: "Crear pago para tienda",
				payments_store: "Pagos de tiendas",
				delete        : "Eliminar",
				description   : "Descripción",
				country       : "País",
				expire_time   : "Tiempo de expiración",
				payment_id    : "ID del pago",
				expire_at     : "Expira en",
				info          : "Información",
				info_pay      : "Información de pago",
				status        : "Estado del pago",
				payment       : {
					create            : "Crear cobro a tienda",
					price             : "Precio",
					chose_country     : "Elige un país",
					chose_store       : "Elige una tienda",
					select_an_time    : "Selecciona el tiempo",
					select_expire_time: "Elige el tiempo de vigencia",
					create_pay        : "Crear enlace de pago"
				},
				create: {
					add     : "Añadir tienda",
					phone   : "Teléfono",
					link    : "Enlace",
					select  : "Selecciona una imágen",
					create  : "Crear tienda",
					required: "Imágen requerida",
				}
			},
			partners:{
				add_partner      : "Agregar socio",
				search           : "Búsqueda",
				name             : "Nombre",
				products_discount: "Descuento en productos",
				actions          : "Acciones",
				options          : "Opciones",
				edit             : "Editar",
				deleted          : "Eliminar",
				address          : "Dirección",
				address_invoice  : "Dirección de invoice",
				users            : "Usuarios",
				delete           : {
					del    : "¿Eliminar socio?",
					del_txt: "¿Estás seguro de eliminar este socio?",
					del_btn: "Eliminar",
				},
				create : {
					partner_creation  : "Creación de socio",
					partner_edition   : "Edición de socio",
					description       : "Descripción",
					discount          : "Descuento",
					street_address    : "Dirección",
					address_line      : "Dirección adicional",
					city              : "Ciudad",
					zip               : "Código postal",
					state             : "Estado",
					country           : "País",
					street_address_inv: "Invoice dirección",
					address_line_inv  : "Invoice dirección adicional",
					city_inv          : "Invoice ciudad",
					zip_inv           : "Invoice código postal",
					state_inv         : "Invoice estado",
					country_inv       : "Invoice país",
					email_inv         : "Invoice correo eléctronico",
					create_partner    : "Crear socio",
					update_partner    : "Actualizar socio",
				},
			},
			payments: {
				pay_customer : "Pagos de clientes",
				pay_partners : "Pos-Pagos de Socios",
				pay_marketing: "Pagos de marketing",
				pay_stores   : "Pagos de tiendas",
				go_customer  : "Ir a pagos de clientes",
				go_partners  : "Ir a pagos de partners",
				go_marketing : "ir a pagos de marketing",
				go_stores    : "ir a pagos de tiendas",
				create       : "Crear un link de pago",
				show         : "Mostrar todas las disputas",
				admin        : {
					stripe_payment    : "Pago de stripe",
					stripe_data       : "Datos de stripe",
					product           : "Producto",
					package           : "Paquete",
					actions           : "Acciones",
					options           : "Opciones",
					refund            : "Reembolso",
					payer_email       : "Email del comprador",
					date_purchase     : "Fecha de adquisición",
					amount_total      : "Monto total",
					status_payment    : "Estado del pago",
					price             : "Precio",
					status            : "Estado",
					expire_at         : "Expira en",
					refound_title     : "No se puede realizar el reembolso",
					refound_txt       : "Para realizar el reembolso deben haber pasado menos de 30 dias",
					refound_title_2   : '¿Realizar reembolso?',
					refound_text_2    : 'El paquete y licencias se deshabilitaran y el dinero se regresará a la cuenta del cliente',
					customers         : "Clientes",
					partners          : "Socios",
					marketings        : "Marketings",
					marketing_data    : "Marketing datos",
					stores            : "Tiendas",
					store             : "Tienda",
					quantity_license  : "Cantidad de licencias",
					count             : "Cuenta",
					created_at        : "Creado el ",
					country           : "País",
					states            : "Estados",
					upload_banners    : "Subir banners",
					create_pay        : "Crear pago de stripe",
					name              : "Nombre",
					description       : "Descripción",
					region_due_date   : "Región y fecha expiración",
					get_url           : "Obtener URL de pago",
					externally_title_1: "Pago externo",
					externally_text_1 : "¿Realizar pago externo? Esto cambiara el estado del pago",
					confirm_externally: "Confirmar pago externo",
					search            : {
						stripe_payment: "Stripe payment",
					},
				},
				customer     : {
					stripe_data   : "Datos de Stripe",
					product       : "Producto",
					package       : "Paquete",
					actions       : "Acciones",
					invoice       : "Invoice",
					payer_email   : "Correo de compra",
					date_purchase : "Fecha de compra",
					amount_total  : "Costo total",
					status_payment: "Estado del pago",
					price         : "Precio",
					status        : "Estado",
					expire_at     : "Expira en",
					search        : {
						stripe_payment: "Pago de stripe",
					},
					payments: "Pagos",
					billing : "Facturas",
				},
				partner:{
					stripe_data : "Datos de Stripe",
					quantity    : "Cantidad de licencias a cobrar",
					amount_total: "Costo total",
					actions     : "Acciones",
					invoice     : "Invoice",
					range       : "Rango",
					due_date    : "Fecha de creación",
					to          : "Al",
					created_at  : "Creado el",
					status      : "Estado de pago",
					checkout    : "Checkout ID",
					payment_id  : "ID de pago",
					pending     : "Pendiente",
					paid        : "Pagado",
					externally  : "Pago externo",
					pay_now     : "Pagar ahora",
					payment     : "Pago",
					options     : "Opciones",
					search      : {
						stripe_payment: "Pago de stripe",
						start_due_date: "Fecha inicial",
						end_due_date  : "Fecha final",
					},
					pospayments: "Pospagos",
					billing    : "Facturas",
				},
				marketing:{
					stripe_data      : "Datos de Stripe",
					status           : "Estado de pago",
					count            : "Cuenta",
					amount_total     : "Costo total",
					due_date         : "Fecha de pago actual",
					created_at       : "Creado el",
					expired_at       : "Expira el",
					region           : "Región",
					country          : "País",
					states           : "Estados",
					actions          : "Acciones",
					options          : "Opciones",
					invoices         : "Invoices",
					upload_banners   : "Subir imagenes",
					cancel_recurring : "Cancelar subscripción",
					add_advertising  : "Agregar publicidad",
					download_invoices: "Descargar invoices",
					remove           : "Remover subscripción?",
					remove_txt       : "Podrás realizar otra suscripción más adelante",
					unsubscribe      : "Quitar subscripción",
					search           : {
						stripe_payment: "Pago de stripe",
						start_due_date: "Fecha inicial",
						end_due_date  : "Fecha final",
					},
					marketing_pay: "Marketing payments",
					billing      : "Billing",
				},				
			},
			licenses: {
				renovated_individual: "Renovación individual de licencia",
				renovated_masive    : "Renovación masiva de licencias",
				charge_stripe       : "Esto generará una liga de pago en stripe",
				time_to_expire      : "Tiempo de expiración",
				close               : "Cerrar",
				year                : "Año",
				years               : "Años",
				download            : "Descargar",
				price_for_renovation: "Precio por renovación",
				show_row_selected   : "Mostrar filas seleccionadas",
				item                : "Fila",
				items               : "Filas",
				selected            : "Seleccionadas",
				product_key         : "Clave del producto",
				user_email          : "Correo del usuario",
				package             : "Paquete",
				customer            : {
					register_licenses : "Registrar Licencias",
					partner           : "Socio",
					package           : "Paquete #NS",
					product_key       : "Clave del producto",
					compatibility     : "Compatibilidad",
					current_device    : "Dispositivo actual",
					number_activation : "Número de activación",
					current_activation: "Activación actual",
					expire_at         : "Expira en",
					status            : "Estado",
					actions           : "Acciones",
					options           : "Opciones",
					remove_from_device: "Remover del dispositivo",
					renovate          : "Renovar",
					product           : "Producto",
					is_about_expire   : "Licencia por expirar",
					expire            : "¡Licencia Expirada!",
					package_disabled  : "Paquete deshabilitado",
					remove            : "Remover",
					str_remove_license: "¿Remover licencia del dispositivo actual?",
					str_this_license  : "Esta licencia puede ser activada en otro o el mismo dispositivo",
				},
				partner:{
					charge_month    : "Cargo recurrente al siguiente mes",
					created_at      : "Creado el",
					email_user_owner: "Correo del propietario",
					first_activation: "",
				},
				link: {
					link_licenses: "Vincular licencias",
					your_licenses: "Coloque aqui sus licencias",
					example      : "Ejemplo",
					send_licenses: "Enviar licencias",
					success      : "Vincular licencias",
				}
			},
			packages:{
				package               : "Paquete #SN",
				comments              : "Comentarios",
				compatibility         : "Compatibilidad",
				user_owner            : "Usuario propietario",
				status_package        : "Estado del paquete",
				status_license        : "Estado de licencia",
				total_licenses        : "Total de licencias",
				created_at            : "Creado el",
				expire_time           : "Tiempo de expiración",
				actions               : "Acciones",
				options               : "Opciones",
				show_all              : "Mostrar todo",
				download_csv          : "Descargar CSV",
				create_packages       : "Crear Paquetes",
				packages              : "Paquetes",
				compatibility_products: "Compatibilidad de producto",
				status                : "Estado",
				select_an_option      : "Selecciona una opción",
				show                  : {
					partner               : "Socio",
					package               : "Paquete #SN",
					product_key           : "Clave del producto",
					created_at            : "Creado el ",
					email_user_owner      : "Usuario propietario",
					user_type             : "Tipo de usuario",
					current_device        : "Dispositivo actual",
					number_activation     : "Número de activación",
					current_activation    : "Activación actual",
					first_activation      : "Primer activación",
					expire_at             : "Expira el",
					status                : "Estado",
					compatibility_products: "Compatibilidad del Producto",
					edit                  : "Editar",
					no_device             : "Sin dispositivo",
				},
				create: {
					quantity            : "Cantidad",
					select_an_time      : "Selecciona el tiempo",
					select_expire_time  : "Cantidad de tiempo",
					comments            : "Comentarios",
					create_package      : "Crear paquete",
					create_pack_licenses: "Crear paquete de licencias",
					partner             : "Socio",
					administrator       : "Administrador",
					compatible          : "Compatible con PDF label cut",
				}
			},
			marketing:{
				add_marketing  : "Agregar marketing",
				search         : "Búsqueda",
				name           : "Nombre",
				address        : "Dirección",
				address_invoice: "Dirección de Invoice",
				price_4_state  : "Precio por estado",
				price          : "Precio",
				users          : "Usuarios",
				actions        : "Acciones",
				options        : "Opciones",
				edit           : "Editar",
				delete         : "Borrar",
				delete_title   : "¿Borrar marketing?",
				delete_txt     : "¿Estás seguro que quieres eliminar el marketing?",
				payment        : "Información de pago",
				banners        : "Información de banners",
				show_banners   : "Mostrar banners",
				count          : "Cuenta de renovación",
				pi_id          : "ID de pago",
				status         : "Estado",
				total          : "Total",
				country        : "País",
				states         : "Estados",
				description    : "Descripción",
				months         : "Meses",
				email          : "Correo eléctronico",
				phone          : "Teléfono",
				no_payments    : "No hay pagos de marketing :(",
				advertising    : {
					freemium          : "Crear marketing freemium",
					phone             : "Teléfono",
					email             : "Correo eléctronico",
					you_can           : "Puedes subir 3 imagenes",
					you_can_txt       : "Para una mejor visualización para tus imagenes, recomendamos el tamaño 720 x 1280:",
					banners_list      : "Lista de imágenes",
					select_image      : "Selecciona una imágen",
					upload_advertising: "Subir publicidad",
					invalid_extension : "Extensión inválida",
					are_you_sure      : "¿Estás seguro?",
					upload_images     : "Subirás imágenes para publicidad, por favor no subas imágenes inapropiadas",
					yes_upload        : "Sí, subir imágenes",
					create            : {
						you_can             : "¡Puedes colocar publicidad en nuestras aplicaciones!",
						select_a_country    : "Selecciona país y estados para mostrar",
						select_a_state      : "Selecciona un estado",
						chose_your_country  : "Elige un país",
						states_selected     : "Estados seleccionados",
						states_not_available: "Estados no disponibles",
						total_amount        : "Monto total",
						months              : "Meses",
						select_option       : "Selecciona una opción",
						go_to_checkout      : "Ir a pagar (stripe)",
						only_values         : "Solo valores 3, 6, 12",
					}
				},
				create:{
					creation          : "Creación de marketing",
					edition           : "Edición de marketing",
					street_address    : "Dirección",
					address_line      : "Dirección adicional",
					city              : "Ciudad",
					zip               : "Código postal",
					state             : "Estado",
					country           : "País",
					street_address_inv: "Invoice dirección",
					address_line_inv  : "Invoice dirección adicional",
					city_inv          : "Invoice ciudad",
					zip_inv           : "Invoice código postal",
					state_inv         : "Invoice estado",
					country_inv       : "Invoice país",
					email_inv         : "Invoice correo eléctronico",
					create_marketing  : "Crear socio",
					update_marketing  : "Actualizar socio",
				}
			},
			products:{
				add_product          : "Agregar producto",
				search               : "Búsqueda",
				products             : "Productos",
				visibility           : "Visibilidad",
				list_price           : "Precio de lista (un año)",
				list_price_2         : "Precio de lista 2 (dos años)",
				order                : "Orden",
				plans_title          : "Planes",
				add_plan             : "Agregar plan",
				plans_archived_txt   : "Planes archivados",
				products_archived_txt: "Productos archivados",
				archive_product      : "Archivar producto",
				archive_product_txt  : "¿Quieres archivar este producto (En las plataformas stripe y portal)?",
				restore_product      : "Restaurar producto",
				restore_product_txt  : "¿Quieres restaurar este producto (En las plataformas stripe y portal)?",
				archive_plan         : "Archivar plan",
				archive_plan_txt     : "¿Quieres archivar este plan (En las plataformas stripe y portal)?",
				restore_plan         : "Restaurar plan",
				restore_plan_txt     : "¿Quieres restaurar este plan (En las plataformas stripe y portal)?",
				archive              : "Archive",
				restore              : "Restaurar",
				hide                 : "Ocultar",
				hidden               : "Ocultar",
				show                 : "Mostrar",
				plan_name            : "Nombre del plan",
				plan_desc            : "Descripción del plan",
				duration             : "Duración",
				best_value           : "Mejor valorada",
				invalid_extension    : "Extensión invalida",
				create               : {
					creation        : "Creación de producto",
					edition         : "Edición de producto",
					name            : "Nombre",
					description     : "Descripción",
					list_price      : "Precio de lista",
					list_price_2    : "Precio de lista 2 (2 years)",
					order           : "Orden",
					select_an_option: "Selecciona una opción",
					select_image    : "Selecciona una imagen",
					create          : "Crear producto",
					update          : "Actualizar producto",
				},
				plans              : {
					not_exist        : "El producto no existe :(",
					not_exist_plan   : "El plan no existe :(",
					creation         : "Creación de plan",
					edition          : "Edición de plan",
					name             : "Nombre",
					description      : "Descripción",
					price            : "Precio",
					select_a_plan    : "Selecciona un plan",
					normal           : "Plan normal",
					best             : "Mejor valorado",
					select_a_time    : "Selecciona un tiempo",
					select_a_expire  : "Duración para expirar",
					select_a_option  : "Selecciona una opción",
					licenses_quantity: "Licencias incluidas en el plan (*)",
					description_list : "Lista descriptiva",
					at_least         : "Al menos uno en la lista",
					create           : "Crear plan",
					update           : "Actualizar plan",
				},
			},
			reports: {
				reports    : "Reportes",
				payments   : "Pagos de clientes",
				pospayment : "Pos pagos de socios",
				marketing  : "Pagos de marketing",
				store      : "Pagos de tiendas",
				suscription: "Subscripción de licencias",
				renovated  : "subscripciones renovadas",
				dashboard  : "Panel (dashboard)",
				get_report : "Obtener reporte",
				start_at   : "Inicia el",
				end_at     : "Termina el",
			},
			no_match:{
				not_found: "Página no encontrada 404",
				p1       : "Está página no existe, cambio de dirección o fue eliminada.",
				p2       : "Puedes regresar al portal principal presionando el boton de ir atras...",
				back     : "Ir atras",
			},
			maintenance:{
				h1  : "¡Estamos realizando tareas de mantenimiento!",
				p1  : "Lamentamos las molestias. Pronto estaremos de vuelta para darte el mejor servicio.",
				p2  : "Para consultas, contáctanos en",
				hour: "El mantenimiento terminará aproximadamente a las: ",
			},
			faqs: {
				title1 : "¿Cómo funcionan las suscripciones en Portal Label Dictate?",
				text1  : "Con las suscripciones en Portal Label Dictate, los usuarios pueden adquirir y gestionar sus licencias de forma centralizada. Pueden renovar, cancelar o adquirir nuevas suscripciones directamente en la plataforma, brindando mayor control y flexibilidad.",
				title2 : "¿Puedo usar cualquier suscripción en cualquier producto de Label Dictate?",
				text2  : "No, las suscripciones de Label Dictate son únicas e independientes para cada producto específico y están limitadas a un solo dispositivo. Cada suscripción se activa y se utiliza exclusivamente en el producto correspondiente para el cual fue adquirida. Por ejemplo, una suscripción de Text2 Barcode solo puede ser activada y utilizada en la aplicación Text2 Barcode.",
				text2_1: "Es importante tener en cuenta que cada suscripción está diseñada para su uso en un solo dispositivo. Si necesita activar la suscripción en varios dispositivos simultáneamente, será necesario adquirir suscripciones adicionales para cada dispositivo adicional en el que desee utilizar el producto correspondiente.",
				title3 : "¿Qué beneficios ofrece el modelo de suscripción en comparación con los códigos de activación de versiones anteriores?",
				text3  : "El modelo de suscripción brinda a los usuarios acceso continuo a las últimas versiones de nuestras aplicaciones. Además, simplifica el proceso de administración de licencias al permitir renovaciones y la posibilidad de administrar las suscripciones en la plataforma Portal Label Dictate.",
				title4 : "¿Qué beneficios ofrece el modelo de suscripción en comparación con los códigos de activación de versiones anteriores?",
				text4  : "El modelo de suscripción brinda a los usuarios acceso continuo a las últimas versiones de nuestras aplicaciones. Además, simplifica el proceso de administración de licencias al permitir renovaciones y la posibilidad de administrar las suscripciones en la plataforma Portal Label Dictate.",
				title5 : "No recibí el código de suscripción, ¿qué debo hacer?",
				text5  : "Si no ha recibido el código de suscripción después de realizar el pago en Portal Label Dictate, aquí hay algunas acciones que puede tomar:",
				text5_1: "Verifique su bandeja de entrada: Revise la bandeja de entrada de su correo electrónico, incluyendo la carpeta de correos no deseados o spam. Es posible que el correo con el código de suscripción se haya filtrado incorrectamente en una de estas carpetas.",
				text5_2: "Descargue la imagen de código de suscripción: Al realizar la compra en Portal Label Dictate se le proporciona la opción de descargar el código de suscripción como una imagen, verifique si descargó la imagen y asegúrese de guardarla en un lugar seguro.",
				text5_3: "Verifique las licencias en Portal Label Dictate: Si ya es usuario de Portal Label Dictate y ha iniciado sesión en su cuenta al realizar la compra, puede verificar si la suscripción está activa en el apartado de licencias de su cuenta. Si aparece allí, significa que la suscripción está correctamente registrada en su cuenta.",
				text5_4: "Si después de realizar estas verificaciones aún no ha recibido el código de suscripción o tiene alguna otra pregunta relacionada, le recomendamos ponerse en contacto con nuestro equipo de atención al cliente. Estaremos encantados de ayudarle a resolver cualquier problema relacionado con su código de suscripción.",
				title6 : "¿Qué sucede si ya tengo un código de activación de versiones anteriores?",
				text6  : "Si ya tiene un código de activación válido, puede continuar usándolo hasta que se agoten las 3 reactivaciones en un solo dispositivo, Sin embargo, para acceder a las ventajas y beneficios del modelo de suscripción, le recomendamos considerar la transición a una suscripción cuando llegue el momento de renovar.",
				title7 : "¿El correo electrónico utilizado para comprar la suscripción es el mismo que se utiliza para iniciar sesión en Portal Label Dictate?",
				text7  : "No, el correo electrónico utilizado para comprar la suscripción no es necesariamente el mismo que se utiliza para iniciar sesión en Portal Label Dictate. El correo electrónico proporcionado al comprar la suscripción se utiliza exclusivamente para enviar el código de  suscripción.",
				text7_1: "Al crear una cuenta en Portal Label Dictate, se le solicitará un correo electrónico que se utilizará para gestionar las suscripciones adquiridas. Este correo electrónico de la cuenta de Portal Label Dictate será utilizado para activar la suscripción en los dispositivos, junto con el código de suscripción proporcionado al realizar la compra.",
				text7_2: "Tenga en cuenta que el correo electrónico de la cuenta en Portal Label Dictate es fundamental para la administración y activación de sus suscripciones, mientras que el correo electrónico utilizado en la compra de la suscripción se utiliza principalmente para el envío de la suscripción y el código de activación.",								
				title8  : "¿Qué sucede si ya tengo un código de activación de versiones anteriores?",
				text8   : "Si ya tiene un código de activación válido, puede continuar usándolo hasta que se agoten las 3 reactivaciones en un solo dispositivo, Sin embargo, para acceder a las ventajas y beneficios del modelo de suscripción, le recomendamos considerar la transición a una suscripción cuando llegue el momento de renovar.",
				title9  : "¿Cómo puedo cambiar o migrar la suscripción de un dispositivo a otro?",
				text9   : "Para cambiar de dispositivo con su suscripción en las aplicaciones de Label Dictate, tiene dos métodos disponibles:",
				title9_1: "Método 1: ",
				text9_1 : "Acceda a Portal Label Dictate en su navegador y desactive la suscripción del dispositivo actual. ",
				text9_2 : "Luego, en el nuevo dispositivo al que desea transferir la suscripción, abra la aplicación de Label Dictate.",
				text9_3 : "En la aplicación de Label Dictate, busque la opción para activar una suscripción e ingrese su dirección de correo electrónico y el código de suscripción correspondiente. Asegúrese de ingresar los datos correctamente.",
				title9_2: "Método 2: ",
				text9_4 : "En el dispositivo actual, abra la aplicación de Label Dictate y desactive la suscripción.",
				text9_5 : "A continuación, en el nuevo dispositivo, abra la aplicación de Label Dictate.",
				text9_6 : "En la aplicación de Label Dictate en el nuevo dispositivo, busque la opción para activar una suscripción e ingrese su dirección de correo electrónico y el código de suscripción correspondiente.",
				text9_7 : "Siga las instrucciones proporcionadas en la aplicación para activar la suscripción en el nuevo dispositivo.",
				text9_8 : "Recuerde que es importante desactivar la suscripción en el dispositivo anterior antes de activarla en el nuevo dispositivo. Esto garantizará que la suscripción se pueda utilizar correctamente y evitará cualquier conflicto en el uso de la misma.",
				title10  : "¿Puedo gestionar las suscripciones adquiridas con un distribuidor autorizado en Portal Label Dictate?",
				text10   : "Sí, las suscripciones adquiridas con un distribuidor autorizado pueden ser gestionadas en Portal Label Dictate. Nuestra plataforma centralizada le permite administrar todas sus suscripciones de forma conveniente y eficiente.",
				text10_1 : "Sin embargo, es importante tener en cuenta que los códigos de activación correspondientes a versiones anteriores, que están limitados a 3 activaciones en un dispositivo, no pueden ser gestionados en Portal Label Dictate. Estos códigos de activación se rigen por sus propias restricciones y políticas.",
				text10_2 : "Para las suscripciones adquiridas con un distribuidor autorizado, le recomendamos utilizar Portal Label Dictate para administrar sus suscripciones, renovaciones y cualquier otro aspecto relacionado con ellas. Esto le brinda un mayor control y una experiencia de gestión unificada en nuestra plataforma.",
				title11 : "¿Cómo puedo cancelar mi suscripción a Label Dictate?",
				text11  : "En Portal Label Dictate, las suscripciones son de bajo costo y, por lo tanto, no ofrecemos la opción de cancelar o cambiar suscripciones una vez que se han adquirido. Sin embargo, entendemos que a veces puede haber circunstancias imprevistas. Por lo tanto, ofrecemos reembolsos durante los primeros 30 días después de la compra de una suscripción. Después de este periodo de 30 días, ya no se pueden solicitar reembolsos debido al bajo costo de las suscripciones. Le sugerimos que revise cuidadosamente las condiciones antes de comprar una suscripción para evitar cualquier inconveniente.",
				title12 : "¿Label Dictate proporciona soporte técnico personalizado sobre el uso y configuración de las aplicaciones?",
				text12  : "No, Label Dictate no proporciona soporte técnico personalizado directamente a los clientes finales. En su lugar, el soporte especializado es brindado por los distribuidores autorizados de Label Dictate, quienes cuentan con expertos en el uso de las aplicaciones de Label Dictate.",
				text12_1: "Si necesita asistencia técnica personalizada, le recomendamos enviar un correo electrónico a contact@labeldictate.com proporcionando su nombre, país, aplicación específica y motivo de consulta. A partir de ahí, será dirigido al distribuidor oficial correspondiente a su país. Esto se hace con el objetivo de brindar un soporte especializado a nivel local, considerando dispositivos y consumibles disponibles en su país.",
				text12_2: "Los distribuidores autorizados están capacitados para brindar asesoramiento técnico, solución de problemas y soporte relacionado con el uso y configuración de las aplicaciones de Label Dictate. Ellos tienen el conocimiento necesario para atender sus consultas y ayudarlo de manera eficiente y especializada.",
				text12_3: "Estamos comprometidos en garantizar que nuestros clientes reciban la mejor experiencia de soporte y atención, y trabajamos en colaboración con nuestros distribuidores autorizados para lograrlo.",
				title13 : "¿Cómo puedo distinguir entre las licencias de versiones anteriores y las suscripciones de las nuevas versiones?",
				text13  : "Las nuevas versiones de nuestras aplicaciones son compatibles con el modelo de suscripción y utilizan códigos de 25 caracteres en el formato AAAAABBBBBCCCCCDDDDDEEEEE. Por otro lado, las licencias que son compatibles con las versiones anteriores emplean códigos de 20 caracteres en el formato AAAA-BBBB-CCCC-DDDD-EEEE. Es crucial identificar qué tipo de licencia o suscripción tienes para asegurarte de descargar la versión adecuada y compatible de nuestra aplicación.",							
			},
			terms_and_conditions : {
				main_title : "Términos y Condiciones de ",
				title1     : "1. Introducción",
				text1      : `Estos Términos y Condiciones rigen el uso del sitio web de Portal Label Dictate ("nuestro sitio") y todos los servicios asociados (colectivamente, "nuestros servicios") proporcionados por Label Dictate Technologies LLC ("nosotros", "nos", "nuestra empresa"). Al utilizar nuestro sitio o nuestros servicios, usted ("usted", "el usuario") acepta cumplir con estos Términos y Condiciones.`,
				title2     : "2. Uso Aceptable",
				text2      : `El usuario se compromete a utilizar nuestro sitio y nuestros servicios de manera responsable, ética y legal. No se permite el uso que pueda dañar, sobrecargar, interrumpir o vulnerar la seguridad de nuestro sitio o nuestros servicios. Cualquier uso de nuestros servicios para fines ilegales o la violación de estos Términos y Condiciones puede resultar en la terminación de su acceso a nuestros servicios.`,
				title3     : "3. Cuentas y Suscripciones",
				text3      : `Al crear una cuenta en nuestro sitio, el usuario acepta proporcionar información precisa y completa. Es responsabilidad del usuario mantener la seguridad de su cuenta y la confidencialidad de su contraseña. Cualquier suscripción adquirida a través de nuestro sitio está sujeta a estos Términos y Condiciones y a cualquier término adicional especificado en el momento de la compra. Todas las suscripciones son no transferibles y sólo pueden ser utilizadas por el titular de la cuenta.`,
				title3_1   : "3.1 Uso de las Versiones Free y Pro de las Aplicaciones de Label Dictate",
				text3_1    : `Label Dictate ofrece versiones Free (gratuitas) y Pro (de pago) de nuestras aplicaciones. Mientras que la versión Free está destinada al uso personal y no lucrativo, la versión Pro está diseñada para ser utilizada por empresas o individuos que buscan utilizar nuestras aplicaciones con fines lucrativos.`,
				text3_2    : `Las empresas que se benefician comercialmente del uso de nuestras aplicaciones están obligadas a utilizar la versión Pro. La versión Free no puede ser utilizada con fines comerciales. El uso de la versión Free de nuestras aplicaciones para fines comerciales se considerará una violación de estos Términos y Condiciones y puede resultar en la terminación de su cuenta y la posible responsabilidad legal.`,
				text3_3    : `Es importante destacar que Label Dictate se reserva el derecho de tomar medidas legales contra cualquier entidad que utilice la versión Free de nuestras aplicaciones para lucrar sin obtener una licencia de la versión Pro. Dichas acciones estarán sujetas a las leyes de propiedad intelectual y de licencias del país correspondiente.`,
				text3_4    : `Todas las empresas están obligadas a cumplir con estas condiciones de uso y a respetar nuestros derechos de propiedad intelectual. Al utilizar cualquier versión de nuestras aplicaciones, usted acepta cumplir con estos Términos y Condiciones. Cualquier violación de estos términos puede resultar en la terminación inmediata de su acceso a nuestras aplicaciones y servicios y puede dar lugar a acciones legales.`,
				text3_5    : `Al aceptar estos Términos y Condiciones, usted está aceptando la responsabilidad de determinar si su uso de nuestras aplicaciones es legal en su jurisdicción específica, y acepta asumir la responsabilidad por cualquier infracción legal que pueda surgir de su uso indebido de nuestras aplicaciones.`,
				title3_2   : "3.2 Uso y Activación de Licencias y Códigos Promocionales de Versiones Anteriores de Label Dictate Technologies",
				text3_6    : "Aplicabilidad: Estos términos y condiciones se aplican a las licencias o códigos promocionales adquiridos para las versiones de las aplicaciones listadas a continuación que se adhieren al formato de licencia de 20 caracteres: AAAA-BBBB-CCCC-DDDD-EEEE:",
				text3_7    : `Text2 Barcode Windows versión 4.2.0 e inferiores.`,
				text3_7_1  : `Text2 Barcode MacOS versión 1.3.2 e inferiores.`,
				text3_7_2  : `Text2 Barcode Android versión 1.9.9 e inferiores.`,
				text3_7_3  : `PDF Label Cut versión 2.2.2 e inferiores.`,
				text3_7_4  : `Suite Mobile POS versión 3.9.9 e inferiores.`,
				text3_8    : "Limitación de Uso: Los códigos de activación por licencia o códigos promocionales solo pueden ser utilizados en un solo dispositivo y están limitados a un máximo de 3 activaciones. Después de tres activaciones, el sistema deshabilitará el código de activación por licencia o el código promocional.",
				text3_9    : "Continuación del Servicio: Para seguir utilizando la versión de pago de la aplicación después de la desactivación del código, será necesario adquirir una suscripción mediante el método de activación actual. La suscripción permitirá el uso de la versión de pago de la aplicación por el periodo de tiempo contratado en el Portal Label Dictate.",
				text3_10   : "Exclusiones: Los códigos de activación por licencia o códigos promocionales no pueden ser utilizados en el sistema administrativo Portal Label Dictate y no serán elegibles para una actualización al modelo de suscripción.",
				text3_11   : "Actualizaciones: Para obtener una actualización al modelo de suscripción, deberá adquirirse a través de una compra directa en el Portal Label Dictate (https://portal.labeldictate.com/). Dicha actualización tendrá un costo adicional.",
				text3_12   : "Al contar con una licencia o código promocional, usted acepta estos términos y condiciones. Para cualquier duda o consulta, por favor póngase en contacto con nuestro equipo de atención al cliente por medio de correo electrónico.",
				text3_13   : "Label Dictate Technologies se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso.",
				title4     : "4. Política de Privacidad",
				text4      : `Nuestra Política de Privacidad, que se encuentra en `,
				text4_1    : `describe cómo recopilamos, usamos y protegemos su información personal y forma parte de estos Términos y Condiciones.`,
				title5     : "5. Pagos, Renovaciones, Cancelaciones y Reembolsos",
				text5      : `El usuario es responsable de todos los cargos asociados con su cuenta, incluyendo, pero no limitado a, la compra y renovación de suscripciones. Todas las ventas son finales, a excepción de lo establecido en nuestra política de reembolsos, que es parte de estos Términos y Condiciones. Las renovaciones de suscripciones deben hacerse de forma manual a través de nuestro sitio.`,
				title5_1   : "5.1 Pagos de usuario",
				text5_1    : `Todos los pagos por suscripciones a las aplicaciones de Label Dictate Technologies LLC se realizan a través de Portal Label Dictate. Aceptamos tarjetas de crédito y débito como métodos de pago. Al realizar un pago, usted garantiza que tiene el derecho legal de utilizar el método de pago seleccionado. Todos los precios se presentan y deben pagarse en la moneda indicada en el Portal Label Dictate.`,
				text5_1_2  : `Portal Label Dictate se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso.`,
				title5_2   : "5.2 Pago de Distribuidores",
				text5_2_1  : `Los distribuidores autorizados de Label Dictate Technologies tienen la opción de realizar pagos a través de dos métodos: prepago y postpago. Ambos métodos permiten al distribuidor generar suscripciones para los usuarios finales, pero difieren en el momento del pago y cómo se maneja el vencimiento de las suscripciones.`,
				text5_2_2  : `Las normativas específicas y los procedimientos de pago para cada método están detallados en las siguientes subsecciones (5.2.1 Prepago y 5.2.2 Postpago). Todos los distribuidores deben adherirse a estos términos y condiciones para asegurar la entrega continua de suscripciones y evitar posibles sanciones.`,
				text5_2_3  : `Label Dictate Technologies se reserva el derecho de cambiar estos términos y condiciones en cualquier momento sin previo aviso. Es responsabilidad del distribuidor mantenerse actualizado y cumplir con las condiciones actuales en todo momento.`,
				title5_2_1 : "5.2.1 Prepago",
				text5_2_1_1: `Los distribuidores que cuentan con la función de prepago podrán generar suscripciones por el periodo de tiempo permitido por el sistema hasta su expiración. Estas suscripciones son para ser distribuidas por el propio distribuidor y tendrán un máximo de 1 año para poder ser activadas por el usuario final. En caso de no realizar esa activación, las suscripciones se bloquearán y no podrán ser activadas.`,
				text5_2_1_2: `Para activar las suscripciones, se generará un invoice que deberá ser pagado por el distribuidor. Una vez que el invoice sea pagado y las suscripciones se activen en el dispositivo del usuario final, comenzará el conteo del tiempo hasta la expiración de las suscripciones, de acuerdo con el tiempo de validez establecido en las suscripciones generadas.`,
				text5_2_1_3: `El límite de pago para estas suscripciones es de un máximo de 30 días naturales a partir de la fecha de generación del invoice. En caso de no realizar el pago dentro de este plazo, Label Dictate Technologies tiene la facultad de suspender el servicio de las suscripciones generadas hasta recibir el pago correspondiente.`,
				text5_2_1_4: `Es responsabilidad del distribuidor generar suscripciones de manera responsable y oportuna para no generar un excedente de suscripciones sin uso. Label Dictate Technologies no se hace responsable por el mal uso y una mala gestión en la generación de suscripciones. La suspensión del servicio puede resultar en la inactividad de las suscripciones para los usuarios finales y puede afectar la reputación y la relación comercial del distribuidor con Label Dictate Technologies y con los usuarios finales.`,
				text5_2_1_5: `Los distribuidores están sujetos a estos términos y condiciones al generar suscripciones a través del prepago. Todo incumplimiento de estas condiciones puede resultar en la revocación del estatus de distribuidor y la pérdida de acceso a la función de prepago.`,
				text5_2_1_6: `Portal Label Dictate se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso.`,
				title5_2_2 : "5.2.1 Postpago",
				text5_2_2_1: `Los distribuidores con la opción de postpago podrán generar suscripciones con la duración que el sistema permite hasta su vencimiento. Estas suscripciones son para ser distribuidas por el propio distribuidor y tendrán un máximo de 1 año para poder ser activadas por el usuario final. En caso de no realizar esa activación, las suscripciones se bloquearán y no podrán ser activadas. Es responsabilidad del distribuidor generar suscripciones de manera responsable y oportuna para no generar un excedente de suscripciones sin uso. Label Dictate Technologies no se hace responsable por el mal uso y una mala gestión en la generación de suscripciones.`,
				text5_2_2_2: `El pago de las suscripciones se realizará en el siguiente mes natural posterior a la activación de la suscripción por parte del usuario final en su dispositivo. El invoice correspondiente a las suscripciones se generará el primer día de cada mes y el distribuidor tendrá 30 días naturales para realizar el pago de dicho invoice. En caso de no realizar el pago dentro de este plazo, Label Dictate Technologies tiene la facultad de inactivar las suscripciones activas que correspondan al invoice generado. La suspensión del servicio puede resultar en la inactividad de las suscripciones para los usuarios finales y puede afectar la reputación y la relación comercial del distribuidor con Label Dictate Technologies y con los usuarios finales.`,
				text5_2_2_3: `Una vez que las suscripciones se activan en el dispositivo del usuario final, comienza el tiempo hasta la expiración de dichas suscripciones, de acuerdo con la duración establecida al generar las suscripciones.`,
				text5_2_2_4: `Es responsabilidad del distribuidor asegurar el pago oportuno del invoice generado en base a las suscripciones activadas. Todo retraso en el pago puede resultar en la interrupción de las suscripciones del usuario final, la inactivación de las suscripciones pendientes y posibles sanciones para el distribuidor.`,
				text5_2_2_5: `Los distribuidores están sujetos a estos términos y condiciones al generar suscripciones a través del postpago. Cualquier incumplimiento de estas condiciones puede resultar en la revocación del estatus de distribuidor y la pérdida de acceso a la función de postpago.`,
				text5_2_2_6: `Portal Label Dictate se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso.`,
				title5_3   : "5.3 Renovaciones",
				text5_3    : `La renovación de sus suscripciones debe realizarse de forma manual por el usuario a través de Portal Label Dictate. No realizamos cargos automáticos o recurrentes debido a que cada licencia tiene diferentes tipos de paquetes que pueden ser adquiridos de manera individual o en conjunto. La opción para renovar estará disponible un mes antes de la fecha de expiración de su suscripción y se indicará directamente en el Portal Label Dictate. Además, se enviará una notificación al usuario por correo electrónico.`,
				title5_3_1 : "5.3.1 Renovaciones de Suscripciones por Distribuidor",
				text5_3_1  : `Los distribuidores autorizados de Label Dictate Technologies tienen la posibilidad de renovar las suscripciones que han distribuido un mes antes de su fecha de expiración. Esta renovación temprana asegura una continuidad sin interrupciones del acceso a las aplicaciones y servicios de Label Dictate Technologies para el usuario final.`,
				text5_3_2  : `Las renovaciones pueden ser gestionadas directamente por el distribuidor a través de la plataforma Portal Label Dictate, lo que les proporciona mayor autonomía y control sobre sus suscripciones y usuarios finales.`,
				text5_3_3  : `Es importante destacar que, aunque el proceso de renovación puede ser manejado por el distribuidor, las renovaciones generadas deben ser pagadas a Label Dictate Technologies de acuerdo al precio establecido en ese momento hacia el distribuidor. Este precio puede variar y dependerá de su política comercial y las condiciones de mercado.`,
				text5_3_4  : `Es responsabilidad del distribuidor informar a sus usuarios sobre la necesidad y el procedimiento para renovar sus suscripciones en tiempo y forma para evitar la suspensión del servicio.`,
				text5_3_5  : `Label Dictate Technologies no se hace responsable de las políticas de precios de los distribuidores y cualquier conflicto relacionado con los precios de renovación debe ser manejado directamente entre el distribuidor y el usuario final.`,
				text5_3_6  : `Label Dictate Technologies se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso. Es responsabilidad del distribuidor mantenerse informado y cumplir con los términos y condiciones actuales en todo momento.`,
				title5_4   : "5.4 Cancelaciones y Reembolsos usuario",
				text5_4    : `Portal Label Dictate ofrece un periodo de reembolso de 30 días para sus suscripciones. Los usuarios que no estén satisfechos con su suscripción pueden solicitar un reembolso completo dentro de los primeros 30 días posteriores a la compra de la suscripción. Después de este periodo, debido a la naturaleza de bajo costo de nuestras suscripciones, no se aceptarán solicitudes de reembolso ni cancelación. Las suscripciones no pueden ser transferidas, cambiadas o modificadas una vez que se han adquirido. Los usuarios deben revisar detenidamente los términos de la suscripción antes de realizar la compra para evitar cualquier malentendido o inconveniente futuro. Este término está diseñado para garantizar una experiencia justa y equitativa para todos nuestros usuarios y apreciamos su comprensión y cooperación.`,
				title5_5   : "5.5 Cancelaciones de Suscripciones a Distribuidor",
				text5_5    : `Label Dictate Technologies se reserva el derecho de cancelar cualquier suscripción generada por un distribuidor en caso de incumplimiento de los términos y condiciones establecidos en la plataforma Portal Label Dictate, o en caso de falta de pago por las suscripciones generadas. La cancelación puede resultar en la suspensión o terminación del acceso a las aplicaciones y servicios de Label Dictate Technologies para los usuarios finales relacionados con dichas suscripciones.`,
				text5_5_1  : `Es responsabilidad del distribuidor garantizar que cumplen con todos los términos y condiciones, así como con sus obligaciones de pago para evitar la cancelación de las suscripciones de sus usuarios finales.`,
				title5_6   : "5.6 Política de Reembolsos para Distribuidores",
				text5_6    : `Dado que los distribuidores tienen control sobre la generación y activación de suscripciones, los distribuidores no son elegibles para reembolsos por las suscripciones activadas generadas por ellos mismos. Esto se debe a que se considera que el distribuidor tiene la responsabilidad de administrar correctamente las suscripciones y las interacciones con los usuarios finales.`,
				text5_6_1  : `Los distribuidores deben informar a sus usuarios finales sobre esta política de no reembolso al momento de la venta y la activación de la suscripción. Cualquier solicitud de reembolso o disputa relacionada con el reembolso debe ser manejada directamente entre el distribuidor y el usuario final.`,
				text5_6_2  : `Label Dictate Technologies no se hace responsable de las políticas de reembolso de los distribuidores ni interviene en los conflictos de reembolso entre los distribuidores y los usuarios finales.`,
				text5_6_3  : `Como en todas las secciones de estos términos y condiciones, Label Dictate Technologies se reserva el derecho de modificar estas políticas en cualquier momento y sin previo aviso. Los distribuidores deben revisar regularmente estos términos y condiciones para estar al tanto de cualquier cambio.`,
				title6     : "6. Derechos de Propiedad Intelectual",
				text6      : `Todos los derechos de propiedad intelectual en nuestro sitio y nuestros servicios, incluyendo, pero no limitado a, el software, el diseño, el texto, las imágenes y los gráficos, son propiedad de nuestra empresa. El usuario no tiene derecho a usar, copiar, reproducir, modificar o distribuir nuestro contenido sin nuestro consentimiento previo por escrito.`,
				title7     : "7. Limitación de Responsabilidad",
				text7      : `Hasta el máximo permitido por la ley, nuestra empresa no será responsable de ningún daño directo, indirecto, incidental, especial, consecuencial o ejemplar que resulte del uso o la incapacidad de usar nuestros servicios.`,
				title8     : "8. Modificaciones a los Términos y Condiciones",
				text8      : `Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en nuestro sitio. El uso continuado de nuestros servicios después de cualquier cambio constituirá la aceptación de los Términos y Condiciones modificados.`,
				title9     : "9. Ley Aplicable",
				text9      : `Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes del estado donde se encuentra nuestra empresa.`,
			},
			privacy_policy: {
				policy   : "Política de ",
				privacy  : "Privacidad ",
				title1   : "Introducción:",
				text1    : "Label Dictate Technologies LLC valora su privacidad y se preocupa por cómo se utiliza su información personal. Esta política de privacidad se aplica a nuestro sitio web, Portal Label Dictate, y describe cómo recopilamos, utilizamos y protegemos su información personal. Lea atentamente esta política de privacidad y asegúrese de comprenderla. Al utilizar nuestro sitio web, se considerará que ha aceptado esta política de privacidad. Si no está de acuerdo con los términos de esta política, le recomendamos que no utilice nuestro sitio web.",
				title2   : "Información sobre nosotros:",
				text2    : "Portal Label Dictate es propiedad y está operado por Label Dictate Technologies LLC, una compañía limitada registrada en 10909 Wurzbach Rd, San Antonio, TX 78230, USA. Somos responsables de recopilar y procesar su información personal de acuerdo con esta política de privacidad.",
				title3   : "Recopilación de información personal:",
				text3    : "Cuando utiliza nuestro sitio web y crea una cuenta en Portal Label Dictate, podemos recopilar los siguientes datos personales:",
				text3_1  : "Nombre",
				text3_2  : "Dirección de correo electrónico",
				text3_3  : "Dirección",
				text3_4  : "Nombre de la empresa",
				text3_5  : "Dirección IP",
				text3_5_1: "Utilizamos esta información para administrar su cuenta, brindarle acceso a nuestro sitio web y gestionar las suscripciones adquiridas en Portal Label Dictate. También podemos utilizar su dirección de correo electrónico para comunicarnos con usted y enviarle información relevante sobre nuestras aplicaciones y servicios.",
				title4   : "Divulgación de información personal:",
				text4    : "No compartimos su información personal con terceros, excepto en los casos siguientes:",
				text4_1  : "Si contratamos a terceros para proporcionar servicios específicos, como procesamiento de pagos o cumplimiento de pedidos. En tales casos, tomaremos medidas para garantizar que su información personal se maneje de manera segura y de acuerdo con sus derechos y nuestras obligaciones legales. Cuando estamos legalmente obligados a divulgar su información personal, por ejemplo, en respuesta a una orden judicial o a las instrucciones de una autoridad gubernamental. Protección de la información personal:",
				text4_2  : "Tomamos medidas de seguridad adecuadas para proteger su información personal y evitar el acceso no autorizado o el uso indebido de la misma. Implementamos salvaguardias técnicas y organizativas para proteger su información personal contra pérdida, robo y acceso no autorizado.",
				title5   : "Retención de información personal:",
				text5    : "Mantenemos su información personal durante el tiempo necesario para cumplir con los fines para los que se recopiló, a menos que la ley exija o permita un período de retención más largo. Cuando ya no necesitemos su información personal, la eliminaremos de manera segura o la anonimizaremos.",
				title6   : "Sus derechos:",
				text6    : "Usted tiene ciertos derechos con respecto a su información personal. Estos derechos incluyen:",
				text6_1  : "Acceder a su información personal y obtener una copia de la misma. Corregir cualquier información personal inexacta o incompleta. Solicitar la eliminación de su información personal en ciertas circunstancias. Restringir el procesamiento de su información personal en determinadas situaciones. Oponerse al uso de su información personal para fines de marketing directo. Solicitar la portabilidad de sus datos personales a otro proveedor de servicios, en la medida en que sea técnicamente posible. Presentar una queja ante una autoridad de protección de datos si considera que no hemos cumplido con nuestras obligaciones de protección de datos.",
				title7   : "Cookies:",
				text7    : "Utilizamos cookies en nuestro sitio web para mejorar su experiencia de usuario y ofrecerle un servicio personalizado. Puede controlar el uso de cookies a través de la configuración de su navegador.",
				title8   : "Cambios en la política de privacidad:",
				text8    : "Podemos actualizar esta política de privacidad de vez en cuando. Le recomendamos que consulte periódicamente esta página para estar al tanto de cualquier cambio. Los cambios entrarán en vigencia a partir de la fecha de su publicación en nuestro sitio web.",
				title9   : "Contáctenos:",
				text9    : "Si tiene alguna pregunta o inquietud sobre nuestra política de privacidad o sobre cómo tratamos su información personal, comuníquese con nosotros a través de los detalles de contacto proporcionados en nuestro sitio web.",				
			},
			about_us: {
				about: "Sobre",
				us   : " Nosotros",
				text0: "En Label Dictate, somos más que una empresa de desarrollo de software; somos una comunidad apasionada por brindar soluciones de impresión e identificación innovadoras y eficientes. Fundada en San Antonio, Texas, y con una notable presencia en Latinoamérica, nos hemos consolidado como líderes en nuestro campo, marcados por nuestra dedicación a la excelencia.",
				text1: "Nuestra misión es clara y fuertemente arraigada en todos nuestros esfuerzos: desarrollar soluciones de software accesibles, económicas y fáciles de usar. Nos orientamos hacia el futuro, con la visión de adaptarnos a las necesidades cambiantes de la industria y de mantenernos a la vanguardia de la innovación tecnológica.",
				text2: "Nuestros valores están al centro de todo lo que hacemos. Creemos en la integridad, la excelencia, la innovación y el respeto mutuo. Nuestra cultura promueve un ambiente de colaboración, en el que cada miembro de nuestro equipo puede crecer, aprender y contribuir con su expertise.",
				text3: "El equipo de Label Dictate está compuesto por profesionales experimentados y apasionados por su trabajo. Cada miembro desempeña un papel vital en la entrega de soluciones de alta calidad a nuestros clientes. Nos enorgullece tener clientes satisfechos que respaldan la eficacia de nuestros productos y servicios.",
				text4: "Nos diferenciamos de nuestra competencia no sólo por nuestras soluciones de software innovadoras y efectivas, sino también por nuestra dedicación a la excelencia. Nos esforzamos por entender las necesidades únicas de cada cliente y trabajar en conjunto para encontrar la mejor solución.",
				text5: "Bienvenido a Label Dictate. Estamos aquí para impulsar tu éxito.",
			},
			contact_us: {
				contact  : "¡Contactanos",
				us       : "!",
				name     : "Nombre",
				name_1   : "Mi nombre",
				email    : "Correo eléctronico",
				company  : "Compañia",
				company_1: "Mi compañia",
				comments : "Comentarios",
				send_form: "Enviar formulario",
				text0    : "¡Estamos emocionados de escuchar de ti! Si eres un usuario de nuestras aplicaciones y tienes dudas sobre tu suscripción en el Portal Label Dictate, o si eres un distribuidor de impresoras térmicas y consumibles y estás interesado en convertirte en distribuidor oficial de nuestros productos, nos encantaría tener noticias tuyas.",
				text1    : "Correo Electrónico: Puedes contactarnos fácilmente enviándonos un correo electrónico a contact@labeldictate.com. Nuestro dedicado equipo de atención al cliente está listo para responder a todas tus consultas de manera rápida y eficiente.",
				text2    : "Si tienes preguntas o inquietudes sobre tu suscripción a través del Portal Label Dictate, no dudes en escribirnos. Estamos aquí para ayudarte a entender y aprovechar al máximo nuestras soluciones de software.",
				text3    : "Y si eres un distribuidor de productos relacionados con la impresión térmica y estás interesado en llevar tu negocio al siguiente nivel convirtiéndote en un distribuidor oficial de Label Dictate, nos encantaría hablar contigo. Juntos, podemos explorar cómo nuestras soluciones de software pueden complementar y mejorar tus ofertas actuales.",
				text4    : "Esperamos tener noticias tuyas pronto. ¡Hablemos de cómo podemos trabajar juntos para mejorar y hacer crecer tu negocio!",
			},
		}
	}

};

export default resources;