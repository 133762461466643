
const utilities = {
    makeid: function(length, lowercase = true) {
        let   result           = '';
        // let   characters       = '';
        let characters = (lowercase) ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    getRandomInt: (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is 
    },
    getBase64: (file, onLoadCallback) => {
        return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    },
    copyToClipboard: async(text) => {
        window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
        try {
            await navigator.clipboard.writeText(text);
            console.log('Content copied to clipboard');            
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
        // try {
        //     let input       = document.body.appendChild(document.createElement("input"));
        //         input.value = text;
        //         input.focus();
        //         input.select();
        //         document.execCommand('copy');
        //         input.parentNode.removeChild(input);
        //         console.log('Content copied to clipboard');  
        // } catch (err) {
        //     console.error('Failed to copy: ', err);
        // }
    }    
}

export default utilities