import React, {useEffect, useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import utilities from '../../utilities/utilities';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom'

const EditStore = () => {
  /***************************************************************************************************************************/
  const {getHeaders, token} = useContext(AuthContext);
  const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
  const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
  const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
  const { t }                             = useTranslation();
  const [image, setImage]                 = useState('');
  const [loading, setloading]             = useState(false);
  const [free, setfree]                   = useState(false);
  const [_image, set_image]               = useState(false);
  const { id }                            = useParams();
  const [initialValues, setinitialValues] = useState({ 
    name    : '',
    phone   : '',
    whatsapp: '',
    email   : '',
    link    : '',
});
/***************************************************************************************************************************/
    useEffect(() => {
        (async () =>{
            const headers = await getHeaders();
            await axios.get(`${url}stores/edit/${id}`, {headers})
                        .then(async res => {
                            const _free  = res.data?.message;
                            const _image = res.data?.message?.image
                            await axios.get(`${url}store/${_image}`, {responseType: 'blob'})    
                                        .then(res =>{
                                            let reader = new FileReader();
                                            reader.readAsDataURL(res.data);
                                            reader.onload = (e) => {
                                                console.log('DataURL:', e.target.result);
                                                console.log(typeof e.target.result);
                                                setImage(e.target.result)                                             
                                            };
                                        })  
                                        .catch(err =>{
                                            console.log(err)
                                        });
                            setinitialValues({
                                name    : _free.name,
                                phone   : _free.phone,
                                whatsapp: _free.whatsapp,
                                email   : _free.email,
                                link    : _free.link,
                            })
                            setfree(true);
                        })
                        .catch(err =>{
                            console.log(err)
                        })
        })()
    }, [])
    
/***************************************************************************************************************************/ 
    const updateStore = async(params) => { 
        if(image === ''){
            set_image(true)
            return false;
        }
        set_image(false)
        params.image = image;
        console.log(params)
        const headers = await getHeaders();
        await axios.put(`${url}stores/${id}`, params, {headers})
                    .then(res => {
                        console.log(res);
                        Swal.fire({
                            icon: 'success',
                            title: res?.data?.response,
                        });
                    })
                    .catch(err =>{
                        console.log(err);
                        Swal.fire({
                            icon : 'error',
                            title: err?.response?.data?.response || err,
                            text : err?.response?.data?.message || err,
                        });
                    })
        
    }
/*************************************************************************************************************************/
    const handleImage = async(e, index) => {

        console.log(index)
        const _extension_ = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length);
        if(_extension_ !== 'png' && _extension_ !== 'jpg' && _extension_ !== 'jpeg' && _extension_ !== 'gif' && _extension_ !== 'bmp'){
            setImage('');
            document.getElementById(`formFile`).value = "";
            Swal.fire({
                icon             : 'info',
                title            : 'Invalid Extensions',
                toast            : true,
                position         : 'top-end',
                showConfirmButton: false,
                timer            : 3000,
                timerProgressBar : true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return false;
        }else{
            let _img = await utilities.getBase64(e.target.files[0])
            _img = JSON.stringify(_img)
            _img = _img.replace(/^"(.*)"$/, '$1');
            setImage(_img)
            // setImage(img => [...img, _img]);
            // setImage(img => [...img, e.target.files[0]])
        }
    }
/***************************************************************************************************************************/ 
    //Validate form
    const formSchema = Yup.object().shape({
        name    : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        email   : Yup.string().required(),
        phone   : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        whatsapp: Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        link    : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),

    });
/*************************************************************************************************************************/
  return (
        <>
            {
                free 
                ?   <div className='container mb-5'>
                        <div className='card card-shadow'>
                            <div className="card-header">
                                <h1 className="h3 m-0 fw-bold text-center">
                                    Update store payment
                                </h1>
                            </div>
                            <div className="card-body">
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
                                        <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                            {
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={formSchema}
                                                    onSubmit={(values, actions) => updateStore(values, actions)}
                                                >
                                                    {({ values, setFieldValue  }) => (
                                                    <Form>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="name" className="form-control form-control-sm" id="floatingname" placeholder="name" name="name"/>
                                                                <label htmlFor="floatingname">Name</label>
                                                            </div>
                                                            <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                                                <label htmlFor="floatingInput">{t('login.email')}</label>
                                                            </div>
                                                            <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="whatsapp" className="form-control" id="floatingwhatsapp" placeholder="whatsapp" name="whatsapp"/>
                                                                <label htmlFor="floatingwhatsapp">whatsapp</label>
                                                            </div>
                                                            <ErrorMessage name="whatsapp" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="phone" className="form-control" id="floatingphone" placeholder="phone" name="phone"/>
                                                                <label htmlFor="floatingphone">phone</label>
                                                            </div>
                                                            <ErrorMessage name="phone" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>											
                                                                                                    

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="link" className="form-control" id="floatingname" placeholder="link" name="link"/>
                                                                <label htmlFor="floatingname">link</label>
                                                            </div>
                                                            <ErrorMessage name="link" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className='mb-3'>                                                    
                                                            <input className="d-none form-control border-1" 
                                                                type="file" 
                                                                id={`formFile`}
                                                                onChange={(e) => handleImage(e)} 
                                                                accept=".png,.jpg,.jpeg,.gif,.bmp"
                                                            />  
                                                            <div className="input-group">
                                                            
                                                                <button 
                                                                    className="btn btn-secondary border-1" 
                                                                    type="button" id={`button-addon`}
                                                                    onClick={() => document.querySelector(`#formFile`).click()}
                                                                >Select image</button>
                                                                <input 
                                                                    id={`input`}
                                                                    type="text" 
                                                                    className="form-control border-1 pointer bg-secondary-subtle" 
                                                                    placeholder="" 
                                                                    aria-label="Example text with button addon" 
                                                                    aria-describedby={`button-addon`}
                                                                    readOnly
                                
                                                                    onClick={() => document.querySelector(`#formFile`).click()}
                                                                />
                                                            </div>
                                                                    
                                                            {
                                                                image !== '' &&
                                                                <img className='img-fluid img-thumbnail border-1' width={200}  src={(image) || '#'} alt="..." />                                                    
                                                            }                                                                                                    

                                                            <ErrorMessage
                                                                name={`link`}
                                                                component='div'
                                                                className='field-error text-danger'
                                                            />
                                                        </div>
                                                                                            
                                                        <button 
                                                            className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                            type="submit"
                                                            disabled={loading}
                                                        ><span className='me-3 fw-bold'>
                                                            Update store
                                                        </span>
                                                        {
                                                            loading &&
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        }
                                                        </button>   
                                                    </Form>
                                                    )}
                                                </Formik>
                                            }
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>			
                    </div>
                :
                    <div className="row">
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>                                        
                    </div>    
            }
            
        </>
  )
}

export default EditStore