import React, { useEffect, useState } from 'react'
import utilities from '../../utilities/utilities'
import { Link } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Products = () => {

    /***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
    /***************************************************************************************************************************/
    const { t }                   = useTranslation();
    const [loading, setloading]   = useState(false);
    const [products, setproducts] = useState([]);


    const getProducts = async() => {
        console.log(url)
        await axios.get(`${url}products/home`)
                    .then(res =>{
                        setproducts(res.data.message);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setloading(true)
                    });

    }
    useEffect(() => {
        getProducts()
    }, [])
    
    return (
    <>
        <section className='container-fluid py-5 border-bottom border-5 border-secondary '>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 p-5 border-1 justify-content-center'>
            {
                !loading ?
                [1,2,3,4].map((e,i) => {
                    return <div className={`col animate__animated animate__fadeIn`} key={`loading-${i}`}>
                        <div className="card h-100 text-bg-light" aria-hidden="true">
                            <div className="card-header">
                                <p className="placeholder-glow">
                                    <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                </p>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title placeholder-glow">
                                    <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                </h5>
                                <p className="card-text placeholder-glow">
                                <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <a href="#!" tabIndex="-1" className="btn btn-bd-primary disabled placeholder col-6"></a>
                            </div>
                                
                        </div>
                    </div>                   
                })
                :
                <>
                {
                    products.map((e,i) => {
                    return <div className='col animate__animated animate__fadeIn' key={`product-${i}`}>
                        <div className="card card-shadow h-100 bs-dark-border-subtle text-dark-emphasis">
                            <div className="mt-3 border-0  text-center bs-dark-border-subtle">
                                <h5 className='m-0 fw-bold'>{e.name}</h5>
                            </div>
                            <div className="mt-3 text-center border-0 h-25 d-flex align-items-center justify-content-center">
                                <div className='d-flex justify-content-center'>
                                    <img src={`${url}images/${e.image}`}  className="img-fluid" width={75}/>
                                </div>
                            </div>
                            <div className="card-body bs-dark-border-subtle">
                                
                                <p className="card-text">{e.description}</p>

                            </div>
                            <div className='p-2 footer border-0  text-center bs-dark-border-subtle mb-2'>
                                <Link to={`/products/${e.name}`} className="btn btn-bd-primary btn-shadow">
                                    <span>{t('pages.products.view_prices')}</span>    
                                </Link> 
                            </div>
                        </div>
                    </div>
                    })
                }
                </>
            }
            </div>
        </section>
    </>

  )
}

export default Products