import React, { useMemo } from 'react';
import CartContext from './CartContext';
import { useLocalStorage } from "./useLocalStorage";
const CartProvider = ({ children }) => {

    const [cart, setCart] = useLocalStorage("cart", []);
  
    const countItem = (_cart, num) => {
      // console.log(_cart)
      setCart(
        cart.map( item =>
          item.product.stripe_id === _cart.product.stripe_id
          ? {...item, quantity: item.quantity+(num)}
          : item
        )
      )
    };

    const addItem = (_cart) => {
      console.log(_cart.stripe_id);
      const comp = cart.find(x => x.product.stripe_id === _cart.stripe_id);
      console.log(comp);
      if(comp === undefined){
        console.log('indefinido')
        setCart([...cart, {product: _cart, quantity: 1}])
      }else{
        console.log('ta definido')
        setCart(
          cart.map( item =>
            item.product.stripe_id === _cart.stripe_id
            ? {...item, quantity: item.quantity+1}
            : item
          )
        )
      }   
    };
  
    const deleteItem = (_cart) => {
      console.log(_cart)
      setCart(
        cart.filter( item =>
          item.product.stripe_id !== _cart.product.stripe_id &&
          item
        )
      )
    };

    const deleteCart = () => {
      setCart([]);
    };

    const getTotal = () => { 

      

    }
  
    const value = useMemo(
      () => ({
        cart,
        addItem   : addItem,
        countItem : countItem,
        deleteItem: deleteItem,
        deleteCart: deleteCart
      }),
    [cart]
    );
  
    return (
      <CartContext.Provider value={value}>
        {children}
      </CartContext.Provider>
    );
};

export default CartProvider;