import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../../layouts/CustomLoader';
import CardProduct from './CardProduct';
import AuthContext from '../../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../../layouts/paginationComponentOptions';

const RestoreProduct = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/**************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [products, setProducts]    = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [_search, set_search]      = useState('');
    const {t}                        = useTranslation()
    const paginationComponentOptions = usePaginationComponentOptions();
    const getTrash = async () => {
        // await axios.get(`${url}products?page=${page}&per_page=${perPage}&search=${_search}`)
        const headers = await getHeaders();
        await axios.get(`${url}products/trash`, {headers})
                    .then(res =>{
                        setProducts(res.data.message);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getTrash(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}products?page=${page}&per_page=${newPerPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setProducts(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_search(params.search);
        const headers = await getHeaders();
        await axios.get(`${url}products?page=1&per_page=${perPage}&search=${params.search}`, {headers})
                    .then(res =>{
                        setProducts(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    useEffect(() => {
        getTrash(1);
    }, []);
/***************************************************************************************************************************/

    const columns = [
        {
            name: t('products.products'),
            cell: (row, index, column, id) => <CardProduct 
                                                    product={row}
                                                    i={index}
                                                    url={url}
                                                    setProducts={setProducts}
                                                />
            },
       
    ];

/***************************************************************************************************************************/
  return (
    <div className='row align-items-center'>
        <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
            <Formik
                initialValues={{ 
                    search: _search
                }}
                onSubmit={(values, actions) => setSearch(values, actions)}
                enableReinitialize
            >
                <Form>
                    <div className="input-group rounded-pill">
                        <Field 
                            type="text" 
                            className="form-control" 
                            placeholder={t('products.search')}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            name="search"
                        />                        
                        <button 
                            className="btn btn-outline-secondary" 
                            type="submit" 
                            id="button-addon2"
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>

        <div className="col-md-12 mb-5">
            <div className="card card-shadow">
                <DataTable
                    columns={columns}
                    data={products}
                    fixedHeader
                    fixedHeaderScrollHeight="800px"
                    progressPending={loading}
                    pagination
                    paginationPerPage={perPage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    progressComponent={<CustomLoader />}
                />
            </div>
        </div>
    </div>
  )
}

export default RestoreProduct