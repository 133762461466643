import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUserEdit, faTrash, faCommentDollar, faPenToSquare, faDollar, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const Marketings = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [data, setData]            = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [_search, set_search]      = useState('');
    const {t,i18n}                   = useTranslation()
    const paginationComponentOptions = usePaginationComponentOptions();
/***************************************************************************************************************************/

    const getMarketings = async (page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}marketings?page=${page}&per_page=${perPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getMarketings(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}marketings?page=${page}&per_page=${newPerPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_search(params.search);
        const headers = await getHeaders();
        await axios.get(`${url}marketings?page=1&per_page=${perPage}&search=${params.search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const deleteMarketing = (params) => {

        Swal.fire({
            title: t('marketing.delete_title'),
            text: t('marketing.delete_txt'),
            showCancelButton: true,
            confirmButtonText: t('marketing.delete'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                        const headers = await getHeaders();
                        await axios.delete(`${url}marketings/destroy/${params}`, {headers})
                            .then(res =>{
                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });                        
                                    getMarketings(1);
                            })
                            .catch(err =>{
                                    Swal.fire({
                                        icon: 'error',
                                        title: err?.response?.data?.response,
                                    });
                            })
                            .then(() =>{

                            })
                }
        })
    }
/***************************************************************************************************************************/
    const ExpandedComponent = ({ data }) => {
        console.log(data?.Markpayments);
        return <>
            {
                data?.Markpayments.length > 0
                ?   data?.Markpayments.map((e,i) =>{
                        return <div className='row border-bottom border-4 border-light-subtle mb-2' key={`${e.pi_stripe_id}-${i}`}>
                            <div className='col-md-6'>
                                <div className='bg-bs-primary w-100'>
                                    <h4 className='text-center'>
                                        {t('marketing.payment')}
                                    </h4>
                                </div>
                                <div>
                                    <ul>
                                        <li>{t('marketing.count')} : {e?.count_subscription || '-'}</li>
                                        <li>{t('marketing.pi_id')} : {e?.pi_stripe_id || '-'}</li>
                                        <li>{t('marketing.status')} : {e?.status_suscription || '-'}</li>
                                        <li>{t('marketing.total')} : {e?.total_amount || '-'}</li>
                                        <li>{t('marketing.country')} : {e?.Markprice.country || '-'}</li>
                                        <li>{t('marketing.states')}: 
                                            {
                                                e?.Markprice.States.map((f,j) => ' ' + f.name + ' - ')
                                            }
                                        </li>
                                        <li>{t('marketing.description')} : {e?.Markprice.description || '-'}</li>
                                        <li>{t('marketing.months')} : {e?.Markprice.months || '-'}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='bg-bs-primary w-100'>
                                    <h4 className='text-center'>
                                        {t('marketing.banners')}
                                    </h4>
                                </div>
                                <div>
                                    <ul>
                                        <li>{t('marketing.email')} : {e?.Freemium?.email || '-'}</li>
                                        <li>{t('marketing.phone')} : {e?.Freemium?.phone || '-'}</li>
                                        <li>WhatsApp : {e?.Freemium?.whatsapp || '-'}</li>
                                        <li>
                                            <button className="btn btn-primary btn-sm py-0 mt-1 btn-shadow" type="button" data-bs-toggle="collapse" data-bs-target={`#${e.pi_stripe_id}-${i}`} aria-expanded="false" aria-controls="collapseExample">
                                                {t('marketing.show_banners')}
                                            </button>

                                            <div className="collapse" id={`${e.pi_stripe_id}-${i}`}>
                                                {
                                                    e?.Freemium?.banners.length > 0
                                                    ?   e?.Freemium?.banners.map((f,j) => {
                                                            return <div className='p-2 border rounded'>
                                                                <div className='row'>
                                                                    <div className='col-md-5'>
                                                                        <img src={`${url}freemium/${f}` || '#'} width={150} className='img-fluid' alt={e?.Freemium?.link[j] || '-'}/> 

                                                                    </div>
                                                                    <div className='col-md-7'>
                                                                        <p>{e?.Freemium?.link[j] || '-'}</p>
                                                                    </div>
                                                                </div>                                                    
                                                            </div>
                                                        })
                                                    :
                                                        <p>No banners :(</p>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })
                :
                    <p>{t('marketing.no_payments')}</p>
            }
                
        </>

    }
/***************************************************************************************************************************/

    useEffect(() => {
        getMarketings(1)
    }, []);

/***************************************************************************************************************************/
const columns = [
  {
      name: t('marketing.name'),
      maxWidth: '100px',
      selector: row => {
          return <>
                      <p className='text-capitalize mb-0'>{row.name}</p>     
                      <p className='text-capitalize mb-0'>{row.description}</p>           
                 </>
      },
  },
  {
      name: t('marketing.address'),
      cell: row => {
          return <>
                      <ul className='mt-2 ps-0'>
                          {
                              <>
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.street_address}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.address_line_2}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.zip_code}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.city}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.state}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.country}</li>   
                              </>
                          }
                      </ul>
                 </>
      }
  },
  {
      name: t('marketing.address_invoice'),
      cell: row => {
          return <>
                      <ul className='mt-2 ps-0'>
                          {
                              <>
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_street_address}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_address_line_2}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_zip_code}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_city}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_state}</li>   
                                  <li className='text-capitalize list-group-item bg-body-tertiary'>{row.invoice_country}</li>   
                                  <li className='list-group-item bg-body-tertiary'>{row.invoice_email}</li>   
                              </>
                          }
                      </ul>
                 </>
      }
  },
  {
      name: t('marketing.price_4_state'),
      maxWidth: '100px',
      cell: row => <p className='text-capitalize list-group-item bg-body-tertiary'>${row.price}</p>   
  },
  {
        name: t('marketing.users'),
        cell: row => {
            return <ul className='px-2 mt-2 w-100 text-start'>
                {
                    row?.Users.length > 0 
                    ?   row?.Users.map((e,i) => {
                            return <li className='list-group-item bg-body-tertiary mb-1 pb-1 border-bottom' key={`user-${e}-${i}`}>
                                <div className='d-flex align-items-center'>
                                    <div className="me-2">
                                        {e?.email}
                                    </div>
                                    {/* <div className="border border-success rounded border-2 p-1"> */}
                                    <div className="btn btn-sm btn-success p-1">
                                        <Link to={`/users/edit/${e.id}`}>
                                            <FontAwesomeIcon icon={faUserEdit} className='text-light'/>
                                        </Link>
                                    </div>
                                </div>
                            </li> 
                        })
                    :
                        <li>Not users found :(</li>
                }
            </ul>
        }
  },
  {
    minWidth: '150px',
      name: t('marketing.actions'),
      cell: (row, index, column, id) => {
          return <>      
                    <div className="dropdown mb-2">
                        <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                            <span>{t('marketing.options')}</span>	
                        </a>
                        <ul className="dropdown-menu" style={{width: '200px'}}>
                            <li>                                                                             
                                <Link 
                                    className="dropdown-item fw-bold text-success" 
                                    to={`edit/${row.id}`} 
                                    onClick={() => console.log(row)}
                                >
                                    <div className="row">
                                        <div className="col-2">
                                            <FontAwesomeIcon icon={faPenToSquare}/>  
                                        </div>
                                        <div className="col-10">
                                            <span>{t('marketing.edit')}</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>                                                                             
                                <Link 
                                    className="dropdown-item fw-bold" 
                                    to={`/payments/admin/marketings`}
                                >
                                    <div className="row">
                                        <div className="col-2">
                                            <FontAwesomeIcon icon={faDollar}/>  
                                        </div>
                                        <div className="col-10">
                                            <span>{t('marketing.payment')}</span>
                                        </div>
                                    </div>                                        
                                </Link>
                            </li>
                            <li>                                      
                                <a           
                                    href="#!"                      
                                    className={"dropdown-item fw-bold text-danger"}
                                    onClick={() => {
                                        deleteMarketing(row.id)
                                    }}    
                                >
                                    <div className="row">
                                        <div className="col-2">
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </div>
                                        <div className="col-10">
                                            <span>{t('marketing.delete')}</span>
                                        </div>
                                    </div>                                                                         
                                </a>
                            </li>
                        </ul>
                    </div>                   
                 </>
      },
  },
];
/***************************************************************************************************************************/
return (
  <div className='row align-items-center'>
          <div className="col-sm-4 col-md-4 col-lg-2 mb-3">
              <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                  <FontAwesomeIcon icon={faCommentDollar} className="me-2"/>
                  <span className='text-capitalize fw-bold'>{t('marketing.add_marketing')}</span>
              </Link>
          </div>
          <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
              <Formik
                  initialValues={{ 
                      search: _search
                  }}
                  onSubmit={(values, actions) => setSearch(values, actions)}
                  enableReinitialize
              >
                  <Form>
                      <div className="input-group rounded-pill">
                          <Field 
                              type="text" 
                              className="form-control" 
                              placeholder={t('marketing.search')}
                              aria-label="Recipient's username"
                              aria-describedby="button-addon2"
                              name="search"
                          />                        
                          <button 
                              className="btn btn-outline-secondary" 
                              type="submit" 
                              id="button-addon2"
                          >
                              <FontAwesomeIcon icon={faMagnifyingGlass}/>
                          </button>
                      </div>
                  </Form>
              </Formik>
          </div>

          <div className="col-md-12">
              <div className="card card-shadow">
                  <DataTable
                      columns={columns}
                      data={data}
                      fixedHeader
                      fixedHeaderScrollHeight="550px"
                      progressPending={loading}
                      pagination
                      paginationPerPage={perPage}
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationComponentOptions={paginationComponentOptions}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      progressComponent={<CustomLoader />}
                      dense
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                  />
              </div>
          </div>
      </div>
    )
}

export default Marketings