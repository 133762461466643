import React from 'react'
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    const year     = new Date();
    const { i18n } = useTranslation();
return(
    <footer className='bg-body-tertiary mt-auto border-top shadow-lg'>
        <div className="container-fluid">
            <div className='footer-bottom p-3'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-8 col-md-8 align-items-center'>
                        <div style={{fontSize: '.75rem', lineHeight: '1.2rem'}} className='mb-1'>
                            © {year.getFullYear()} Portal Label Dictate •
                            <Link to="/terms-and-conditions" className='text-bs-primary text-decoration-none text-capitalize'> 
                                <span> {t('footer.terms_and_conditions')}</span> 
                            </Link> •
                            <Link to="/privacy-policy" className='text-bs-primary text-decoration-none text-capitalize'>
                                <span> {t('footer.privacy_policy')}</span> 
                            </Link>
                            <Link to="/faqs" className='d-none text-bs-primary text-decoration-none text-capitalize'> FAQS</Link> •
                            <Link to="/about-us" className='text-bs-primary text-decoration-none text-capitalize'> 
                                <span> {t('footer.about_us')}</span> 
                            </Link> •
                            <Link to="/contact-us" className='text-bs-primary text-decoration-none text-capitalize'> 
                                <span> {t('footer.contact_us')}</span> 
                            </Link>
                        </div>
                        <div style={{fontSize: '.75rem', lineHeight: '1.2rem'}} className='mb-1'>
                            <span> {t('footer.recaptcha')} </span>
                            <Link to="https://policies.google.com/privacy" className=' text-capitalize'>{t('footer.privacy_google')}</Link> <span> & </span>
                            <Link to="https://policies.google.com/terms" className=' text-capitalize'>{t('footer.terms_google')}</Link> {(i18n.language === 'en') ?<span> apply</span> : <span> de google</span>}
                            <p style={{fontSize : '10px'}}>Version: { packageJson.version }</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4'>
                        <a className=' title link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-3' href='https://www.youtube.com/@LabelDictate' target='_blank'>
                            <i className='fa-brands fa-youtube text-danger me-1'></i>
                            <span>                            
                                YouTube
                            </span>
                        </a>
                        <a  className=' title link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-3' href='https://www.facebook.com/profile.php?id=61552169408426' target='_blank'>
                            <i className='fa-brands fa-facebook text-primary me-1'></i>
                            <span>                            
                                Facebook
                            </span>
                        </a>
                        <a className=' title link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-3' href='https://www.instagram.com/labeldictate/' target='_blank'>
                            <i className='fa-brands fa-instagram  title me-1'></i>
                            <span>                            
                                Instagram
                            </span>
                        </a>
                        <a className=' title link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-3'  href='https://www.tiktok.com/@labeldictate' target='_blank'>
                            <i className='fa-brands fa-tiktok  title me-1'></i>
                            <span>                            
                                Tiktok
                            </span>
                        </a>
                        {/* V{ packageJson.version } */}
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer