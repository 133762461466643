import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import AuthContext from '../../../middlewares/AuthContext';
import Swal from 'sweetalert2';
import utilities from '../../../utilities/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTableList, faWarning, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const MarketingDashboard = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [dashboardOne, setDashboardOne] = useState(null);
    const [today, settoday]               = useState(DateTime.now({zone: 'utc'}));
    const [data, setdata]                 = useState(null);
    const [loading, setloading]           = useState(false);
    const {t, i18n}                       = useTranslation()
/***************************************************************************************************************************/

    useEffect(() => {
        
        // (async () =>{
        //     const headers = await getHeaders()
        //     await axios.get(`${url}dashboards/customer`, {headers})
        //                 .then(res => {
        //                     setDashboardOne(res.data.message)
        //                     // console.log(res.data.dates)
        //                     const {activated, renovated, to_expire, expired, available} = res.data.message
        //                     setdata({
        //                         labels: [t('dashboard.activated'), t('dashboard.renovated'), t('dashboard.to_expire'), t('dashboard.expired'), t('dashboard.available')],
        //                         datasets: [{
        //                             label          : 'Total',
        //                             data           : [ activated, renovated, to_expire, expired, available],
        //                             backgroundColor: ['#198754', '#6eb446', '#ffc107', '#dc3545', '#0d6efd'],
        //                             borderColor    : ['#157348', '#3b7d3c', '#d9a406', '#bb2d3b', '#0b60dc'],
        //                             borderWidth    : 1,
        //                         }]
        //                     });
        //                     console.log(res.data);
        //                     setloading(true);
        //                 })
        //                 .catch(err => {
        //                     Swal.fire({
        //                         icon: 'error',
        //                         title: err?.response?.data?.response || err
        //                     });
        //                 });
        // })();

        // everySecond();

    }, [])
    

/***************************************************************************************************************************/

    // const everySecond = () => { 
    //     setInterval(() => {
    //         settoday(prevDateTime => prevDateTime.plus({seconds: 1}));
    //     }, 1000);
    // }

/***************************************************************************************************************************/
    // const options = {
    //     responsive: true,
    //     plugins   : {
    //     legend: {
    //         position: 'top',
    //     },
    //     title: {
    //         display: true,
    //         text   : 'Month',
    //     },
    //     },
    // };
/***************************************************************************************************************************/

    return (
        <>
            
            <div className="row animate__animated animate__fadeIn">                
                <h2>Today {today.setLocale(i18n.language).toFormat('yyyy-LLLL-dd HH:mm:ss')}</h2>

            </div>
            <div>
                <h1 className='text-center mt-3 animate__animated animate__zoomIn'> Portal Label Dictate - Marketing</h1>
            </div>

                        
            
            
        </>
    )
}

export default MarketingDashboard