import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {

	const {t} = useTranslation()
  	return (
		
	<main>
		<div className="px-4 py-5 border-bottom border-5 border-secondary">
			<h1 className="display-1 text-center fw-bold">{t("terms_and_conditions.main_title")}<span className='text-bs-primary'>Portal Label Dictate</span></h1>
			<div className="col-lg-10 mx-auto">

					<ul className="list-group list-group-flush  text-justify">
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title1")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text1")}</li>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title2")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text2")}</li>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title3")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text3")}</li>

						<ul className="list-group list-group-flush ms-5">
							<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title3_1")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_1")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_2")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_3")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_4")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_5")}</li>
						</ul>
						
						<ul className="list-group list-group-flush ms-5">
							<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title3_2")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_6")}</li>
							<ul className="list-group list-group-flush ms-5">
								<li className="list-group-item border-0 p-0">{t("terms_and_conditions.text3_7")}</li>
								<li className="list-group-item border-0 p-0">{t("terms_and_conditions.text3_7_1")}</li>
								<li className="list-group-item border-0 p-0">{t("terms_and_conditions.text3_7_2")}</li>
								<li className="list-group-item border-0 p-0">{t("terms_and_conditions.text3_7_3")}</li>
								<li className="list-group-item border-0 p-0">{t("terms_and_conditions.text3_7_4")}</li>

							</ul>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_8")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_9")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_10")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_11")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_12")}</li>
							<li className="list-group-item border-0">{t("terms_and_conditions.text3_13")}</li>
						</ul>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title4")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text4")} <Link to="/privacy-policy">privacy-policy</Link> {t("terms_and_conditions.text4_1")} </li>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text5")}</li>
						
							<ul className="list-group list-group-flush ms-5">
								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_1")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_1")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_1_2")}</li>
								
								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_2")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_3")}</li>
								
								<ul className="list-group list-group-flush ms-5">
									<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_2_1")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_1")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_2")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_3")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_4")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_5")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_1_6")}</li>
								</ul>

								<ul className="list-group list-group-flush ms-5">
									<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_2_2")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_1")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_2")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_3")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_4")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_5")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_2_2_6")}</li>
								</ul>

								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_3")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_3")}</li>

								<ul className="list-group list-group-flush ms-5">
									<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_3_1")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_1")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_2")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_3")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_4")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_5")}</li>
									<li className="list-group-item border-0">{t("terms_and_conditions.text5_3_6")}</li>
								</ul>

								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_4")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_4")}</li>

								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_5")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_5")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_5_1")}</li>

								<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title5_6")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_6")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_6_1")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_6_2")}</li>
								<li className="list-group-item border-0">{t("terms_and_conditions.text5_6_3")}</li>
								
							</ul>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title6")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text6")}</li>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title7")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text7")}</li>

						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title8")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text8")}</li>
						
						<li className="list-group-item border-0 fw-bold">{t("terms_and_conditions.title9")}</li>
						<li className="list-group-item border-0">{t("terms_and_conditions.text9")}</li>

						

					</ul>

			</div>
		</div>
	</main>	
  	)
}

export default TermsAndConditions