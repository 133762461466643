import React, {useContext, useState, useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight, faBars } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../../middlewares/AuthContext';
import Swal from 'sweetalert2';
import { useNavigate, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from 'react-i18next';

const Header = ({showNavbar, flip}) => {
    
    const {onLogout, token, getHeaders} = useContext(AuthContext);
    
    const [name, setname] = useState('');
    const { t, i18n }     = useTranslation();
    const tokenDecode     = (token!==null) ? jwt_decode(token) : null
    let   navigate        = useNavigate();

    const logout = () => { 
     
        Swal.fire({
            title             : t('header.logout_session'),
            showCancelButton  : true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor : '#d33',
            confirmButtonText : t('header.yes_logout_session'),
            showClass         : {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                onLogout();
                navigate('/')
            }
        })        
    }
/**********************************************************************************************************************/

    useEffect(() => {
        document.querySelector("#header-toggle").click();
    }, [])

    useEffect(() => {
        getNameHeader();
    }, [i18n.language])
    

/**********************************************************************************************************************/

    const getNameHeader = () => { 
        console.log(tokenDecode);
        switch (tokenDecode?.roles[0]?.name) {
            case 'admin':
                setname(t('header.administrator'));
                break;
            case 'customer':
                setname('Portal LD');
                break;
            case 'marketing':
                const mar = (tokenDecode?.marketing?.name === null) ? '' : 'Marketing - '+tokenDecode?.marketing?.name;
                setname(mar);
                break;
            case 'partner':
                const par = (tokenDecode?.partner?.name === null) ? '' : `${t('header.partner')} - `+tokenDecode?.partner?.name;
                setname(par);
                break;
            case 'subpartner':
                const subpar = (tokenDecode?.partner?.name === null) ? '' : `S - `+tokenDecode?.partner?.name;
                setname(subpar);
                break;
        
            default:
                setname('Portal LD');
                break;
        }
    }
/**********************************************************************************************************************/    
    return (
        <header className="header mb-5 bg-body-tertiary shadow-sm border-bottom" id="header">
            <div className="header_toggle d-flex"> 
                <div id="header-toggle" onClick={() => showNavbar('header-toggle','nav-bar','body-pd','header')}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <div className='ms-3'>
                    <span className='text-capitalize fw-bold m-0'>{name}</span>
                    {/* <span className='fw-bold m-0'> area</span> */}
                </div>
            </div>
            {/* <div className="header_img">  */}
                <div className="dropdown">
                    <a className="dropdown-toggle text-body-emphasis text-decoration-none" data-bs-display="static" data-bs-auto-close="outside"  href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: '100px'}}>
                        {tokenDecode.email}
                    </a>

                    <ul className="dropdown-menu" style={{width: '100%'}}>
                        <li>
                            <Link className="dropdown-item" to="/">{t('header.main_portal')}</Link>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#!" onClick={() => logout()}>{t('header.logout')}</a>
                        </li>
                        <li className="dropdown-item">
                            {/* <div className="dropdown"> */}
                            <div className="btn-group dropstart">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" data-bs-toggle="dropdown" data-bs-display="static" data-bs-auto-close="outside" aria-expanded="false">
                                    {
                                        i18n.language === 'en'
                                        ?<span className="fi fi-us"></span> 
                                        :<span className="fi fi-mx"></span> 
                                    }								
                                    <span className='ms-1'>
                                        {t('header.language')}
                                    </span>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    {/* <li className="dropdown">
                                        <a className="dropdown-item dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Categoría 1
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                            <li><a className="dropdown-item" href="#">Subcategoría 1.1</a></li>
                                            <li><a className="dropdown-item" href="#">Subcategoría 1.2</a></li>
                                            <li><a className="dropdown-item" href="#">Subcategoría 1.3</a></li>
                                        </ul>
                                    </li> */}
                                    <li>
                                        <button className="dropdown-item" type="button" onClick={()=> i18n.changeLanguage('en')} >
                                            <span className="fi fi-us"></span> 
                                            <span className='ms-1'>
                                                English
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" type="button" onClick={()=> i18n.changeLanguage('es')} >
                                            <span className="fi fi-mx"></span> 
                                            <span className='ms-1'>
                                                Español
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div> 
            {/* </div> */}
        </header>
  )
}

export default Header