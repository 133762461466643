import React, { useEffect, useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import AuthContext from '../../../middlewares/AuthContext';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';

const Login = () => {
    const { t }                          = useTranslation();
    const { token, onLogin, getHeaders } = useContext(AuthContext);
    const [loading, setloading]          = useState(false)
    let   navigate                       = useNavigate();
    let   location                       = useLocation();
    let   state                          = location.state;
    let   from                           = state ? state.from.pathname : '/dashboard';
    useEffect(() => {
        const first = async(second) => { 
            const headers = await getHeaders();
            console.log(headers)
        }
        first()
      console.log(token);
      
    }, [])
    
/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/************************************************************************************************************************/
    const login = async (params) => { 
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        setloading(true);
        await axios.post(`${url}auth/login`, params, {headers: headers})
			.then((response) => {

                onLogin(response.data.token);
                navigate(from);
                
			}).catch(err =>{
                    Swal.fire({
                        icon: 'error',
                        title: err?.response?.data?.response || err,
                    })

			}).then(() =>{
                setloading(false);
            });
    }
/*************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        password: Yup.string()
            .required(),
    });
/*************************************************************************************************************************/
    if (token) {
        return <Navigate to="/dashboard"/>;
    }
/*************************************************************************************************************************/
  return (
    <>
        <div className='container mb-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-8 col-md-8 col-lg-6'>
                    <main className='mt-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                        <Formik
                                initialValues={{ 
                                    email: '', 
                                    password: '' 
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => login(values, actions)}
                            >
                                <Form>
                                <h1 className="h3 mb-3 mt-2 fw-normal text-center">{t('login.please_login')}</h1>

                                <div className="mb-3">
                                    <div className="form-floating">
                                        <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                        <label htmlFor="floatingInput">{t('login.email')}</label>
                                    </div>
                                    <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                </div>


                                <div className="mb-3">
                                    <div className="form-floating">
                                        <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                        <label htmlFor="floatingPassword">{t('login.password')}</label>
                                    </div>
                                    <ErrorMessage name="password" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                </div>


                                <button 
                                    className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                    type="submit"
                                    disabled={loading}
                                ><span className='me-3'>
                                    {t('header.login')}
                                </span>
                                {
                                    loading &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                </button>   
                                    <Link to="/signup" className='link-primary'>
                                        <p>{t('login.create_account')}</p>
                                    </Link>            

                                    <Link to="/recovery-password" className='link-primary'>
                                        <p>{t('login.forgot_password')}</p>
                                    </Link>                         
                                <p className="mt-5 mb-0 text-muted">&copy; 2023–2023</p>
                            </Form>
                        </Formik>
                    </main>
                </div>
            </div>
        </div>
	</>
  )
}

export default Login