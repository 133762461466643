import React, {useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faEdit, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
import { DateTime } from 'luxon';

const ShowPackage = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null;

/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    //Search
    const [partner, setpartner]           = useState('');
    const [_product_key, set_product_key] = useState('');
    const [products, setproducts]         = useState('');
    const [useremail, setuseremail]       = useState('');
    const [status, setstatus]             = useState('0');
    const [usertype, setusertype]         = useState('0');
/***************************************************************************************************************************/
    const { serial_number }          = useParams();
    const [data, setData]            = useState([]);
    const [loading, setLoading]      = useState(false);
    const [charge, setcharge]        = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [_search, set_search]      = useState('');
    const {t, i18n}                        = useTranslation();
    const download                   = useRef(null);
    const downloadCls                = useRef(null);
    const paginationComponentOptions = usePaginationComponentOptions();
/***************************************************************************************************************************/

    const getPackage = async (page) => {
        setLoading(true);
        const search  = `&partner=${partner}&product_key=${_product_key}&products=${products}&useremail=${useremail}&status=${status}&usertype=${usertype}`
        // await axios.get(`${url}packages/show/${serial_number}?page=${page}&per_page=${perPage}&search=${_search}`)
        const headers = await getHeaders();
        await axios.get(`${url}packages/show/${serial_number}?page=${page}&per_page=${perPage}${search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false);
                        setcharge(true);
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPackage(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const headers = await getHeaders();
    await axios.get(`${url}packages/show/${serial_number}?page=${page}&per_page=${newPerPage}&search=${_search}`, {headers})
                .then(res =>{
                    setData(res.data.message);
                    setPerPage(newPerPage);
                    console.log(res.data);
                })
                .catch(err =>{
                    console.log(err)
                })
                .then(() =>{
                    setLoading(false)
                });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

    setLoading(true);
    setpartner(params.partner)  
    set_product_key(params._product_key)
    setproducts(params.products)
    setuseremail(params.useremail)        
    setstatus(params.status)    
    setusertype(params.usertype)
    set_search(params.search);

    const partner      = params.partner;
    const product_key_ = params._product_key;
    const products     = params.products;
    const useremail    = params.useremail;
    const status       = params.status;
    const usertype     = params.usertype;

    const search  = `&partner=${partner}&product_key=${product_key_}&products=${products}&useremail=${useremail}&status=${status}&usertype=${usertype}`
    const headers = await getHeaders();
    await axios.get(`${url}packages/show/${serial_number}?page=1&per_page=${perPage}${search}`, {headers})
                .then(res =>{
                    setData(res.data.message);
                    setTotalRows(res.data.total);
                    console.log(res.data);
                })
                .catch(err =>{
                    console.log(err)
                })
                .then(() =>{
                    setLoading(false)
                });

    }
/***************************************************************************************************************************/

    const getLicensesCsv = async(params) => { 
        console.log(params);
        // return;
        download.current.click();
        const headers = await getHeaders();
        await axios.get(`${url}licenses/csv/${params}`, {headers})
                    .then(res =>{
                        const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const link      = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `licenses-${params}.csv`);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(err =>{
                        console.log(err.response)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            await downloadCls.current.click();
                        }, 500);
                    });
    }   
/***************************************************************************************************************************/
    useEffect(() => {
        getPackage(1);
    }, []);

/***************************************************************************************************************************/

    const columns = [
        {
            name: t('packages.show.partner'),
            cell: row => {                                                
                        return (row.Package?._partner?.Partner !== undefined && row.Package?._partner?.Partner !== null)
                        ?   <p className='text-capitalize mb-0'>{row.Package?._partner.Partner.name} </p>
                        :   <p>-</p>                                                            
            }
        },
        {
            name: t('packages.show.package'),
            cell: row => <p className='text-capitalize mb-0'>{row.Package.serial_number} </p>     
        },
        {
            minWidth: '270px',
            name: t('packages.show.product_key'),
            cell: row => <p className='text-capitalize mb-0 w-100 text-start'>{row.product_key} </p>   
                        
        },
        {
            name: t('packages.show.created_at'),
            cell: row => {
                const d = new Date(row.createdAt);
                return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>     
            }
        },
        {
            name: t('packages.show.email_user_owner'),
            cell: row => {
                return (row?.User !== null)
                    ?   <p>{row.User?.email}</p>
                    :   <p>-</p>

            }
                        
        },
        {
            // wrap: true,
            name: t('packages.show.user_type'),
            cell: row => {
                return <>
                        {
                            (row.Package.UsertypeId === 1) &&
                                <p>Created by <b className='text-uppercase'>Admin</b></p>                            
                        }
                        {
                            (row.Package.UsertypeId === 2) &&
                                <p>Adquiried by <b className='text-uppercase'>partner</b></p>                            
                        }
                        {
                            (row.Package.UsertypeId === 3) &&
                                <p>Adquiried by <b className='text-uppercase'>customer</b></p>                            
                        }
                        </>
            }                            
        },
        {
            name: t('packages.show.current_device'),
            cell: row => {
                return <div className='w-100 text-start'>
                        {
                            (row?.Activations.length > 0) 
                                ?  <p><b>{row?.Activations[0].device}</b> </p>  
                                :  <p>-</p>
                        }                                                            
                        </div>
            }                            
        },
        {
            name: t('packages.show.number_activation'),
            cell: row => {
                return <div className='w-100 text-start'>
                        {
                            (row?.Activations.length > 0) 
                                ?   <p>
                                        <b>Number activation</b> : {row?.Activations[0].number_activation}
                                    </p>   
                                :   <p>-</p>
                        }                                                            
                        </div>
            }                            
        },
        {
            name: t('packages.show.current_activation'),
            cell: row => {
                let da = '-';
                if(row?.Activations.length > 0){
                    da = DateTime.fromISO(row?.Activations[0].createdAt, { zone: 'utc-6' }).setLocale(i18n.language).toFormat('yyyy-MM-dd HH:mm:ss')
                        //   da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                }
              
                return <div className='w-100 text-start'>
                        {
                            (row?.Activations.length > 0) 
                                ?   <>                                    
                                    <p>
                                        {da}
                                    </p>
                                    <p className='mb-0'><b>Product: </b></p>
                                    <p>{row.Activations[0].Product.name}</p>                               
                                    </>   
                                :   <p>-</p>
                        }                                                            
                        </div>
            }                            
        },
        {
            name:  t('packages.show.first_activation'),
            cell: row => {
                let da = '-';
                if(row?.license_first.length > 0){
                    const d  = new Date(row?.license_first[0].createdAt);
                          da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                }
                return <div className='w-100 text-start'>
                        {
                            (row?.license_first.length > 0) 
                                ?   <p>{da}</p>   
                                :   <p>-</p>
                        }                                                            
                        </div>
            }                            
        },
        {
            name: t('packages.show.expire_at'),
            cell: row => {
                const d = new Date(row.expireAt);
                return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>
            }
        },
        {
            name: t('packages.show.status'),
            cell: row => {
                let _class = '';
                switch (row.StatusId) {
                    case 1:
                        _class = 'badge text-bg-primary';
                        break;

                    case 2:
                        _class = 'badge text-bg-danger';
                        break;
                
                    default:
                        _class = 'badge text-bg-warning';
                        break;
                }

                return  <>
                            {
                                (row.Package.StatusId === 2 || row.Package.StatusId === 5)
                                ?   <p style={{fontSize: '14px'}} className={'text-danger'}>Package is: <b className='badge text-bg-danger text-capitalize'>{t(`status.${row.Package.Status.name}`)}</b> </p>     
                                :   <p style={{fontSize: '12px'}} className={`mb-0 ${_class}`}>{t(`status.${row.Status.name}`)} </p>     
                            }
                        </>
            }
        },
        {
            minWidth: () => {
                return tokenDecode.roles[0].name === 'admin' 
                ? '120px'
                : '0px'
            },
            maxWidth: () => {
                return tokenDecode.roles[0].name === 'admin' 
                ? '121px'
                : '1px'
            },
            name:  () => {
                return tokenDecode.roles[0].name === 'admin' 
                ? 'Actions'
                : '-'
            },
            cell: (row, index, column, id) => {
                return tokenDecode.roles[0].name === 'admin' &&
                        <>                          
                            <div className='d-flex w-100 justify-content-center flex-column p-1'>
                                <div className='mb-2'>
                                    <Link
                                        to={`/licenses/edit/${row.id}`} 
                                        className='btn btn-success btn-shadow btn-sm'
                                    >
                                        <FontAwesomeIcon icon={faEdit} className="me-1"/>
                                        <span className='fw-bold'>
                                            {t('packages.show.edit')}
                                        </span>
                                    </Link>
                                </div>   
                            </div>
                    </>
            },
        },
    ];

/***************************************************************************************************************************/
  return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={download}></button>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={downloadCls}></button>
                    <div className="modal-body text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className="col-sm-4 col-md-4 col-lg-2 mb-3">
                {/* <Link to="create" className='btn btn-bd-primary btn-shadow d-block'>
                    <FontAwesomeIcon icon={faHandshake} className="me-2"/>
                    <span className='text-capitalize fw-bold'>Add Licenses admin</span>
                </Link> */}
                {
                    data.length > 0 &&
                    <button 
                        className={"btn btn-primary btn-shadow btn-sm w-100"}
                        onClick={() => {
                            getLicensesCsv(data[0].Package.id)
                        }}    
                    >
                        <FontAwesomeIcon icon={faFileCsv} className="me-2"/>
                        <span className='fw-bold'>
                            {t('packages.download_csv')}
                        </span>
                    </button>

                }
            </div>
            <div className="col-sm-8 col-md-8 col-lg-10 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                    {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <Formik
                                    initialValues={{ 
                                        partner,
                                        _product_key,
                                        products,
                                        useremail,
                                        status,
                                        usertype,
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="partner" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.partner')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.show.partner')} 
                                                    name="partner"
                                                    id="partner"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="_product_key" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.product_key')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.show.product_key')}
                                                    name="_product_key"
                                                    id="_product_key"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="products" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.compatibility_products')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.show.compatibility_products')} 
                                                    name="products"
                                                    id="products"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="useremail" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.email_user_owner')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('packages.show.email_user_owner')} 
                                                    name="useremail"
                                                    id="useremail"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="status" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.status')}</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder={t('packages.show.status')}
                                                    name="status"
                                                    id="status"
                                                >
                                                    <option value='0' className='text-muted'>{t('packages.select_an_option')}</option>
                                                    <option value='1'>{t('status.available')}</option>
                                                    <option value='2'>{t('status.disabled')}</option>
                                                    <option value='3'>{t('status.activated')}</option>
                                                    <option value='4'>{t('status.registered')}</option>
                                                    <option value='5'>{t('status.expired')}</option>
                                                    <option value='6'>{t('status.renovated')}</option>
                                                    
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="usertype" className="col-sm-2 col-form-label col-form-label-sm">{t('packages.show.user_type')}</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    as="select"
                                                    type="text" 
                                                    className="form-select form-select-sm" 
                                                    placeholder="usertype" 
                                                    name="usertype"
                                                    id="usertype"
                                                >
                                                    <option value='0' className='text-muted'>{t('packages.select_an_option')}</option>
                                                    <option value='1'>{t('user_type.admin')}</option>
                                                    <option value='2'>{t('user_type.partner')}</option>
                                                    <option value='3'>{t('user_type.customer')}</option>
                                                    
                                                </Field>
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold'>
                                                    {t('utilities.search_btn')}
                                                </span>
                                            </button>
                                        </div>  
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card card-shadow">
                    <div className='card-header'>
                    
                        {
                            data.length > 0 &&
                            <>
                                <p className='mb-0 text-center fw-bold'>{data.serial_number} </p>
                                {/* <p className='mb-0 text-center fw-bold'>{data.serial_number} </p>
                                <p className='mb-0'>Get invoice: {data.pay_online.toString()} </p>
                                <p className='mb-0'>Comments: {data.comments} </p>
                                {
                                    (data.Usertype === 2 && !data.pay_online) &&
                                        <p className='mb-0'>Distribuitor: {data.User.email} </p>
                                } */}
                            </>

                        }
                        
                    </div>
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="550px"
                        progressPending={loading}
                        pagination
                        paginationPerPage={perPage}
			            paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                        dense
                    />
                </div>
            </div>
        </div>
    </>
  )
}

export default ShowPackage