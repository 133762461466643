import React, {useState, useContext} from 'react'
import jwt_decode from "jwt-decode";
import AuthContext from '../../middlewares/AuthContext';
import AdminDashboard from './dashboards/AdminDashboard';
import CustomerDashboard from './dashboards/CustomerDashboard';
import MarketingDashboard from './dashboards/MarketingDashboard';
import SubpartnerDashboard from './dashboards/SubpartnerDashboard';

const Dashboard = () => {
/*************************************************************************************************************/
	const { token }                     = useContext(AuthContext);
    const [tokenDecode, setTokenDecode] = useState(jwt_decode(token));
/*************************************************************************************************************/
	return (
		<>
			{
				tokenDecode.roles.find(x => {return x.name === 'admin' || x.name === 'partner'}) &&
				<AdminDashboard/>
			}
			{
				tokenDecode.roles.find(x => x.name === 'subpartner') &&
				<SubpartnerDashboard/>
			}
			{
				tokenDecode.roles.find(x => x.name === 'customer') &&
				<CustomerDashboard/>
			}
			{
				tokenDecode.roles.find(x => x.name === 'marketing') &&
				<MarketingDashboard/>
			}
		</>
	)
}

export default Dashboard