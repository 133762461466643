import React, {useEffect, useState, useRef, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleArrowDown, faHandshake, faCommentDollar, faStore, faPlugCirclePlus, faDashboard, faExplosion, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { faStripe, faCcStripe } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios'
import DatePicker from "react-datepicker";
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import AuthContext from '../../middlewares/AuthContext';
import { useTranslation } from 'react-i18next';

const Reports = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/****************************************************************************************************************************/
	const {t, i18n}                   = useTranslation()
	const [allReports, setallReports] = useState([])

/*****************************************************************************************************************************/

	useEffect(() => {

		if(tokenDecode.roles[0].name === 'admin'){		
			setallReports([
				{
					title   : t('reports.payments'),
					subtitle: t('reports.get_report'),
					link    : 'reports/customers',
					faicon  : faUser,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.pospayment'),
					subtitle: t('reports.get_report'),
					link    : 'reports/partners',
					faicon  : faHandshake,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.marketing'),
					subtitle: t('reports.get_report'),
					link    : 'reports/marketings',
					faicon  : faCommentDollar,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.store'),
					subtitle: t('reports.get_report'),
					link    : 'reports/stores',
					faicon  : faStore,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.suscription'),
					subtitle: t('reports.get_report'),
					link    : 'reports/licenses',
					faicon  : faStripe,
					opacity : 'opacity-100',
				},
				{
					// title   : t('reports.suscription'),
					// subtitle: t('reports.get_report'),
					title   : 'Subscription to Expire',
					subtitle: t('reports.get_report'),
					link    : 'reports/to-expire',
					faicon  : faExplosion,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.renovated'),
					subtitle: t('reports.get_report'),
					link    : 'reports/licenses/renovated',
					faicon  : faPlugCirclePlus,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.dashboard'),
					subtitle: t('reports.get_report'),
					link    : 'reports/dashboard',
					faicon  : faDashboard,
					opacity : 'opacity-100',
				},
				{
					title   : t('Primera activación'),
					subtitle: t('reports.get_report'),
					link    : 'reports/first-activation',
					faicon  : faWandSparkles,
					opacity : 'opacity-100',
				},
				{
					title   : 'Uso diario de aplicaciones',
					subtitle: t('reports.get_report'),
					link    : 'reports/average',
					faicon  : faWandSparkles,
					opacity : 'opacity-100',
				},
			]);

		}else if (tokenDecode.roles[0].name === 'partner'){
			setallReports([
				{
					title   : t('reports.pospayment'),
					subtitle: t('reports.get_report'),
					link    : 'reports/partner/partners',
					faicon  : faHandshake,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.suscription'),
					subtitle: t('reports.get_report'),
					link    : 'reports/partner/licenses',
					faicon  : faStripe,
					opacity : 'opacity-100',
				},
				{
					// title   : t('reports.suscription'),
					// subtitle: t('reports.get_report'),
					title   : 'Subscription to Expire',
					subtitle: t('reports.get_report'),
					link    : 'reports/to-expire',
					faicon  : faExplosion,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.renovated'),
					subtitle: t('reports.get_report'),
					link    : 'reports/licenses/renovated',
					faicon  : faPlugCirclePlus,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.dashboard'),
					subtitle: t('reports.get_report'),
					link    : 'reports/dashboard',
					faicon  : faDashboard,
					opacity : 'opacity-100',
				},
			]);
		}else if (tokenDecode.roles[0].name === 'subpartner'){
			setallReports([
				{
					title   : t('reports.suscription'),
					subtitle: t('reports.get_report'),
					link    : 'reports/partner/licenses',
					faicon  : faStripe,
					opacity : 'opacity-100',
				},
				// {
				// 	title   : t('reports.renovated'),
				// 	subtitle: t('reports.get_report'),
				// 	link    : 'reports/licenses/renovated',
				// 	faicon  : faPlugCirclePlus,
				// 	opacity : 'opacity-100',
				// },
				{
					// title   : t('reports.suscription'),
					// subtitle: t('reports.get_report'),
					title   : 'Subscription to Expire',
					subtitle: t('reports.get_report'),
					link    : 'reports/to-expire',
					faicon  : faExplosion,
					opacity : 'opacity-100',
				},
				{
					title   : t('reports.dashboard'),
					subtitle: t('reports.get_report'),
					link    : 'reports/dashboard',
					faicon  : faDashboard,
					opacity : 'opacity-100',
				},
			]);
		}

	}, [i18n.language])
	

/*****************************************************************************************************************************/
	const addYears = (date, years) => {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}
/*****************************************************************************************************************************/
	const [startDate, setStartDate]   = useState(
		[0,1,2,3,4,5,6,7,8,9,10].map((e,i) => {
			return addYears(new Date(), -1)
			// return DateTime.now().minus({years: 1}).toJSDate()
		})
	);
	const [endDate, setEndDate]       = useState(
		[0,1,2,3,4,5,6,7,8,9,10].map((e,i) => {
			return new Date()
			// return DateTime.now().toJSDate()
		}));
	const [startValid, setStartValid] = useState(false);
	const [endValid, setEndValid]     = useState(false);
	const [loading, setLoading]       = useState(false);
	const download                    = useRef(null);
	const downloadCls                 = useRef(null);
/**************************************************************************************************************************************/
	const getReport = async(e, link, i) => { 
		e.preventDefault();
		setLoading(true);
		const _startDate = DateTime.fromJSDate(startDate[i]);
		const _endDate   = DateTime.fromJSDate(endDate[i]);
        if(!_startDate.isValid){
            setStartValid(false);
            setLoading(false);
            return;
        }
        if(!_endDate.isValid){
            setEndValid(false);
            setLoading(false);
            return;
        }
		const diffInYears = _endDate.diff(_startDate, ['months', 'days']);
		console.log(diffInYears.toObject())
		if(diffInYears.months === 12 && diffInYears.days > 0){
			Swal.fire({
				icon: 'info',
				title: 'You only can get report into one year ',
			});
			return false;
		}
		let _link;
		if(link === 'reports/to-expire'){
			_link = `${url}${link}`
		}else{
			_link = `${url}${link}/${_startDate.toISODate()}/${_endDate.toISODate()}`
		}
		download.current.click();
		const headers = await getHeaders();
		await axios.get(_link, {headers})
					.then(res =>{
						console.log(res);
						const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const _link      = document.createElement('a');
                              _link.href = url;
                            _link.setAttribute('download', `${link}-${_startDate.toISODate()}-${_endDate.toISODate()}.csv`);
                        document.body.appendChild(_link);
                        _link.click();
					})
					.catch(err =>{
						console.log(err)
						Swal.fire({
                            icon : 'error',
                            title: err?.response?.data?.response || err,
                            text : err?.response?.data?.message || err,
                        });
					}).then(() =>{
                        setTimeout(async() => {
                            await downloadCls.current.click();
                        }, 500);
                    });

	}
/**************************************************************************************************************************************/
/**************************************************************************************************************************************/
	return (
		<>
			{/* <!-- Button trigger modal --> */}
			<button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={download}>
				Launch static backdrop modal
			</button>

			{/* <!-- Modal --> */}
			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-sm">
					<div className="modal-content">
						<button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={downloadCls}></button>
						<div className="modal-body text-center">
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<section className='container-fluid pb-5 border-bottom border-5 border-secondary '>
				<div className='text-center'>
					<h1 className='fw-bold'>{t('reports.reports')}</h1>
				</div>
				<div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 pt-2 border-1'>
					{
						allReports.map((e,i) =>
							<div className={`col animate__animated animate__fadeIn ${e.opacity}`} key={`all-${i}`}>
								<div className="card card-shadow h-100 bs-dark-border-subtle text-dark-emphasis bg-light-subtle" style={{zIndex:0 }}>                        
									<div className="card-body">										
										<div className="row align-items-center">

											<div className="col-md-8 text-center  text-md-start">
												<h5 className="card-title fw-bold text-uppercase mb-0">{e.title}</h5>
											</div>

											<div className="col-md-4 text-center text-md-end mt-3 mt-md-0">
												<FontAwesomeIcon icon={e.faicon} size="3x"/>
											</div>

										</div>
									</div>
									<div className='card-footer'>
										<form onSubmit={(evt) => getReport(evt, e.link, i) }>
											<div className={e.link === 'reports/to-expire' ? 'd-none' : ''}>
												<div className="mb-3">
													<label>{t('reports.start_at')}</label>
													<DatePicker
														portalId={`root-portal-start-${i}`}
														dateFormat="yyyy-MM-dd"
														selected={startDate[i]} 
														onChange={(value) => {
															setStartDate(
																startDate.map((f,j) => {
																	return j === i
																	? value
																	: f
																})
															)
														}}
														className="form-control" id={`startAt-${i}`} placeholder={`startAt-${i}`} name={`startAt-${i}`}
													/>

													{
														startValid &&
														<div name={`startAt-${i}`} component="div" className='field-error text-danger fw-bold text-capitalize'>
															<p>Invalid Date</p>
														</div>
													}
												</div>
												<div className="mb-3">
													<label>{t('reports.end_at')}</label>
													<DatePicker
														portalId={`root-portal-end-${i}`}
														dateFormat="yyyy-MM-dd"
														selected={endDate[i]} 
														onChange={(value) => {
															setEndDate(
																endDate.map((f,j) => {
																	return j === i
																	? value
																	: f
																})
															)
														}}
														className="form-control" id={`endAt-${i}`} placeholder={`endAt-${i}`} name={`endAt-${i}`}
													/>

													{
														endValid &&
														<div name={`endAt-${i}`} component="div" className='field-error text-danger fw-bold text-capitalize'>
															<p>Invalid Date</p>
														</div>
													}
												</div>
											</div>
											<button 
												className='btn btn-sm btn-shadow btn-bd-primary align-items-center justify-content-center'
											>
												<span className="card-text me-1">{e.subtitle}</span>
												<FontAwesomeIcon icon={faCircleArrowDown} size="1x"/>                        
											</button>
										</form>
									</div>
								</div>							
							</div>
						)
					}
				</div>
			</section>
		</>
	)
}

export default Reports