import React, {useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import utilities from '../../utilities/utilities';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';

const CreateStore = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const [image, setImage]     = useState('');
    const [loading, setloading] = useState(false);
    const [_image, set_image]   = useState(false);
/***************************************************************************************************************************/ 
	const createStore = async(params, {resetForm}) => { 

        if(image === ''){
            set_image(true)
            return false;
        }
        set_image(false)
        params.image = image;
        console.log(params)
        const headers = await getHeaders();
        await axios.post(`${url}stores`, params, {headers})
                    .then(res => {
                        console.log(res);
                        Swal.fire({
                            icon: 'success',
                            title: res?.data?.response,
                            // text: res?.data?.message,
                        });
                        setImage('');
                        document.getElementById(`formFile`).value = "";
                        resetForm();
                    })
                    .catch(err =>{
                        console.log(err);
                        Swal.fire({
                            icon : 'error',
                            title: err?.response?.data?.response || err,
                            text : err?.response?.data?.message || err,
                        });
                    })
		
	}
/*************************************************************************************************************************/
    const handleImage = async(e, index) => {

        console.log(index)
        const _extension_ = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length);
        if(_extension_ !== 'png' && _extension_ !== 'jpg' && _extension_ !== 'jpeg' && _extension_ !== 'gif' && _extension_ !== 'bmp'){
            setImage('');
            document.getElementById(`formFile`).value = "";
            Swal.fire({
                icon             : 'info',
                title            : 'Invalid Extensions',
                toast            : true,
                position         : 'top-end',
                showConfirmButton: false,
                timer            : 3000,
                timerProgressBar : true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return false;
        }else{
            let _img = await utilities.getBase64(e.target.files[0])
            _img = JSON.stringify(_img)
            _img = _img.replace(/^"(.*)"$/, '$1');
            setImage(_img)
            // setImage(img => [...img, _img]);
            // setImage(img => [...img, e.target.files[0]])
        }
    }
/***************************************************************************************************************************/ 
    //Validate form
    const formSchema = Yup.object().shape({
        name    : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        email   : Yup.string().required(),
        phone   : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        whatsapp: Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        link    : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),

    });
/*************************************************************************************************************************/
	return (
		<div className='container mb-5'>
			<div className='card card-shadow'>
				<div className="card-header">
					<h1 className="h3 m-0 fw-bold text-center">
						{t('stores.create.add')}
					</h1>
				</div>
				<div className="card-body">
					<div className='row justify-content-center'>
						<div className='col-12 col-sm-10 col-md-8 col-lg-6'>
							<main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
								{
									<Formik
										initialValues={{ 
											name    : '',
											email   : '',
											phone   : '',
											whatsapp: '',
											link    : '',
										}}
										validationSchema={formSchema}
										onSubmit={(values, actions) => createStore(values, actions)}
									>
										{({ values, setFieldValue  }) => (
										<Form>

                                            <div className="mb-3">
												<div className="form-floating">
													<Field type="name" className="form-control form-control-sm" id="floatingname" placeholder="name" name="name"/>
													<label htmlFor="floatingname">{t('stores.name')}</label>
												</div>
												<ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<div className="form-floating">
													<Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
													<label htmlFor="floatingInput">{t('login.email')}</label>
												</div>
												<ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<div className="form-floating">
													<Field type="whatsapp" className="form-control" id="floatingwhatsapp" placeholder="whatsapp" name="whatsapp"/>
													<label htmlFor="floatingwhatsapp">whatsapp</label>
												</div>
												<ErrorMessage name="whatsapp" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

											<div className="mb-3">
												<div className="form-floating">
													<Field type="phone" className="form-control" id="floatingphone" placeholder="phone" name="phone"/>
													<label htmlFor="floatingphone">{t('stores.create.phone')}</label>
												</div>
												<ErrorMessage name="phone" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>											
																						

											<div className="mb-3">
												<div className="form-floating">
													<Field type="link" className="form-control" id="floatingname" placeholder="link" name="link"/>
													<label htmlFor="floatingname">{t('stores.create.link')}</label>
												</div>
												<ErrorMessage name="link" component="div" className='field-error text-danger fw-bold text-capitalize'/>
											</div>

                                            <div className='mb-3'>                                                    
                                                <input className="d-none form-control border-1" 
                                                    type="file" 
                                                    id={`formFile`}
                                                    onChange={(e) => handleImage(e)} 
                                                    accept=".png,.jpg,.jpeg,.gif,.bmp"
                                                    required
                                                />  
                                                <div className="input-group">
                                                
                                                    <button 
                                                        className="btn btn-secondary border-1" 
                                                        type="button" id={`button-addon`}
                                                        onClick={() => document.querySelector(`#formFile`).click()}
                                                    >{t('stores.create.select')}</button>
                                                    <input 
                                                        id={`input`}
                                                        type="text" 
                                                        className="form-control border-1 pointer bg-secondary-subtle" 
                                                        placeholder="" 
                                                        aria-label="Example text with button addon" 
                                                        aria-describedby={`button-addon`}
                                                        readOnly
                    
                                                        onClick={() => document.querySelector(`#formFile`).click()}
                                                    />
                                                </div>
                                                {
                                                    _image &&
                                                    <div className='field-error text-danger'>
                                                        image required  
                                                    </div>
                                                }
                                                        
                                                {
                                                    image !== '' &&
                                                    <img className='img-fluid img-thumbnail border-1' width={200}  src={(image) || '#'} alt="..." />                                                    
                                                }                                                                                                    

                                                <ErrorMessage
                                                    name={`link`}
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                            									
											<button 
												className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
												type="submit"
												disabled={loading}
											><span className='me-3 fw-bold'>
												{t('stores.create.create')}
											</span>
											{
												loading &&
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											}
											</button>   
										</Form>
										)}
									</Formik>
								}
							</main>
						</div>
					</div>
				</div>
			</div>			
		</div>
	)
}

export default CreateStore