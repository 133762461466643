import { useTranslation } from 'react-i18next';

const usePaginationComponentOptions = () => {
    const { t } = useTranslation();
  
    const paginationComponentOptions = {
        rowsPerPageText      : t('pagination_component.rowsPerPageText'),
        rangeSeparatorText   : t('pagination_component.rangeSeparatorText'),
        selectAllRowsItem    : true,
        selectAllRowsItemText: t('pagination_component.selectAllRowsItemText'),
    };
  
    return paginationComponentOptions;
};
  
  export default usePaginationComponentOptions;