import React, {useEffect, useState, useContext} from 'react'
import SideBar from './SideBar';
import { Outlet, Navigate, useLocation, useNavigate  } from "react-router-dom"
import AuthContext from '../../middlewares/AuthContext';
import axios from 'axios';
const Main = () => {
    const url                   = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
	const { token, getHeaders, onLogout } = useContext(AuthContext);
	const [flip, setflip]       = useState(false);
	let   location              = useLocation();
	let   navigate              = useNavigate();

    const second = async() => { 
        const _headers = await getHeaders();
        await axios.get(`${url}auth/validate`, {headers: _headers})
                    .then(res =>{
                        console.log(res);
                    })
                    .catch(async err =>{
                        await onLogout();
                        navigate('/Login', {
                            state: {
                                from: location 
                            }
                        });
                    })
    }

	const showNavbar      = (toggleId, navId, bodyId, headerId) =>{
		setflip(!flip);
        const toggle   = document.getElementById(toggleId),
              nav      = document.getElementById(navId),
              bodypd   = document.getElementById(bodyId),
              headerpd = document.getElementById(headerId)
        // Validate that all variables exist
        if(toggle && nav && bodypd && headerpd){
			
			// console.log('si existen')
				console.log('click')
                // show navbar
                nav.classList.toggle('nav_show')
                // change icon
                // toggle.classList.toggle('bx-x')
                // add padding to body
                bodypd.classList.toggle('body-pd2')
                // add padding to header
                headerpd.classList.toggle('body-pd')
        }
    }
        
    function colorLink(e,l, linkColor, active='active'){
        if(linkColor){
            linkColor.forEach(m=> m.classList.remove(active))
            console.log(l)
            l.classList.add(active)
        }
    }

    const first = (linkColor) => { 
        linkColor.forEach(link => {
            const _link = link.getAttribute('href');
            if(location.pathname.includes(_link)) link.classList.add('active')       
            // console.log(link.getAttribute('href'))
        });

    }

    useEffect(() => {
        /*===== LINK ACTIVE =====*/
        const linkColor = document.querySelectorAll('.nav_link');        
        linkColor.forEach(l=> l.addEventListener('click', (e) => colorLink(e,l, linkColor)))
        first(linkColor);
        second();
        /*===== SUBLINK ACTIVE =====*/
        // const subLinkColor = document.querySelectorAll('.nav_sublink');        
        // subLinkColor.forEach(l=> l.addEventListener('click', (e) => colorLink(e,l, subLinkColor, 'subactive')))
    }, [])
	

    if (!token) {
        return <Navigate to="/login" state={{ from: location }}/>;
    }

	return (
        <>

            <div id='body-pd'>
            {
				process.env.REACT_APP_ENV === 'qa' &&
					<div className='position-fixed bg-danger opacity-75 fw-bold p-3 rounded dev-qa'>
						Entorno de QA
					</div>				
			}	        
            {
				process.env.REACT_APP_ENV === 'development' &&
					<div className='position-fixed bg-danger opacity-75 fw-bold p-3 rounded dev-qa'>
						Entorno de desarrollo
					</div>				
			}	        
            
                <SideBar
                    showNavbar={showNavbar}
                    flip={flip}                   
                />
                <main style={{marginTop: '4rem'}}>
                    <Outlet/>
                </main>
            </div>
            
        </>
		// </div>
	)
}

export default Main