import React, {useState, useEffect, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import CustomLoader from '../../layouts/CustomLoader';
import AuthContext from '../../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
const EditPlan = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                             = useTranslation();
    const { id }                            = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [plan, setPlan]                   = useState(null);
    const [charge, setcharge]               = useState(false);
    const [loading, setloading]             = useState(false);
/***************************************************************************************************************************/

    const first = async () => {
        const headers = await getHeaders();
        await axios.get(`${url}plans/edit/${id}`, {headers})
                    .then(res =>{
                        
                        const _plan      = res.data.message;
                        const best_value = (_plan.best_value) ? 2 : 1;
                        const visibility = (_plan.visibility) ? 2 : 1;
                        setInitialValues({ 
                            name            : _plan.name,
                            description     : _plan.description,
                            best_value      : best_value,
                            list_description: _plan.list_description,
                            visibility      : visibility,
                            order           : _plan.order,
                        });
                        setPlan(res.data.message)
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                    })
                    .then(() =>{
                        setcharge(true);
                    })

    }

    useEffect(() => {    
        first();
    }, [])
    

/***************************************************************************************************************************/

    const editPlan = async (params, {resetForm}) => {
        
        console.log(params);
        // return;
        const headers = await getHeaders();
        await axios.put(`${url}plans/update/${id}`, params, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        console.log(res);
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setloading(false);
                    });
    }
/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        name            : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        description     : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        best_value      : Yup.number().min(1).max(2).required(),
        visibility      : Yup.number().min(1).max(2).required(),
        list_description: Yup
        .array()
        .of(Yup.string().required('Is a field required').min(3, 'min 3 chars'))
        .required('almost one element')
        .min(1, 'almost one element'),
        order: Yup.number().min(1, 'min 1').max(99, 'max 99').required(),
    });
/***************************************************************************************************************************/
    return (
        <div className='container mb-5'>
            <div className='card'>
                {
                !charge
                ?   <CustomLoader/>
                :   
                    <>
                    {
                        (plan === null)
                        ?   <h1 className='text-center p-5'>{t('products.plans.not_exist_plan')}</h1>
                        :
                            <>
                            <div className='card-header'>
                                <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                                    <FontAwesomeIcon icon={faClipboardList} className="me-2"/>
            
                                    <span className='mb-0'>{t('products.plans.edition')}</span>
                                </h1>
                            </div>
                            <div className='card-body'>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={formSchema}
                                    onSubmit={(values, actions) => editPlan(values, actions)}
                                >
                                    {({values}) => (
                                    <Form>
                                        <div className='row justify-content-center'>
                                            <div className="col-md-3 col-lg-4 order-md-last">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <h5 className='mb-0 text-muted'>Plan for: {plan.Product.name}</h5>
                                                            <p className='mb-0 text-muted'>Price: ${parseFloat(plan.price).toFixed(2)}</p>
                                                            <p className='mb-0 text-muted text-capitalize'>Expiry time: {plan.expiry_time} {plan.Time.name}(s)</p>
                                                            <p className='mb-0 text-muted text-capitalize'>Quantity licenses: {plan.quantity}</p>
                                                            <p className='mb-0 text-muted text-capitalize'>Stripe ID: {plan.stripe_id}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-9 col-lg-8'>
                                                <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                                                                    
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="name" placeholder="name" name="name"/>
                                                            <label htmlFor="name">{t('products.plans.name')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>
            
                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="description" placeholder="Description" name="description"/>
                                                            <label htmlFor="description">{t('products.plans.description')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="description" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>  
                                                   
                                                    <div className="mb-3">
                                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="best_value">
                                                            <option value={1} className="text-capitalize">{t('products.plans.normal')}</option>
                                                            <option value={2} className="text-capitalize">{t('products.plans.best')}</option>
                                                            
                                                        </Field>
                                                        <ErrorMessage name="best_value" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="visibility">
                                                            <option value={1} className="text-capitalize">{t('products.hidden')}</option>
                                                            <option value={2} className="text-capitalize">{t('products.show')}</option>
                                                            
                                                        </Field>
                                                        <ErrorMessage name="visibility" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="form-floating">
                                                            <Field type="text" className="form-control" id="order" placeholder="order" name="order"/>
                                                            <label htmlFor="order">{t('products.order')} (*)</label>
                                                        </div>
                                                        <ErrorMessage name="order" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>

                                                     {/* Array */}
                                                    <div className="mb-3">
                                                        <FieldArray
                                                            name="list_description"
                                                            render={({insert, remove, push}) => ( 
                                                            <>
                                                                <div className="row">
                                                                        <label className="col-form-label col-md-10">{t('products.plans.description_list')}</label>
                                                                        <div className="col-sm-2">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-shadow btn-bd-primary fw-bold mb-3 form-control-plaintext"
                                                                                onClick={() => push("")}
                                                                            >+</button>
                                                                        </div>
                                                                </div>
                                                                {values.list_description.map((element, index) => (
                                                                    <div key={index}>
                                                                        <div className="input-group mb-3">
                                                                            <Field type="text" className="form-control" name={`list_description.${index}`} placeholder={`#${index+1}.- Description list`}/>
                                                                            <button className="btn btn-danger" type="button" onClick={() => remove(index)}>-</button>
                                                                        </div>
                                                                    <ErrorMessage
                                                                        name={`list_description.${index}`}
                                                                        component='div'
                                                                        className='field-error text-danger'
                                                                    />
                                                                </div>
                                                                ))}
                                                                 {
                                                                    
                                                                    values.list_description.length <= 0  &&                                                                 
                                                                        <div className='field-error text-danger'>
                                                                            <p>{t('products.plans.at_least')}</p>
                                                                        </div>
                                                                }
                                                            </>
                                                            )}
                                                        />
                                                    </div>
                                                    {/* End Array  */}
                                                
                                                    <div className="mt-4">
                                                        <button 
                                                            className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                            type="submit"
                                                            disabled={loading}
                                                        ><span className='me-3 fw-bold'>
                                                            Update Plan
                                                        </span>
                                                        {
                                                            loading &&
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        }
                                                        </button> 
                                                    </div>
            
                                                </main>
                                            </div>
                                            
                                        </div>
                                    </Form>
                                    )}
                                </Formik>
                            </div>
                            </>
                    }                                        
                    </>
                }                
            </div>
        </div>
    )
}

export default EditPlan