import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCommentDollar, faTrash } from '@fortawesome/free-solid-svg-icons';
// import 'react-select-search/style.css';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import utilities from '../../utilities/utilities';
import { useTranslation } from 'react-i18next';

const Advertising = () => {
    const { id }              = useParams();
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url       = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const {t, i18n}                     = useTranslation()
    const [charge, setcharge]           = useState(false);
    const [loading, setloading]         = useState(false);
    const [image, setImage]             = useState([]);
    const [free, setfree]               = useState(false);
    const [advertising, setadvertising] = useState(false);
    const [banners, setbanners]         = useState([]);
    // const myRefs                            = useRef([]);
    const [refs, setrefs]                   = useState([])
    const [initialValues, setinitialValues] = useState({ 
        phone   : '',
        whatsapp: '',
        email   : '',
        links   : []
    });
    
/***************************************************************************************************************************/
        
    useEffect(() => {

        (async () => { //Get freemium if exist
            const headers = await getHeaders();
            await axios.get(`${url}marketings/freemium/${id}`, {headers})
                        .then(async res =>{
                            if(res.data?.message?.Markpayments[0]?.FreemiumId !== null){
                                setfree(true);
                                const _banners = res.data?.message?.Markpayments[0]?.Freemium?.banners
                                if(_banners.length > 0){
                                    for (const ban of _banners) {
                                        await axios.get(`${url}freemium/${ban}`, {responseType: 'blob'})    
                                                    .then(res =>{
                                                        let reader = new FileReader();
                                                        reader.readAsDataURL(res.data);
                                                        reader.onload = (e) => {
                                                            console.log('DataURL:', e.target.result);
                                                            console.log(typeof e.target.result);
                                                            setImage(img => [...img, e.target.result])                                             
                                                        };
                                                    })  
                                                    .catch(err =>{
                                                        console.log(err)
                                                    });
                                    }
                                }
                                setbanners(_banners);
                                const _free = res.data?.message?.Markpayments[0]?.Freemium;
                                const lin_arr = [];
                                for (const lin of _free.link) {
                                    const str = lin.replace('http://','')
                                    lin_arr.push(str)
                                }
                                setinitialValues({
                                    phone   : _free.phone,
                                    whatsapp: _free.whatsapp,
                                    email   : _free.email,
                                    links   : lin_arr || [],
                                })
                            }
                            console.log(res)
                        })
                        .catch(err =>{
                            console.log('pto')
                            console.log(err)
                            setadvertising(true)
                        })
                        .then(() =>{
                            setcharge(true)
                        })
        })()
    }, [])
/***************************************************************************************************************************/  
    const handleImage = async(e, index) => {

        console.log(index)
        const _extension_ = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length);
        if(_extension_ !== 'png' && _extension_ !== 'jpg' && _extension_ !== 'jpeg' && _extension_ !== 'gif' && _extension_ !== 'bmp'){
            // setImage([]);
            document.getElementById(`formFile${index}`).value = "";
            Swal.fire({
                icon             : 'info',
                title            : t('marketing.advertising.invalid_extension'),
                toast            : true,
                position         : 'top-end',
                showConfirmButton: false,
                timer            : 3000,
                timerProgressBar : true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return false;
        }else{
            let _img = await utilities.getBase64(e.target.files[0])
            _img = JSON.stringify(_img)
            _img = _img.replace(/^"(.*)"$/, '$1');
            if(image[index] === undefined){
                setImage(img => [...img, _img]);
            }else{
                setImage(
                    image.map((img,i) => {
                        console.log(_img)
                        return i === parseInt(index)
                        ? _img
                        : img                        
                    }
                ))
            }
            // setImage(img => [...img, _img]);
            // setImage(img => [...img, e.target.files[0]])
        }
    }

/***************************************************************************************************************************/
    const uploadAdvertising = async(params, {resetForm}) => { 
        Swal.fire({
            showClass         : { popup: 'animate__animated animate__fadeInDown' },
            hideClass         : { popup: 'animate__animated animate__fadeOutUp' },
            title             : t('marketing.advertising.are_you_sure'),
            text              : t('marketing.advertising.upload_images'),
            icon              : 'info',
            showCancelButton  : true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor : '#d33',
            confirmButtonText : 'Yes, Upload!'
          }).then(async (result) => {
            if (result.isConfirmed) {

                params.images = JSON.stringify(image);
                const links_arr = [];
                for (const link of params.links) {
                    const str = `http://${link}`
                    links_arr.push(str)
                }
                // console.log(params.links)
                // return;
                // params.links_arr = JSON.stringify(params.links);
                      params.links_arr = JSON.stringify(links_arr);
                const headers          = await getHeaders();
                await axios.post(`${url}marketings/freemium/${id}`, params, {headers})
                            .then(res =>{
                                Swal.fire({
                                    icon: 'success',
                                    title: res.data.response,
                                });
                            })
                            .catch(err =>{
                                Swal.fire({
                                    icon: 'error',
                                    title: err?.response?.data?.response || err,
                                    text: err?.response?.data?.message || err
                                });
                            });
            }//End if result is confirmed
          }); //End then
    }
/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        phone   : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').required(),
        whatsapp: Yup.string().max(255, 'max 255 chars'),
        email   : Yup.string().email().required(),
        links   : Yup
        .array()
        .of(Yup.string().required(t('utilities.is_a_required_field')).min(3, 'min 3 chars'))
        .required('almost one element')
        .min(1, 'almost one element'),
    });/***************************************************************************************************************************/
    return (
        <>
        {
            charge 
            ?   <>
                {

                    !advertising 
                    ?
                        <div className='container mb-5'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                                        <FontAwesomeIcon icon={faCommentDollar} className="me-2"/>
                                        <span className='mb-0'>{t('marketing.advertising.freemium')}</span>
                                    </h1>
                                </div>
                                <div className='card-body'>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={formSchema}
                                        onSubmit={(values, actions) => uploadAdvertising(values, actions)}
                                    >   
                                        {({values}) => (
                                        <Form>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-10 col-lg-8'>
                                                    <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="text" className="form-control" id="phone" placeholder="phone" name="phone"/>
                                                                <label htmlFor="phone">{t('marketing.advertising.phone')} (*)</label>
                                                            </div>
                                                            <ErrorMessage name="phone" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="text" className="form-control" id="whatsapp" placeholder="whatsapp" name="whatsapp"/>
                                                                <label htmlFor="whatsapp">Whats App (*)</label>
                                                            </div>
                                                            <ErrorMessage name="whatsapp" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="form-floating">
                                                                <Field type="text" className="form-control" id="email" placeholder="email" name="email"/>
                                                                <label htmlFor="email">{t('marketing.advertising.email')} (*)</label>
                                                            </div>
                                                            <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                        </div>
                                                        
                                                        <hr/>

                                                        <div className="row mb-3">
                                                            <div className="col-md-7">
                                                                <p className="fw-bold mb-1">{t('marketing.advertising.you_can')}</p>
                                                                <span>
                                                                    {t('marketing.advertising.you_can_txt')}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="size"></div>
                                                            </div>
                                                        </div>    
                                                        
                                                        {/* First image  */}
                                                        <div className="mb-3 pb-2">
                                                            <FieldArray
                                                                name="links"
                                                                render={({insert, remove, push}) => ( 
                                                                <>
                                                                     <div className="row ">
                                                                         <label className="col-form-label col-md-10">{t('marketing.advertising.banners_list')}</label>
                                                                         <div className="col-sm-2">
                                                                             <button
                                                                                type="button"
                                                                                className="btn btn-shadow btn-bd-primary fw-bold mb-3 form-control-plaintext"
                                                                                disabled={(values.links.length) === 3 ? true : false}
                                                                                onClick={() => {
                                                                                    return (values.links.length === 3) ? null: push("")                                                                                    
                                                                                }}
                                                                             >+</button>
                                                                         </div>
                                                                    </div>
                                                                    {
                                                                    values.links.length > 0 &&
                                                                    values.links.map((element, index) => (
                                                                        <div key={index} className='border-bottom border-2 mb-3'>
                                                                            {
                                                                                // banners[index] === undefined &&
                                                                                <>
                                                                                    <input className="d-none form-control border-1" 
                                                                                        type="file" 
                                                                                        id={`formFile${index}`}
                                                                                        onChange={(e) => handleImage(e, index)} 
                                                                                        accept=".png,.jpg,.jpeg,.gif,.bmp"
                                                                                        // required
                                                                                    />  
                                                                                    <div className="input-group">
                                                                                    
                                                                                        <button 
                                                                                            className="btn btn-secondary border-1" 
                                                                                            type="button" id={`button-addon${index}`}
                                                                                            onClick={() => document.querySelector(`#formFile${index}`).click()}
                                                                                        >{t('marketing.advertising.select_image')}</button>
                                                                                        <input 
                                                                                            id={`input${index}`}
                                                                                            type="text" 
                                                                                            className="form-control border-1 pointer bg-secondary-subtle" 
                                                                                            placeholder="" 
                                                                                            aria-label="Example text with button addon" 
                                                                                            aria-describedby={`button-addon${index}`}
                                                                                            readOnly
                                                        
                                                                                            onClick={() => document.querySelector(`#formFile${index}`).click()}
                                                                                        />
                                                                                        <button className="btn btn-outline-danger" type="button" onClick={async() => {
                                                                                            await remove(index)
                                                                                            setImage(imgs => imgs.filter((e,i) => i != index))
                                                                                            }
                                                                                        }>-</button>
                                                                                    </div>
                                                                                    <div className="input-group">      
                                                                                    
                                                                                        <span 
                                                                                            className="input-group-text fw-bold" 
                                                                                            id={`basic-addon${index}`}
                                                                                        >http://</span>                                                                          
                                                                                        <Field type="text" className="form-control border-1" name={`links.${index}`} placeholder={`mypage.com`}/>
                                                                                    </div>
                                                                                    
                                                                                </>
                                                                            }

                                                                            {
                                                                                image[index] !== undefined &&
                                                                                <img className='img-fluid img-thumbnail border-1' width={200}  src={(image[index]) || '#'} alt="..." />
                                                                                // :   <img className='img-fluid img-thumbnail border-1' width={200}  src={`${URL.createObjectURL(image[index])}` || '#'} alt="..." />
                                                                                
                                                                            }
                                                                                                    

                                                                            <ErrorMessage
                                                                                name={`links.${index}`}
                                                                                component='div'
                                                                                className='field-error text-danger'
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                )}
                                                            />
                                                        </div>
                                                    
                                                        <div className="mt-4">
                                                            <button 
                                                                className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                                type="submit"
                                                                disabled={loading}
                                                            ><span className='me-3 fw-bold'>
                                                                {t('marketing.advertising.upload_advertising')}
                                                            </span>
                                                            {
                                                                loading &&
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            }
                                                            </button> 
                                                        </div>

                                                    </main>
                                                </div>
                                            </div>
                                        </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    :
                        <div className='container mb-5'>
                            <h1>Sub does not exist :(</h1>
                        </div>
                }
                </>
            :
                <div className='container mb-5'>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow text-success" role="status" style={{width: '5rem', height: '5rem'}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default Advertising