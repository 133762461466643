import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import AuthContext from '../../../middlewares/AuthContext';
import Swal from 'sweetalert2';
import utilities from '../../../utilities/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTableList, faWarning, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const AdminDashboard = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [dashboardOne, setDashboardOne] = useState(null);
    const [today, settoday]               = useState(DateTime.now({zone: 'utc'}));
    const [data, setdata]                 = useState(null);
    const [loading, setloading]           = useState(false);
    const {t, i18n}                       = useTranslation()
/***************************************************************************************************************************/

    useEffect(() => {
        
        (async () =>{
            const headers = await getHeaders()
            await axios.get(`${url}dashboards/customer`, {headers})
                        .then(res => {
                            setDashboardOne(res.data.message)
                            // console.log(res.data.dates)
                            const {activated, renovated, to_expire, expired, available} = res.data.message
                            setdata({
                                labels: [t('dashboard.activated'), t('dashboard.renovated'), t('dashboard.to_expire'), t('dashboard.expired'), t('dashboard.available')],
                                datasets: [{
                                    label          : 'Total',
                                    data           : [ activated, renovated, to_expire, expired, available],
                                    backgroundColor: ['#198754', '#6eb446', '#ffc107', '#dc3545', '#0d6efd'],
                                    borderColor    : ['#157348', '#3b7d3c', '#d9a406', '#bb2d3b', '#0b60dc'],
                                    borderWidth    : 1,
                                }]
                            });
                            console.log(res.data);
                            setloading(true);
                        })
                        .catch(err => {
                            Swal.fire({
                                icon: 'error',
                                title: err?.response?.data?.response || err
                            });
                        });
        })();

        everySecond();

    }, [])
    

/***************************************************************************************************************************/

    const everySecond = () => { 
        setInterval(() => {
            settoday(prevDateTime => prevDateTime.plus({seconds: 1}));
        }, 1000);
    }

/***************************************************************************************************************************/
    const options = {
        responsive: true,
        plugins   : {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text   : 'Month',
        },
        },
    };
/***************************************************************************************************************************/

    return (
        <>
            {
            dashboardOne !== null
            ?   <div className="row animate__animated animate__fadeIn">
                
                <h2>Today {today.setLocale(i18n.language).toFormat('yyyy-LLLL-dd HH:mm:ss')}</h2>
                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-success">
                            <div className="card-body position-relative">
                                <h6 className="card-title fw-bold h4">{dashboardOne.activated} <span className="card-text fs-6 fw-normal">{t('dashboard.licenses_activated')}</span></h6>
                                <h6 className="card-title fw-bold h4">{dashboardOne.renovated} <span className="card-text fs-6 fw-normal">{t('dashboard.licenses_renovated')}</span></h6>
                                <h3 className="card-title fw-bold h1">{parseInt(dashboardOne.renovated) + parseInt(dashboardOne.activated) } <span className="card-text fs-4 fw-normal">Total</span></h3>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faCheckCircle} size='4x'/>
                                </div>
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.activated')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-warning">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.to_expire}</h3>
                                <p className="card-text">{t('dashboard.licenses_to_expire')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faWarning} size='4x'/>
                                </div>							
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.to_expire')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-danger">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.expired}</h3>
                                <p className="card-text">{t('dashboard.licenses_expired')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faXmarkCircle} size='4x'/>
                                </div>									
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.expired')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mb-3">
                        <div className="card h-100 text-bg-primary">
                            <div className="card-body position-relative">
                                <h3 className="card-title fw-bold h1">{dashboardOne.available}</h3>
                                <p className="card-text">{t('dashboard.licenses_available')}</p>
                                <div className="position-absolute icon-dashboard">
                                    <FontAwesomeIcon icon={faTableList} size='4x'/>
                                </div>										
                            </div>
                            <div className='card-footer text-center py-1'>
                                <span className='fw-bold text-capitalize'>{t('dashboard.available')}</span>
                            </div>
                        </div>
                    </div>

                </div>

            :   <section className='container-fluid border-bottom border-5 border-secondary '>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 border-1 justify-content-center'>
                    {
                        [1,2,3,4].map((e,i) => {
                            return <div className={`col animate__animated animate__fadeIn`} key={`loading-${i}`}>
                                <div className="card h-100 text-bg-light" aria-hidden="true">
                                    <div className="card-header">
                                        <p className="placeholder-glow">
                                            <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </p>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title placeholder-glow">
                                            <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </h5>
                                        <p className="card-text placeholder-glow">
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        <span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
                                        </p>
                                    </div>
                                    <div className="card-footer">
                                        <a href="#!" tabIndex="-1" className=" bg-secondary disabled placeholder col-6"></a>
                                    </div>
                                        
                                </div>
                            </div>                   
                        })
                    }
                </div>      
                </section>             
            }
            {
                // data !==null &&
                loading &&
                // <div className='row'>
                //     <div className="col-md-6">
                        // <div className='w-100 mb-5 d-flex justify-content-center' style={{ height: '300px' }}>
                        <div className='mb-5 d-flex justify-content-center' style={{ width: '100%', height: '300px' }}>
                            {/* <Chart type='line' data={data} options={options}/> */}
                            <Chart type='pie' data={data} options={{
                                    maintainAspectRatio: false,                                   
                                    responsive: true,                                
                                    plugins   : {
                                        legend: {
                                            position: 'top',
                                            
                                        },
                                        title: {
                                            display: true,
                                            text   : today.setLocale(i18n.language).toLocaleString({month: 'long'}),
                                        },
                                    },
                                    elements: {
                                        point: {
                                          radius: 6, // Tamaño del radio de los puntos
                                          borderWidth: 2, // Ancho del borde de los puntos
                                        },
                                        line: {
                                          borderWidth: 2, // Ancho del borde de las líneas
                                        },
                                    },
                                }}
                            />
                        </div>
                    // </div>
                    // <div className="col-md-6">
                        // <div className='w-100 mb-5'>
                            // <Chart type='line' data={data} options={options}/>
                        // </div>
                    // </div>
                // </div>
            }
        </>
    )
}

export default AdminDashboard