import React, {useEffect, useContext} from 'react'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CartContext from '../../middlewares/cart/CartContext';
import Products from './Products';
import Sections from './Sections';
const Home = () => {
	const { t }          = useTranslation();
	const { search }     = useLocation();
	const parameters     = new URLSearchParams(search);
	const { deleteCart } = useContext(CartContext);

	useEffect(() => {
		const x = parameters.get('deleteCart');
		if(x !== null && x === 'true'){
			deleteCart();
		}
	}, [])
	
	
  return (
	<>
		<main>

			<div className="px-4 py-2 border-bottom border-5 border-secondary">
				<h1 className="display-1 fw-bold text-center" >
					<span className=' title'>Portal</span> 
					{
						process.env.REACT_APP_ENV === 'production' 
						? <span className='text-bs-primary'> Label Dictate</span>
						: <span className='text-danger text-uppercase'> {process.env.REACT_APP_ENV}</span>

					}
					
				</h1>
				<div className="col-lg-8 mx-auto">
					<p className="lead text-justify mb-4">{t('home.text1')}</p>
					<div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
						<Link className="btn btn-bd-primary btn-lg px-4 me-sm-3 rounded-pill" to="/signup">
							<span>
								{t('header.signup')}
							</span>
						</Link>
					</div>
				</div>
			</div>
		</main>
		<Products/>
		<Sections/>
		
	</>
  )
}

export default Home