import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate  } from "react-router-dom";
import CartContext from '../../middlewares/cart/CartContext';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
const Product = () => {
    /***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    /***************************************************************************************************************************/
    const { t }                    = useTranslation();
    const { addItem, cart }        = useContext(CartContext);
    // const [loading, setloading]    = useState(false);
    const [product, setproduct]    = useState(null);
    const { product:product_name } = useParams();
    let   navigate                 = useNavigate();
    const [isInMexico, setIsInMexico] = useState(false);
  /*************************************************************************************************************************************/
    const first = async() => {

        await axios.get(`${url}products/show/${product_name}`)
                .then(res =>{
                    setproduct(res.data.message);
                    console.log(res.data);
                })
                .catch(err =>{
                    console.log(err)
                });

    }

/***********************************************************************************************************************************/
    const getUserLocation = async() => {
        // Verifica si el navegador soporta la geolocalización
        await axios.get(`${url}api/location`)
                    .then(res => {
                        const {country, countryIso} = res.data;
                        console.log('res.data---------------')
                        console.log(res.data)
                        console.log(country)
                        if(country.toLowerCase() === 'mexico' || countryIso.toLowerCase() === 'mx'){
                            setIsInMexico(true);
                        }else{
                            setIsInMexico(false);
                        }
                    }).catch(err => {
                        setIsInMexico(false);
                        console.log(err)
                    })
    };
/***********************************************************************************************************************************/

    useEffect(() => {      
        first();
        getUserLocation();
    }, [product_name]);

/*************************************************************************************************************************************/

    const goToCheckout = e => { 
        if(cart.length === 0){
            e.product_name = product.name;
            addItem(e);
        }
        navigate('/checkout');
    }

/*************************************************************************************************************************************/

    const _addItem = (e) => {

        const p = isInMexico ? `$${parseFloat(e?.price_mxn).toFixed(2)} MXN` : `$${parseFloat(e?.price).toFixed(2)} USD`

        Swal.fire({
            icon: 'success',
            customClass: {
                popup: 'bg-body-tertiary',
            },
            html: `<div id="liveToastBtn">
                    <div class="toast-header">
                        <img src="${url}images/${product?.image}" class="rounded me-2 img-fluid" alt="${e?.name} image" width='50px'/>
                        <p class='me-auto mb-0 fw-bold'>Producto agregado</p>
                    </div>
                    <div class="toast-body">
                        <p class='mb-0'>
                            <strong class="me-2">
                                Producto: ${e?.name}
                            </strong>
                        </p>
                        <span><b class='fw-bold text-capitalize'>Precio:</b> ${p}</span>
                    </div>
                </div>`,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            showClass: {
                popup: 'animate__animated animate__fadeIn'
              },
            hideClass: {
                popup: 'animate__animated animate__fadeOut'
            },
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        addItem(e);
    }

/*************************************************************************************************************************************/
    return (
        <>            
            {/* <BackButton/> */}
            {                
                product !== null &&
                <>
                <main className='animate__animated animate__fadeIn'>
                    <div className="px-4 py-5 text-center border-bottom border-5 border-secondary">
                        <h1 className="display-1 fw-bold">
                            <span className='text-danger'>{product.name}</span>
                        </h1>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">{product.description}</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-center mb-5">
                                <img src={`${url}images/${product.image}`} alt='Product solution' className='img-fluid' width={300}/>
                            </div>
                        </div>
                    </div>
                </main>


                <section>
                    {
                        (product!== null && product?.Plans?.length > 0 ) ?
                        <div className='container-fluid animate__animated animate__fadeIn'>
                            <div className="row row-cols-1 row-cols-md-3 g-4 p-5 border-1">
                                {
                                    product.Plans.map((e,i) => {
                                    return <div className='col' key={`products-${i}`}>
                                        <div className="card card-shadow h-100 border-light bs-dark-text bg-light-subtle">
                                            {
                                                !e.best_value 
                                                ? <div className='card-header bg-light-subtle border-0'>
                                                    <p className='mb-0'>&nbsp;</p>
                                                </div>
                                                : <>
                                                    <div className='card-header bg-primary border-0 fw-bold text-center text-white  best-value'>
                                                        <p className='mb-0 position-relative'>{t('pages.product.bestvalue')}</p>
                                                    </div>
                                                </> 
                                            }
                                            
                                            <div className='card-header bg-light-subtle border-0'>
                                                <h5 className="card-title text-center fw-bold">{e.name}</h5>    
                                                <div className="text-center">
                                                    {
                                                        !e.best_value                                                    
                                                        ? <FontAwesomeIcon className="border-icon" icon={faStar} color="gold" size='3x'/>
                                                        :
                                                        <>
                                                            <FontAwesomeIcon className="border-icon" icon={faStar} color="gold" size='2x'/>
                                                            <FontAwesomeIcon className="border-icon" icon={faStar} color="gold" size='3x'/>
                                                            <FontAwesomeIcon className="border-icon" icon={faStar} color="gold" size='2x'/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className='text-muted mb-0'>{t("pages.product.price")}</p>
                                                <p className='fs-2 fw-bold'>
                                                    {
                                                        isInMexico
                                                        ?   <>
                                                                ${parseFloat(e.price_mxn).toFixed(2)} MXN
                                                            </>
                                                        :   <>
                                                                ${parseFloat(e.price).toFixed(2)} USD
                                                            </>
                                                    
                                                    }
                                                </p>
                                                <p className='text-capitelize'>
                                                    <b>{e.quantity}</b> {e.quantity > 1 ? t("pages.product.licenses"): t("pages.product.license")} {t("pages.product.for")} <b>{e.expiry_time} {e.expiry_time > 1 ? t(`utilities.${e.Time.name}s`): t(`utilities.${e.Time.name}`)}</b>
                                                </p>{}
                                                <p className="card-text">{e.description}</p>
                                                <ul>
                                                    {
                                                        e.list_description.map((f,j) =>{
                                                            return <li key={`list-description-${j}`}>{f.toString()}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="card-footer bg-light-subtle border-0 mb-3">
                                                <button onClick={() => {
                                                        e.product_name = product.name;
                                                        _addItem(e)
                                                    }
                                                } className='btn btn-warning border-dark-subtle btn-shadow w-100 mb-3'>
                                                    <FontAwesomeIcon icon={faCartPlus}/> <span className='fw-bold'>{t('pages.product.addtocart')}</span>
                                                </button>

                                                <button className='btn btn-bd-primary border-dark-subtle btn-shadow w-100' type='button' onClick={() => goToCheckout(e) }>
                                                    <span>{t('pages.product.buynow')}</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    })
                                }
                                </div>
                        </div>
                        :
                        <div className='container text-center py-4'>
                            {/* <BackButton/> */}
                            <h2>
                                Plans for this product solution does not exists :(
                            </h2>

                        </div>
                    }
                </section>
                </>
            }
        </>
  )
}

export default Product