import React, {useEffect, useState, useContext, Fragment} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";
import { DateTime } from "luxon";
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
import { useTranslation } from 'react-i18next';

const Customer = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
    const {t,i18n}            = useTranslation();
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    //search
    const [_stripe, set_stripe] = useState('');  // -- customer & Partner

/***************************************************************************************************************************/
    const [data, setData]            = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    // const [_stripe, set_stripe]      = useState('');
    const paginationComponentOptions = usePaginationComponentOptions();
/***************************************************************************************************************************/

    const getPayments = async (page) => {
		setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/customer/?page=${page}&per_page=${perPage}&stripe=${_stripe}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPayments(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/customer/?page=${page}&per_page=${newPerPage}&stripe=${_stripe}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_stripe(params.stripe);
        // set_search(params.stripe);
        const headers = await getHeaders();
        await axios.get(`${url}payments/customer/?page=1&per_page=${perPage}&stripe=${params.stripe}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/

    const invoice = async(params) => { 
        
        // await axios.get(`${url}payments/customer-pdf/${params}`,)
        const send_data = {
            pi: params
        }
        await axios.post(`${url}payments/customer-pdf`, send_data)
                  .then(res =>{
                       const url           = `data:application/pdf;base64,${res.data.message}`;
                       const link          = document.createElement('a');
                       const fileName      = res.data.name;
                             link.href     = url;
                             link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                  })
                  .catch(err => {
                    console.log(err);
                    Swal.fire({
                        icon: 'error',
                        title: err?.response?.data?.response,
                    });
                  })
    }
/***************************************************************************************************************************/
    const sumTotal = (items) => { 
        let sum = 0;

        for (const element of items) {
            sum += parseFloat(element.total_amount);
        }

        return sum.toFixed(2);
    }
/***************************************************************************************************************************/
    useEffect(() => {
        getPayments(1)
    }, []);
/***************************************************************************************************************************/
    const columns = [
        {
            maxWidth: "35%",
            name: t('payments.customer.stripe_data'),
            cell: (row, index, column, id) => {
                const d      = new Date(row.createdAt);
                let   amount = 0
                return  <>
                            <div className='w-100 text-start mb-3'>
                                <b>{t('payments.customer.payer_email')}: </b>
                                <span>{row.Payeremail.email}</span>
                            </div>
                            
                            <div className='w-100 text-start mb-3'>
                                <b>{t('payments.customer.date_purchase')}: </b>
                                <span>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)}</span>
                            </div>

                            <div className='w-100 text-start mb-3'>
                                <b>{t('payments.customer.amount_total')}: </b>
                                <span>${sumTotal(row.Itempayments)}</span>        
                            </div>

                              
                        {                            
                            (row.payment_status === 'paid')
                            ?   <div className='w-100 text-start mb-3'>
                                    <span className='fw-bold'>{t('payments.customer.status_payment')}: </span>   
                                    <span className='badge text-bg-success rounded-pill text-center mb-1 text-uppercase fw-bold p-1 text-light'>
                                        {row.payment_status}
                                    </span>
                                </div>
                            :   <div className='w-100 text-start mb-3'>
                                    <span className='fw-bold'>{t('payments.customer.status_payment')}: </span>   
                                    <span className='badge text-bg-warning rounded-pill text-center mb-1 text-uppercase fw-bold p-1 text-light'>
                                        {row.payment_status}
                                    </span>
                                </div>
                        }   
                        </>
            }
        },
        {
            maxWidth: "25%",
            name: t('payments.customer.product'),
            cell: (row, index, column, id) => {
                let counter = 0
                return  <ul className="ps-0 my-2">
                            { 
                                row?.Plans.map((e,i) =>
                                {
                                    let x = new Array(e.PaymentPlan.purchased_amount).fill("").map((_, i) => i + 1);
                                    return x.map((_e,_i) =>{
                                        counter++;
                                        return <Fragment key={`plans-${i}-${_i}`}>
                                            <li className='list-group-item pb-1 mb-1 border-bottom heigths-pac-pro' >
                                                <div className='row'>
                                                    <div className='col-2 p-0'>
                                                        <b>{counter}.-</b>
                                                    </div>
                                                    <div className='col-10 p-0'>
                                                        <div>
                                                            <b>Plan: </b> <span>{e.name}</span>
                                                        </div>
                                                        <div>
                                                            <b>{t('payments.customer.product')}: </b> <span>{e.Product.name}</span>
                                                        </div>
                                                        <div>
                                                            <b>{t('payments.customer.price')}: </b> <span>${parseFloat( row.Itempayments[i].individual_amount).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> 
                                        </Fragment>
                                    })

                                }
                                )
                            }
                        </ul>                         
            }
        },
        {
            maxWidth: "25%",
            name: t('payments.customer.package'),
            cell: (row, index, column, id) => {
                return  <ul className="ps-0 my-2">
                            {
                            row?.Packages.map((e,i) =>
                                {
                                    let days = 0, months = 0, years = 0;
                                    switch (parseInt(e.TimeId)) {
                                        case 1:
                                            years = e.expiry_time
                                            break;
                                        case 2:
                                            months = e.expiry_time
                                            break;
                                        case 3:
                                            days = e.expiry_time
                                            break;
                                    }
                                    const expiry = DateTime.fromISO(e.createdAt).plus({days, months, years}).toISODate();
                                    return <Fragment  key={`plans-${i}`}>
                                    <li className='list-group-item pb-1 mb-1 border-bottom heigths-pac-pro' >
                                        <div className='row'>
                                            <div className='col-2 p-0'>
                                                <b>{i+1}.-</b>
                                            </div>
                                            <div className='col-10 p-0'>
                                                <div>
                                                    <b>{t('payments.customer.package')}: </b>  
                                                    {/* <Link to={`/packages/show/${e.serial_number}`} 
                                                        target="_blank" 
                                                        rel="noopener noreferrer"
                                                    > */}
                                                        <span>{e.serial_number}</span>
                                                    {/* </Link> */}
                                                </div>
                                                <div>
                                                    <b>{t('payments.customer.status')}: </b> <span>{e.Status.name}</span>
                                                </div>
                                                <div>
                                                    <b>{t('payments.customer.expire_at')}: </b> <span>{expiry}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li> 
                                </Fragment>}

                            )
                            }
                        </ul>                         
            }
        },
        {
            maxWidth: "15%",
            name: t('payments.customer.actions'),
            cell: (row, index, column, id) => {
                return <>                          
                            <div className='d-flex w-100 justify-content-center'>
                                <div className=''>
                                    {
                                        row.payment_status !== 'refunded' &&
                                        <button type='button' 
                                            className='btn btn-sm btn-shadow btn-warning fw-bold mb-2 w-100'
                                            onClick={() => invoice(row.pi_stripe_id)} 
                                        > 
                                            <FontAwesomeIcon icon={faFilePdf} className="me-1"/>
                                            <span>
                                                Invoice
                                            </span>
                                        </button>
                                    }

                                </div>  
                            </div>
                    </>
            },
        },
    ];
/*************************************************************************************************************************/

    return (
        <div className='row align-items-start'>
            <div className="col-sm-4 col-md-2 col-lg-2 mb-3 my-1">
                <span className='text-capitalize fw-bold'>{t('payments.customer.payments')}</span>
                <p>{t('payments.customer.billing')}</p>
            </div>
            <div className="col-sm-8 col-md-10 col-lg-10 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                    {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">                               
                                <Formik
                                    initialValues={{ 
                                        stripe : _stripe,
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="stripe" className="col-sm-2 col-form-label col-form-label-sm">{t('payments.customer.search.stripe_payment')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('payments.customer.search.stripe_payment')} 
                                                    name="stripe"
                                                    id="stripe"
                                                /> 
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold'>
                                                    {t('utilities.search_btn')}
                                                </span>
                                            </button>
                                        </div>                
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="card card-shadow">
                    <DataTable
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        progressPending={loading}
                        pagination
                        paginationPerPage={perPage}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        progressComponent={<CustomLoader />}
                        dense
                    />
                </div>
            </div>
        </div>
    )
}

export default Customer