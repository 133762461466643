import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSquarePlus, faEdit, faFileCsv, faComputer, faRecycle } from '@fortawesome/free-solid-svg-icons';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";
import { DateTime } from 'luxon';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const Licenses = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [startDate, setStartDate] = useState(new Date());
    const [dateValid, setdateValid] = useState(false);
    const [statuses, setstatuses]   = useState([]);
    const {t, i18n}                 = useTranslation();
/***************************************************************************************************************************/
    const [selectedRowIndividual, setSelectedRowIndividual] = useState(null);
    const [selectedRows, setSelectedRows]                   = useState([]);
    const [toggledClearRows, setToggleClearRows]            = useState(false);
    const [licenses, setlicenses]                           = useState("10");
    const [data, setData]                                   = useState([]);
    const [currency, setCurrency]                           = useState('');
    const [loading, setLoading]                             = useState(false);
    const [totalRows, setTotalRows]                         = useState(0);
    const [perPage, setPerPage]                             = useState(50);
    const [columns, setcolumns]                             = useState([]);
    const [_product_key, set_product_key]                   = useState('');
    const [_useremail, set_useremail]                       = useState('');
    const [_package, set_package]                           = useState('');
    const download                                          = useRef(null);
    const downloadCls                                       = useRef(null);
    const updatesBtn                                        = useRef(null);     // modal btn  to open
    const renovateBtn                                       = useRef(null);     // modal btn  to open
    const renovateIndividualBtn                             = useRef(null);     // modal btn  to open renovateIndividualBtn

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const paginationComponentOptions = usePaginationComponentOptions();
    // const paginationComponentOptions             = {
    //     rowsPerPageText: 'Filas por página',
    //     rangeSeparatorText: 'de',
    //     // selectAllRowsItem: true,
    //     // selectAllRowsItemText: 'Todos',
    // };
/****************************************************************************************************************************************/
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        if(selectedRows.length > 0){
            document.querySelector('.rdt_TableHeader').classList.remove('d-none')
        }else{
            document.querySelector('.rdt_TableHeader').classList.add('d-none')
        }
        // handleRowSelected(selectedRows)
        console.log(selectedRows);
    };
    
    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }
/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        time: Yup.number().min(1, 'min 1').max(2, 'max 2').required(),
    });
/***************************************************************************************************************************/
    const handleRenovate = async(params, onlyOne=false) => {
      
        // const rows = selectedRows.map(e => e.product_key);
        setLoading(true);
        let rows = [];
        if(onlyOne){
            rows.push(selectedRowIndividual.product_key);
        }else{
            rows = selectedRows.map(e => e.product_key);
        }
        
        const data    = { keys : rows, time: params.time }
        const headers = await getHeaders();
        await axios.post(`${url}licenses/renovate`, data, {headers})
                .then(async res =>{
                    if(!res.data.partner){
                        window.location.href = res.data.url;
                    }else{
                        Swal.fire({
                            icon: 'success',
                            title: 'Renovate successfully (charge to next month)',
                        });
                        getLicenses(1);
                        setResetPaginationToggle(!resetPaginationToggle)
                        await renovateBtn.current.click();
                        await renovateIndividualBtn.current.click();
                    }
                    console.log(res.data);
                })
                .catch(err =>{
                    Swal.fire({
                        icon: 'error',
                        title: err?.response?.data?.response,
                    });
                })
                .then(() =>{
                    setLoading(false)
                });
    }
/***************************************************************************************************************************/
    const contextActions = React.useMemo(() => {
        
        return (tokenDecode.roles[0].name !== 'admin') 
        ?   <button 
                // onClick={handleRenovate} 
                data-bs-toggle="modal" data-bs-target="#renovate" 
                className='btn btn-sm btn-shadow btn-primary' >
                {t('licenses.customer.renovate')}
            </button>
        :   
            <>
                <button 
                    type='submit' 
                    className='btn btn-sm btn-shadow btn-success'
                    data-bs-toggle="modal" data-bs-target="#updates" 
                >
                    Update Licenses 
                </button>
            </>
        
    }, [data, selectedRows, toggledClearRows]);
/***************************************************************************************************************************/
    const getLicenses = async (page) => {
        setLoading(true);

        const headers = await getHeaders();
        await axios.get(`${url}licenses?page=${page}&per_page=${perPage}&product_key=${_product_key}&useremail=${_useremail}&package=${_package}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        setCurrency(res.data.currency)
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const rowDisabledCriteria = (row) => {
        const d = new Date(row.expireAt);
        const ex   = DateTime.fromJSDate(d);
        const now  = DateTime.now();
        const diff = ex.diff(now, ['months']);
        if(row.product_key === 'J30FFLDRBWHXAGX1VH9HGDZMR'){
            console.log(diff.toObject().months)
        }
        // if(row.Activations.length > 0  && diff.toObject().months < 1000){ //chane this for one
        if(row.Activations.length > 0  && diff.toObject().months < 1){ //chane this for one
            return false;
        }else{
            return true;
        }
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getLicenses(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}licenses?page=${page}&per_page=${newPerPage}&product_key=${_product_key}&useremail=${_useremail}&package=${_package}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_product_key(params.product_key);
        set_useremail(params.useremail);
        set_package(params.package);

        const product_key = params.product_key;
        const useremail   = params.useremail;
        const package_    = params.package;

        const headers = await getHeaders();
        await axios.get(`${url}licenses?page=1&per_page=${perPage}&product_key=${product_key}&useremail=${useremail}&package=${package_}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const getLicensesCsv = async() => { 
        // console.log(licenses);
        const headers = await getHeaders();
        download.current.click();
        await axios.get(`${url}licenses/all/csv/${licenses}`, {headers})
                    .then(res =>{
                        const url       = window.URL.createObjectURL(new Blob([res.data]));
                        const link      = document.createElement('a');
                              link.href = url;
                            link.setAttribute('download', `licenses-all-${licenses}.csv`);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(err =>{
                        console.log(err.response)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            await downloadCls.current.click();
                        }, 500);
                    });
    } 
/***************************************************************************************************************************/
    const deactivate = async(params, device) => { 
        console.log(params);
        const data    = { license : params}
        const headers = await getHeaders();

        Swal.fire({
            title: t('licenses.customer.str_remove_license') +' '+device,
            text: t('licenses.customer.str_this_license'),
            showCancelButton: true,
            confirmButtonText: t('licenses.customer.remove'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await axios.post(`${url}licenses/deactivate`, data, {headers})
                    .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.message,
                        });
                        console.log(res.data);
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                    })
                    .then(() =>{
                        setLoading(false)
                    });
                }
        });

        
    }
/***************************************************************************************************************************/
    const getStatuses = async() => { 
        const headers = await getHeaders();
        await axios.get(`${url}statuses`, {headers})
                    .then(res =>{
                        setstatuses(res.data.message);
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                    })
    }
/***************************************************************************************************************************/
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
/***************************************************************************************************************************/
    useEffect(() => {
        setcolumns(_columns());
        getLicenses(1);
        getStatuses();
        
        document.querySelector('.rdt_TableHeader').classList.add('d-none')
        console.log(tokenDecode);
    }, []);
/***************************************************************************************************************************/
    const admin = () => { 
        const result = [
            {
                name: 'Partner',
                cell: row => {                                                
                            return (row.Package.User?.Partner !== undefined && row.Package.User?.Partner !== null)
                            ?   <p className='text-capitalize mb-0'>{row.Package.User.Partner.name} </p>
                            :   <p>-</p>                                                            
                }
            },
            {
                name: 'Package #S/N',
                cell: row => <p className='text-capitalize mb-0'>{row.Package.serial_number} </p>     
            },
            {
                minWidth: '270px',
                name: 'Product Key',
                cell: row => <p className='text-capitalize mb-0 w-100 text-start'>{row.product_key} </p>   
                            
            },
            {
                name: 'Created At',
                cell: row => {
                    // const d = new Date(row.createdAt);
                    const d = DateTime.fromISO(row?.createdAt, { zone: 'utc-6' }).setLocale(i18n.language).toFormat('yyyy-MM-dd HH:mm:ss')

                    // return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>     
                    return <p className='text-capitalize mb-0'>{d} </p>     
                }
            },
            {
                name: 'Email user own',
                cell: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>
                }                            
            },
            {
                // wrap: true,
                name: 'User type',
                cell: row => {
                    return <>
                            {
                                (row.Package.UsertypeId === 1) &&
                                <>
                                    <p><b className='text-uppercase'>Admin</b></p>            
                                </>                            
                            }
                            {
                                (row.Package.UsertypeId === 2) &&
                                <>
                                    <p><b className='text-uppercase'>Partner</b></p>                            
                                </>
                            }
                            {
                                (row.Package.UsertypeId === 3) &&
                                <>
                                    <p><b className='text-uppercase'>Customer</b></p>                            
                                    <p>Plan: {row?.Package?.Plans[0]?.name || '-'}</p>
                                    <p>Soft: {row?.Package?.Plans[0]?.Product?.name || '-'}</p>
                                </>
                            }
                            </>
                }                            
            },
            {
                name: 'Current device',
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?  <p><b>{row?.Activations[0].device}</b> </p>  
                                    :  <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: 'Number activations',
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <p>
                                            <b>Number activation</b> : {row?.Activations[0].number_activation}
                                        </p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: 'Current activation',
                cell: row => {
                    let da = '-';
                    if(row?.Activations.length > 0){
                        // const d  = new Date(row?.Activations[0].createdAt);
                            //   da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                            da = DateTime.fromISO(row?.Activations[0].createdAt, { zone: 'utc-6' }).setLocale(i18n.language).toFormat('yyyy-MM-dd HH:mm:ss')

                    }
                  
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <>                                    
                                        <p>
                                            {da}
                                        </p>
                                        <p className='mb-0'><b>Product: </b></p>
                                        <p>{row.Activations[0].Product.name}</p>                               
                                        </>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: 'First activation',
                cell: row => {
                    let da = '-';
                    if(row?.license_first.length > 0){
                        // const d  = new Date(row?.license_first[0].createdAt);
                        //       da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                        // da = DateTime.fromISO(row?.license_first[0].createdAt, { zone: 'utc-6' }).setLocale(i18n.language).toFormat('yyyy-MM-dd HH:mm:ss')
                        da = DateTime.fromISO(row?.license_first[0].createdAt, { zone: 'utc-6' }).setLocale(i18n.language).toFormat('yyyy-MM-dd HH:mm:ss')

                    }
                    return <div className='w-100 text-start'>
                            {
                                (row?.license_first.length > 0) 
                                    ?   <p>{da}</p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: 'Renovations',
                cell: row => {
                    return <p className='text-capitalize mb-0'>{row.Renovateds.length}</p>
                }
            },
            {
                name: 'Expire At',
                cell: row => {
                    // const d    = new Date(row.expireAt);
                    // return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)}</p>
                    const d = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    let   x    = '';
                    let   clss = '';
                    if(diff.toObject().months < 1 && diff.toObject().months >= 0){
                        console.log(diff.toObject().months)
                        // const y = `${_diff.toObject().months}`
                        x    = t('licenses.customer.is_about_expire')
                        clss = 'text-warning'
                    }else if(diff.toObject().months < 0){
                        x    = t('licenses.customer.expire')
                        clss = 'text-danger'
                    }
                    return <>
                                <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} </p>
                                <p className={clss}>
                                    <b>{x}</b>
                                </p>
                           </>
                }
            },
            {
                name: 'Status',
                cell: row => {
                    let _class = '';
                switch (row.StatusId) {
                    case 1:
                        _class = 'badge text-bg-primary';
                        break;

                    case 2:
                        _class = 'badge text-bg-danger';
                        break;
                
                    default:
                        _class = 'badge text-bg-warning';
                        break;
                }
                return  <>
                            {
                                (row.Package.StatusId === 2 || row.Package.StatusId === 5)
                                ?   <p style={{fontSize: '14px'}} className={'text-danger'}>Package is: <b className='badge text-bg-danger text-capitalize'>{row.Package.Status.name}</b> </p>     
                                :   <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>{row.Status.name} </p>     
                            }
                        </>
                    // return <p className='text-capitalize mb-0'>{row.Status.name} </p>     
                }
            },
            {
                minWidth: '120px',
                name: 'Actions',
                cell: (row, index, column, id) => {
                    return <>                          
                                <div className='d-flex w-100 justify-content-center flex-column p-1'>
                                    <div className='mb-2'>
                                        <Link
                                            to={`edit/${row.id}`} 
                                            className='btn btn-success btn-shadow btn-sm'
                                        >
                                            <FontAwesomeIcon icon={faEdit} className="me-1"/>
                                            <span className='fw-bold'>
                                                Edit
                                            </span>
                                        </Link>
                                    </div>   
                                </div>
                        </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const customers = () => { 
        const result = [
            {
                name: t('licenses.customer.partner'),
                cell: row => {                                                
                            return (row.Package.User?.Partner !== undefined && row.Package.User?.Partner !== null)
                            ?   <p className='text-capitalize mb-0'>{row.Package.User.Partner.name} </p>
                            :   <p>-</p>                                                            
                }
            },
            {
                name: t('licenses.customer.package'),
                cell: row => <p className='text-capitalize mb-0'>{row.Package.serial_number} </p>
            },
            {
                minWidth: '270px',
                name: t('licenses.customer.product_key'),
                cell: row => <p className='text-capitalize mb-0 w-100 text-start'>{row.product_key} </p>   
                            
            },
            {
                minWidth: '100px',
                name: t('licenses.customer.compatibility'),
                cell: row => {
                    return row.Package.Products.map((e,i) => <p className='text-capitalize mb-0 w-100 text-start' key={`com-${i}`}>{e.name}</p>)                    
                }                            
            },
            // {
            //     hidden    : true,
            //     minWidth: '100px',
            //     name    : 'Pay online',
            //     cell    : row => {
            //         let x = ''
            //         if(row?.Package.pay_online){
            //             x = 'Plan adquired: ' + row?.Package?.Plans[0].name
            //         }else{
            //             x = '-'
            //         }
            //         return <p className='text-capitalize mb-0 w-100 text-start'>{x}</p>
            //     }                            
            // },
            {
                name: t('licenses.customer.current_device'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?  <p><b>{row?.Activations[0].device}</b> </p>  
                                    :  <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.number_activation'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <p>
                                            <b>{t('licenses.customer.number_activation')}</b> : {row?.Activations[0].number_activation}
                                        </p>
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.current_activation'),
                cell: row => {
                    let da = '-';
                    if(row?.Activations.length > 0){
                        const d  = new Date(row?.Activations[0].createdAt);
                              da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                    }
                  
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <>                                    
                                        <p>
                                            {da}
                                        </p>
                                        <p className='mb-0'><b>{t('licenses.customer.product')}: </b></p>
                                        <p>{row.Activations[0].Product.name}</p>                               
                                        </>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.expire_at'),
                cell: row => {
                    const d    = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    let   x    = '';
                    let   clss = '';
                    if(diff.toObject().months < 1 && diff.toObject().months >= 0){
                        console.log(diff.toObject().months)
                        // const y = `${_diff.toObject().months}`
                        x    = t('licenses.customer.is_about_expire')
                        clss = 'text-warning'
                    }else if(diff.toObject().months < 0){
                        x    = t('licenses.customer.expire')
                        clss = 'text-danger'
                    }
                    return <>
                                <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} </p>
                                <p className={clss}>
                                    <b>{x}</b>
                                </p>
                           </>
                }
            },
            {
                name: t('licenses.customer.status'),
                cell: row => {
                let _class = '';
                switch (row.StatusId) {
                    case 1:
                        _class = 'badge text-bg-primary';
                        break;

                    case 2:
                        _class = 'badge text-bg-danger';
                        break;
                
                    default:
                        _class = 'badge text-bg-warning';
                        break;
                }
                return  <>
                            {
                                (row.Package.StatusId === 2 || row.Package.StatusId === 5)
                                ?   <p style={{fontSize: '14px'}} className={'text-danger'}>Package is: <b className='badge text-bg-danger text-capitalize'>{row.Package.Status.name}</b> </p>     
                                :   <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>{t(`status.${row.Status.name}`)} </p>     
                            }
                        </>

                }
            },
            {
                minWidth: '150px',
                name: t('licenses.customer.actions'),
                cell: (row, index, column, id) => {
                    const d = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    // const x = (row.Activations.length > 0  && diff.toObject().months < 1000) ? true : false; Only one month
                    const x = (row.Activations.length > 0  && diff.toObject().months < 1) ? true : false;
                    console.log(x)
                    console.log(diff.toObject().months)
                    return <>   
                                <div className="dropdown mb-2">
                                    <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                        <span>
                                            {t(`licenses.customer.options`)}
                                        </span>	
                                    </a>
                                        <ul className="dropdown-menu" style={{width:'260px'}}>                        
                                            <li>
                                                {
                                                row.Status.name === 'activated'
                                                ?   <a           
                                                        href="#!"                      
                                                        className={"dropdown-item fw-bold"}
                                                        onClick={() => {
                                                            deactivate(row.product_key, row?.Activations[0].device)
                                                        }}    
                                                    >
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faComputer}/>
                                                            </div>
                                                            <div className="col-10  text-swarning">
                                                                <span>
                                                                    {t(`licenses.customer.remove_from_device`)}
                                                                </span>
                                                            </div>
                                                        </div>                                                                         
                                                    </a>
                                                :
                                                    <a           
                                                        href="#!"                      
                                                        className={"dropdown-item fw-bold text-muted disabled opacity-25 prohibed"}
                                                    >
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <FontAwesomeIcon icon={faComputer}/>
                                                            </div>
                                                            <div className="col-10">
                                                                <span>{t(`licenses.customer.remove_from_device`)}</span>
                                                            </div>
                                                        </div>                                                                         
                                                    </a>
                                                }                                      
                                            </li>
                                            <li>
                                                {
                                                    x
                                                    ?   <a           
                                                            href="#!"                      
                                                            className={"dropdown-item fw-bold"}
                                                            data-bs-toggle="modal" data-bs-target="#renovateIndividual" 
                                                            onClick={() => {
                                                                console.log(row.product_key)
                                                                setSelectedRowIndividual(row)
                                                            }}    
                                                        >
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faRecycle}/>
                                                                </div>
                                                                <div className="col-10  text-swarning">
                                                                    <span>{t(`licenses.customer.renovate`)}</span>
                                                                </div>
                                                            </div>                                                                         
                                                        </a>
                                                    
                                                    :   <a           
                                                            href="#!"                      
                                                            className={"dropdown-item fw-bold disabled opacity-25 prohibed"}
                                                        >
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faRecycle}/>
                                                                </div>
                                                                <div className="col-10">
                                                                    <span>{t(`licenses.customer.renovate`)}</span>
                                                                </div>
                                                            </div>                                                                         
                                                        </a>
                                                }                                                
                                            </li>
                                        </ul>
                                    </div>                     
                            </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const partners = () => { 
        const result = [
            {
                name: t('licenses.customer.partner'),
                cell: row => {                                                
                            return (row.Package.User?.Partner !== undefined && row.Package.User?.Partner !== null)
                            ?   <p className='text-capitalize mb-0'>{row.Package.User.Partner.name} </p>
                            :   <p>-</p>                                                            
                }
            },
            {
                name: t('licenses.customer.package'),
                cell: row => <p className='text-capitalize mb-0'>{row.Package.serial_number} </p>     
            },
            {
                minWidth: '270px',
                name: t('licenses.customer.product_key'),
                cell: row => <p className='text-capitalize mb-0 w-100 text-start'>{row.product_key} </p>   
                            
            },
            {
                minWidth: '100px',
                name: t('licenses.customer.compatibility'),
                cell: row => {
                    return row.Package.Products.map((e,i) => <p className='text-capitalize mb-0 w-100 text-start' key={`com-${i}`}>{e.name}</p>)                    
                }                            
            },
            {
                name: t('licenses.partner.created_at'),
                cell: row => {
                    const d = new Date(row.createdAt);
                    return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>     
                }
            },
            {
                name: t('licenses.partner.email_user_owner'),
                cell: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>
                }                            
            },
            {
                // name: t('licenses.partner.email_user_owner'),
                name: 'Charges',
                cell: row => {
                    return <ul className='ps-1'>
                        {
                            (row?.Pospayments.length > 0)
                                ?   row?.Pospayments.map((e,i) =>{
                                        const createdAt = DateTime.fromISO(e?.PospaymentLicense?.createdAt).setLocale(i18n.language).toFormat('yyyy LLLL dd');
                                        return <li className='mb-1 border-bottom' key={`price-${i}`}>{e?.PospaymentLicense?.price+'$' || '-'} {createdAt || '-'}</li>
                                    })
                                :   <li>
                                        <p>-</p>
                                    </li>
                        }
                    </ul>
                }                            
            },
            {
                name: t('licenses.customer.current_device'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?  <p><b>{row?.Activations[0].device}</b> </p>  
                                    :  <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.number_activation'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <p>
                                            <b>Number activation</b> : {row?.Activations[0].number_activation}
                                        </p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.current_activation'),
                cell: row => {
                    let da = '-';
                    if(row?.Activations.length > 0){
                        const d  = new Date(row?.Activations[0].createdAt);
                              da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                    }
                  
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <>                                    
                                        <p>
                                            {da}
                                        </p>
                                        <p className='mb-0'><b>Product: </b></p>
                                        <p>{row.Activations[0].Product.name}</p>                               
                                        </>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.partner.first_activation'),
                cell: row => {
                    let da = '-';
                    if(row?.license_first.length > 0){
                        const d  = new Date(row?.license_first[0].createdAt);
                              da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                    }
                    return <div className='w-100 text-start'>
                            {
                                (row?.license_first.length > 0) 
                                    ?   <p>{da}</p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.expire_at'),
                cell: row => {
                    const d = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    let   x    = '';
                    let   clss = '';
                    if(diff.toObject().months < 1 && diff.toObject().months >= 0){
                        console.log(diff.toObject().months)
                        // const y = `${_diff.toObject().months}`
                        x    = t('licenses.customer.is_about_expire')
                        clss = 'text-warning'
                    }else if(diff.toObject().months < 0){
                        x    = t('licenses.customer.expire')
                        clss = 'text-danger'
                    }
                    return <>
                                <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} </p>
                                <p className={clss}>
                                    <b>{x}</b>
                                </p>
                           </>
                }
            },
            {
                name: t('licenses.customer.status'),
                cell: row => {
                    let _class = '';
                switch (row.StatusId) {
                    case 1:
                        _class = 'badge text-bg-primary';
                        break;

                    case 2:
                        _class = 'badge text-bg-danger';
                        break;
                
                    default:
                        _class = 'badge text-bg-warning';
                        break;
                }
                return  <>
                            {
                                (row.Package.StatusId === 2 || row.Package.StatusId === 5)
                                ?   <p style={{fontSize: '14px'}} className={'text-danger'}>Package is: <b className='badge text-bg-danger text-capitalize'>{row.Package.Status.name}</b> </p>     
                                :   <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>{t(`status.${row.Status.name}`)} </p>     
                            }
                        </>

                }
            },
            {
                minWidth: '150px',
                name: t('licenses.customer.actions'),
                cell: (row, index, column, id) => {
                    const d = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    // const x = (row.Activations.length > 0  && diff.toObject().months < 1000) ? true : false; Only one month
                    const x = (row.Activations.length > 0  && diff.toObject().months < 1) ? true : false;
                    console.log(x)
                    console.log(diff.toObject().months)
                    return <>   
                                <div className="dropdown mb-2">
                                    <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                        <span>
                                            {t('licenses.customer.options')}
                                        </span>	
                                    </a>
                                        <ul className="dropdown-menu" style={{width:'220px'}}>                                                                    
                                            <li>
                                                {
                                                    x
                                                    ?   <a           
                                                            href="#!"                      
                                                            className={"dropdown-item fw-bold"}
                                                            data-bs-toggle="modal" data-bs-target="#renovateIndividual" 
                                                            onClick={() => {
                                                                console.log(row.product_key)
                                                                setSelectedRowIndividual(row)
                                                            }}    
                                                        >
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faRecycle}/>
                                                                </div>
                                                                <div className="col-10  text-swarning">
                                                                    <span>
                                                                        {t('licenses.customer.renovate')}
                                                                    </span>
                                                                </div>
                                                            </div>                                                                         
                                                        </a>
                                                    
                                                    :   <a           
                                                            href="#!"                      
                                                            className={"dropdown-item fw-bold disabled opacity-25 prohibed"}
                                                        >
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faRecycle}/>
                                                                </div>
                                                                <div className="col-10">
                                                                    <span>{t('licenses.customer.renovate')}</span>
                                                                </div>
                                                            </div>                                                                         
                                                        </a>
                                                }
                                                
                                            </li>
                                        </ul>
                                    </div>                     
                            </>
                },
            },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const subpartners = () => { 
        const result = [
            {
                name: t('licenses.customer.partner'),
                cell: row => {                                                
                            return (row.Package.User?.Partner !== undefined && row.Package.User?.Partner !== null)
                            ?   <p className='text-capitalize mb-0'>{row.Package.User.Partner.name} </p>
                            :   <p>-</p>                                                            
                }
            },
            {
                name: t('licenses.customer.package'),
                cell: row => <p className='text-capitalize mb-0'>{row.Package.serial_number} </p>     
            },
            {
                minWidth: '270px',
                name: t('licenses.customer.product_key'),
                cell: row => <p className='text-capitalize mb-0 w-100 text-start'>{row.product_key} </p>   
                            
            },
            {
                minWidth: '100px',
                name: t('licenses.customer.compatibility'),
                cell: row => {
                    return row.Package.Products.map((e,i) => <p className='text-capitalize mb-0 w-100 text-start' key={`com-${i}`}>{e.name}</p>)                    
                }                            
            },
            {
                name: t('licenses.partner.created_at'),
                cell: row => {
                    const d = new Date(row.createdAt);
                    return <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} {d.toISOString().slice(11,19)} </p>     
                }
            },
            {
                name: t('licenses.partner.email_user_owner'),
                cell: row => {
                    return (row?.User !== null)
                        ?   <p>{row.User?.email}</p>
                        :   <p>-</p>
                }                            
            },
            {
                // name: t('licenses.partner.email_user_owner'),
                name: 'Charges',
                cell: row => {
                    return <ul className='ps-1'>
                        {
                            (row?.Pospayments.length > 0)
                                ?   row?.Pospayments.map((e,i) =>{
                                        const createdAt = DateTime.fromISO(e?.PospaymentLicense?.createdAt).setLocale(i18n.language).toFormat('yyyy LLLL dd');
                                        return <li className='mb-1 border-bottom' key={`price-${i}`}>{e?.PospaymentLicense?.price+'$' || '-'} {createdAt || '-'}</li>
                                    })
                                :   <li>
                                        <p>-</p>
                                    </li>
                        }
                    </ul>
                }                            
            },
            {
                name: t('licenses.customer.current_device'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?  <p><b>{row?.Activations[0].device}</b> </p>  
                                    :  <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.number_activation'),
                cell: row => {
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <p>
                                            <b>Number activation</b> : {row?.Activations[0].number_activation}
                                        </p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.current_activation'),
                cell: row => {
                    let da = '-';
                    if(row?.Activations.length > 0){
                        const d  = new Date(row?.Activations[0].createdAt);
                              da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                    }
                  
                    return <div className='w-100 text-start'>
                            {
                                (row?.Activations.length > 0) 
                                    ?   <>                                    
                                        <p>
                                            {da}
                                        </p>
                                        <p className='mb-0'><b>Product: </b></p>
                                        <p>{row.Activations[0].Product.name}</p>                               
                                        </>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.partner.first_activation'),
                cell: row => {
                    let da = '-';
                    if(row?.license_first.length > 0){
                        const d  = new Date(row?.license_first[0].createdAt);
                              da = `${d.toISOString().slice(0,10)} ${d.toISOString().slice(11,19)}`
                    }
                    return <div className='w-100 text-start'>
                            {
                                (row?.license_first.length > 0) 
                                    ?   <p>{da}</p>   
                                    :   <p>-</p>
                            }                                                            
                            </div>
                }                            
            },
            {
                name: t('licenses.customer.expire_at'),
                cell: row => {
                    const d = new Date(row.expireAt);
                    const ex   = DateTime.fromJSDate(d);
                    const now  = DateTime.now();
                    const diff = ex.diff(now, ['months']);
                    // const _diff = ex.diff(now, ['months', 'days']);
                    let   x    = '';
                    let   clss = '';
                    if(diff.toObject().months < 1 && diff.toObject().months >= 0){
                        console.log(diff.toObject().months)
                        // const y = `${_diff.toObject().months}`
                        x    = t('licenses.customer.is_about_expire')
                        clss = 'text-warning'
                    }else if(diff.toObject().months < 0){
                        x    = t('licenses.customer.expire')
                        clss = 'text-danger'
                    }
                    return <>
                                <p className='text-capitalize mb-0'>{d.toISOString().slice(0,10)} </p>
                                <p className={clss}>
                                    <b>{x}</b>
                                </p>
                           </>
                }
            },
            {
                name: t('licenses.customer.status'),
                cell: row => {
                    let _class = '';
                switch (row.StatusId) {
                    case 1:
                        _class = 'badge text-bg-primary';
                        break;

                    case 2:
                        _class = 'badge text-bg-danger';
                        break;
                
                    default:
                        _class = 'badge text-bg-warning';
                        break;
                }
                return  <>
                            {
                                (row.Package.StatusId === 2 || row.Package.StatusId === 5)
                                ?   <p style={{fontSize: '14px'}} className={'text-danger'}>Package is: <b className='badge text-bg-danger text-capitalize'>{row.Package.Status.name}</b> </p>     
                                :   <p style={{fontSize: '12px'}} className={`text-capitalize mb-0 ${_class}`}>{t(`status.${row.Status.name}`)} </p>     
                            }
                        </>

                }
            },
            // {
            //     minWidth: '150px',
            //     name: t('licenses.customer.actions'),
            //     cell: (row, index, column, id) => {
            //         const d = new Date(row.expireAt);
            //         const ex   = DateTime.fromJSDate(d);
            //         const now  = DateTime.now();
            //         const diff = ex.diff(now, ['months']);
            //         // const _diff = ex.diff(now, ['months', 'days']);
            //         // const x = (row.Activations.length > 0  && diff.toObject().months < 1000) ? true : false; Only one month
            //         const x = (row.Activations.length > 0  && diff.toObject().months < 1) ? true : false;
            //         console.log(x)
            //         console.log(diff.toObject().months)
            //         return <>   
            //                     <div className="dropdown mb-2">
            //                         <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
            //                             <span>
            //                                 {t('licenses.customer.options')}
            //                             </span>	
            //                         </a>
            //                             <ul className="dropdown-menu" style={{width:'220px'}}>                                                                    
            //                                 <li>
            //                                     {
            //                                         x
            //                                         ?   <a           
            //                                                 href="#!"                      
            //                                                 className={"dropdown-item fw-bold"}
            //                                                 data-bs-toggle="modal" data-bs-target="#renovateIndividual" 
            //                                                 onClick={() => {
            //                                                     console.log(row.product_key)
            //                                                     setSelectedRowIndividual(row)
            //                                                 }}    
            //                                             >
            //                                                 <div className="row">
            //                                                     <div className="col-2">
            //                                                         <FontAwesomeIcon icon={faRecycle}/>
            //                                                     </div>
            //                                                     <div className="col-10  text-swarning">
            //                                                         <span>
            //                                                             {t('licenses.customer.renovate')}
            //                                                         </span>
            //                                                     </div>
            //                                                 </div>                                                                         
            //                                             </a>
                                                    
            //                                         :   <a           
            //                                                 href="#!"                      
            //                                                 className={"dropdown-item fw-bold disabled opacity-25 prohibed"}
            //                                             >
            //                                                 <div className="row">
            //                                                     <div className="col-2">
            //                                                         <FontAwesomeIcon icon={faRecycle}/>
            //                                                     </div>
            //                                                     <div className="col-10">
            //                                                         <span>{t('licenses.customer.renovate')}</span>
            //                                                     </div>
            //                                                 </div>                                                                         
            //                                             </a>
            //                                     }
                                                
            //                                 </li>
            //                             </ul>
            //                         </div>                     
            //                 </>
            //     },
            // },
        ];
        return result;
    }
/**************************************************************************************************************************************/
    const _columns = () => { 
        console.log(tokenDecode.roles[0].name)
        let result = [];
        switch (tokenDecode.roles[0].name) {
            case 'customer':
                result = customers();
                break;

            case 'partner':
                result = partners();
                break;

            case 'subpartner':
                result = subpartners();
                break;

            case 'admin':
                result = admin();
                break;
        
            default:
                break;
        }
        return result;
    }
/**************************************************************************************************************************************/
    const updateLicenses = async(params) => { 
        setLoading(true);
        const headers  = await getHeaders();
        const expireAt = DateTime.fromJSDate(startDate);
        if(!expireAt.isValid){
            setdateValid(false);
            setLoading(false);
            return;
        }
        params.expireAt = expireAt.toFormat('yyyy-MM-dd');
        console.log(params);
        const licenses = [];
        for (const lic of selectedRows) {
            licenses.push(lic.id);
        }
        params.licenses = licenses;
        await axios.put(`${url}licenses/updates-masive/`, params, {headers})
                   .then(async res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        // await getLicenses(1);
                        setResetPaginationToggle(!resetPaginationToggle)
                        await updatesBtn.current.click();
                        console.log(res);
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setLoading(false);
                    });




    }
/**************************************************************************************************************************************/
    const sumListPrices = (params, time) => { 
        console.log(params)
        const initialValue = 0;
        let sumWithInitial;
        if(parseInt(time) === 1){
            if(currency === 'mxn'){
                sumWithInitial = params.reduce(
                    (accumulator, currentValue) => accumulator + parseFloat(currentValue.Activations[0].Product.list_price_mx),
                    initialValue
                );
            }else{
                sumWithInitial = params.reduce(
                    (accumulator, currentValue) => accumulator + parseFloat(currentValue.Activations[0].Product.list_price),
                    initialValue
                );
            }
        }else{
            if(currency === 'mxn'){
                sumWithInitial = params.reduce(
                    (accumulator, currentValue) => accumulator + parseFloat(currentValue.Activations[0].Product.list_price_mx_2),
                    initialValue
                );
            }else{
                sumWithInitial = params.reduce(
                    (accumulator, currentValue) => accumulator + parseFloat(currentValue.Activations[0].Product.list_price_2),
                    initialValue
                );
            }
        }
        let ret = sumWithInitial.toFixed(2); 
        if(tokenDecode.roles[0].name === 'partner'){
            // return `Charge month invoice`
            return t("licenses.partner.charge_month")
        }else{
            return `${t("licenses.price_for_renovation")}: $${ret}`
        }
    }
/**************************************************************************************************************************************/

    return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={download}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={downloadCls}></button>
                    <div className="modal-body text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="updates" tabIndex="-1" aria-labelledby="updatesLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="updatesLabel">Edit licenses masive</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" 
                                aria-label="Close" ref={updatesBtn}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={{
                                StatusId: 0
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, actions) => updateLicenses(values, actions)}
                            // onSubmit={(values, actions) => console.log(values)}
                        >
                            {({values, setFieldValue}) => (
                            <Form>
                                <div className='row justify-content-center'>
                                    <div className='col-md-9'>
                                        <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                            <div className="mb-3">
                                                <label>Expire at</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={startDate} 
                                                    onChange={(value) => {
                                                        setStartDate(value);
                                                    }}
                                                    className="form-control" id="expireAt" placeholder="expireAt" name="expireAt"
                                                />

                                                {
                                                    dateValid &&
                                                    <div name="expireAt" component="div" className='field-error text-danger fw-bold text-capitalize'>
                                                        <p>Invalid Date</p>
                                                    </div>
                                                }
                                            </div>
                                            

                                            <div className="mb-3">
                                                <label>Status License</label>
                                                <Field as="select" className="form-select text-capitalize" aria-label="Default select example" name="StatusId">
                                                    <option className="text-capitalize" disabled value={0}>-Select option-</option>
                                                    {
                                                        statuses.length > 0 &&
                                                        statuses.map((e,i) => <option key={`status-${i}`} value={e.id} className="text-capitalize">{e.name}</option> )
                                                    }                                                    
                                                </Field>
                                                <ErrorMessage name="StatusId" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>

                                                                                        
                                            <div className="mt-4">
                                                <button 
                                                    className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                    type="submit"
                                                    disabled={loading}
                                                ><span className='me-3 fw-bold'>
                                                    Update License
                                                </span>
                                                {
                                                    loading &&
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                }
                                                </button> 
                                            </div>        
                                        </main>
                                    </div>                                        
                                </div>
                            </Form>
                            )}
                        </Formik>

                        <div className='row mt-3'>
                            <div className="col-md-12">                            
                                <button 
                                    className="btn btn-primary btn-sm btn-shadow mb-2" 
                                    type="button" data-bs-toggle="collapse" 
                                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    {t("licenses.show_row_selected")}
                                </button>
                                <div className="collapse w-100" id="collapseExample">
                                    <div className="border rounded  p-2">                                    
                                       {
                                            selectedRows &&
                                            selectedRows.map((e,i) => <p className='m-0'>{e.product_key}</p>)
                                       }                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("licenses.close")}</button>
                    </div>
                </div>
            </div>
        </div>
        {/* Renovate multi */}
        <div className="modal fade" id="renovate" tabIndex="-1" aria-labelledby="renovateLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="renovateLabel">{t("licenses.renovated_masive")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" 
                                aria-label="Close" ref={renovateBtn} disabled={loading}
                        ></button>
                    </div>
                    <Formik
                        initialValues={{
                            time: 1
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => handleRenovate(values, false)}
                        // onSubmit={(values, actions) => console.log(values)}
                    >
                        {({values, setFieldValue}) => (
                        <Form>
                    <div className="modal-body">
                        <h4>{t('licenses.charge_stripe')}</h4>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                                                                        
                                            <div className="mb-3">
                                                <label>{t('licenses.time_to_expire')}</label>
                                                <Field as="select" className="form-select" name="time">
                                                    <option value={1}> 1 {t('licenses.year')}</option>
                                                    <option value={2}> 2 {t('licenses.years')}</option>
                                                </Field>
                                            </div>
                                             
                                        </main>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-12">     
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <button className="btn btn-primary btn-sm btn-shadow mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                {t("licenses.show_row_selected")}
                                                </button>
                                            </div>
                                            <div className="col-md-6">
                                                {
                                                    selectedRows &&
                                                    sumListPrices(selectedRows, values.time)
                                                } 
                                                <span> {currency}</span>
                                            </div>
                                        </div>                       
                                        <div className="collapse w-100" id="collapseExample">
                                            <div className="border rounded  p-2">                                    
                                            {
                                                    selectedRows &&
                                                    selectedRows.map((e,i) => <p className='m-0' key={`pk-${i}`}>{e.product_key}</p>)
                                            }                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button disabled={loading} type="submit" className="btn btn-primary">
                                    <span>{t("licenses.customer.renovate")}</span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                    }
                                </button>
                                <button 
                                    disabled={loading} 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    data-bs-dismiss="modal"
                                >   <span>
                                        {t("licenses.close")}
                                    </span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                    } 
                                </button>
                            </div>
                        </Form>
                        )}
                    </Formik>                    
                </div>
            </div>
        </div>

        {/* renovate individual */}
        <div className="modal fade" id="renovateIndividual" tabIndex="-1" aria-labelledby="renovateIndividualLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="renovateIndividualLabel">{t('licenses.renovated_individual')}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={loading}
                                aria-label="Close" ref={renovateIndividualBtn}
                        ></button>
                    </div>
                    <Formik
                        initialValues={{
                            time: 1
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values) => handleRenovate(values, true)}
                        // onSubmit={(values, actions) => console.log(values)}
                    >
                        {({values, setFieldValue}) => (
                        <Form>
                            <div className="modal-body">
                                <h4>{t('licenses.charge_stripe')}</h4>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                                                                        
                                            <div className="mb-3">
                                                <label>{t('licenses.time_to_expire')}</label>
                                                <Field as="select" className="form-select" name="time">
                                                    <option value={1}> 1 {t('licenses.year')}</option>
                                                    <option value={2}> 2 {t('licenses.years')}</option>
                                                </Field>
                                            </div>
                                             
                                        </main>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-12">     
                                        <div className='row'>
                                            <div className="col-md-6">
                                                { 
                                                    selectedRowIndividual !== null &&                                                
                                                    <span>
                                                        {            
                                                            tokenDecode?.roles[0]?.name === 'customer'                                                 
                                                            ?   parseInt(values.time) === 1
                                                                ?   currency === 'mxn'
                                                                    ?   selectedRowIndividual.Activations[0].Product.list_price_mx.toString() + '$ ' + currency 
                                                                    :   selectedRowIndividual.Activations[0].Product.list_price.toString() + '$ ' + currency 
                                                                    
                                                                :   currency === 'mxn'
                                                                    ?   selectedRowIndividual.Activations[0].Product.list_price_mx_2.toString() + '$ ' + currency 
                                                                    :   selectedRowIndividual.Activations[0].Product.list_price_2.toString() + '$ ' + currency 
                                                            : <span>{t('licenses.partner.charge_month')}</span>
                                                            }
                                                    </span>
                                                } 
                                            </div>
                                        </div>                       
                                        <div className="w-100">
                                            <div className="border rounded  p-2">                                    
                                            {
                                                    selectedRowIndividual !== null &&
                                                    <p className='m-0'>{selectedRowIndividual.product_key}</p>
                                            }                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button disabled={loading} type="submit" className="btn btn-primary">
                                    <span>{t('licenses.customer.renovate')}</span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                    }
                                </button>
                                <button 
                                    disabled={loading} 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    data-bs-dismiss="modal"
                                >   <span>
                                        {t('licenses.close')}
                                    </span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                    } 
                                </button>
                            </div>
                        </Form>
                        )}
                    </Formik>                    
                </div>
            </div>
        </div>


        <div className='row align-items-start'>
            <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
                {
                    tokenDecode?.roles[0]?.name === 'customer' &&
                        <Link to="link" className='btn btn-bd-primary btn-shadow d-block'>
                            <FontAwesomeIcon icon={faSquarePlus} className="me-2"/>
                            <span className='text-capitalize fw-bold'>{t('licenses.customer.register_licenses')}</span>
                        </Link>
                }
                {
                    tokenDecode?.roles[0]?.name !== 'customer' &&
                    <div className='row'>
                        <div className="col-md-7">
                            <button 
                                className={"btn btn-success btn-sm w-100"}
                                onClick={() => {
                                    getLicensesCsv()
                                }} 
                            >
                                <span className='fw-bold me-2'>{t('licenses.download')}</span>
                                <FontAwesomeIcon icon={faFileCsv}/>
                            </button>
                            <select className="form-select form-select-sm" aria-label="Default select example" defaultValue={"10"} onChange={(e) => setlicenses(e.target.value)}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">All</option>
                            </select>
                        </div>

                        
                    </div> 
                    // <div className="col-sm-4 col-md-2 col-lg-2 mb-3 my-1">
                    //     <span className='text-capitalize fw-bold'>Licenses</span>
                    // </div>

                }
            </div>
            <div className="col-sm-8 col-md-8 col-lg-9 mb-3">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className='fw-bold me-2'>
                                    {t('utilities.search')}
                                </span> 
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <Formik
                                    initialValues={{ 
                                        product_key: _product_key,
                                        useremail  : _useremail,
                                        package    : _package
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="product_key" className="col-sm-2 col-form-label col-form-label-sm">{t('licenses.product_key')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('licenses.product_key')}
                                                    name="product_key"
                                                    id="product_key"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="useremail" className="col-sm-2 col-form-label col-form-label-sm">{t('licenses.user_email')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('licenses.user_email')}
                                                    name="useremail"
                                                    id="useremail"
                                                /> 
                                            </div>
                                        </div>

                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="package" className="col-sm-2 col-form-label col-form-label-sm">{t('licenses.package')}</label>
                                            <div className="col-sm-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder={t('licenses.package')}
                                                    name="package"
                                                    id="package"
                                                /> 
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold'>
                                                    {t('utilities.search_btn')}
                                                </span>
                                            </button>
                                        </div>                
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-5">
                <div className="card card-shadow" id='tableLicenses'>
                    <DataTable
                        title=" "
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent} 
                        columns={columns}
                        data={data}
                        fixedHeader
                        fixedHeaderScrollHeight="550px"
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle}
                        pagination
                        paginationPerPage={perPage}
			            paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[50, 100, 200, 500]}
                        progressComponent={<CustomLoader />}
                        // selectableRows
                        selectableRows={tokenDecode.roles[0].name !== 'subpartner'}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggledClearRows}
                        contextActions={contextActions}
                        contextMessage={{
                            singular: t('licenses.item'),
                            plural  : t('licenses.items'),
                            message: t('licenses.selected'),
                        }}
                        selectableRowDisabled={rowDisabledCriteria}
                        dense
                        // selectableRowsHighlight

                        
                    />
                </div>
            </div>
        </div>
    </>
    )
}

export default Licenses