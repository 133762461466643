import React, {useEffect, useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import CustomLoader from '../layouts/CustomLoader';
import jwt_decode from 'jwt-decode';
import AuthContext from '../../middlewares/AuthContext';
const CreateUser = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const [users, setUsers]                   = useState([]);
    const [roles, setroles]                   = useState([]);
    const [partners, setpartners]             = useState([]);
    const [marketings, setmarketings]         = useState([]);
    const [showPartner, setShowPartner]       = useState(false)
    const [showSubPartner, setShowSubPartner] = useState(false)
    const [showMarketing, setShowMarketing]   = useState(false)
    const [charge, setCharge]                 = useState(false)
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const [loading, setloading] = useState(false);
/*************************************************************************************************************************/

    const first = async () => {
        const headers = await getHeaders();
        await axios.get(`${url}users/roles`, {headers})
            .then(res =>{
                setroles(res.data.message);
                console.log(res.data);
            })
            .catch(err =>{
                console.log(err);
            });   
    }

    const second = async () => { 
        const headers = await getHeaders();
        await axios.get(`${url}partners`, {headers})
            .then(res =>{
                setpartners(res.data.message)
                console.log(res.data);
            })
            .catch(err =>{
                console.log(err);
            });
            
        await axios.get(`${url}marketings/all`, {headers})
            .then(res =>{
                setmarketings(res.data.message)
                console.log(res.data);
            })
            .catch(err =>{
                console.log(err);
            })
            .then(() =>{
                setCharge(true);
            })
    }

    useEffect(() => {
      
        first();
        second();
    
    }, [])
    
/***************************************************************************************************************************/ 

const AutoSubmit = () => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext();
    useEffect(() => {
        console.log(values)
        setShowPartner( parseInt(values?.role) === 2 );
        setShowSubPartner( parseInt(values?.role) === 3 );
        setShowMarketing( parseInt(values?.role) === 4 );
        // (parseInt(values?.role) === 2) ? setShowPartner(true) : setShowPartner(false)
        
    }, [values.role]);

    return null;
};

/***************************************************************************************************************************/ 

    const createUser = async (params, { resetForm }) => {
        setloading(true);
        console.log(params);
        const headers = await getHeaders();
        await axios.post(`${url}users`, params, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        resetForm();
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                   })
                   .then(() =>{
                        setloading(false);
                   })        
    }

/***************************************************************************************************************************/

    const getUsers = async(params, setFieldValue) => {
        const headers = await getHeaders(); 
        console.log(parseInt(params) );
        setFieldValue('partner', parseInt(params) );
        await axios.get(`${url}users/partner/${params}`, {headers})
                    .then(res =>{
                        setUsers(res?.data?.message || []);
                        console.log(res)
                    })
                    .catch(err =>{
                        console.log(err)
                    });
        
    }

/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(t('utilities.is_a_required_field')),
        password: Yup.string()
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&.¡,+¿()]+/, "One special character")
            .matches(/\d+/, "One number")
            .required(t('utilities.is_a_required_field')),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        role: Yup.number()
            .min(1, 'Select a role')
            .required(t('utilities.is_a_required_field')),
        partner  : Yup.number().min(0),
        marketing: Yup.number(),
        user     : Yup.number(),
        pospay   : Yup.number().min(0).max(1),
    });
/*************************************************************************************************************************/

return (
        <div className='container mb-5'>
            <div className='row justify-content-center'>
                <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
                    <main className='mt-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                        {
                            <Formik
                                initialValues={{ 
                                    email          : '',
                                    password       : '',
                                    passwordConfirm: '',
                                    role           : 0,
                                    partner        : 0,
                                    marketing      : 0,
                                    user           : 0,
                                    prepay         : 1,
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => createUser(values, actions)}
                            >
                                {({values, setFieldValue}) => (
                                <Form>
                                    <AutoSubmit/>
                                    <h1 className="h3 mb-3 fw-bold text-center">
                                        <FontAwesomeIcon icon={faUserPlus}/>
                                        <p>{t('users.create.user_create')}</p>
                                    </h1>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                            <label htmlFor="floatingInput">{t('login.email')}</label>
                                        </div>
                                        <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>


                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                            <label htmlFor="floatingPassword">{t('login.password')}</label>
                                        </div>
                                        <ErrorMessage name="password" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="password" className="form-control" id="floatingPassword2" placeholder="Confirm Password" name="passwordConfirm"/>
                                            <label htmlFor="floatingPassword2">{t('login.pass_confirm')}</label>
                                        </div>
                                        <ErrorMessage name="passwordConfirm" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="role">
                                            <option value={0} disabled >{t('users.create.select_an_option')}</option>
                                            {
                                                roles.map((e,i) =>{
                                                    // return <option value={e.id} key={`option-${i}`} className="text-capitalize">{e.name}</option>
                                                    return <option value={e.id} key={`option-${i}`}>{t(`users.${e.name}`)}</option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage name="role" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>
                                    {
                                        charge &&
                                        <>
                                            <div className={showPartner ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="partner">
                                                    <option value={0} disabled>{t('users.create.select_an_option')}</option>
                                                    {
                                                        partners.map((e,i) =>{
                                                            return <option value={e.id} key={`option2-${i}`} className="text-capitalize">{e.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="partner" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>

                                            <div className={showPartner ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="prepay">
                                                    <option value={1} >Pos payment</option>
                                                    <option value={0} >Pre payment</option>
                                                </Field>

                                                <ErrorMessage name="prepay" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>
                                            
                                        </>
                                    }
                                    {
                                        charge &&
                                        <>
                                            <div className={showSubPartner ? "mb-3" : "d-none"}>
                                                <Field onChange={(e) => getUsers(e.target.value, setFieldValue)} as="select" className="form-select form-select-lg" aria-label="Default select example" name="partner">
                                                    <option value={0} disabled>{t('users.create.select_an_option')}</option>
                                                    {
                                                        partners.map((e,i) =>{
                                                            return <option value={e.id} key={`option2-${i}`} className="text-capitalize">{e.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="partner" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                
                                            </div>
                                            <div className={showSubPartner ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="user">
                                                    <option value={0} disabled>{t('users.create.select_an_option')}</option>
                                                    {
                                                        users.map((e,i) =>{
                                                            return <option value={e.id} key={`option3-${i}`}>{e.email}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="user" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>
                                        </>
                                    }
                                    {
                                        charge &&
                                            <div className={showMarketing ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="marketing">
                                                    <option value={0} disabled>{t('users.create.select_an_option')}</option>
                                                    {
                                                        marketings.map((e,i) =>{
                                                            return <option value={e.id} key={`option3-${i}`} className="text-capitalize">{e.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="marketing" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>
                                    }
                                    <button 
                                        className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                        type="submit"
                                        disabled={loading}
                                    ><span className='me-3 fw-bold'>
                                        {t('users.create.create')}
                                    </span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    </button>   
                                </Form>
                                )}
                            </Formik>
                        }
                    </main>
                </div>
            </div>
        </div>
    )
}

export default CreateUser