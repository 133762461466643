import React, {useState, useEffect, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import CustomLoader from '../layouts/CustomLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import AuthContext from '../../middlewares/AuthContext';
const EditLicense = () => {
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                             = useTranslation();
    const { id }                            = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [license, setLicense]             = useState(null);
    const [charge, setcharge]               = useState(false);
    const [loading, setloading]             = useState(false);
    const [startDate, setStartDate]         = useState(new Date());
    const [dateValid, setdateValid]         = useState(false);
    const [statuses, setstatuses]           = useState([]);
    const { token, getHeaders }             = useContext(AuthContext);
    
/*************************************************************************************************************************/
    useEffect(() => {
      
        (async () => {
            const headers = await getHeaders();
            await axios.get(`${url}licenses/edit/${id}`, {headers})
            .then(res =>{
                const expire_at = new Date(res.data.message.expireAt)
                console.log(expire_at)
                console.log(new Date())
                setStartDate(expire_at)
                setInitialValues({ 
                    StatusId: res.data.message.StatusId,
                });
                setLicense(res.data.message)
                setstatuses(res.data.statuses)
            })
            .catch(err =>{
                Swal.fire({
                    icon: 'error',
                    title: err?.response?.data?.response || err,
                });
            })
            .then(() =>{
                setcharge(true);
            })
        })()

    }, [])
/***************************************************************************************************************************/
    const editLicense = async(params) => { 
        setloading(true);
        const headers  = await getHeaders();
        const expireAt = DateTime.fromJSDate(startDate);
        if(!expireAt.isValid){
            setdateValid(false);
            setloading(false);
            return;
        }
        params.expireAt = expireAt.toFormat('yyyy-MM-dd');
        console.log(params);

        await axios.put(`${url}licenses/update/${id}`, params, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        setLicense(res.data.message);
                        console.log(res);
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setloading(false);
                    });

    }
/***************************************************************************************************************************/
    const _class = (status) => {
        let c = '';
        switch (parseInt(status)) {
            case 1:
                c = 'badge text-bg-success';
                break;
            case 2:
                c = 'badge text-bg-danger';
                break;
            case 3:
                c = 'badge text-bg-primary';
                break;
            case 4:
                c = 'badge text-bg-primary';
                break;                                                                    
            default:
                c = 'badge text-bg-warning';
                break;
        }
        return c;

    }
/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        StatusId: Yup.number().min(1, 'min 1').max(7, 'max 7').required(),
    });
/*************************************************************************************************************************/

    return (
        <div className='container mb-5'>
        <div className='card'>
            {
            !charge
            ?   <CustomLoader/>
            :   
                <>
                {
                    (license === null)
                    ?   <h1 className='text-center p-5'>Plan Does not exist :(</h1>
                    :
                        <>
                        <div className='card-header'>
                            <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                                <FontAwesomeIcon icon={faClipboardList} className="me-2"/>
        
                                <span className='mb-0'>Edit Plan</span>
                            </h1>
                        </div>
                        <div className='card-body'>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => editLicense(values, actions)}
                            >
                                {({values, setFieldValue}) => (
                                <Form>
                                    <div className='row justify-content-center'>
                                        <div className="col-md-9 order-md-first">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <h5 className='mb-0 text-muted'>Product key: {license.product_key}</h5>
                                                        <p className='mb-0 text-muted'>Package S/N: {license.Package.serial_number}</p>
                                                        <p className='mb-0 text-muted text-capitalize'>Status: 
                                                                                                                    
                                                            <span className={`ms-2 ${_class(license.StatusId)}`}>
                                                                {
                                                                    statuses.map(e => {
                                                                        if(e.id == license.StatusId){
                                                                            return e.name
                                                                        }
                                                                    })
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-9'>
                                            <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                                <div className="mb-3">
                                                    <label>Expire at</label>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={startDate} 
                                                        onChange={(value) => {
                                                            setStartDate(value);
                                                        }}
                                                        className="form-control" id="expireAt" placeholder="expireAt" name="expireAt"
                                                    />

                                                    {
                                                        dateValid &&
                                                        <div name="expireAt" component="div" className='field-error text-danger fw-bold text-capitalize'>
                                                            <p>Invalid Date</p>
                                                        </div>
                                                    }
                                                </div>
                                               

                                                <div className="mb-3">
                                                    <label>Status License</label>
                                                    <Field as="select" className="form-select text-capitalize" aria-label="Default select example" name="StatusId">
                                                        {
                                                            statuses.length > 0 &&
                                                            statuses.map((e,i) => <option key={`status-${i}`} value={e.id} className="text-capitalize">{e.name}</option> )
                                                        }                                                    
                                                    </Field>
                                                    <ErrorMessage name="StatusId" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                </div>

                                                
                                            
                                                <div className="mt-4">
                                                    <button 
                                                        className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                                        type="submit"
                                                        disabled={loading}
                                                    ><span className='me-3 fw-bold'>
                                                        Update License
                                                    </span>
                                                    {
                                                        loading &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    }
                                                    </button> 
                                                </div>
        
                                            </main>
                                        </div>
                                        
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>
                        </>
                }                                        
                </>
            }                
        </div>
    </div>
    )
}

export default EditLicense