import React from 'react'
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
const CustomerForm = ({_stripe, _payeremail, _package, set_stripe, set_payeremail, set_package, setLoading, usertypes,
                      perPage, setData, setTotalRows, getHeaders}) => {
/***************************************************************************************************************************/
const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const {t} = useTranslation();
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_stripe(params.stripe);
        set_payeremail(params.payeremail);
        set_package(params.package);

        const stripe     = params.stripe;
        const payeremail = params.payeremail;
        const package_   = params.package;
        const headers    = await getHeaders();
        await axios.get(`${url}payments/admin/${usertypes}?page=1&per_page=${perPage}&stripe=${stripe}&payeremail=${payeremail}&package=${package_}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
    return (
    <Formik
        initialValues={{ 
            stripe    : _stripe,
            payeremail: _payeremail,
            package   : _package
        }}
        onSubmit={(values, actions) => setSearch(values, actions)}
        enableReinitialize
    >
        <Form>
            <div className="row align-items-center mb-3">
                <label htmlFor="stripe" className="col-sm-2 col-form-label col-form-label-sm">{t('payments.admin.stripe_payment')}</label>
                <div className="col-sm-10">
                    <Field 
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder={t('payments.admin.stripe_data')}
                        name="stripe"
                        id="stripe"
                    /> 
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <label htmlFor="payeremail" className="col-sm-2 col-form-label col-form-label-sm">{t('payments.admin.payer_email')}</label>
                <div className="col-sm-10">
                    <Field 
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder={t('payments.admin.payer_email')}
                        name="payeremail"
                        id="payeremail"
                    /> 
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <label htmlFor="package" className="col-sm-2 col-form-label col-form-label-sm">{t('payments.admin.package')}</label>
                <div className="col-sm-10">
                    <Field 
                        type="text" 
                        className="form-control form-control-sm" 
                        placeholder={t('payments.admin.package')}
                        name="package"
                        id="package"
                    /> 
                </div>
            </div>

            <div className='mt-3'>
                <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                    <span className='fw-bold'>
                        {t('utilities.search_btn')}
                    </span>
                </button>
            </div>                
        </Form>
    </Formik>
  )
}

export default CustomerForm