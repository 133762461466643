import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilePdf, faMoneyCheck, faMoneyBill, faEye } from '@fortawesome/free-solid-svg-icons';
import CustomLoader from '../layouts/CustomLoader';
import Swal from 'sweetalert2';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from "jwt-decode";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';

const Partners = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url       = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    //search
    const [_stripe, set_stripe]       = useState('');                               // --customer & Partner
    const [_startDate, _setStartDate] = useState(new Date('2023-01-01 00:00:01'));
    const [_endDate, _setEndDate]     = useState(new Date());

    const [startDate, setStartDate] = useState('2023-01-01');
    const [endDate, setEndDate]     = useState('2099-12-31');
/***************************************************************************************************************************/
    const [data, setData]            = useState([]);
    const [loading, setLoading]      = useState(false);
    const [totalRows, setTotalRows]  = useState(0);
    const [perPage, setPerPage]      = useState(10);
    const [columns, setcolumns]      = useState([]);
    const download                   = useRef(null);
    const downloadCls                = useRef(null);
    const prepayment                 = useRef(null);
    const prepaymentCls              = useRef(null);
    const {t, i18n}                  = useTranslation();
    const paginationComponentOptions = usePaginationComponentOptions();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const [file, setFile]                 = useState('');
    const [extension, setextension]       = useState('');
    const [filename, setfilename]         = useState('');
    const [idPospayment, setIdPospayment] = useState('')
/***************************************************************************************************************************/
    const getPayments = async (page) => {
		setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/partner?page=${page}&per_page=${perPage}&stripe=${_stripe}&start_date=${startDate}&end_date=${endDate}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getPayments(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}payments/partner?page=${page}&per_page=${newPerPage}&stripe=${_stripe}&start_date=${startDate}&end_date=${endDate}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const retriveSession = async (params) => { 
        console.log(params);
        const headers = await getHeaders();
        download.current.click();
        await axios.get(`${url}payments/partner/retrive/${params}`, {headers})
                    .then(res =>{
                        window.location.replace(res.data.message);
                        // console.log(res.data)
                        console.log(res.data.message)
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });      
                        console.log(err)
                    });
        
    }
/***************************************************************************************************************************/
    const viewForm = (cs_stripe_id) => { 
        setIdPospayment(cs_stripe_id)
        prepayment.current.click()
    }
/***************************************************************************************************************************/
    const viewPaymentConfirm = async (params) => { 
        
        const headers = await getHeaders();
        await axios({
            url         : `${url}payments/view-confirm-prepayment/${params}`,
            method      : 'get',
            headers     : headers,
            responseType: 'blob'
        })
        .then(res => {
            const disposition = res.headers['content-disposition'];
            const match       = disposition.match(/filename="(.+)"/);
            const fileName    = match ? match[1] : 'File-download';
            const fileType    = res.headers['content-type'];

            const url         = window.URL.createObjectURL(new Blob([res.data]));
            const link        = document.createElement('a');
                  link.href   = url;
            link.setAttribute('download', fileName);
            link.setAttribute('type', fileType);
            document.body.appendChild(link);
            link.click();
            console.log(res)
        })
        .catch(err =>{
            Swal.fire({
                icon : 'error',
                title: err?.response?.data?.response || err,
                text : err?.response?.data?.message || err
            });
            console.log(err)
        })
    }
/***************************************************************************************************************************/
    const partners = () => { 
        const result = [
            {
                maxWidth: "25%",
                name: t('payments.customer.stripe_data'),
                cell: row => {
                    return  <>
                                <ul className="ps-0 my-2">
                                    <li className='list-group-item pb-1 mb-1 border-bottom'>
                                        <b>{ t(`payments.partner.checkout`)}: </b>
                                        <span>
                                            {row?.cs_stripe_id}
                                        </span>
                                    </li> 
                                    <li className='list-group-item pb-1 mb-1 bottom'>
                                        <p className='mb-0'><b>{ t(`payments.partner.payment_id`)}: </b>{row?.pi_stripe_id}</p>
                                    </li>                             
                                </ul>   
                            </>
                },
            },
            {
                maxWidth: "10%",
                name: t('payments.customer.status'),
                cell: (row, index, column, id) => {                   
                    return  <>
                            {
                                (row.payment_status === 'paid')
                                &&  <div className='badge text-bg-primary text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                        {t(`payments.partner.${row.payment_status}`) }
                                        </p>                                
                                    </div>                                
                            }
                            {
                                (row.payment_status === 'pending')
                                &&   <div className='badge text-bg-warning text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                        {t(`payments.partner.${row.payment_status}`) }
                                        </p>                                
                                    </div>
                            }                                   
                            {
                                (row.payment_status === 'pending transfer')
                                &&   <div className='badge text-bg-warning text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                        pending transfer
                                        {/* {t(`payments.partner.${row.payment_status}`) } */}
                                        </p>                                
                                    </div>
                            }                                   
                            {
                                (row.payment_status === 'payment confirm send')
                                &&   <div className='badge text-bg-light text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-dark">
                                        payment confirm send
                                        {/* {t(`payments.partner.${row.payment_status}`) } */}
                                        </p>                                
                                    </div>
                            }                                   
                            {
                                (row.payment_status === 'externally paid')
                                &&   <div className='badge text-bg-success text-wrap'>
                                        <p style={{fontSize: '12px'}} className="text-capitalize fw-bold mb-0 text-light">
                                        {t(`payments.partner.externally`) }
                                        </p>                                
                                    </div>
                            }         
                            </>
                }
            },
            {
                maxWidth: "10%",
                name: t(`payments.partner.quantity`) ,
                cell: (row, index, column, id) => <p>{row?.licenses_quantity}</p>
                
            },
            {
                maxWidth: "10%",
                name: t(`payments.partner.amount_total`),
                cell: (row, index, column, id) => {
                    const total_amount = (row.total_amount !== null) ? `$${parseFloat(row.total_amount).toFixed(2)}` : '-'
                    return <p className='text-capitalize'>{total_amount}</p>
                }
            },
            {
                maxWidth: "15%",
                name: t(`payments.partner.range`),
                cell: (row, index, column, id) => {
                    const start = DateTime.fromISO(row.start_date, { zone: 'utc' }).setLocale(i18n.language).toFormat('yyyy LLLL dd')
                    const end   = DateTime.fromISO(row.end_date, { zone: 'utc' }).setLocale(i18n.language).toFormat('yyyy LLLL dd')
                    return <>
                        <span className='text-capitalize'>{start}</span>
                        <b>{t(`payments.partner.to`)}</b>
                        <span className='text-capitalize'>{end}</span>
                    </>
                }

            },
            {
                maxWidth: "15%",
                name: t(`payments.partner.due_date`),
                cell: (row, index, column, id) => {
                    const createdAt = DateTime.fromISO(row.createdAt)
                    console.log(createdAt)
                    return <span className='text-capitalize'><b>{t(`payments.partner.created_at`)}:</b> {createdAt.toFormat('yyyy-MM-dd')}</span>
                }
            },
            {
                maxWidth: "15%",
                name: t(`payments.partner.actions`),
                cell: (row, index, column, id) => {
                    return <>                          
                                <div className='d-flex w-100 justify-content-center'>
                                    <div className=''>
                                        {
                                            row.pi_stripe_id !== null
                                            ?   <>
                                                    <div className="dropdown mb-2">
                                                        <a className="btn btn-sm btn-primary btn-shadow dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 120}}>
                                                            <span>{ t(`payments.partner.options`)}</span>	
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a
                                                                    className='dropdown-item fw-bold'
                                                                    onClick={() => getInvoicePartner(row.id)}
                                                                > 
                                                                    <FontAwesomeIcon icon={faFilePdf}/>
                                                                    <span className="ms-1">
                                                                        Invoice
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li> 
                                                                {
                                                                    <a
                                                                        href="#!"
                                                                        className={row.payment_status === 'pending' ? `dropdown-item text-muted opacity-50 prohibed` : `dropdown-item fw-bold`}
                                                                        // onClick={() => getInvoicePartner(row.id)}
                                                                        onClick={() => {
                                                                            row.payment_status !== 'pending' ? getInvoicePartnerPayment(row.pi_stripe_id) : console.log('Pay pls')                                                
                                                                        }}
                                                                    > 
                                                                        <FontAwesomeIcon icon={faMoneyBill}/>
                                                                        <span className="ms-1">
                                                                            Payment
                                                                        </span>
                                                                    </a>
                                                                }                                                                                                  
                                                            </li>
                                                            <li>                                               
                                                                <a           
                                                                    href="#!"                      
                                                                    className={`dropdown-item fw-bold`}
                                                                    onClick={() => viewPaymentConfirm(row?.cs_stripe_id)}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-2">
                                                                            <FontAwesomeIcon icon={faEye} className='me-2'/>
                                                                        </div>
                                                                        <div className="col-10">
                                                                            <span>Payment confirm</span>
                                                                        </div>
                                                                    </div>                                                                         
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div> 

                                                </>
                                            :   
                                                row.cs_stripe_id !==null
                                                ?   !row.User.prepay 
                                                    ?   <button type='button'
                                                            className='btn btn-sm btn-shadow btn-warning rounded fw-bold w-100'
                                                            onClick={() => retriveSession(row.cs_stripe_id)}
                                                        > 
                                                            <FontAwesomeIcon icon={faFilePdf}/>
                                                            <span className="ms-1">
                                                                {t('payments.partner.pay_now')}
                                                            </span>
                                                        </button>
                                                    :   
                                                        row?.pi_stripe_id ===null
                                                        ?   
                                                            (row?.Prepayment === null) 
                                                            ?    <button type='button'
                                                                    className='btn btn-sm btn-shadow btn-warning rounded fw-bold w-100'
                                                                    onClick={() => viewForm(row.cs_stripe_id) }
                                                                > 
                                                                    <FontAwesomeIcon icon={faFilePdf}/>
                                                                    <span className="ms-1">
                                                                        Subir comprobante de pago
                                                                        {/* {t('payments.partner.pay_now')} */}
                                                                    </span>
                                                                </button>
                                                            :   <p>Check-in payment ...</p>
                                                        : <p>Descargar comprobante</p>
                                                :   <p className='badge rounded-pill text-bg-danger fs-6'>!</p>
                                        }                                        
                                    </div>  
                                </div>
                        </>
                },
            },
        ];
    return result;
    }
/**************************************************************************************************************************************/
    const getInvoicePartner = async(params) => { 
        console.log(params);
        // download.current.click();
        const headers = await getHeaders();
        await axios.get(`${url}payments/partner/invoice/${params}`, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/**************************************************************************************************************************************/
    const getInvoicePartnerPayment = async(params) => { 
        console.log(params);
        // download.current.click();
        const headers = await getHeaders();
        const data = {
            pi : params
        }
        await axios.post(`${url}partners/partner-pdf`, data, {headers})
                    .then(res =>{
                        // console.log(res.data)
                        // const url       = window.URL.createObjectURL(new Blob(res.data, {type: 'application/pdf'}));
                        const url           = `data:application/pdf;base64,${res.data.message}`;
                        const link          = document.createElement('a');
                        const fileName      = res.data.name;
                              link.href     = url;
                              link.download = fileName;
                            document.body.appendChild(link);
                            link.click();
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });                        
                    })
                    .then(() =>{
                        setTimeout(async() => {
                            // await downloadCls.current.click();
                        }, 500);
                    });
    }
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_stripe(params.stripe);
        const startDate_ = DateTime.fromJSDate(_startDate).toFormat('yyyy-MM-dd');
        const endDate_   = DateTime.fromJSDate(_endDate).toFormat('yyyy-MM-dd');
        setStartDate(startDate_);
        setEndDate(endDate_);


        const stripe  = params.stripe;
        const headers = await getHeaders();
        await axios.get(`${url}payments/partner?page=1&per_page=${perPage}&stripe=${stripe}&start_date=${startDate_}&end_date=${endDate_}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/

    const handleFile = (e) => {
        setFile(e.target.files[0]);
        const extension= e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        setextension(extension);
        const name= e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.'));
        setfilename(name);
    }

/***************************************************************************************************************************/

    const sendForm = async () => { 
        console.log(idPospayment);
        if ((file==='' ) && (extension!== 'gif' && extension!== 'bmp' && extension!== 'pdf' && extension!== 'png' && extension!== 'jpg' && extension!== 'jpeg')) {
            document.getElementById("formFileSm").value = "";
            setFile('');
            setextension('');
            setfilename('');
            Swal.fire({
                icon             : 'info',
                title            : 'Debe subir un archivo compatible',
                toast            : true,
                position         : 'top-end',
                showConfirmButton: false,
                timer            : 3000,
                timerProgressBar : true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            return;
        }

        let   _formData  = new FormData();
        _formData.append('file', file);
        const headers = await getHeaders();
        headers['Content-Type'] = 'multipart/form-data'
        await axios.post(`${url}partners/save-confirm-payment/${idPospayment}`, _formData, {headers})
                    .then(res =>{
                        getPayments(1);
                        setResetPaginationToggle(!resetPaginationToggle);
                        Swal.fire({
                            icon : 'success',
                            title: res.data.response,
                            text : res.data.message,
                        });
                        prepaymentCls.current.click();
                        setFile('');
                        document.getElementById("formFileSm").value = "";
                        console.log(res);
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon : 'error',
                            title: err?.response?.data?.response || err,
                            text : err?.response?.data?.message || err,
                        });  
                        console.log(err);
                    });
    }

/***************************************************************************************************************************/
    useEffect(() => {
        setcolumns(partners);
        getPayments(1);
    }, [i18n.language]);
/*************************************************************************************************************************/

    return (
        <>
            {/* <!-- Button trigger modal --> */}
            <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={download}>
                Launch static backdrop modal
            </button>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <button type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close" ref={downloadCls}></button>
                        <div className="modal-body text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- PRE Payment confirm --> */}
            <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#prepayment" ref={prepayment}>
                Launch static backdrop modal
            </button>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="prepayment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="updatesLabel">Upload payment confirm</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" 
                                    aria-label="Close" ref={prepaymentCls}
                            ></button>
                        </div>
                        <div className="modal-body">
                        <Formik
                                initialValues={{}}
                                onSubmit={(values, actions) => sendForm(values)}
                            >
                                <Form>
                                    <div className="row">
                                        <label htmlFor="formFile" className="form-label">Select a file</label>
                                        <div className="mb-3 ">
                                            <div className="mb-3 ">
                                                <input disabled={loading} className="form-control" id="formFileSm" type="file" name="file" onChange={handleFile} accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-bd-primary" disabled={loading}>Send file</button>                                                
                                        </div>
                                        {
                                            loading &&
                                            <div className="col-md-12 text-center">
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Form>
                            </Formik> 

                        </div>
                    </div>
                </div>
            </div>

            <div className='row align-items-start'>
                <div className="col-sm-4 col-md-2 col-lg-2 mb-3 my-1">
                    <span className='text-capitalize fw-bold'>{ t(`payments.partner.pospayments`)} {t(`payments.partner.billing`)}</span>
                </div>
                <div className="col-sm-8 col-md-10 col-lg-10 mb-3">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className='fw-bold me-2'>
                                        {t('utilities.search')}
                                    </span> 
                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse w-100" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                <Formik
                                    initialValues={{ 
                                        stripe : _stripe,
                                    }}
                                    onSubmit={(values, actions) => setSearch(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <label htmlFor="stripe" className="col-sm-2 col-form-label col-form-label-sm">{ t(`payments.partner.search.stripe_payment`)}</label>
                                            <div className="col-md-10">
                                                <Field 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder="Stripe data" 
                                                    name="stripe"
                                                    id="stripe"
                                                /> 
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="due-date" className="col-form-label col-form-label-sm fw-bold">{ t(`payments.partner.search.start_due_date`)}</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={_startDate} 
                                                    onChange={(value) => {
                                                        // console.log(value);
                                                        _setStartDate(value);
                                                    }}
                                                    className="form-control" id="start" placeholder="start" name="start"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="due-date" className="col-form-label col-form-label-sm fw-bold">{ t(`payments.partner.search.end_due_date`)}</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={_endDate} 
                                                    onChange={(value) => {
                                                        console.log(value);
                                                        _setEndDate(value);
                                                    }}
                                                    className="form-control" id="end" placeholder="end" name="end"
                                                />
                                            </div>
                                            
                                        </div>

                                        <div className='mt-3'>
                                            <button className='btn btn-sm btn-shadow btn-bd-primary' type='submit'>
                                                <span className='fw-bold'>
                                                    {t('utilities.search_btn')}
                                                </span>
                                            </button>
                                        </div>                
                                    </Form>
                                </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mb-5">
                    <div className="card card-shadow">
                        <DataTable
                            columns={columns}
                            data={data}
                            fixedHeader
                            fixedHeaderScrollHeight="500px"
                            progressPending={loading}
                            paginationResetDefaultPage={resetPaginationToggle}
                            pagination
                            paginationPerPage={perPage}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                            progressComponent={<CustomLoader />}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partners