import React from 'react'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
    const {t} = useTranslation()
    return (
        <main>
            <div className="px-4 py-5 border-bottom border-5 border-secondary">
                <h1 className="display-1  text-center fw-bold">{t('about_us.about')}<span className='text-bs-primary'>{t('about_us.us')}</span></h1>
                <div className="col-lg-8 mx-auto">
                    <ul className="list-group list-group-flush text-justify">                    
                    {
                        [0,1,2,3,4,5].map((e) =>{
                            return <li className="list-group-item border-0" key={`au-${e}`}>
                                {t(`about_us.text${e}`)}
                            </li>
                        })
                    }
                    </ul>
                </div>
            </div>
        </main>
    )
}

export default AboutUs