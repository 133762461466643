import React, { useState, useContext } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import AuthContext from '../../../middlewares/AuthContext';


const RecoveryPassword = () => {
/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/************************************************************************************************************************/
    const [loading, setloading] = useState(false);
    const { token }             = useContext(AuthContext);
    let   navigate              = useNavigate();
    const { t }                 = useTranslation();
/*****************************************************************************************************************************/
    const sendValues = async (params) => {
        setloading(true);
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        console.log(params);
        // return;
        await axios.post(url+'auth/reset-password', params, {headers: headers})
                    .then(async (response) =>{
                            Swal.fire({
                                icon : 'success',
                                title: 'Success',
                                text : response.data.response,
                            });

                            console.log(response.data);
                    }).catch((err) => {
                        console.log(err.response.data);
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: err?.response?.data?.response,
                            })
                    }).then(() =>{
                        setloading(false);
                    });
    }
/*****************************************************************************************************************************/
    //Validador de datos
    const formSchemaPassword = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email()
            .required('Campo requerido'),
    });
/*****************************************************************************************************************************/

    if (token) {
        return <Navigate to="/dashboard"/>;
    }

/*****************************************************************************************************************************/
  return (
    <div className='container mb-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-8 col-md-8 col-lg-6'>
                    <main className='mt-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                {/* <Images/> */}
                    <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={formSchemaPassword}
                            onSubmit={(values, actions) => sendValues(values, actions)}
                    >
                        <Form>
                            {/* <img className="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}
                            <h1 className="h3 mb-3 mt-2 fw-normal text-center">{t('login.recovery_password')}</h1>

                            <div className="form-floating mb-3">
                                <Field disabled={loading} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                <label htmlFor="floatingInput">Email</label>
                                <ErrorMessage
                                    name='email'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                            <div className=" mb-3">
                                <Link to="/signup" className="link-primary" >
                                    {t("login.create_account")}
                                </Link>
                            </div>

                            <button disabled={loading} className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" type="submit">
                                {t("login.recovery_password")}
                            </button>
                            {
                                loading &&                                            
                                    <div className="row">
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>                                        
                                    </div>                                        
                            }
                            <p className="mt-5 mb-3 text-muted">© 2022 - Version {process.env.npm_package_version}</p>
                        </Form>     
                    </Formik>        
                </main>                        
            </div>
        </div>
    </div>  
  )
}

export default RecoveryPassword