import React, {useState, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faStar, faPenToSquare, faClipboardList, faRecycle, faEye, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const CardProduct = ({product:e, i, url, setProducts}) => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const [loading, setloading]       = useState(false);
    const [plansTrash, setPlansTrash] = useState(null);
    const { t }                       = useTranslation()

/*********************************************************************************************************************/
    const softDeleteProduct = async (params) => {
        console.log(params)
        Swal.fire({
            title: t('products.archive_product'),
            text: t('products.archive_product_txt'),
            showCancelButton: true,
            confirmButtonText: t('products.archive'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    await axios.delete(`${url}products/destroy/${params}`, {headers})
                                .then(res =>{
                                    setProducts(res.data.message)
                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });
                                    console.log(res);
                                })
                                .catch(err =>{
                                    Swal.fire({
                                        icon: 'error',
                                        title: err?.response?.data?.response,
                                    });
                                    console.log(err);
                                })
                                .then(() => {

                                })
                            }
                        })
    }
/*********************************************************************************************************************************/
    const softDeletePlan = async (params) => {
        console.log(params)
        Swal.fire({
            title: t('products.archive_plan'),
            text: t('products.archive_plan_txt'),
            showCancelButton: true,
            confirmButtonText: t('products.archive'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    await axios.delete(`${url}plans/destroy/${params}`, {headers})
                                .then(res =>{
                                    setProducts(res.data.message)
                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });
                                    console.log(res);
                                })
                                .catch(err =>{
                                    Swal.fire({
                                        icon: 'error',
                                        title: err?.response?.data?.response,
                                    });
                                    console.log(err);
                                })
                                .then(() => {

                                })
                            }
                        })
    }
/*********************************************************************************************************************************/
    const getPlansTrash = async(params) => { 
        setloading(true)
        console.log(params);
        const headers = await getHeaders();
        await axios.get(`${url}plans/trash/${params}`, {headers})
                    .then(res =>{
                        setPlansTrash(res.data.message);
                        console.log(res);
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        console.log(err);
                    })
                    .then(() => {
                        setloading(false);
                    })
    }   

/*********************************************************************************************************************************/

    const restorePlan = async(params) => { 
        console.log(params);
        Swal.fire({
            title: t('products.restore_plan'),
            text: t('products.restore_plan_txt'),
            showCancelButton: true,
            confirmButtonText: t('products.restore'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setloading(true)
                    const headers = await getHeaders();
                    await axios.put(`${url}plans/restore/${params}`, {}, {headers})
                            .then(res =>{                        
                                setProducts(res.data.message)
                                setPlansTrash(
                                    plansTrash.filter(x => x.id !== params)
                                );
                                Swal.fire({
                                    icon: 'success',
                                    title: res.data.response,
                                });
                                console.log(res);
                            })
                            .catch(err =>{
                                Swal.fire({
                                    icon: 'error',
                                    title: err?.response?.data?.response,
                                });
                                console.log(err);
                            })
                            .then(() => {
                                setloading(false);
                            })
                }
            });

    }

/*********************************************************************************************************************************/

  return (
     <div className='row w-100 mt-2'>
        <div className="col-12">
            <div className="card mb-3 card-shadow">
                <div className="row g-0">
                    <div className="col-md-4 d-flex  justify-content-center bg-body-secondary">
                        <img src={`${url}images/${e.image}`} className="img-fluid rounded-start p-1" alt={`${e.name}`} style={{height: 150}}/>
                    </div>
                    <div className="col-md-8">
                        <div className='pt-1 px-3'>
                            <div className='d-flex w-100 justify-content-start justify-content-md-end'>
                                <div className='me-4'>
                                    <Link
                                        to={`products/edit/${e.id}`} 
                                        className='btn btn-success btn-shadow btn-sm rounded-circle'
                                    >
                                        <FontAwesomeIcon icon={faPenToSquare}/>
                                    </Link>
                                </div>  
                                <div >
                                    <button 
                                        className={"btn btn-danger btn-shadow btn-sm rounded-circle"}
                                        onClick={() => {
                                            softDeleteProduct(e.id)
                                        }}    
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </button>
                                </div>  
                            </div>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title fw-bold">{e.name}</h5>
                            <p className="card-text mb-0">{e.description}</p>
                            <p className='mb-0 text-capitalize'><b>{t('products.visibility')}:</b> {e.visibility ? t('products.show'): t('products.hidden')}</p>
                            <p className='mb-0'><b>{t('products.list_price')}:</b> ${e.list_price} usd</p>
                            <p className='mb-0'><b>{t('products.list_price_2')}:</b> ${e.list_price_2} usd</p>
                            <p className='mb-0'><b>Precio de lista en pesos:</b> ${e.list_price_mx} mxn</p>
                            <p className='mb-0'><b>Precio de lista 2 (dos años) en pesos:</b> ${e.list_price_mx_2} mxn</p>
                            <p className='mb-1'><b>{t('products.order')}:</b> {e.order}</p>
                            <a href="#!" className="btn btn-outline-success w-100" role="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`}>
                                <span className='card-text me-2'>
                                    {t('products.plans_title')}
                                </span>
                                <span>
                                    <FontAwesomeIcon icon={faCaretDown}/>
                                </span>
                            </a>
                                                        
                            <div className="collapse mt-2 w-100" id={`collapse-${i}`}>
                                <div className='row border-bottom pb-3 justify-content-start justify-content-sm-end'>
                                    <div className='col-sm-5'>
                                        <Link to={`plans/create/${e.id}`} className='btn btn-sm btn-shadow btn-bd-primary w-100'>
                                            <FontAwesomeIcon icon={faClipboardList} className="me-2"/>
                                            <span className='fw-bold text-capitalize'>
                                                {t('products.add_plan')}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <ul>
                                {
                                    e.Plans.map((el, il) =>
                                        <li className='border-bottom mt-2' key={`plans-${il}`}>
                                            <div className='row align-items-center'>
                                                <div className='col-sm-8'>

                                                    <p className='mb-0' to={`products/edit/${el.id}`}>
                                                        <b>{t('products.plan_name')}: </b> {el.name}
                                                    </p>
                                                    <p className='mb-0'>
                                                        <b>{t('products.plan_desc')}: </b>{el.description}
                                                    </p>
                                                    <p className='mb-0 text-capitalize'>
                                                        <b>{t('products.duration')}: </b>{el.expiry_time} {el.Time.name}
                                                    </p>
                                                    {
                                                        el.best_value
                                                        ? <p className='mb-0'> <b>{t('products.plans.best')}: </b><FontAwesomeIcon icon={faStar} color="gold"/> </p>
                                                        : <p className='mb-0'>{t('products.plans.normal')}</p>
                                                    }
                                                    <p className='mb-0'><b>{t('products.order')}:</b> {el.order}</p>                                                    
                                                    <p className='mb-0'><b>{t('products.visibility')}:</b> {el.visibility ? 'Show': 'Hidden'}</p>                                                    
                                                </div>
                                                <div className="col-sm-4 py-4">
                                                    <div className='d-flex w-100 '>
                                                        <div className='me-2'>
                                                            <Link
                                                                to={`plans/edit/${el.id}`} 
                                                                className='btn btn-success btn-shadow btn-sm rounded-circle'
                                                            >
                                                                <FontAwesomeIcon icon={faPenToSquare}/>
                                                            </Link>
                                                        </div>  
                                                        <div >
                                                            <button 
                                                                className={"btn btn-danger btn-shadow btn-sm rounded-circle"}
                                                                onClick={() => {
                                                                    softDeletePlan(el.id)
                                                                }}    
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </button>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }                                
                                </ul>

                                <div  className='border-top'>
                                    <button className='btn btn-sm btn-shadow btn-warning opacity-50 d-flex align-items-center mt-2' 
                                            type='button'
                                            onClick={() => getPlansTrash(e.id)}
                                            disabled={loading}
                                    >
                                        <FontAwesomeIcon icon={faRecycle}/>
                                        <span className='mx-2'>
                                            {t('products.plans_archived_txt')}
                                        </span>
                                        {
                                            loading &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        }
                                    </button>

                                    <ul className='text-muted'>
                                    {
                                        plansTrash !== null &&
                                            plansTrash.length > 0 &&
                                            plansTrash.map((em, im) =>                    
                                            <li className='border-bottom mt-2' key={`plans-${im}`}>
                                                <div className='row align-items-center'>
                                                    <div className='col-sm-8'>

                                                        <p className='mb-0' to={`products/edit/${em.id}`}>
                                                            <b>{t('products.plan_name')}: </b> {em.name}
                                                        </p>
                                                        <p className='mb-0'>
                                                            <b>{t('products.plan_desc')}: </b>{em.description}
                                                        </p>
                                                        <p className='mb-0 text-capitalize'>
                                                            <b>{t('products.duration')}: </b>{em.expiry_time} {em.Time.name}
                                                        </p>
                                                        {
                                                            em.best_value
                                                            ? <p className='mb-0'> <b>{t('products.plans.best')}: </b><FontAwesomeIcon icon={faStar} color="gold"/> </p>
                                                            : <p className='mb-0'>{t('products.plans.normal')}</p>
                                                        }
                                                    </div>
                                                    <div className="col-sm-4 py-4">
                                                        <div className='d-flex w-100 '>
                                                            <div>
                                                                <button 
                                                                    className={"btn btn-info btn-shadow btn-sm rounded-circle"}
                                                                    onClick={() => {
                                                                        restorePlan(em.id)
                                                                    }}
                                                                    disabled={loading}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye}/>
                                                                </button>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </ul>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                                
    </div>
  )
}

export default CardProduct