import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrash, faPenToSquare, faDollar, faPlusCircle, faStore, faCircle, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import CustomLoader from '../layouts/CustomLoader';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import jwt_decode from 'jwt-decode';
import AuthContext from '../../middlewares/AuthContext';
import { useTranslation } from 'react-i18next';
import usePaginationComponentOptions from '../layouts/paginationComponentOptions';
import { DateTime } from 'luxon';
const Stores = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [data, setData]                                   = useState([]);
    const [loading, setLoading]                             = useState(false);
    const [totalRows, setTotalRows]                         = useState(0);
    const [perPage, setPerPage]                             = useState(10);
    const [_search, set_search]                             = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const {t, i18n}                                         = useTranslation()
    const paginationComponentOptions                        = usePaginationComponentOptions();

/***************************************************************************************************************************/
    const getStores = async (page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}stores?page=${page}&per_page=${perPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    }
/***************************************************************************************************************************/
    const handlePageChange = page => {
        getStores(page);
    };
/***************************************************************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const headers = await getHeaders();
        await axios.get(`${url}stores?page=${page}&per_page=${newPerPage}&search=${_search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setPerPage(newPerPage);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });
    };
/***************************************************************************************************************************/
    const setSearch = async(params) => { 

        setLoading(true);
        set_search(params.search);
        const headers = await getHeaders();
        await axios.get(`${url}stores?page=1&per_page=${perPage}&search=${params.search}`, {headers})
                    .then(res =>{
                        setData(res.data.message);
                        setTotalRows(res.data.total);
                        console.log(res.data);
                    })
                    .catch(err =>{
                        console.log(err)
                    })
                    .then(() =>{
                        setLoading(false)
                    });

    }
/***************************************************************************************************************************/
    const deleteMarketing = (params, del=true) => { 

        if(del){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    await axios.delete(`${url}stores/${params}`, {headers})
                        .then(res =>{
                            console.log(res);
                            setData(res.data.message);
                            setTotalRows(res.data.total);
                            setResetPaginationToggle(!resetPaginationToggle)
                            Swal.fire({
                                icon: 'success',
                                title: res?.data?.response,
                            });
                        }).catch(err =>{
                            console.log(err);
                            Swal.fire({
                                icon : 'error',
                                title: err?.response?.data?.response || err,
                                text : err?.response?.data?.message || err,
                            });
                        })
                }
            })
            
        }else{
            Swal.fire({
                icon : 'error',
                title: 'Error',
                text : "You can't delete, because there is a payment",
            })
        }

    }

/***************************************************************************************************************************/
    useEffect(() => {
      getStores(1)
    }, [])
/***************************************************************************************************************************/
    const columns = [
        {
            wrap: true,
            name: t('stores.edit'),
            selector: row => <p className='text-capitalize mb-0'>{row.name}</p>      
        },
        {
            wrap: true,
            name: t('stores.email'),
            selector: row => <p className='mb-0'>{row.email}</p>      
        },
        {
            wrap: true,
            name: t('stores.phone'),
            selector: row => <p className='text-capitalize mb-0'>{row.phone}</p>      
        },
        {
            wrap: true,
            name: 'Whatsapp',
            selector: row => <p className='text-capitalize mb-0'>{row.whatsapp}</p>      
        },
        {
            wrap: true,
            name: t('stores.link'),
            selector: row => <p className='mb-0'>{row.link}</p>      
        },
        {
            wrap: true,
            name: t('stores.image'),
            cell: row => <img src={`${url}store/${row.image}`} className='img-fluid' width={200} />   
        },
        {
            name: t('stores.actions'),
            cell: (row, index, column, id) => {
                return <>      
                        <div className="dropdown mb-2">
                            <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 100}}>
                                <span>{t('stores.actions')}</span>	
                            </a>
                            <ul className="dropdown-menu">
                                <li>                                                                             
                                    <Link 
                                        className="dropdown-item fw-bold" 
                                        to={`edit/${row.id}`} 
                                        onClick={() => console.log(row)}
                                    >
                                        <div className="row">
                                            <div className="col-2">
                                                <FontAwesomeIcon icon={faPenToSquare} className='me-2'/>  
                                            </div>
                                            <div className="col-10">
                                                <span>{t('stores.edit')}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>                                                                             
                                    <Link 
                                        className="dropdown-item fw-bold" 
                                        to={`create/payment`}
                                        state={{store: row.name}}
                                        onClick={() => console.log(row)}
                                    >
                                        <div className="row">
                                            <div className="col-2">
                                                <FontAwesomeIcon icon={faCirclePlus} className="me-2"/>   
                                            </div>
                                            <div className="col-10">
                                                <span>{t('stores.create_pay')}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>                                      
                                    <a           
                                        href="#!"                      
                                        // className={row?.Storepayments.length > 0 ? `disabled dropdown-item fw-bold text-danger opacity-50` :`dropdown-item fw-bold text-danger`}
                                        className={row?.Storepayments.length > 0 ? `prohibed dropdown-item fw-bold text-danger opacity-50` :`dropdown-item fw-bold text-danger`}
                                        onClick={() => {
                                                  row?.Storepayments.length > 0
                                                  ?     deleteMarketing(row.id, false)
                                                  :     deleteMarketing(row.id)
                                                // console.log(row.id)
                                        }}    
                                    >
                                        <div className="row">
                                            <div className="col-2">
                                                <FontAwesomeIcon icon={faTrash} className='me-2'/>
                                            </div>
                                            <div className="col-10">
                                                <span>{t('stores.delete')}</span>
                                            </div>
                                        </div>                                                                         
                                    </a>
                                </li>
                            </ul>
                        </div>                   
                    </>
            },
        },
    ];
/***************************************************************************************************************************/
    const getUrlStore = async(params) => { 
        Swal.fire({
            html: `<div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>`,
            showConfirmButton: false,
        });
        const headers = await getHeaders();
        await axios.get(`${url}stores/get-url/${params}`, {headers})
                    .then(res => {
                        Swal.close();
                        Swal.fire({
                            // icon: 'success',
                            html: `<code>${res.data.message}</code>`,
                            // title: res.data.message, 
                        });
                        console.log(res.data);
                    })
                    .catch(err => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                        console.log(err);
                    })
    }
/***************************************************************************************************************************/
    const ExpandedComponent = ({ data }) => {
        
        return data.Storepayments.length > 0   
            ?   data.Storepayments.map((e,i) => {
                let expiry = ''
                if(e?.expireAt !== null){
                    expiry = DateTime.fromISO(e?.expireAt, {zone: 'utc'}).setLocale(i18n.language).toFormat('yyyy LLLL dd');
                }
                return <div className='row border border-5 border-white-subtle' key={`key-${i}`}>
                        <div className='col-6 border-end'>
                            <div className='w-100 bg-bs-primary text-center'>
                                <span className='text-white-subtle'>{t('stores.info')}</span>
                            </div>
                            <ul className='mt-2'>
                                {
                                    <>
                                        <li className='bg-body-tertiary'><b>Total:</b> ${e?.price || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.email')}:</b> {e ?.email || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.name')}:</b> {e?.name || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.description')}:</b> {e?.description || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.country')}:</b> {e?.country || '-'} - {e?.country_iso || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.expire_time')}:</b> {e?.Time?.name || '-'} {e?.expiry_time || '-'}</li>   
                                    </>
                                }
                            </ul>
                        </div>
                        <div className='col-6'>
                            <div className='w-100 bg-bs-primary text-center'>
                                <span className='text-white-subtle'>{t('stores.info_pay')}</span>
                            </div>
                            <ul className='mt-2'>
                                {
                                    <>
                                        <li className='bg-body-tertiary'><b>Checkout:</b> {e?.cs_checkout_id || '-'}</li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.payment_id')}:</b> {e?.pi_stripe_id || '-'}</li>   
                                        <li className='bg-body-tertiary'>
                                            <b className='me-1'>{t('stores.status')}:</b> 
                                                {
                                                    
                                                    <span className={e?.payment_status === 'paid' ? 'badge text-bg-success text-capitalize' : 'badge text-bg-warning  text-capitalize'}>
                                                        {e?.payment_status || '-'}

                                                    </span>

                                                }
                                            </li>   
                                        <li className='bg-body-tertiary'><b>{t('stores.expire_at')}: </b> 
                                            {expiry || '-'}
                                        </li>
                                        {
                                            e.payment_status === 'open' &&
                                            <li className='list-group-item bg-body-tertiary'>
                                                <button 
                                                    className='btn btn-sm btn-primary'
                                                    onClick={() =>{
                                                        getUrlStore(e.cs_checkout_id)
                                                    }}
                                                >
                                                    <span>
                                                        Get URL
                                                    </span>
                                                </button>
                                            </li>  
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                        
                </div>        
            })
            :   <div className='row border border-5 border-white-subtle'>
                    <div className='col-4'>
                        <div className='w-100 bg-bs-primary text-center'>
                            <span className='text-white-subtle'>.</span>
                        </div>
                        <ul className='mt-2'>                       
                            <li>Not Payments found :(</li>                        
                        </ul>
                    </div>
                </div>
    }   
/***************************************************************************************************************************/
  return (
    <div className='row align-items-center'>
        <div className="col-sm-4 col-md-4 col-lg-3 mb-3">
            <div className="dropdown mb-2">
                <a className="btn btn-sm btn-bd-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width: 150}}>
                    <span>{t('stores.options')}</span>	
                </a>
                <ul className="dropdown-menu w-100">
                    <li>                                                                             
                        <Link 
                            className="dropdown-item fw-bold" 
                            to="create"
                        >
                            <div className="row">
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faStore} className="me-2"/>  
                                </div>
                                <div className="col-10">
                                    <span>{t('stores.add_store')}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li className='d-none'>                                                                             
                        <Link 
                            className="dropdown-item fw-bold" 
                            to="create/payment"
                        >
                            <div className="row">
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>  
                                </div>
                                <div className="col-10">
                                    <span>{t('stores.add_create_pay')}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>                                                                             
                        <Link 
                            className="dropdown-item fw-bold" 
                            to="/payments/admin/stores"
                        >
                            <div className="row">
                                <div className="col-2">
                                    <FontAwesomeIcon icon={faDollar} className="me-2"/>  
                                </div>
                                <div className="col-10">
                                    <span>{t('stores.payments_store')}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
            <div className="col-sm-8 col-md-8 col-lg-9 mb-3">
                <Formik
                    initialValues={{ 
                        search: _search
                    }}
                    onSubmit={(values, actions) => setSearch(values, actions)}
                    enableReinitialize
                >
                <Form>
                    <div className="input-group rounded-pill">
                        <Field 
                            type="text" 
                            className="form-control" 
                            placeholder={t('stores.search')}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            name="search"
                        />                        
                        <button 
                            className="btn btn-outline-secondary" 
                            type="submit" 
                            id="button-addon2"
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </button>
                    </div>
                </Form>
            </Formik>
        </div>

        <div className="col-md-12">
            <div className="card card-shadow">
                <DataTable
                    columns={columns}
                    data={data}
                    fixedHeader
                    fixedHeaderScrollHeight="550px"
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle}
                    pagination
                    paginationPerPage={perPage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    progressComponent={<CustomLoader />}
                    dense
                    responsive
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}             
                />
            </div>
        </div>
    </div>
  )
}

export default Stores