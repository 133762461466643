import React, {useEffect, useState} from 'react'
import axios from 'axios';
import {
	useParams,
} from "react-router-dom";
const VerifyEmail = () => {
    const { email, id, token_verified } = useParams();
    const [loading, setloading]         = useState(false);
    const [msg, setmsg]                 = useState({});
/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/************************************************************************************************************************/
    useEffect(() => {
        
        const first = async () => { 
            await axios.get(`${url}auth/verify-email/${email}/${id}/${token_verified}`)
                       .then(res =>{
                            console.log(res)
                            setmsg(res.data)
                       }).catch(err =>{
                            console.log(err.response.data);
                            setmsg(err.response.data)
                       }).then(() =>{
                            setloading(true);
                       });                       
        }

        first()

        console.log(email)
        console.log(id)
        console.log(token_verified)

    }, []);
    

    return (

        <>
            {
                !loading 
                ? <div className='row mt-3 justify-content-center'>
                    <div className="col-sm-8 col-md-6-col-lg-6">
                        <div className="card" aria-hidden="true">
                            <div className="card-body">
                                <h5 className="card-title placeholder-glow">
                                    <span className="placeholder col-6"></span>
                                </h5>
                                <p className="card-text placeholder-glow">
                                    <span className="placeholder col-7"></span>
                                    <span className="placeholder col-4"></span>
                                    <span className="placeholder col-4"></span>
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>   
                :
                    // (Object.keys(msg).length === 0 && msg.constructor === Object)
                    (Object.keys(msg).length !== 0 && msg.constructor === Object) &&
                    <div className='row mt-3 justify-content-center'>
                        <div className="col-sm-8 col-md-6-col-lg-6">
                        {
                            msg?.count === 1 &&
                            <>
                                <h1>Error</h1>
                                <p>Uri URL has invalid</p>
                            </>
                        }
                        {
                            msg?.count === 2 &&
                            <>
                                <h1>Warning</h1>
                                <p>{msg.response}</p>
                            </>
                        }
                        {
                            msg?.count === 3 &&
                            <>
                                <h1>Warning</h1>
                                <p>{msg.response}</p>
                                <p>{msg.message}</p>
                            </>
                        }
                        {
                            msg?.count === 4 &&
                            <>
                                <h1>Success</h1>
                                <p>{msg.response}</p>
                                <p>{msg.message.email}</p>
                            </>
                        }
                        </div>
                    </div>
            }
            
        </>
        
    )
}

export default VerifyEmail