import React, {useEffect, useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-solid-svg-icons'
import utilities from '../../utilities/utilities';
import CartContext from '../../middlewares/cart/CartContext';
import AuthContext from '../../middlewares/AuthContext';
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
const Checkout = () => {
	const { token, getHeaders }   = useContext(AuthContext);
    const tokenDecode = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
/***************************************************************************************************************************/
	const { t }                       = useTranslation();
	const { cart }                    = useContext(CartContext);
	const [price, setprice]           = useState(0);
	const [loading, setloading]       = useState(false);
	const [charge, setcharge]         = useState(false);
	const [email, setemail]           = useState('');
	const [disabled, setDisabled]     = useState(false);
	const [isInMexico, setIsInMexico] = useState(false);

/***********************************************************************************************************************************/
	const getUserLocation = async() => {
		// Verifica si el navegador soporta la geolocalización
		const pase = await axios.get(`${url}api/location`)
					.then(res => {
						const {country, countryIso} = res.data;
						if(country.toLowerCase() === 'mexico' || countryIso.toLowerCase() === 'mx'){
							setIsInMexico(true);
							return true;
						}else{
							setIsInMexico(false);
							return false;
						}
					}).catch(err => {
						setIsInMexico(false);
						console.log(err)
						return false
					});

					return pase;
	};
/***************************************************************************************************************************/
	const first = async() => {
			let   x = 0;
			const y = await getUserLocation();
			if(y){
				cart.forEach(element => {
					x+= parseFloat(element.quantity * parseFloat(element.product.price_mxn) ) 
				})
			}else{
				cart.forEach(element => {
					x+= parseFloat(element.quantity * parseFloat(element.product.price) ) 
				})
			}
			
			console.log(x);
			setprice(x);			
	}
/***************************************************************************************************************************/

	const checkout = async (params) => {
		setcharge(true);
		params.products = cart;
		console.log(params);
		const headers = await getHeaders();
		// return;
		await axios.post(`${url}payments`, params, {headers})
			.then(res =>{
				console.log(res);
				console.log(res.data);
				if(res.status === 200 && res.data.status === 'success'){
					window.location.href = res.data.url;
				}
			})
			.catch(err =>{
				Swal.fire({
					icon: 'error',
					title: err?.response?.data?.response || err,
					text: err?.response?.data?.message || err,
				})
				console.log(err);
				setcharge(false);
			});
	}

/***************************************************************************************************************************/

	useEffect(() => {
		setloading(true);
		if(tokenDecode !== null){
			if(tokenDecode.roles.find(x => x.name === 'customer') !== undefined){
				setemail(tokenDecode.email);
				setDisabled(true);
			}
		}
		
	}, [])
	

/***************************************************************************************************************************/

	useEffect(() => {		
		first();
	}, [cart])
/*************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required()
    });
/*************************************************************************************************************************/

  return (
		<div className='container'>
			<div className="pt-5 pb-4 text-center">
				{
					cart.length !== 0
					? <h2 className='display-6 text-success fw-bold'>{t('checkout.choice')}</h2>
					: <h2 className='display-6  fw-bold'> 
						<FontAwesomeIcon icon={faSadTear}/>
					</h2>

				}
			</div>

			<div className="row g-5">
				{
					!loading
					?
						<>
						{
							[{md: 'col-md-5', lg: 'col-lg-4', order: 'order-md-last', mb: ''},
							{md: 'col-md-7', lg: 'col-lg-8', order: '', mb: 'mb-5'}].map((e,i) => {
							return <div className={`${e.md} ${e.lg} ${e.order} ${e.mb} animate__animated animate__fadeIn`} key={`arr-${i}`}>
								<div className="card h-100 text-bg-light" aria-hidden="true">
									<div className="card-header">
										<p className="placeholder-glow">
											<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										</p>
									</div>
									<div className="card-body">
										<h5 className="card-title placeholder-glow">
											<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										</h5>
										<p className="card-text placeholder-glow">
										<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										<span className={`placeholder col-${utilities.getRandomInt(1,11)}`}></span>
										</p>
									</div>
									<div className="card-footer">
										<a href="#!" tabIndex="-1" className="btn btn-bd-primary disabled placeholder col-6"></a>
									</div>
										
								</div>
							</div> 
							})
						}
						</>
					:
					<>
					{
						cart.length !== 0	
						?
						<>
						<div className="col-md-5 col-lg-5 order-md-last animate__animated animate__fadeIn mb-4">
							<div className='border rounded card-shadow p-3'>
								<h4 className="d-flex justify-content-between align-items-center mb-3">
									<span className="text-success">{t('checkout.your_cart')}</span>
									<span className="badge bg-success rounded-pill">
										{
											cart.reduce((accumulator, currentValue) =>
												accumulator + currentValue.quantity, 0
											)
										}
									</span>
								</h4>
								<ul className="list-group mb-3">
									{
										cart.map((_product,i) =>{
											return <li className="list-group-item lh-sm" key={`cart-${i}`}>
												<div className='d-flex justify-content-between'>
													<div>
														<h5 className="my-0"><b>{_product.product.product_name}</b></h5>
														<h6 className="my-0">{_product.product.name}</h6>
														<small className="text-muted">{_product.product.description}</small>
													</div>
													{
														isInMexico
														?	<span className="text-muted">
																${parseFloat(_product.product.price_mxn).toFixed(2)} X {_product.quantity} = ${(parseFloat(_product.product.price_mxn).toFixed(2) * _product.quantity).toFixed(2)} MXN
															</span>
														:	<span className="text-muted">
																${parseFloat(_product.product.price).toFixed(2)} X {_product.quantity} = ${(parseFloat(_product.product.price).toFixed(2) * _product.quantity).toFixed(2)} USD
															</span>
													}
													

												</div>
												<div className='d-flex justify-content-between mt-2'>
													<span className='fw-bold'>{t('checkout.items')}: </span>
													<span className='badge text-body-emphasis border border-success rounded-pill'>{_product.quantity}</span>

												</div>
											</li>									
										})

									}
									<li className="list-group-item d-flex justify-content-between bg-light-subtle">

									{
										isInMexico
										?	<span className='fw-bold'>Total (MXN)</span>
										:	<span className='fw-bold'>Total (USD)</span>
									}
										<strong>${parseFloat(price).toFixed(2)}</strong>
										
									</li>
								</ul>

							</div>
						</div>

						
						<div className="col-md-7 col-lg-7 mb-5 animate__animated animate__fadeIn">
							<div className='border rounded p-3 card-shadow'>
								<h4 className="mb-3">{t('checkout.email_send')}</h4>
								<Formik
										initialValues={{ 
											email: email, 
										}}
										validationSchema={formSchema}
										onSubmit={(values, actions) => checkout(values, actions)}
									>
									<Form className="needs-validation" noValidate>
										<div className="row g-3">

											<div className="col-12">
												{
													token !== null &&
													(tokenDecode.roles.find(x => x.name === 'customer') !== undefined) &&
													<p className='text-success fw-bold mb-0 text-uppercase'>You are currently logged in</p>
												}
												<label htmlFor="email" className="form-label">{t('checkout.email_ob')}</label>
												<Field 
													type="email" 
													className="form-control" 
													id="email" 
													placeholder="email@example.com" 
													name="email"
													disabled={disabled}
												/>
												<ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>

											</div>

										</div>
										<hr className="my-4" />

										
										<button 
											className="w-100 btn btn-bd-primary btn-shadow btn-lg" 
											type="submit"
											disabled={charge}
										>
											<span className='me-3'>
												{t('checkout.continue')}
											</span>
											{
												charge &&
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											}
										</button>
									</Form>
								</Formik>
							</div>
						</div>
					</>
					:
					<>
						<div className="col-md-12 mb-5 animate__animated animate__fadeIn">
							<div className='border rounded p-3 card-shadow'>
								<h1 className='text-center'>
									No hay artículos en el carrito.
								</h1>
							</div>
						</div>
					</>
					}
				</>
				}
			</div>
		</div>
  )
}

export default Checkout