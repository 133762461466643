import React, {useEffect, useState} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
	useParams,
    useNavigate,
} from "react-router-dom";
import Swal from 'sweetalert2';

const UpdatePassword = () => {
/*************************************************************************************************************************/
const { t }                  = useTranslation();
const { email, passwordkey } = useParams();
const [loading, setloading]  = useState(false);
const [msg, setmsg]          = useState({});
let   navigate               = useNavigate();

/************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/************************************************************************************************************************/
    useEffect(() => {
        
        const first = async () => { 
            await axios.get(`${url}auth/update-password/${email}/${passwordkey}`)
                        .then(res =>{
                            console.log(res)
                            setmsg(res.data)
                        }).catch(err =>{
                            console.log(err.response.data);
                            setmsg(err.response.data)
                        }).then(() =>{
                            setloading(true);
                        });                       
        }

        first()

    }, []);
/*************************************************************************************************************************/

    const updatePassword = async (params) => {
        await axios.post(`${url}auth/update-password/${email}/${passwordkey}`, params)
                        .then(res =>{
                            console.log(res)                            
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: res?.data?.response,
                            });
                            navigate('/login');
                        }).catch(err =>{
                            console.log(err.response.data);
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: err?.response?.data?.response,
                            })
                        }).then(() =>{
                            setloading(true);
                        });     
    }

/*************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&.¡,+¿()]+/, "One special character [@$!%*#?&.¡,-+¿()]")
            .matches(/\d+/, "One number")
            .required(),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
/*************************************************************************************************************************/
    return (
        <>
        {
            !loading 
            ? <div className='row mt-3 justify-content-center'>
                <div className="col-sm-8 col-md-6-col-lg-6">
                    <div className="card" aria-hidden="true">
                        <div className="card-body">
                            <h5 className="card-title placeholder-glow">
                                <span className="placeholder col-6"></span>
                            </h5>
                            <p className="card-text placeholder-glow">
                                <span className="placeholder col-7"></span>
                                <span className="placeholder col-4"></span>
                                <span className="placeholder col-4"></span>
                                <span className="placeholder col-6"></span>
                                <span className="placeholder col-8"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>   
            :
                // (Object.keys(msg).length === 0 && msg.constructor === Object)
                (Object.keys(msg).length !== 0 && msg.constructor === Object) &&
                <div className='row mt-3 justify-content-center'>
                    <div className="col-sm-8 col-md-6-col-lg-6">
                    {
                        msg?.message 
                        ?   <>
                                <main className='my-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                                    <Formik
                                            initialValues={{ 
                                                password: '',
                                                passwordConfirm: '',
                                            }}
                                            validationSchema={formSchema}
                                            onSubmit={(values, actions) => updatePassword(values, actions)}
                                        >
                                            <Form>
                                            <h1 className="h3 mb-3 mt-2 fw-normal text-center text-capitalize">{t('login.update_password')}</h1>

                                            <div className='mb-3'>
                                                <div className="form-floating">
                                                    <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                                    <label htmlFor="floatingPassword">{t('login.password')}</label>
                                                </div>
                                                <ErrorMessage name="password" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                            </div>

                                            <div className="mb-3">
                                                <div className="form-floating">
                                                    <Field type="password" className="form-control" id="floatingPassword2" placeholder="Confirm Password" name="passwordConfirm"/>
                                                    <label htmlFor="floatingPassword2">{t('login.pass_confirm')}</label>
                                                </div>
                                                <ErrorMessage name="passwordConfirm" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                            </div>


                                            <button className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" type="submit">{t('login.update_password')}</button>                                            
                                            <p className="mt-5 mb-0 text-muted">&copy; 2017–2022</p>
                                        </Form>
                                    </Formik>
                                </main>
                            </>
                        :   <>
                                <main className='my-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow text-center'>
                                    <h1 className='text-capitalize'>{msg.status}</h1>
                                    <p>{msg.response}</p>
                                    
                                    <button className='btn btn-lg btn-bd-primary btn-shadow fw-bold' onClick={() => navigate('/')}>
                                            <span>
                                                Return
                                            </span>
                                    </button>

                                    
                                </main>

                            </>
                    }
                    </div>
                </div>
        }
        
    </>
    )
}

export default UpdatePassword