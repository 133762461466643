import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
const MaintenancePage = ({pos_date, pos_maintenance}) => {

    console.log(pos_maintenance)
    const {t, i18n} = useTranslation();
    const dt  = new Date(pos_date)
    const d   = DateTime.fromJSDate(dt, {zone: 'utc'}).setLocale(i18n.language).toFormat('yyyy LLLL dd - HH:mm:ss');
    return (
        <div className='container'>
            <div className='mt-3'>
                <div className='text-center'>
                    <img src='https://labeldictate.com/assets/img/Label-Dictate-Logo-V2021.png' className='mb-2' width={250} />
                    <h1>{t('maintenance.h1')}</h1>
                </div>
                <p>{t('maintenance.p1')}</p>
                <p>{t('maintenance.p2')} <a href="mailto:contact@labeldictate.com">contact@labeldictate.com</a></p>
                <p>{t('maintenance.hour')} <b>{d} hrs</b> - (UTC)</p>
                <div className='text-center'>
                    {/* <img src='https://miro.medium.com/v2/resize:fit:1054/1*hJYATDzbTPR2mQdW0jL_dA.gif' width={400} /> */}
                    <img className='mt-2' src='https://i.pinimg.com/originals/cf/6f/cf/cf6fcf14be2cd01dd4923b36445ca632.gif' width={400} />
                    
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;