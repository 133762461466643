import React,{useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faStar, faEye } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2'
import AuthContext from '../../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const CardProduct = ({product:e, i, url, setProducts}) => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
    const {t}                 = useTranslation()
/********************************************************************************************************/
    const restoreProduct = async (params) => {
        console.log(params)
        Swal.fire({
            title: t('products.restore_product'),
            text: t('products.restore_product_txt'),
            showCancelButton: true,
            confirmButtonText: t('products.restore'),
            customClass: {
                confirmButton: 'btn btn-outline-danger',
                cancelButton: 'btn btn-primary mx-2'
            },
            buttonsStyling: false,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const headers = await getHeaders();
                    console.log(headers)
                    await axios.put(`${url}products/restore/${params}`, {}, {headers})
                                .then(res =>{
                                    setProducts(res.data.message)
                                    Swal.fire({
                                        icon: 'success',
                                        title: res.data.response,
                                    });
                                    console.log(res);
                                })
                                .catch(err =>{
                                    Swal.fire({
                                        icon: `${err?.response?.data?.status}` || 'error',
                                        title: err?.response?.data?.message || err,
                                    });
                                    console.log(err?.response?.data);
                                });
                            }
                        })
    }


  return (
     <div className='row w-100 mt-2'>
        <div className="col-12">
            <div className="card mb-3 card-shadow">
                <div className="row g-0">
                    <div className="col-md-4 d-flex  justify-content-center bg-body-secondary">
                        <img src={`${url}images/${e.image}`} className="img-fluid rounded-start p-1" alt={`${e.name}`} style={{height: 150}}/>
                    </div>
                    <div className="col-md-8">
                        <div className='pt-1 px-3'>
                            <div className='d-flex w-100 justify-content-start justify-content-md-end'>
                                <div >
                                    <button 
                                        className={"btn btn-info btn-shadow btn-sm rounded-circle"}
                                        onClick={() => {
                                            restoreProduct(e.id)
                                        }}    
                                    >
                                        <FontAwesomeIcon icon={faEye}/>
                                    </button>
                                </div> 
                            </div>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title fw-bold">{e.name}</h5>
                            <p className="card-text">{e.description}</p>
                            <a href="#!" className="btn btn-outline-success w-100" role="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`}>
                                <span className='card-text me-2'>
                                    {t('products.plans_title')}
                                </span>
                                <span>
                                    <FontAwesomeIcon icon={faCaretDown}/>
                                </span>
                            </a>

                            
                            
                            <ul className="collapse mt-2 w-100" id={`collapse-${i}`}>
                                {
                                    e.Plans.map((el, il) =>
                                        <li className='border-bottom mt-2' key={`plans-${il}`}>
                                            <div className='row align-items-center'>
                                                <div className='col-sm-8'>

                                                    <p className='mb-0' to={`products/edit/${el.id}`}>
                                                        <b>{t('products.plan_name')}: </b> {el.name}
                                                    </p>
                                                    <p className='mb-0'>
                                                        <b>{t('products.plan_desc')}: </b>{el.description}
                                                    </p>
                                                    <p className='mb-0 text-capitalize'>
                                                        <b>{t('products.duration')}: </b>{el.expiry_time} {el.Time.name}
                                                    </p>
                                                    {
                                                        el.best_value
                                                        ? <p className='mb-0'> <b>{t('products.plans.best')}: </b><FontAwesomeIcon icon={faStar} color="gold"/> </p>
                                                        : <p className='mb-0'>{t('products.plans.normal')}</p>
                                                    }
                                                </div>
                                                <div className="col-sm-4 py-4">
                                                    <div className='d-flex w-100 '>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>                                
    </div>
  )
}

export default CardProduct