import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
	const {t} = useTranslation()
	return (
	  
  	<main>
	  <div className="px-4 py-5 border-bottom border-5 border-secondary">
		  <h1 className="display-1 text-center fw-bold">{t("privacy_policy.policy")} <span className='text-bs-primary'>{t("privacy_policy.privacy")}</span></h1>
		  <div className="col-lg-10 mx-auto">

				<ul className="list-group list-group-flush  text-justify">
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title1")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text1")}</li>
					
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title2")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text2")}</li>
					
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title3")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text3")}</li>
					  
					<ul className="list-group list-group-flush ms-5">
						<li className="list-group-item border-0 mb-0 pb-0">{t("privacy_policy.text3_1")}</li>
						<li className="list-group-item border-0 mb-0 pb-0">{t("privacy_policy.text3_2")}</li>
						<li className="list-group-item border-0 mb-0 pb-0">{t("privacy_policy.text3_3")}</li>
						<li className="list-group-item border-0 mb-0 pb-0">{t("privacy_policy.text3_4")}</li>
						<li className="list-group-item border-0 mb-0 pb-0">{t("privacy_policy.text3_5")}</li>

					</ul>
					<li className="list-group-item border-0">{t("privacy_policy.text3_5_1")} </li>
					
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title4")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text4")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text4_1")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text4_2")}</li>
															
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title5")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text5")}</li>

					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title6")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text6")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text6_1")}</li>
					
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title7")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text7")}</li>

					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title8")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text8")}</li>
					
					<li className="list-group-item border-0 fw-bold">{t("privacy_policy.title9")}</li>
					<li className="list-group-item border-0">{t("privacy_policy.text9")}</li>					  

				</ul>

		  </div>
	  </div>
  </main>	
  )
}

export default TermsAndConditions