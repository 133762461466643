import React, {useEffect, useState} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import './App.css';
import './scss/custom.css';
import './sidebar.css';
import 'animate.css';
import {
	Routes,
	Route,
} from "react-router-dom";
import axios from 'axios';
import AuthProvider from './middlewares/AuthProvider';
import CartProvider from './middlewares/cart/CartProvider';
import NoMatch from './views/layouts/NoMatch';
import Main from './views/layouts/Main';
import Home from './views/home/Home';
import Login from './views/home/login/Login';
import Signup from './views/home/login/Signup';
import Product from './views/home/Product';
import Checkout from './views/home/Checkout';
import AboutUs from './views/home/AboutUs';
import ContactUs from './views/home/ContactUs';
import FAQS from './views/home/FAQS';
import PrivacyPolicy from './views/home/PrivacyPolicy';
import TermsAndConditions from './views/home/TermsAndConditions';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from './views/home/Header';
import Dashboard from './views/layouts/Dashboard';
import Contentdos from './views/layouts/Contentdos';
import RecoveryPassword from './views/home/login/RecoveryPassword';
import VerifyEmail from './views/home/login/VerifyEmail';
import UpdatePassword from './views/home/login/UpdatePassword';
import Users from './views/users/Users';
import EditUser from './views/users/EditUser';
import CreateUser from './views/users/CreateUser';
import Partners from './views/partners/Partners';
import CreatePartner from './views/partners/CreatePartner';
import EditPartner from './views/partners/EditPartner';
import ProductsPlans from './views/products-plans/ProductsPlans';
import CreateProduct from './views/products-plans/products/CreateProduct';
import EditProduct from './views/products-plans/products/EditProduct';
import EditPlan from './views/products-plans/plans/EditPlan';
import CreatePlan from './views/products-plans/plans/CreatePlan';
import RestoreProduct from './views/products-plans/products/RestoreProduct';
import Licenses from './views/licenses/Licenses';
import _Link from './views/licenses/Link';
import ProtectedRoute from './middlewares/protects/ProtectRoute';
import Payments from './views/payments/Payments';
import Customer from './views/payments/Customer';
import PaymentsPartner from './views/payments/Partners';
import PaymentsMarketing from './views/payments/Marketings';
import Admin from './views/payments/Admin';
import CreatePackages from './views/packages/CreatePackages';
import Package from './views/packages/Packages';
import ShowPackage from './views/packages/ShowPackage';
import EditLicense from './views/licenses/EditLicense';
import Marketings from './views/marketings/Marketings';
import CreateMarketing from './views/marketings/CreateMarketing';
import Advertising from './views/marketings/Advertising';
import EditMarketing from './views/marketings/EditMarketing';
import CreateAdvertising from './views/marketings/CreateAdvertising';
import packageJson from '../package.json';
import Stores from './views/stores/Stores';
import CreateStore from './views/stores/CreateStore';
import CreateStorePayment from './views/stores/CreateStorePayment';
import EditStore from './views/stores/EditStore';
import Reports from './views/reports/Reports';
import MaintenancePage from './views/layouts/MaintenancePage'
import ScrollToTop from './utilities/ScrollToTop';
function App() {
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
	// const isUnderMaintenance = (process.env.REACT_APP_MAINTENANCE?.toLowerCase?.() === 'true');
	const [pos_maintenance, setPos_maintenance] = useState(null);
	const [pos_date, setPos_date]               = useState(null);
	const [pre_maintenance, setPre_maintenance] = useState(null);
	const [pre_date, setPre_date]               = useState(null);
	  //Dark modes
	useEffect(() => {
		const first = async() => { 
			try {
				const dark_mode = await AsyncStorage.getItem('@dark_mode');
				if(dark_mode !== null) {
					// value previously stored
					if (dark_mode === 'light') {
						document.documentElement.setAttribute('data-bs-theme','light')
						await AsyncStorage.setItem('@dark_mode', 'light')
					}
					else {
						document.documentElement.setAttribute('data-bs-theme','dark')
						await AsyncStorage.setItem('@dark_mode', 'dark')
					}
				}else{
					await AsyncStorage.setItem('@dark_mode', 'light')
					document.documentElement.setAttribute('data-bs-theme','light')
				}
			  
			}catch (e) {
			  console.log(e)
			}	
		}//end first
		const second = async() => { 
			await axios.get(`${urlapi}maintenance`)
						.then(res =>{
							// const {pre_maintenance, pre_date, pos_maintenance, pos_date} = res.data.message;
        					setPos_maintenance(res?.data.message?.pos_maintenance?.toLowerCase() === 'true');
        					setPos_date(res?.data?.message.pos_date);
        					setPre_maintenance(res?.data?.message?.pre_maintenance?.toLowerCase() === 'true');
        					setPre_date(res?.data?.message?.pre_date);
						})
						.catch(err =>{
							console.error(err)
							console.error(err?.response?.data)
						})
		}
		first();
		second();
	}, []);

	if (pos_maintenance === null) {
		// Loading component first time
		return (
			<div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
			  <div className="spinner-grow text-secondary" role="status" style={{width: "3rem", height: "3rem"}}>
				<span className="visually-hidden">Loading...</span>
			  </div>
			</div>
		);
	}
	//Delete all console log
	// if (process.env.NODE_ENV === 'production') { console.log = () => {} }
	if (process.env.REACT_APP_ENV === 'production') { console.log = () => {} }
	
	return (
		<>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_PUBLIC}
				scriptProps={{ async: true, defer: true, appendTo: 'body' }}
			>
				<AuthProvider >
					<CartProvider >
						<ScrollToTop/>
						<Routes>
								{
									pos_maintenance ? (
										<Route path="*" element={<MaintenancePage 
														pos_maintenance={pos_maintenance}
														pos_date={pos_date}
										/>} />										
									) : (
								<>
								{/* Home not login  */}
								<Route element={ <Header/>}>
									<Route path="/" element={ <Home/>}/>									
									<Route path="/login" element={ <Login/>}/>
									<Route path="/signup" element={ <Signup/>}/>
									<Route path="/recovery-password" element={ <RecoveryPassword/>}/>
									<Route path="/verify-email/:email/:id/:token_verified" element={ <VerifyEmail/>}/>
									<Route path="/update-password/:email/:passwordkey" element={ <UpdatePassword/>}/>
									<Route path="/products/:product" element={<Product/>}/>
									<Route path="/checkout" element={ <Checkout/>}/>
									<Route path="/about-us" element={ <AboutUs/>}/>
									<Route path="/contact-us" element={ <ContactUs/>}/>
									{/* <Route path="/faqs" element={ <FAQS/>}/> */}
									<Route path="/privacy-policy" element={ <PrivacyPolicy/>}/>
									<Route path="/terms-and-conditions" element={ <TermsAndConditions/>}/>
								</Route>

								{/* All aplcation login  */}
								<Route path="dashboard" element={ <Main/>}>
									<Route path="" element={ <Dashboard/>}/>
									<Route path="content" element={ <Contentdos/>}/>
								</Route>

								{/* USERS */}
								<Route path="users" element={ 
									<ProtectedRoute
											roles={['admin']}
										>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Users/>}/>
									<Route path="create" element={ <CreateUser/>}/>
									<Route path="edit/:id" element={ <EditUser/>}/>
								</Route>

								{/* STORES */}
								<Route path="stores" element={ 
									<ProtectedRoute
											roles={['admin']}
										>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Stores/>}/>
									<Route path="create" element={ <CreateStore/>}/>
									<Route path="create/payment" element={ <CreateStorePayment/>}/>
									<Route path="edit/:id" element={ <EditStore/>}/>
								</Route>

								{/* PARTNERS */}
								<Route path="partners" element={ 
									<ProtectedRoute
										roles={['admin']}
									>
										<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Partners/>}/>
									<Route path="create" element={ <CreatePartner/>}/>
									<Route path="edit/:id" element={ <EditPartner/>}/>
								</Route>			

								{/* MARKETINGS */}
								<Route path="marketings" element={ 
									<ProtectedRoute
										roles={['admin']}
									>
										<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Marketings/>}/>
									<Route path="create" element={ <CreateMarketing/>}/>
									<Route path="edit/:id" element={ <EditMarketing/>}/>
									<Route path="advertising/:id" element={ <Advertising/>}/>
								</Route>								

								{/* PRODUCTS & PLANS */}
								<Route path="products-plans" element={ 
									<ProtectedRoute
											roles={['admin']}
										>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <ProductsPlans/>}/>
									<Route path="products/create" element={ <CreateProduct/>}/>
									<Route path="products/edit/:id" element={ <EditProduct/>}/>
									<Route path="products/restore" element={ <RestoreProduct/>}/>
									<Route path="plans/create/:id" element={ <CreatePlan/>}/>
									<Route path="plans/edit/:id" element={ <EditPlan/>}/>
								</Route>

								{/* PAYMENTS */}
								<Route path="payments" element={ 
									<ProtectedRoute roles={['admin', 'partner', 'customer', 'marketing']}>
										<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ 
										<ProtectedRoute roles={['admin']}>
											<Payments/>
										</ProtectedRoute>
									}/>
									<Route path="admin/:usertypes" element={ 
										<ProtectedRoute roles={['admin']}>
											<Admin/>
										</ProtectedRoute>
									}/>
									<Route path="customer" element={ 
										<ProtectedRoute roles={['customer']}>
											<Customer/>
										</ProtectedRoute>
									}/>
									<Route path="partner" element={ 
										<ProtectedRoute roles={['partner']}>
											<PaymentsPartner/>
										</ProtectedRoute>
									}/>
									<Route path="marketing" element={ 
										<ProtectedRoute roles={['marketing']}>
											<PaymentsMarketing/>
										</ProtectedRoute>										
									}/>
								</Route>

								{/* REPORTS */}
								<Route path="reports" element={ 
									<ProtectedRoute roles={['admin', 'partner', 'subpartner']}>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Reports/>}/>
									<Route path="create" element={<CreatePackages/>}/>
									<Route path="show/:serial_number" element={ <ShowPackage/>}/>
								</Route>

								{/* PACKAGES */}
								<Route path="packages" element={ 
									<ProtectedRoute roles={['admin', 'partner', 'subpartner']}>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Package/>}/>
									<Route path="create" element={<CreatePackages/>}/>
									<Route path="show/:serial_number" element={ <ShowPackage/>}/>
								</Route>

								{/* LICENSES */}
								<Route path="licenses" element={ 
									<ProtectedRoute roles={['admin', 'partner', 'subpartner', 'customer']}>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="" element={ <Licenses/>}/>
									<Route path="edit/:id" element={ <EditLicense/>}/>
									<Route path="link" element={ <_Link/>}/>
									<Route path="create" element={<CreatePackages/>}/>
								</Route>

								{/* USER MARKETING */}
								<Route path="marketing" element={ 
									<ProtectedRoute roles={['marketing']}>
											<Main/>										
									</ProtectedRoute>
								}>
									<Route path="advertising/:id" element={ <Advertising/>}/>
									<Route path="advertising/create" element={ <CreateAdvertising/>}/>
								</Route>

								{/* Route not found  */}
								<Route path="*" element={<NoMatch /> } />
								</>
							)}						
							</Routes>
					</CartProvider>
				</AuthProvider>
			</GoogleReCaptchaProvider>
		</>
	);
}

export default App;
