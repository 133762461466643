import React, {useEffect, useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import CustomLoader from '../layouts/CustomLoader';
import Swal from 'sweetalert2'
import jwt_decode from 'jwt-decode';
import AuthContext from '../../middlewares/AuthContext';

const EditUser = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const [user, setUser]                   = useState({});
    const [roles, setroles]                 = useState([]);
    const [partners, setpartners]           = useState([]);
    const [marketings, setmarketings]       = useState([]);
    const [showPartner, setShowPartner]     = useState(false);
    const [showMarketing, setShowMarketing] = useState(false)
    const [initialValues, setinitialValues] = useState({});
    const { id }                            = useParams();
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const [charge, setCharge]   = useState(false);
    const [loading, setloading] = useState(false);

/***************************************************************************************************************************/
    const first = async () => {
        const headers = await getHeaders();
        await axios.get(`${url}users/show/${id}`, {headers})
            .then(res =>{
                setUser(res.data.message);
                setroles(res.data.roles);
                const partner   = (res.data.message.PartnerId === null) ? 0 : res.data.message.PartnerId;
                const marketing = (res.data.message.MarketingId === null) ? 0 : res.data.message.MarketingId;
                const role      = (res.data.message?.Roles[0] === undefined) ? 0 : res.data.message?.Roles[0]?.id;
                setinitialValues({ 
                    email          : res.data.message.email,
                    password       : '',
                    passwordConfirm: '',
                    partner        : partner,
                    marketing      : marketing,
                    role           : role,
                })
                console.log(res.data.message);
            })
            .catch(err =>{
                console.log(err);
            });   
    }    

    const second = async () => { 
        const headers = await getHeaders();
        await axios.get(`${url}partners`, {headers})
            .then(res =>{
                setpartners(res.data.message)
                console.log(res.data);
            })
            .catch(err =>{
                console.log(err);
            })
            .then(() =>{
                setCharge(true);
            });

        await axios.get(`${url}marketings/all`, {headers})
            .then(res =>{
                setmarketings(res.data.message)
                console.log(res.data);
            })
            .catch(err =>{
                console.log(err);
            })
            .then(() =>{
                setCharge(true);
            })
    }

    useEffect(() => { 
        first();
        second();
    }, [])
    
/***************************************************************************************************************************/

const AutoSubmit = () => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext();
    useEffect(() => {
        console.log(values)
        setShowPartner( parseInt(values?.role) === 2 );
        setShowMarketing( parseInt(values?.role) === 4 );
        // (parseInt(values?.role) === 2) ? setShowPartner(true) : setShowPartner(false)
        
    }, [values.role]);

    return null;
};
/***************************************************************************************************************************/

    const updateUser = async (params) => { 
        
        console.log(params);
        const headers = await getHeaders();
        await axios.put(`${url}users/update/${id}`, params, {headers})
                   .then(res =>{
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response,
                        });
                   });  
    }


/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        password: Yup.string()
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&.¡,+¿()]+/, "One special character")
            .matches(/\d+/, "One number"),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        role: Yup.number()
            .min(1, 'Select a role')
            .required(),
        partner: Yup.number(),
        marketing: Yup.number(),
    });
/***************************************************************************************************************************/
    return (
        <div className='container mb-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-8 col-md-8 col-lg-6'>
                    <main className='mt-5 p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>
                        {
                            charge 
                            ?   <Formik
                                    initialValues={initialValues}
                                    validationSchema={formSchema}
                                    onSubmit={(values, actions) => updateUser(values, actions)}
                                    enableReinitialize
                                >
                                    <Form>
                                    <AutoSubmit/>
                                    <h1 className="h3 mb-3 fw-bold text-center">
                                        <FontAwesomeIcon icon={faUserPen}/>
                                        <p>{t('users.user_edit')}</p>
                                    </h1>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                            <label htmlFor="floatingInput">{t('login.email')}</label>
                                        </div>
                                        <ErrorMessage name="email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>


                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                            <label htmlFor="floatingPassword">{t('login.password')}</label>
                                        </div>
                                        <ErrorMessage name="password" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="password" className="form-control" id="floatingPassword2" placeholder="Confirm Password" name="passwordConfirm"/>
                                            <label htmlFor="floatingPassword2">{t('login.pass_confirm')}</label>
                                        </div>
                                        <ErrorMessage name="passwordConfirm" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="role">
                                            <option value={0} disabled className="text-capitalize">{t('users.create.select_an_option')}</option>
                                            {
                                                roles.map((e,i) =>{
                                                    return <option value={e.id} key={`option-${i}`} className="text-capitalize">{t(`users.${e.name}`)}</option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage name="role" component="div" className='field-error text-warning fw-bold text-capitalize'/>
                                    </div>
                                    {
                                        charge &&
                                            <div className={showPartner ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg text-capitalize" aria-label="Default select example" name="partner">
                                                    <option value={0} disabled className="text-capitalize">{t('users.create.select_an_option')}</option>
                                                    {
                                                        partners.map((e,i) =>{
                                                            return <option value={e.id} key={`option2-${i}`} className="text-capitalize">{e.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="partner" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>
                                    }
                                    {
                                        charge &&
                                            <div className={showMarketing ? "mb-3" : "d-none"}>
                                                <Field as="select" className="form-select form-select-lg" aria-label="Default select example" name="marketing">
                                                    <option value={0} disabled>{t('users.create.select_an_option')}</option>
                                                    {
                                                        marketings.map((e,i) =>{
                                                            return <option value={e.id} key={`option3-${i}`} className="text-capitalize">{e.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="marketing" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                            </div>
                                    }
                                    <button 
                                        className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                        type="submit"
                                        disabled={loading}
                                    ><span className='me-3 fw-bold'>
                                        {t('users.update')}
                                    </span>
                                    {
                                        loading &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    </button>   
                                </Form>
                            </Formik>

                            : <CustomLoader />

                        }
                    </main>
                </div>
            </div>
        </div>
    )
}

export default EditUser