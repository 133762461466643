import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';
import SelectSearch from 'react-select-search';
import countries from '../../countries.json';
import states from '../../states.json';
// import 'react-select-search/style.css';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const Advertising = () => {
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url       = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/***************************************************************************************************************************/
    const [advertising, setAdvertising]   = useState(null);
    const [country, setcountry]           = useState('');
    const [_states, set_states]           = useState([]);
    const [state, setstate]               = useState([]);
    const [loading, setloading]           = useState(false);
    const [charge, setcharge]             = useState(false);
    const [notAvailable, setnotAvailable] = useState([]);
          //vals
    const [countryVal, setcountryVal] = useState(false);
    const [stateVal, setstateVal]     = useState(false);

    const {t, i18n} = useTranslation();
/***************************************************************************************************************************/
    useEffect(() => {
      
        (async () =>{
            const headers = await getHeaders();
            await axios.get(`${url}marketings/show`, {headers})
                        .then(res => {
                            setAdvertising(res.data.message)
                            console.log(res.data.message);
                        })
                        .catch(err =>{
                            console.log(err);
                        })
                        .then(() =>{
                            setcharge(true);
                        })
        })()

    }, [])
/***************************************************************************************************************************/

    const checkoutAdvertising = async (params) => {
        setloading(true);
        if(country.trim() === '' ){
            setcountryVal(true);
            setloading(false)
            return false;
        }else{
            setcountryVal(false);
        }
        
        if(state.length === 0){
            setstateVal(true);
            setloading(false)
            return false;
        }else{
            setstateVal(false);
        }
        params.states  = state;
        params.country = country;
        console.log(params)
        const headers = await getHeaders();
        await axios.post(`${url}marketings/advertising`, params, {headers})
                    .then(res => {
                        if(res.status === 200 && res.data.status === 'success'){
                            window.location.href = res.data.message;
                        }
                        console.log(res.data.message);
                    })
                    .catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                            title: err?.response?.data?.message || err,
                        });
                    })
                    .then(() =>{
                        setloading(false)
                    });                
    }
/***************************************************************************************************************************/

    const changeCountry = async(e) => { 
        setcountry(e);
        const fd = countries.find(e2 => {
            return e2.name === e &&
                e2                                                                        
        });

        const headers = await getHeaders();
        await axios.get(`${url}marketings/advertising/${e}`, {headers})
                    .then(res => {
                        const not_available = res?.data?.message;
                        setnotAvailable(not_available);
                        setstate('');
                        set_states(
                            states.filter(e3 => e3.country_id === fd.id && !not_available.includes(e3.name) )
                        )
                    })
                    .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });
                    })
                    .then(() =>{
                        setloading(false)
                    }); 
    }

/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        months: Yup.number().oneOf([3, 6, 12], t('marketing.advertising.create.only_values')).required(),
    });
/***************************************************************************************************************************/

    return (
        <>
            {
                charge 
                ?   <div className='container'>
                            <div className="card">
                                <div className="card-header">
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{ t('marketing.advertising.create.you_can')}</h5>
                                    <p className="card-text">{ t('marketing.advertising.create.select_a_country')}</p>
                                    <Formik
                                        initialValues={{ 
                                            months: 0,                                        
                                        }}
                                        validationSchema={formSchema}
                                        onSubmit={(values, actions) => checkoutAdvertising(values, actions)}
                                    >
                                        {({ values, setFieldValue  }) => (
                                        <Form>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-12 mb-1">
                                                            <SelectSearch 
                                                                options={countries}
                                                                value={country}
                                                                onChange={(e) => changeCountry(e)}
                                                                name="country" 
                                                                placeholder={ t('marketing.advertising.create.chose_your_country')}
                                                                search
                                                                autoComplete='off'
                                                            />
                                                            {
                                                                countryVal &&
                                                                <div name="country-val" className='field-error text-danger fw-bold text-capitalize'>
                                                                    <p>{ t('marketing.advertising.create.chose_your_country')}</p>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="col-12">
                                                            {
                                                                _states.length > 0 &&
                                                                <SelectSearch 
                                                                    options={_states}
                                                                    value={state}
                                                                    onChange={(e) => setstate(e)}
                                                                    name="state" 
                                                                    placeholder={ t('marketing.advertising.create.states_selected')}
                                                                    search
                                                                    multiple
                                                                    closeOnSelect
                                                                    autoComplete='off'
                                                                />                
                                                            }
                                                            {
                                                                stateVal &&
                                                                <div name="state-val" className='field-error text-danger fw-bold text-capitalize'>
                                                                    <p>{ t('marketing.advertising.create.select_a_state')}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>                                               

                                                <div className="col-md-6">
                                                    <span className="fw-bold">{ t('marketing.advertising.create.states_selected')}</span>
                                                    <ul className="">                                                    
                                                    {
                                                        state.length > 0 &&
                                                        state.map((e,i) =>{
                                                            return <li className="list-group-item" key={`state-${i}`}>{e}</li>
                                                        })
                                                    }
                                                    </ul>

                                                    <span className="fw-bold">{ t('marketing.advertising.create.states_not_available')}</span>
                                                    <ul className="">                                                    
                                                    {
                                                        notAvailable.length > 0 &&
                                                        notAvailable.map((e,i) =>{
                                                            return <li className="list-group-item text-danger" key={`not_available-${i}`}>{e}</li>
                                                        })
                                                    }
                                                    </ul>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <div className="">
                                                        <label>{ t('utilities.months')}</label>
                                                        <Field as="select" className="form-select text-capitalize" aria-label="Default select example" name="months">
                                                            <option className="text-capitalize" disabled value={0}>{ t('marketing.advertising.create.select_option')}</option>
                                                            {                                                            
                                                                [3,6,12].map((e,i) => <option key={`months-${i}`} value={e} className="text-capitalize">{e} { t('utilities.months')}</option> )
                                                            }                                                    
                                                        </Field>
                                                        <ErrorMessage name="months" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label>{ t('marketing.advertising.create.total_amount')}</label>                                                   
                                                    <div>
                                                        {
                                                            (advertising !== null && state.length > 0 && values.months !== 0) &&
                                                            <>
                                                                <b>
                                                                    ${((advertising.price * state.length) * values.months).toFixed(2)} USD
                                                                </b>
                                                            </>                                            
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-6">
                                                    <button className="btn btn-shadow btn-bd-primary"
                                                            type="submit"
                                                            role="submit"
                                                            disabled={loading}                                                  
                                                    >
                                                       <span className='me-3'>{ t('marketing.advertising.create.go_to_checkout')}</span>
                                                    {
                                                        loading &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    }
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>

                :   <div className="text-center w-100">
                        <div className="spinner-grow text-success text-center"  style={{width: '10rem', height: '10rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
            }
        </>
    )
}

export default Advertising