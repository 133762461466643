import React, {useEffect, useState, useContext} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon, faUsers, faHandshake, faCommentDollar, faBarcode, faMoneyBill, faBoxes, faKey, faDollarSign, faFile, faToiletPaper, faReceipt, faRectangleAd } from '@fortawesome/free-solid-svg-icons'
import Header from './Header'
import { Link } from "react-router-dom"
import jwt_decode from "jwt-decode";
import AuthContext from '../../middlewares/AuthContext';
import packageJson from '../../../package.json';
import { useTranslation } from 'react-i18next';

const SideBar = ({showNavbar, flip}) => {

    const { token }                     = useContext(AuthContext);
    const [tokenDecode, setTokenDecode] = useState(jwt_decode(token))
    const { t }                         = useTranslation();

    const darkMode = async() => {
		// value previously stored
		if (document.documentElement.getAttribute('data-bs-theme') === 'dark') {
			document.documentElement.setAttribute('data-bs-theme','light')
			await AsyncStorage.setItem('@dark_mode', 'light')
			setdarkmode(true)
		}
		else {
			document.documentElement.setAttribute('data-bs-theme','dark')
			await AsyncStorage.setItem('@dark_mode', 'dark')
			setdarkmode(false)
		}
	}

    const [darkmode, setdarkmode] = useState(false);

    return (
    <>
        <Header 
            showNavbar={showNavbar}
            flip={flip}
        />
        <div className="l-navbar shadow-lg" id="nav-bar">
            <nav className="nav">
                <div> 
                    <Link className="nav_logo" to="/dashboard">
						<img src='https://labeldictate.com/assets/img/Label-Dictate-Logo-V2021.png' width={80}/>
					</Link>
                    <div className="nav_list">                             
                        {
                            tokenDecode.roles.find(x => x.name === 'admin') &&
                            <>
                                <Link to="/users" className="nav_link w-100">
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faUsers}/>
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.users')}
                                        </span>                                          
                                    </div>
                                </Link> 

                                <Link to="/stores" className="nav_link w-100">
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faToiletPaper}/>
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.stores')}
                                        </span>                                         
                                    </div>
                                </Link> 

                                <Link to="/partners" className="nav_link ">
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faHandshake}/>
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.partners')}
                                        </span> 
                                    </div>                                    
                                </Link> 

                                <Link to="/marketings" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faCommentDollar}/>
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.marketing')}                                            
                                        </span>                                         
                                    </div>
                                </Link> 

                                <Link to="/products-plans" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faBarcode}/>
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.products_plans')}    
                                        </span>                                         
                                    </div>
                                </Link> 

                                <Link to="/payments" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faDollarSign}/>    
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.payments')}    
                                        </span>                                         
                                    </div>
                                </Link> 
                            </>
                        }
                        {
                            tokenDecode.roles.find(x => {return x.name === 'customer'}) &&
                            <>
                                <Link to="/payments/customer" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faFile}/>  
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {/* {t('sidebar.billing')} */}
                                            {t('sidebar.billing')}
                                        </span>                                         
                                    </div>
                                </Link> 
                            </>
                        }
                        {
                            tokenDecode.roles.find(x => {return x.name === 'partner'}) &&
                            <>
                                <Link to="/payments/partner" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faFile}/>                                   
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.billing')}
                                        </span> 
                                    </div>
                                </Link> 
                            </>
                        }
                        {
                            tokenDecode.roles.find(x => {return x.name === 'marketing'}) &&
                            <>
                                <Link to="/payments/marketing" className="nav_link ">                                
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faFile}/>                                      
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.billing')}
                                        </span> 
                                    </div>
                                </Link>

                                <Link to="/marketing/advertising/create" className="nav_link ">                                
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faRectangleAd}/>                                      
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.add_advertising')}
                                        </span> 
                                    </div>
                                </Link>
                            </>
                        }
                        {
                            tokenDecode.roles.find(x => {return x.name === 'partner' || x.name === 'admin' || x.name === 'subpartner'}) &&
                            <>
                                <Link to="/reports" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faReceipt}/>                                    
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.reports')}
                                        </span> 
                                    </div>
                                </Link> 

                                <Link to="/packages" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faBoxes}/>                                    
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.packages')}
                                        </span> 
                                    </div>
                                </Link> 

                                
                            </>
                        }
                        {
                            tokenDecode.roles.find(x => {return x.name === 'partner' || x.name === 'subpartner' || x.name === 'admin' || x.name === 'customer'}) &&
                            <>
                                <Link to="/licenses" className="nav_link ">                                    
                                    <div className='col-3'>
                                        <FontAwesomeIcon icon={faKey}/>                                      
                                    </div>
                                    <div className='col-9'>
                                        <span className="nav_name">
                                            {t('sidebar.licenses')}
                                        </span> 
                                    </div>
                                </Link> 
                            </>
                        }
                        
                    </div>
                </div> 
                <div className="">
                    <div className='flex flex-column align-items-start'> 
                        <div className='nav_link'>
                            <p className='m-0'>V{packageJson.version}</p>                
                        </div>
                        <div className='nav_link'>
                            <span href="#!" onClick={() => darkMode()} className="pointer link-white">
                                <FontAwesomeIcon icon={darkmode ? faMoon : faSun}/>
                            </span>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    </>

    )
}

export default SideBar