import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FAQS = () => {
    const {t} = useTranslation();
    const faqs = [
		{
			title: t("faqs.title1"),
			text0 : t("faqs.text1"),
		},
		{
			title: t("faqs.title2"),
			text0 : t("faqs.text2"),
            text1: t("faqs.text2_1")
		},
		{
			title: t("faqs.title3"),
			text0: t("faqs.text3"),
		},
		// {
		// 	title: t("faqs.title4"),
		// 	text0: t("faqs.text4"),
		// },
		{
			title: t("faqs.title5"),
			text0: t("faqs.text5"),
			text1: t("faqs.text5_1"),
			text2: t("faqs.text5_2"),
			text3: t("faqs.text5_3"),
			text4: t("faqs.text5_4"),
		},
        {
			title: t("faqs.title6"),
			text0: t("faqs.text6"),
		},
        {
			title: t("faqs.title7"),
			text0: t("faqs.text7"),
			text1: t("faqs.text7_1"),
			text2: t("faqs.text7_2"),
		},
        {
			title: t("faqs.title8"),
			text0: t("faqs.text8"),
		},
        {
            title : t("faqs.title9"),
            title1: t("faqs.title9_1"),
            title2: t("faqs.title9_2"),
            text0 : t("faqs.text9"),
            text1 : t("faqs.text9_1"),
            text2 : t("faqs.text9_2"),
            text3 : t("faqs.text9_3"),
            text4 : t("faqs.text9_4"),
            text5 : t("faqs.text9_5"),
            text6 : t("faqs.text9_6"),
            text7 : t("faqs.text9_7"),
            text8 : t("faqs.text9_8"),
            ul    : true,
            ul2   : true,
		},
        {
			title: t("faqs.title10"),
			text0: t("faqs.text10"),
			text1: t("faqs.text10_1"),
			text2: t("faqs.text10_2"),
		},
        {
			title: t("faqs.title11"),
			text0: t("faqs.text11"),
		},
        {
			title: t("faqs.title12"),
			text0: t("faqs.text12"),
			text1: t("faqs.text12_1"),
			text2: t("faqs.text12_2"),
			text3: t("faqs.text12_3"),
		},
		{
			title: t("faqs.title13"),
			text0: t("faqs.text13"),
		},
	]
  return (
    <section className='container-fluid pt-5 pb-3 border-bottom border-5 border-secondary bg-gradient-reverse'>
				<h3 className='text-center fw-bold display-5 mb-4'>{t('home.faqs')}</h3>
				<div className='row align-items-center px-5 text-dark-emphasis'>
					<div className="accordion border-2" id={`accordion`}>
						{
							faqs.map((e,i) =>{
								return <div className="accordion-item pb-2"  key={`sect-${i}`}>
											<h2 className="accordion-header" id={`heading${i}`}>
												<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>
													<span className='fw-bold me-2'>
														{e.title}
													</span> 
												</button>
											</h2>
											<div id={`collapse${i}`} className="accordion-collapse collapse w-100" aria-labelledby={`heading${i}`} data-bs-parent={`#accordion`}>
												<div className="accordion-body">
                                                        {
                                                            e?.ul 
                                                            ?    <>
                                                                    <p className=' text-justify'>{e?.text0}</p>
                                                                    <span className='fw-bold'>
                                                                        {e.title1}
                                                                    </span>
                                                                    <ul className='list-group list-group-flush mb-4  text-justify'>
                                                                       <li className="list-group-item border-0">{e?.text1}</li>
                                                                       <li className="list-group-item border-0">{e?.text2}</li>
                                                                       <li className="list-group-item border-0">{e?.text3}</li>                                                                        
                                                                    </ul>                                                  
                                                                    <span  className='fw-bold'>
                                                                        {e.title2}
                                                                    </span>
                                                                    <ul className='list-group list-group-flush  text-justify'>
                                                                       <li className="list-group-item border-0">{e?.text4}</li>
                                                                       <li className="list-group-item border-0">{e?.text5}</li>
                                                                       <li className="list-group-item border-0">{e?.text6}</li>
                                                                       <li className="list-group-item border-0">{e?.text7}</li>                                                                        
                                                                       <li className="list-group-item border-0">{e?.text8}</li>                                                                        
                                                                    </ul>                                                  
                                                                </>
                                                            :   [0,1,2,3,4,5,6,7,8,9,10].map((x,i) =>{ 
                                                                return <p key={`e-${i}`} className=' text-justify'>
                                                                    {e[`text${i}`]}
                                                                </p>
                                                            })
                                                        }
												</div>
											</div>
										</div>
							})
						}
						</div>      					
					</div>
					<div className='text-center mt-3'>
						<Link to="https://ayuda.labeldictate.com/" className='btn btn-lg btn-bd-primary fw-bold fs-3'>
							{t('home.help')}
						</Link>
					</div>
			</section>
  )
}

export default FAQS