import React, {useState, useContext} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import CustomLoader from '../layouts/CustomLoader';
import AuthContext from '../../middlewares/AuthContext';
import jwt_decode from 'jwt-decode';

const CreatePartner = () => {
/***************************************************************************************************************************/
    const {getHeaders, token} = useContext(AuthContext);
    const tokenDecode         = (token!==null) ? jwt_decode(token) : null
/***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
/*************************************************************************************************************************/
    const { t }                 = useTranslation();
    const [loading, setloading] = useState(false);
/***************************************************************************************************************************/

    const createPartner = async (params, { resetForm }) => {
        setloading(true);
        console.log(params);
        const headers = await getHeaders();
        await axios.post(`${url}partners`, params, {headers})
                   .then(res => {
                        Swal.fire({
                            icon: 'success',
                            title: res.data.response,
                        });
                        console.log(res);
                        resetForm();
                   })
                   .catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        });
                        console.log(err);
                   })
                   .then(() =>{
                        setloading(false);
                   });

        
    }

/***************************************************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        name          : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        description   : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        street_address: Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        city          : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        address_line_2: Yup.string().max(255, 'max 255 chars').trim(),
        zip_code      : Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                                    .min(3, 'min 3 digits')
                                    .max(10, 'max 10 digits').required('This is a field required'),
        state                 : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        country               : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        invoice_street_address: Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        invoice_address_line_2: Yup.string().max(255, 'max 255 chars').trim(),
        invoice_zip_code      : Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                                            .min(3, 'min 3 digits')
                                            .max(10, 'max 10 digits').required('This is a field required'),
        invoice_state  : Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        invoice_country: Yup.string().min(3, 'min 3 chars').max(255, 'max 255 chars').trim().required('This is a field required'),
        invoice_email  : Yup.string().trim().email().required('This is a field required'),
        discount       : Yup.number().typeError('Need a number').min(1, 'Min 1').max(99, 'max 99').required('This is a field required'),
    });
  return (
    <div className='container mb-5'>
        <div className='card'>
            <div className='card-header'>
                <h1 className="h5 fw-bold justify-content-center d-flex align-items-center">
                    <FontAwesomeIcon icon={faHandshake} className="me-2"/>
                    <span className='mb-0'>{t('partners.create.partner_creation')}</span>
                </h1>
            </div>
            <div className='card-body'>
                <Formik
                    initialValues={{ 
                        name                  : '',
                        description           : '',
                        street_address        : '',
                        address_line_2        : '',
                        city                  : '',
                        zip_code              : '',
                        state                 : '',
                        country               : '',
                        invoice_street_address: '',
                        invoice_address_line_2: '',
                        invoice_city          : '',
                        invoice_zip_code      : '',
                        invoice_state         : '',
                        invoice_country       : '',
                        invoice_email         : '',
                        discount              : 0,
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, actions) => createPartner(values, actions)}
                >
                    <Form>
                        <div className='row'>
                            <div className="col-12 mb-3">
                                <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow-sm'> 
                                    <div className="row">
                                        <div className="col-md-5 col-lg-5 mb-2">
                                            <span className="h5 fw-bold text-uppercase justify-content-center d-flex align-items-center">
                                                <span className='mb-0'>{t('partners.name')}</span>
                                            </span>
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="name" placeholder="name" name="name"/>
                                                <label htmlFor="name">{t('partners.name')} (*)</label>
                                            </div>
                                            <ErrorMessage name="name" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="col-md-7 col-lg-7 mb-2">
                                            <span className="h5 fw-bold text-uppercase justify-content-center d-flex align-items-center">
                                                <span className='mb-0'>{t('partners.create.description')}</span>
                                            </span>
                                            <div className="form-floating">
                                                <Field style={{height: '100px'}} as="textarea" type="text" className="form-control" id="description" placeholder="name@example.com" name="description"></Field>
                                                <label htmlFor="description">{t('partners.create.description')} (*)</label>
                                            </div>
                                            <ErrorMessage name="description" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>

                                        <div className="col-md-5 col-lg-5 mb-2">
                                            <span className="h5 fw-bold text-uppercase justify-content-center d-flex align-items-center">
                                                <span className='mb-0'>{t('partners.products_discount')}</span>
                                            </span>
                                            <div className="form-floating">
                                                <Field type="text" className="form-control" id="discount" placeholder="discount" name="discount"/>
                                                <label htmlFor="name">{t('partners.create.discount')} (*)</label>
                                            </div>
                                            <ErrorMessage name="discount" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                        </div>
                                    </div>
                                </main>
                            </div>

                            <div className='col-md-6'>
                                <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                
                                    <span className="h5 fw-bold text-uppercase justify-content-center d-flex align-items-center">
                                        {/* <FontAwesomeIcon icon={faHandshake} className="me-2"/> */}
                                        <span className='mb-0'>{t('partners.address')}</span>
                                    </span>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="street_address" placeholder="street address" name="street_address"/>
                                            <label htmlFor="street_address">{t('partners.address')} (*)</label>
                                        </div>
                                        <ErrorMessage name="street_address" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>


                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="address_line_2" placeholder="address line 2" name="address_line_2"/>
                                            <label htmlFor="address_line_2">{t('partners.create.address_line')}</label>
                                        </div>
                                        <ErrorMessage name="address_line_2" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="city" placeholder="city" name="city"/>
                                            <label htmlFor="city">{t('partners.create.city')} (*)</label>
                                        </div>
                                        <ErrorMessage name="city" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="zip_code" placeholder="zip_code" name="zip_code"/>
                                            <label htmlFor="zip_code">{t('partners.create.zip')} (*)</label>
                                        </div>
                                        <ErrorMessage name="zip_code" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="state" placeholder="state" name="state"/>
                                            <label htmlFor="state">{t('partners.create.state')} (*)</label>
                                        </div>
                                        <ErrorMessage name="state" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="country" placeholder="country" name="country"/>
                                            <label htmlFor="country">{t('partners.create.country')} (*)</label>
                                        </div>
                                        <ErrorMessage name="country" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>                                    

                                </main>
                            </div>

                            {/* second form  */}

                            <div className='col-md-6'>
                                <main className='p-4 border rounded bs-dark-border-subtle text-dark-emphasis bg-secondary-subtle shadow'>                                                                

                                    <span className="h5 fw-bold text-uppercase justify-content-center d-flex align-items-center">
                                        {/* <FontAwesomeIcon icon={faHandshake} className="me-2"/> */}
                                        <span className='mb-0'>{t('partners.create.street_address_inv')}</span>
                                    </span>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_street_address" placeholder="invoice street address" name="invoice_street_address"/>
                                            <label htmlFor="invoice_street_address">{t('partners.create.street_address_inv')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_street_address" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>


                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_address_line_2" placeholder="address line 2" name="invoice_address_line_2"/>
                                            <label htmlFor="invoice_address_line_2">{t('partners.create.address_line_inv')}</label>
                                        </div>
                                        <ErrorMessage name="invoice_address_line_2" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_city" placeholder="invoice city" name="invoice_city"/>
                                            <label htmlFor="invoice_city">{t('partners.create.city_inv')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_city" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_zip_code" placeholder="invoice zip code" name="invoice_zip_code"/>
                                            <label htmlFor="invoice_zip_code">{t('partners.create.zip')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_zip_code" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_state" placeholder="invoice state" name="invoice_state"/>
                                            <label htmlFor="invoice_state">{t('partners.create.state')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_state" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="text" className="form-control" id="invoice_country" placeholder="invoice country" name="invoice_country"/>
                                            <label htmlFor="invoice_country">{t('partners.create.country_inv')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_country" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-floating">
                                            <Field type="email" className="form-control" id="invoice_email" placeholder="invoice email" name="invoice_email"/>
                                            <label htmlFor="invoice_email">{t('partners.create.email_inv')} (*)</label>
                                        </div>
                                        <ErrorMessage name="invoice_email" component="div" className='field-error text-danger fw-bold text-capitalize'/>
                                    </div>
                                    
                                </main>
                            </div>
                            <div className="col-md-12 mt-4">
                                <button 
                                    className="w-100 btn btn-lg btn-bd-primary btn-shadow mb-3" 
                                    type="submit"
                                    disabled={loading}
                                ><span className='me-3 fw-bold'>
                                    {t('partners.create.create_partner')}
                                </span>
                                {
                                    loading &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                </button> 
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    </div>
  )
}

export default CreatePartner